.fieldGroup {
  position: relative;
  font-size: 1.4rem;
  margin-bottom: 20px;

  @media (max-width: 750px) {
    margin-bottom: 10px;
  }
}

.fieldGroup__label {
  position: relative;
  min-height: 1px;
  color: #2a2a2a;
  text-align: left;
  max-width: calc(100% - 25px);
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0 0 7px;
  line-height: 1.82rem;
}

.fieldGroup__helpIcon {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 0 0 4px;
  background-image: url('../../../styles/icons/help-icon.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  cursor: pointer;
}

.fieldGroup__icon {
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  right: 2px;
  top: 0;
  font-size: 20px;
}

.fieldGroup__tickIcon {
  color: #6bc348;
}

.fieldGroup__crossIcon {
  color: #e66c6c;
}

.fieldGroup__inputContainer {
}

.fieldGroup__errorMessage {
  color: #e66c6c;
  font-weight: 600;
}
