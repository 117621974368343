.Vrm {
  width: 100px;
  padding: 0 0 5px;
  position: relative;
  margin-top: 10px;
}

.Vrm__reg {
  border-radius: 1px;
  background-color: #fff36b;
  width: 100%;
  height: 23px;
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  position: relative;
  text-transform: uppercase;
  padding: 3px 0 0 7px;
  color: #000000;
}

.Vrm__regBlue {
  width: 10px;
  height: 100%;
  background: #4d8df5;
  position: absolute;
  left: 0;
  top: 0;
}
