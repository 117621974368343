.starRating__container {
  display: flex;
}
.starRating__star {
  margin: 0 1px;
  color: #c4c4c4;
}
.starRating__star--fill {
  color: #ffd700;
  margin: 0 1px;
}