@import '../../../styles/themes/themes.scss';

.quotesListCardCheckbox {
  width: fit-content;
  text-align: center;
  margin: 0 auto 10px;
  padding: 10px;
  border-radius: 0 0 3px 3px;
  border-left: solid #dedede 1px;
  border-right: solid #dedede 1px;
  border-bottom: solid #dedede 1px;
  transition: border-color 200ms ease-in;
}

.quotesListCardCheckbox--checked {
  border-left: solid 1px #6bc348;
  border-right: solid 1px #6bc348;
  border-bottom: solid 1px #6bc348;
  transition: border-color 200ms ease-in;
}

.quotesListCardCheckbox__text {
  vertical-align: top;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 400;
  color: #adadad;
  text-align: center;
  margin: 0 10px 5px 0;
  text-transform: uppercase;
}

.quotesListCardCheckbox__checkbox {
  display: inline-block;
}
