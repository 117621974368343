.cont {
  width: 100%;
  vertical-align: middle;
  height: 40px;
  position: relative;

  &:hover {
    > label {
      border-right: 1px solid #a8a8a8;
    }

    > input {
      border: 1px solid #a8a8a8;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
    }
  }

  &:focus-within {
    > label {
      border-right: 1px solid #055bd4;
    }

    > input {
      border: 1px solid #055bd4;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
    }
  }
}

.input {
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  margin: 0;
  border: 1px solid #e4ebef;
  border-radius: 4px;
  background-color: #fff;
  font-size: 1.4rem;
  outline: none;
  background-position: right;
  background-repeat: no-repeat;
  transition: 0.4s ease;

  &.input--disabled {
    background-color: #eaeaea;
    pointer-events: none;
  }

  &:where(.input--valid) {
    border: 1px solid #6bc348;
  }

  &:where(.input--invalid) {
    border: 1px solid #e66c6c;
  }
}

.input--small {
  height: 30px;
  padding: 0 10px 0 40px;
}

.numberPadding {
  padding: 10px;
}
.currencyPadding {
  padding: 10px 10px 10px 40px;
}
.label {
  position: absolute;
  left: 2px;
  top: 2px;
  height: 36px;
  border-right: 1px solid #e4ebef;
  border-radius: 4px 0px 0px 4px;
  background-color: #f9fafc;
  padding: 0 10px;
  text-align: center;
  vertical-align: middle;
  line-height: 34px;
  font-size: 1.96rem;

  &.hover {
    border-right: 1px solid #a8a8a8;
  }

  &:focus-within {
    border-right: 1px solid #055bd4;
  }

  &:where(.label--valid) {
    border-right: 1px solid #6bc348;
  }

  &:where(.label--invalid) {
    border-right: 1px solid #e66c6c;
  }
}

.label--small {
  height: 26px;
  line-height: 26px;
  font-size: 16px;
}
