@import '../../../styles/themes/themes.scss';

.applicationNavigationItem {
  display: block;
  height: 100px;
  box-shadow: 0px 6px 20px rgba(188, 194, 204, 0.34);
  transform: translateX(0);
  background: white;
  position: relative;
  transition: 0.2s all ease-out;
  opacity: 0.6;
  border-radius: 12px;
  margin-bottom: 12px;

  &:hover {
    opacity: 0.9;
    transform: translateX(4px);
  }

  @media (max-width: 1000px) {
    float: left;
    height: 150px;
    padding: 0 10px;
  }

  @media (max-width: 600px) {
    height: 125px;
  }

  @media (max-width: 500px) {
    height: 80px;
    padding: 0;
  }
}

.applicationNavigationItem__active {
  background: #fff;
  box-shadow: 0px 20px 30px rgba(188, 194, 204, 0.6);
  transform: translateX(4px);
  padding: 0;
  opacity: 1;
  z-index: 2;
}

.applicationNavigationItem__inner {
  overflow: hidden;
  width: 100%;
}

.applicationNavigationItem__link {
  display: flex;
  text-decoration: none;
  outline: 0;
  cursor: pointer;

  @media (max-width: 1000px) {
    display: block;
  }
}

.applicationNavigationItem__imageOuter {
  display: flex;
  align-items: center;
}

.applicationNavigationItem__image {
  width: 60px;
  height: 60px;
  margin: 10px 0px 10px 0px;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5e6977;

  @media (max-width: 1000px) {
    width: 100%;
    margin-bottom: 0;
  }

  @media (max-width: 500px) {
    width: 100%;
    margin: 8px auto;
  }
}

.applicationNavigationItem__text {
  color: black;
  display: flex;
  align-items: center;
  margin: 12px 0 0 0;
  font-weight: bold;
  height: 38px;

  @media (max-width: 1000px) {
    font-size: 1.2rem;
    text-align: center;
    margin: auto;
    height: 34px;
    justify-content: center;
  }
  @media (max-width: 850px) {
    font-size: 1.1rem;
    text-align: center;
    justify-content: center;
  }
  @media (max-width: 500px) {
    display: none;
  }
}

.applicationNavigationItem__borderRightActive {
  width: 3px;
  height: 88px;
  position: absolute;
  top: 0;
  right: -2px;
  z-index: 2000;
  background-color: white;

  @media (max-width: 1000px) {
    height: 3px;
    width: 100%;
    bottom: -2px;
    top: auto;
    right: 0;
  }
}
