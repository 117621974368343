.groupLead__leads {
  padding: 5px 10px;
  background-color: #eaeaea;
  display: inline-block;
}

.groupLead__dealerName {
  font-weight: bold;
  color: #4c4c4c;
  font-size: 12.5px;
}

.groupLead__createdSource {
  font-weight: lighter;
  color: #4c4c4c;
  font-size: 12.5px;
}
