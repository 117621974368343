.editVapsForm__section {
  padding: 0 0 10px 0;
}

.editVapsForm__valueAddedProducts {
  text-align: center;
  padding: 0 0 10px 0;
}

.editVapsForm__noVaps {
  font-size: 1.4rem;
  line-height: 1.6;
}

.editVapsForm__addVapButton {
  display: inline-block;
  vertical-align: middle;
  margin: 10px 0 0 0;
}

.editVapsForm__termText {
  margin: 0px auto 20px;
  padding: 0 0 0 20px;
  font-size: 1.4rem;
  text-align: center;

  @media (max-width: 768px) {
    padding: 0;
  }
}
