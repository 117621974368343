@media print {
  .printSummary .quotePanel {
    padding: 10px 22px 0 22px;
  }
  .printSummary .summaryTable {
    width: 100%;
  }

  .printSummary .summaryTable__th {
    text-align: left;
    font-weight: 400;
    font-size: 11px;
    color: #4a4a4a;
  }

  .printSummary .summaryTable__td {
    text-align: right;
    font-weight: normal;
    font-size: 11px;
    color: #4a4a4a;
  }
}
