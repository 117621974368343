.organisationContactsPanel {
  padding: 20px;
  overflow: auto;
}

.organisationContactsPanel__header {
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0 0 10px 0;
}

.organisationContactsPanel__address {
  padding: 10px 0 0 0;
  clear: both;
}
