.container {
  border-radius: 9px 9px 0px 0px;
  min-height: max-content;
  height: 100%;
}

.chartFilter {
  display: flex;
  justify-content: flex-end;
}

.chartWrapper {
  height: 400px;
}

