.quoteSmallPrint__outer {
  position: relative;
  max-height: 500px;
  padding-top: 10px;
  padding-bottom: 0.5em;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
}

.quoteSmallPrint__outer--hidden {
  max-height: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
}

.quoteSmallPrint {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.4;
  transition: 0.3s;
}

.quoteSmallPrint__finance {
  color: #666666;
  padding: 5px 0 0 0;
}

.quoteSmallPrint_buttonWrap {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 100%;
  width: 100%;
  z-index: 0;
  @media (max-width: 1166px) {
    margin-top: 1em;
    text-align: right;
    justify-content: flex-end;
    width: 50%;
    right: 0;
  }
  @media (max-width: 460px) {
    width: 100%;
    justify-content: center;
    position: relative;
    text-align: center;
    margin: 0;
    margin-top: 10px;
  }
}

.quotesSmallPrint__newCarOfferText {
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: #666666;
}
