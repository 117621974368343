.multiLoansDetails {
  padding-bottom: 20px;
  gap: 20px;
}
.pushDealQuotePreview {
  &__Container {
    position: relative;
    padding-top: 10px;
    margin-bottom: 1rem;
    width: 100%;
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    box-shadow: 0 5px 15px rgba(188, 194, 208, 0.4);
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__showMore,
  &__showLess {
    display: flex;
    width: 100%;
    background: #fff;
    border-bottom: 0;
    align-items: center;
    overflow: hidden;
    flex-direction: column;
    transition: max-height 0.4s ease;
  }

  &__showMore {
    max-height: 180px;
    border-radius: 6px;
    @media screen and (max-width: 768px) {
      max-height: 190px;
    }
    @media screen and (max-width: 425px) {
      max-height: 225px;
      padding: 20px 10px;
    }
  }

  &__quoteSmallPrint__finance {
    padding: 16px;
    font-size: 1.2rem;
    font-weight: 500;
    color: #707070;
    max-width: 100%;
    background: #f8f9fc;
    margin: 0;
  }

  &__header {
    font-size: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 425px) {
      flex-direction: column;
      margin: 0 0 20px 0;
      min-height: 75px;
    }
  }

  &__subtitle {
    font-weight: 500;
    color: #707070;
    > span:nth-child(2) {
      display: block;
      font-size: 0.75em;
    }
  }

  &__value {
    text-align: right;
  }

  &__section,
  &__modalSection,
  &__totalSection {
    display: flex;
    width: 100%;
    padding: 10px 20px;
    min-height: 40px;
    justify-content: space-between;
    align-items: center;
    color: #464646;
    font-size: 15px;
    font-weight: 600;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    @media screen and (max-width: 425px) {
      padding: 5px 10px;
    }
  }

  &__modalSection {
    font-weight: 500;
  }

  &__totalSection {
    font-weight: 700;
  }

  &__modalDivider {
    border: 1px solid black;
    width: 95%;
    margin: 5px auto;
  }

  &__section--logo {
    min-height: 60px;
    @media screen and (max-width: 425px) {
      min-height: 80px;
    }
  }

  &__highlightedValue {
    color: #6bc348;
  }
}
