@import '../../styles/themes/themes.scss';

.imageButton {
  background-color: transparent;
  padding: 0px;
  border: none;
  display: inline-block;
  cursor: pointer;
  outline: none;
  color: #055bd4;

  &:hover {
    color: darken(#055bd4, 12%);
  }
}
