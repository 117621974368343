.commentsListItem__container {
  margin: 0 0 30px 0;
  display: flex;
}

.commentsListItem__initialsContainer {
  margin: 0 20px 0 0;
  width: 45px;
  height: 45px;
}

.commentsListItem__initials {
  font-size: 2rem;
  font-weight: bold;
  border: solid #9e9e9e 1px;
  border-radius: 2px;
  color: #9e9e9e;
  padding: 8px 0;
  text-align: center;
  width: 45px;
  height: 45px;
}

.commentsListItem__main {
  font-size: 1.4rem;
  font-weight: bold;
  width: calc(100% - 65px);
}

.commentsListItem__top {
  overflow: auto;
}
.commentsListItem__userName {
  float: left;
  margin: 0 10px 0 0;
}

.commentsListItem__dot {
  float: left;
  margin: -5px 10px 0 0;
  font-size: 2rem;
  font-weight: bold;
}

.commentsListItem__date {
  color: #a3a3a3;
}

.commentsListItem__messageContainer {
  color: #4f4f4f;
}

.commentsListItem__messageBody {
  margin: 10px 0;
}
