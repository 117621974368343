.heatMap__outer {
  width: 100%;
}

.heatMap__mapOuter {
  height: 400px;
}

.heatMap__map {
  height: 400px;
}

.heatMap__infoOuter {
  max-width: 500px;
  overflow: hidden;
  margin: 0 auto;
}

.heatMap__infoOuterLeft {
  float: left;
  width: 110px;
  padding: 20px;
  font-size: 60px;
}

.heatMap__infoOuterRight {
  padding: 20px;
  font-size: 1.8rem;
}

.heatMap__mileage {
  font-size: 3rem;
}
