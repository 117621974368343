.tradingNameLookup__message {
  margin: 0 0 10px;
  padding: 0 0 20px;
  border-bottom: 1px solid #ddd;
}

.tradingNameLookup__actions {
  text-align: right;
}

.tradingNameLookup__tooltip {
  top: 68px;
}
