.lenderUpdatesPanelItem {
  padding: 14px;
  cursor: pointer;
  background: white;
  border-radius: 12px;
  border: 1px solid #e9e9e9;
  box-shadow: 0 2px 4px rgba(154, 192, 216, 0.16);
  margin-bottom: 20px;
  transform: translateY(0);
  transition: all 0.2s ease-out;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(188, 194, 208, 0.4);
  }

  &:first-child {
    margin-top: 20px;
  }
}

.lenderUpdatesPanelItem__selfServiceTag {
  padding: 10px 0 5px 0;
  min-width: 110px;

  @media (max-width: 650px) {
    position: absolute;
    top: -20px;
  }
}

.lenderUpdatesPanelItem__inner {
  padding: 0 0 15px 0;
}

.lenderUpdatesPanelItem__borderBottom {
}

.lenderUpdatesPanelItem__topSection {
  overflow: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.lenderUpdatesPanelItem__status {
  overflow: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.lenderUpdatesPanelItem__lenderStatus {
  margin-bottom: 3px;
}

.lenderUpdatesPanelItem__date {
  font-size: 1.2rem;
  color: #b7b7b7;
  margin: 3px 8px;
}

.lenderUpdatesPanelItem__funderLogo {
  width: 100px;
  height: auto;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  & img {
    height: 34px;
    object-fit: cover;
  }
}
.lenderUpdatesPanelItem__business {
  padding-bottom: 1.5px;
}
.lenderUpdatesPanelItem__mainSection {
  font-size: 1.4rem;
  color: #4c4c4c;
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 650px) {
    flex-wrap: wrap;
  }
}

.lenderUpdatesPanelItem__mainSectionInner {
  @media (max-width: 650px) {
    width: 100%;
  }
}

.lenderUpdatesPanelItem__details {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.lenderUpdatesPanelItem__name {
  font-weight: bold;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.lenderUpdatesPanelItem__vehicle {
  font-size: 1.3rem;
  clear: both;
}

.lenderUpdatesPanelItem__ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
  margin-right: 6px;
}

.lenderUpdatesPanelItem__derivative {
  font-size: 1rem;
  margin-top: 2px;
}
