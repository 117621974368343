$breakpoint-mobileSm: 400px;
$breakpoint-mobileXl: 600px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

.orderSummaryActivityContainer {
  display: flex;
  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }
}

.orderSummary__header {
  justify-content: space-between;
  display: flex;
  align-items: center;
  white-space: nowrap;
  @media (max-width: $breakpoint-desktop) {
    flex-direction: row;
    align-items: center;
  }
  @media (max-width: 970px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.orderSummary__headerButton {
  width: 290px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 970px) {
    width: 100%;
    justify-content: space-between;
    margin: 0 0 10px 0;
  }
}

.orderSummary__headerAlert {
  margin-bottom: 20px;
}

.orderSummary__customerDetails {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 0 25px;
  border-radius: 10px;
  box-shadow: 0px 6px 20px rgba(188, 194, 208, 0.2);
  font-size: 1.4rem;
  @media (max-width: $breakpoint-desktop) {
    flex-direction: column;
  }
}

.orderSummary__dealSummary {
  width: 350px;
  margin: 0 20px 0 0;
  .panel__outer {
    margin: 0 0 20px;
  }
  @media (max-width: $breakpoint-tablet) {
    margin: 0;
    width: 100%;
  }
}

.orderSummary__assignContainer {
  height: 40px;
  width: 165px;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  user-select: none;

  .orderSummary__assignIcon:hover + .orderSummary__assignLabel,
  .orderSummary__assignLabel:hover {
    width: 110px;
  }

  .orderSummary__assignLabel {
    width: 1px;
    height: 28px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 4px;
    box-sizing: border-box;
    position: relative;
    right: -15px;
    margin-left: auto;
    border: 1px solid #bcc2d0;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(188, 194, 208, 0.34);
    white-space: nowrap;
    overflow: hidden;
    font-size: 1.4rem;
    transition: width 0.6s ease-in-out;
    cursor: pointer;
  }

  .orderSummary__assignLabel--mobile {
    @extend .orderSummary__assignLabel;
    width: 110px;
    transition: none;
  }

  .orderSummary__assignIcon {
    min-width: 40px;
    height: 40px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: 0 15px 0 0;
    background: #f2f3f6;
    border: 1px solid #979aa6;
    border-radius: 50%;
    font-size: 30px;
    cursor: pointer;
    transition: 0.5s background-color;
    &:hover {
      background: #ffffff;
      color: #64bc46;
      border: 1px solid #64bc46;
      transition: 0.5s background-color;
    }
  }
}
.orderSummary__icon {
  font-size: 30px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 0 15px 0 0;
  text-decoration: none;
  cursor: pointer;
}

.orderSummary__assignHiddenContent {
  width: 100%;
  padding: 0 10px 0 5px;
  margin-right: 5px;
  overflow: hidden;
  font-family: 'Open Sans';
  text-align: center;
  white-space: nowrap;
}

.orderSummary__customerDetails_Item {
  height: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0px 10px 0;
  padding: 0 8px;
  color: rgba(48, 54, 77, 0.6);
  font-size: 1.2rem;
  flex-basis: auto;
  @media (max-width: $breakpoint-desktop) {
    padding: 0 8px 0 50px;
    margin: 5px 0px;
  }
}

.orderSummary__container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  @media (max-width: $breakpoint-desktop) {
    margin-left: 0px;
    justify-content: flex-start;
  }
}

.orderSummary__customerDetails_name {
  @extend .orderSummary__customerDetails_Item;
  text-transform: capitalize;
  padding: 0 15px 0 0;
  font-weight: 700;
  color: #000;
}

.orderSummary__customerDetails_phoneNumber {
  @extend .orderSummary__customerDetails_Item;
  color: rgba(48, 54, 77, 0.6);
  font-size: 1.2rem;

  &:hover {
    text-decoration: underline;
  }
}
.orderSummary__customerDetails_Link {
  color: rgba(48, 54, 77, 0.6);
}
.orderSummary__customerDetails_email {
  @extend .orderSummary__customerDetails_Item;
  word-break: break-all;
  font-size: 1.2rem;
}

.orderSummary__customerDetails_emailLink {
  color: rgba(48, 54, 77, 0.6);
  &:hover {
    text-decoration: underline;
  }
}

.orderSummary__customerDetails_address {
  @extend .orderSummary__customerDetails_Item;
  text-transform: capitalize;
  @media (max-width: $breakpoint-desktop) {
    padding: 10px 8px 30px 50px;
  }
}

.orderSummary__customerDetails_link {
  text-decoration: underline;
  cursor: pointer;
}

.orderSummary__divider {
  height: 25px;
  border-left: 1px solid #eaeaea;
}

.orderSummary__activityTimeline {
  margin: 30px 0 0;
  width: 100%;
  display: inline-block;
  @media (max-width: 700px) {
    margin: 30px 0 0;
  }
}

.orderSummary__dealActivity {
  width: calc(100% - 350px - 20px);
  @media (max-width: 900px) {
    width: 100%;
  }
}

.orderSummary__activity {
  padding: 0 20 3px;
}

.orderSummary__viewApplicationButton {
  margin: 20px 0px 0px 0px;
}

.orderSummary__printButton {
  width: 200px;
  margin-bottom: 30px;
  float: right;
}

.orderSummary__activityTimelineList {
  margin: 0px;
  padding: 0 0 0 15px;
}

.orderSummary__activityTimelineItem {
  font-size: 1.2rem;
  padding: 10px 0 0;
}
