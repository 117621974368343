.latestDealsCustomerInfo {
  display: flex;
}

.latestDealsCustomerInfo__vehicleImage {
  border: solid 1px #c4c4c4;
  border-radius: 3px;
  overflow: hidden;
  background-color: white;
  margin: 0 10px 0 0;
  width: 70px;
  height: 50px;
}

.latestDealsCustomerInfo__left {
  width: calc(100% - 120px);
}

.latestDealsCustomerInfo__name {
  font-size: 1.4rem;
  font-weight: bold;
  color: #000000;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.latestDealsCustomerInfo__tradingName {
  float: left;
  margin: 0 5px 0 0;
}

.latestDealsCustomerInfo__vehicle {
  overflow: auto;
  display: inline-table;
  margin: 5px 0 0 0;
}

.latestDealsCustomerInfo__vrm {
  width: 100px;
  margin: 0 10px 0 0;
  font-size: 1.4rem;
  letter-spacing: 0.5px;
}

.latestDealsCustomerInfo__vehicleName {
  font-size: 1.4rem;
  font-weight: bold;
  color: #808080;
  display: table-cell;
  vertical-align: middle;

  @media (max-width: 768px) {
    display: block;
    margin: 5px 0 0 0;
  }
}
