.applicationCard {
  border-radius: 3px;
  padding: 5px;
  position: relative;
}

.applicationCard__sectionHeader {
  font-size: 1.4rem;
  text-decoration: underline;
  margin: 0 5px 0 5px;
}

.applicationCard__required {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: #ff6003;
  color: white;
  padding: 3px 5px;
}

.applicationCard__inner {
  overflow: hidden;
}

.applicationCard__address {
  min-height: 80px;
  padding: 10px 0 10px 5px;
  font-size: 1.4rem;
  width: 35%;
  overflow: auto;
  float: left;
  border-right: solid #d8d8da 1px;

  @media (max-width: 700px) {
    float: none;
    width: 100%;
    border-right: none;
    border-bottom: solid #d8d8da 1px;
  }
}

.applicationCard__right {
  min-height: 80px;
  width: 65%;
  float: right;
  display: table;
  vertical-align: middle;
  position: relative;

  @media (max-width: 700px) {
    margin: 5px 0 0 0;
    width: 100%;
  }
}

.applicationCard__time {
  min-height: 80px;
  display: table-cell;
  vertical-align: middle;
  width: 50%;
  text-align: center;
  font-size: 1.4rem;
  padding: 12px 0;
}

.applicationCard__number {
  font-size: 2.5rem;
}

.applicationCard__noTime {
  padding: 20px 0 0 0;
}

.applicationCard__divider {
  width: 1px;
  position: absolute;
  background: #d8d8da;
  top: 0;
  bottom: 0;
  left: 50%;
}

.applicationCard__buttonContainer {
  width: 50%;
  display: table-cell;
  vertical-align: middle;
  padding: 0 10px 0 0;

  @media (max-width: 1190px) {
    margin: 0;
  }
}

.applicationCard__button {
  float: right;
  width: 100px;
  margin: 0 0 0 0;
  display: block;
}

.applicationCard__removeButton {
  @media (max-width: 1190px) {
    clear: both;
  }
}

.applicationCard__editButton {
  display: block;
  margin: 0 10px 0 0;

  @media (max-width: 1190px) {
    margin: 10px 0 0 0;
    clear: both;
  }
}
