@import '../../styles/webshops-styles.scss';

.panelSection {
  max-width: 630px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px 0px;
  margin-bottom: 32px;
  border-bottom: $border;

  @media (min-width: 450px) {
    border: $border;
    border-radius: 10px;
    padding: 32px 42px 16px;
  }
}

.panelSection__heading {
  font-family: 'Montserrat', 'Open Sans', Arial, sans-serif;
  text-align: left;
  font-weight: bold;
  margin-bottom: 32px;
  margin-top: 0;
  font-size: 18px;
}

.panelSection--grayStyle {
  background: #fcfcfc;
}
