.visQuotesPage {
  max-width: 800px;
  padding: 0 20px;
  margin: 0 auto;
}
.visQuotesPage__searchBar {
  width: 100%;
  margin: 0 auto;
  padding: 0 0 30px 0;
  animation: show-search 0.6s;
}
.visQuotesPage__searchResults {
  max-width: 1000px;
  margin: 0 auto;
}
.visQuotesPage__footer {
  border-top: 1px solid #e4ebef;
  padding: 20px 0;
  margin: 30px 0 0 0;
  display: flex;
  justify-content: space-between;
}
.visQuotesPage__button {
  min-width: 160px;
}
.visQuotesPage__searchStatus {
  text-align: center;
  font-size: 14px;
  padding: 15px 0 0 0;
  &--error {
    color: #ff6600;
  }
}
.visQuotesPage__noResults {
  text-align: center;
  font-size: 14px;
  padding: 15px 0 0 0;
}

.visQuotesPage__searchResultCards {
  max-height: 900px;
  margin-bottom: 25px;
  animation: select-vehicle 2s;
  @media (max-width: 800px) {
    max-height: 1140px;
  }
}
.visQuotesPage__resultCard {
  animation: vehicle-card 0.5s;
}
.visQuotesPage__manualSwitch {
  font-size: 13px;
  padding: 10px 0 0 0;
  text-align: right;
}
.visQuotesPage__manualSwitch--link {
  color: #0080ff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
@keyframes select-vehicle {
  0% {
    opacity: 0;
    min-height: 130px;
  }
  33% {
    opacity: 0;
    min-height: 870px;
  }
  66% {
    opacity: 1;
    min-height: 870px;
  }
  100% {
    opacity: 1;
    max-height: 870px;
    min-height: 0;
  }
}
@keyframes vehicle-card {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes show-search {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
