// Montserrat
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('../fonts/montserrat/Montserrat-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}

// Open sans

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Light'), local('OpenSans-Light'), url('../fonts/open-sans/OpenSans-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
    url('../fonts/open-sans/OpenSans-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('../fonts/open-sans/OpenSans-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
    url('../fonts/open-sans/OpenSans-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Medium'), local('OpenSans-Medium'),
    url('../fonts/open-sans/OpenSans-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Medium Italic'), local('OpenSans-MediumItalic'),
    url('../fonts/open-sans/OpenSans-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/open-sans/OpenSans-SemiBold.woff2') format('woff2'),
    url('../fonts/open-sans/OpenSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
    url('../fonts/open-sans/OpenSans-SemiBoldItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url('../fonts/open-sans/OpenSans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
    url('../fonts/open-sans/OpenSans-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
    url('../fonts/open-sans/OpenSans-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'),
    url('../fonts/open-sans/OpenSans-ExtraBoldItalic.woff2') format('woff2');
  font-weight: 800;
  font-style: italic;
  font-display: fallback;
}
