.vrmPlate__outer {
  display: inline-flex;
  height: 35px;
  overflow: hidden;
  width: 100%;
}

.vrmPlate__blue {
  width: 20px;
  height: 100%;
  background-color: #4e91f5;
}

.vrmPlate__yellow {
  font-size: 1.9rem;
  font-weight: bold;
  padding: 0 10px;
  min-width: calc(100% - 20px);
  height: 100%;
  background-color: #fff16c;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.vrmPlate__green_en {
  background-color: #72b028;
}
