.connectivityMessageBanner {
  position: fixed;
  background-color: #f1ded6;
  top: 70px;
  width: 100%;
  z-index: 2;
  left: 70px;
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 10px;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    left: 0;
    width: 100%;
  }
  @media (max-width: 340px) {
    font-size: 1.2rem;
  }
}

.connectivityMessageBanner__TextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.connectivityMessageBanner__icon {
  width: 30px;
  margin: 0 10px 0 0;
  float: left;
}

.connectivityMessageBanner__message {
  float: left;
  color: #ee4b26;
  width: calc(100% - 40px);
}

.connectivityMessageBanner-enter {
  transform: translateY(-50px);
}

.connectivityMessageBanner-enter-active {
  transform: translateY(0px);
  transition: transform 300ms ease-in-out;
}

.connectivityMessageBanner-exit-active {
  transform: translateY(-50px);
  transition: transform 200ms ease-in-out;
}
