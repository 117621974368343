@keyframes loadingIndicatorKeyframes {
  0% {
    transform: scale(1);
    background-color: #82bf00;
  }

  100% {
    transform: scale(0.5);
    background-color: rgb(242, 243, 245);
  }
}

.loadingIndicator {
  position: fixed;
  top: calc(50% - 14px);
  left: calc(50% - 45px + (178px / 2));
  width: 90px;
  height: 28px;
  margin: auto;
  @media screen and (max-width: 768px) {
    left: calc(50% - 45px);
  }
  :nth-child(1) {
    animation-delay: 0s;
  }
  :nth-child(2) {
    animation-delay: 0.45s;
  }
  :nth-child(3) {
    animation-delay: 0.9s;
  }
}

.loadingIndicator__segment {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin: 24px 5px;
  background-color: #82bf00;
  animation-name: loadingIndicatorKeyframes;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  border-radius: 19px;
}
