@import '~SM/styles/stockStyles.module.scss';

.container {
  width: 100%;
  cursor: pointer;
}

.icon {
  width: fit-content;
  cursor: pointer;
  color: #64bc46;
  font-size: 14px;
  display: block;
  align-items: center;
  position: relative;
  margin-left: auto;
}

.rating {
  display: flex;
  justify-content: center;
}

.starRating {
  font-size: 22px;
  :global(.starRating__star) {
    color: #c4c4c4;
  }
  :global(.starRating__star--fill) {
    color: #30364e;
  }
}

