.vehicleDetails__outer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 10px;

  > *:first-child {
    align-self: center;
  }

  @media screen and (min-width: 700px) {
    flex-direction: row;
  }
}

.vehicleDetails__info {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
