.dashboardLinkedUsers {
  overflow: hidden;
  margin: 0 0 5px 0;
}

.dashboardLinkedUsers__hidden {
  display: none;
}

.dashboardLinkedUsers__image {
  width: 16px;
  height: 16px;
  float: left;
  font-size: 16px;
  color: #f46231;
}

.dashboardLinkedUsers__text {
  float: left;
  color: #f46231;
  margin: 0 0 0 3px;
}
