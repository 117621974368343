.generalCreditInformationModal {
}

.generalCreditInformationModal__title {
  font-size: 2rem;
  margin: 0 0 20px 0;
  font-weight: bold;
}

.generalCreditInformationModal__subTitle {
  font-size: 1.4rem;
  font-weight: bold;
  color: #7b7b7b;
  padding: 0 0 20px 0;
  margin: 0 0 10px 0;
  border-bottom: solid 1px #dbdbdb;
}

.generalCreditInformationModal__chartContainer {
  overflow: auto;
  padding: 20px;
  position: relative;

  @media (max-width: 525px) {
    padding: 0px;
  }
}

.generalCreditInformationModal__card {
  width: 45%;

  @media (max-width: 782px) {
    width: 60%;
    margin: 30px auto;
  }

  @media (max-width: 525px) {
    width: 100%;
  }
}

.generalCreditInformationModal__left {
  float: left;

  @media (max-width: 782px) {
    float: none;
  }
}

.generalCreditInformationModal__right {
  float: right;

  @media (max-width: 782px) {
    float: none;
  }
}

.generalCreditInformationModal__chart {
  width: 100%;
}

.generalCreditInformationModal__bottom {
  border-top: solid 1px #dbdbdb;
  padding: 20px 0 0 0;
}

.generalCreditInformationModal__button {
  width: 150px;

  @media (max-width: 525px) {
    width: 100%;
  }
}
