.selfServicedDealsTabsItem {
  width: 100%;
  font-size: 1.4rem;
  padding: 0 10px 10px 10px;
}

.selfServicedDealsTabsItem__top {
  overflow: hidden;
}

.selfServicedDealsTabsItem__status {
  color: #5ec1ff;
  float: left;
  font-weight: bold;
}

.selfServicedDealsTabsItem__date {
  color: #808080;
  float: right;
  font-size: 1.3rem;
}

.selfServicedDealsTabsItem__table {
  margin: 10px 0 0 0;
  border: solid #e1e1e1 1px;
  border-radius: 3px;
  color: #808080;
}

.selfServicedDealsTabsItem__row {
  overflow: hidden;
  padding: 3px;
}

.selfServicedDealsTabsItem__topRow {
  border-bottom: solid #e1e1e1 1px;
}

.selfServicedDealsTabsItem__left {
  float: left;
}

.selfServicedDealsTabsItem__right {
  float: right;
  font-weight: bold;
}

.savedSelfServiceDeals {
  padding: 10px;
}
