.sentDeals {
  padding: 0 15px;
  margin: 10px 0 0 0;
}

.sentDeals__loading {
  margin: 0 auto;
  width: 40px;
}

.sentDeals__dropDowns {
  display: flex;
  @media (max-width: 400px) {
    flex-direction: column;
  }
}

.sentDeals__sortBy {
  margin-left: auto;
  margin-right: 10px;
  @media (max-width: 400px) {
    margin-right: 0;
  }
}

.sentDeals__filter {
  margin-left: auto;
}

.sentDeals__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;

  @media (max-width: 560px) {
    display: block;
  }
}

.sentDeals__headerText {
  display: inline-flex;

  @media (max-width: 650px) {
    align-items: center;
  }
}

.sentDeals__headerButton {
  width: 200px;
  height: 40px;
}

.sentDeals__message {
  text-align: center;
  max-width: 600px;
  margin: auto;
  font-size: 1.4rem;
  padding: 20px;
}

.sentDeals__helpButton {
  margin: -5px 0 0 0;

  @media (max-width: 650px) {
    height: 20px;
    margin: -5px 0 0 5px;
  }
}
