.dashBoardTileOld__outer {
  height: 100%;
}

.dashBoardTileOld {
  cursor: pointer;
  height: 100%;
  width: 230px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08);
  backface-visibility: hidden;
  border-radius: 6px;
  overflow: hidden;

  @media (max-width: 400px) {
    margin: 10px auto 0;
  }
}

.dashBoardTileOld__imageOuter {
  height: 100%;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color: #fff;
  float: left;
}

.dashBoardTileOld__bodyWrapper {
  display: inline-table;
  overflow: hidden;
  height: 100%;
  width: 170px;
}

.dashBoardTileOld__bodyOuter {
  display: table-cell;
  vertical-align: top;
  background-color: #ffffff;
  height: 100%;
  width: 170px;
  padding: 10px 7px;
  overflow: auto;
}

.dashBoardTileOld__number {
  min-width: 35px;
  text-align: center;
  float: left;
}

.dashBoardTileOld__textOuter {
  overflow: hidden;
}

.dashBoardTileOld__text {
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.6rem;
  display: inline-table;
}

.dashBoardTileOld__textInner {
  display: table-cell;
  height: 30px;
}

.dashBoardTileOld__timeOuter {
  float: right;
  margin: 3px 0 0 0;
}

.dashBoardTileOld__clock {
  width: 15px;
  float: left;
  margin: 0 5px 0 0;
}

.dashBoardTileOld__time {
  font-size: 1.2rem;
  color: #7b7b7b;
}

.dashBoardTileOld__loading {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.dashBoardTileOld__setup {
  margin: 10px 0 0 0;
}
