.iconListItem {
  display: flex;
  padding: 10px;
}

.iconListItem__icon {
  color: #6bc348;
  display: inline-block;
  margin: 0 10px;
  font-size: 22px;
  height: 26px;
}

.iconListItem--border {
  border: 1px solid #6bc348;
  border-radius: 40px;
  padding: 1px 0 0 0;
}
