.card {
  display: block;
  position: relative;
  border: 1px solid #e9e9e9;
  border-radius: 6px;
  color: #30364e;
  background-color: white;
  box-shadow: 0 2px 4px rgba(154, 192, 216, 0.16);
  transition: all 0.2s ease-in-out;

  &.card__withBorder {
    border: #30364e 1px solid;
  }
}
