.customerListToolbarLayout__outer {
  float: left;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.customerListToolbarLayout__toolbar0 {
  padding: 15px 0 10px 0;
  background: #fff;
}

.customerListToolbarLayout__toolbar1 {
  float: left;
  padding: 10px 10px 0 0;
  min-width: 300px;
  @media (max-width: 1125px) {
    width: 100%;
    min-width: 0;
    float: left;
  }

  @media (max-width: 820px) {
    width: 100%;
    float: none;
  }
}

.customerListToolbarLayout__toolbar2 {
  float: left;
  padding: 15px 0 0 10px;
  margin: 0 0 0 10px;
  @media (max-width: 1125px) {
    float: left;
    padding: 10px 0 0 0;
  }

  @media (max-width: 820px) {
    width: 100%;
    float: left;
    margin: 0 0 7px 10px;
  }
}

.customerListToolbarLayout__toolbar3 {
  float: left;
  padding: 15px 0 0 10px;
  margin: 0 0 0 10px;
  @media (max-width: 1125px) {
    float: left;
    padding: 10px 0 0 10px;
  }

  @media (max-width: 820px) {
    width: 100%;
    float: left;
    margin: 0 0 7px 0;
  }
}

.customerListToolbarLayout__toolbar4 {
  float: right;
  padding: 10px 0;
  @media (max-width: 820px) {
    float: none;
    width: 100%;
    padding: 10px;
  }
}
