.pushDealSelectQuotes__container {
  padding: 10px 60px 20px;
  width: 100%;
  position: relative;
  @media screen and (max-width: 1024px) {
    padding: 10px;
  }
}

.pushDealSelectQuotes__header {
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    justify-content: flex-end;
  }
}

.pushDealSelectQuotes__header-alert {
  @media screen and (min-width: 1200px) {
    margin-right: auto;
  }
}

.pushDealSelectQuotes__QuoteListItem {
  width: 100%;
  margin: auto;
  @media screen and (max-width: 1024px) {
    width: 80%;
  }
  @media screen and (max-width: 900px) {
    width: 90%;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    border: 0px;
    margin: 0px;
  }
}

.pushDealSelectQuotes__loadingSpinner {
  margin: 40px auto;
  width: 52px;
  color: #6bc348;
}

.pushDealSelectQuotes__inner {
  border: solid #e2e2e1 1px;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 425px) {
    border: none;
  }
}

.pushDealSelectQuotes__toolbar {
  position: relative;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding-bottom: 10px;
  border-bottom: 1px solid #e4ebef;
}

.pushDealSelectQuotes__noQuotes {
  padding: 10px 20px;
  margin: 0 auto;
  text-align: center;
  font-size: 2rem;
}

.pushDealSelectQuotes__showMoreButton {
  margin: auto;
}

.pushDealSelectQuotes__footer {
  padding: 0 0 20px;
  border-radius: 5px;
  position: sticky;
  bottom: 0px;
  left: 70px;
  background: #fff;

  @media (max-width: 768px) {
    padding: 0 20px 20px;
  }
}

.pushDealSelectQuotes__footer--sticky {
  box-shadow: 0 -10px 16px rgba(209, 218, 249, 0.42);
}
