.quoteTableHeaderItem {
  position: relative;
  font-size: 1.4rem;
  font-weight: 600;
  color: #9e9e9e;
  padding: 5px;
  text-align: center;
}

.quoteTableHeaderItem__thInteractive {
  cursor: pointer;

  &:hover {
    color: #000000;
  }
}

.quoteTableHeaderItem__alt {
  color: #73777f;
  text-align: left;
  padding: 5px 5px 5px 10px;
}

.quoteTableHeaderItem__active {
  color: #222;
}

.quoteTableHeaderItem__borderRight {
  height: calc(100% + 2px);
  width: 1px;
  background-color: #cccccc;
  position: absolute;
  right: -1px;
  top: -1px;

  @media (max-width: 1023px) {
    display: none;
  }
}
