@import '../../../../styles/themes/themes.scss';

.vehicleRegYear {
  line-height: 30px;
  text-align: center;
  font-size: 38px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 1110px) {
    text-align: center;
  }
}

.vehicleRegYear--small {
  line-height: 30px;
  height: 73px;
  text-align: center;
  padding: 0 10px;
  display: inline-block;
  font-size: 35px;

  color: #5e6977;
}

.vehicleRegYear__icon {
  display: block;
  margin: 0 auto;
  position: relative;
  color: #5e6977;
}

.vehicleRegYear__text {
  font-size: 14px;
}
