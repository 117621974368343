.exportClosedLeadsForm__inner {
  padding: 20px 0 10px;
  max-width: 500px;
  margin: 0 auto;

  @media (max-width: 550px) {
    padding: 20px;
  }
}

.exportClosedLeadsForm__buttonOuter {
  padding: 20px;
  overflow: hidden;
  border-top: 1px solid #e4ebef;
}

.exportClosedLeadsForm__export {
  float: right;
  width: 150px;
  @media (max-width: 450px) {
    width: 100%;
    margin: 0 0 20px 0;
  }
}

.exportClosedLeadsForm__cancel {
  float: left;
  width: 150px;
  @media (max-width: 450px) {
    width: 100%;
  }
}

.exportClosedLeadsForm__modal {
  padding: 20px;
}

.exportClosedLeadsForm__modalText {
  font-size: 16px;
  margin: 0 0 30px 0;
}

.exportClosedLeadsForm__modalButton {
  max-width: 200px;
  margin: 0 auto;
}

.exportClosedLeadsForm__checkboxLabel {
  display: inline-block;
  font-size: 12px;
  vertical-align: top;
  padding: 1px 10px;
}

.exportClosedLeadsForm__groupExports {
  padding: 0 0 20px 0;
}
