.login__title {
  font-family: 'Montserrat', 'Open Sans', Arial, sans-serif;
  text-align: center;
  padding: 0 0 15px 0;
  font-size: 2rem;
}

.login__loginErrors {
  padding: 10px 0;
}

.login__inputContainer {
  margin: 0 0 20px 0;
}

.login__input {
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 10px;
  border: 1px solid #e4ebef;
  border-radius: 4px;
  background-color: #fff;
  font-size: 1.4rem;
  outline: none;
  background-position: right;
  background-repeat: no-repeat;
  transition: 0.2s;

  &:hover,
  &:focus {
    border-color: #999999;
  }
}

.login__actions {
  margin-top: 20px;
  text-transform: uppercase;
}

.login__forgotPasswordContainer {
  color: #64bc46;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.login__forgotPassword {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin: 20px 0;
}

.login__alertLink {
  color: #5ec1ff;
}
