@keyframes indicatorKeyframes {
  0% {
    opacity: 1;
    transform: scale(0.6);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.platformUpdatesIndicator {
  width: 28px;
  height: 28px;
  position: relative;
}

.platformUpdatesIndicator__outer {
  animation: indicatorKeyframes 2s ease 0s infinite;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 50%;
  border: solid #ea544e 4px;
  margin: 0 auto;
  position: absolute;
  transform-origin: 50%;
}

.platformUpdatesIndicator__inner {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 7px;
  left: 7px;
  background-color: #ea544e;
  border-radius: 50%;
  z-index: 4000;
  margin: 0 auto;
}
