.selectMonthlyPaymentModal__header {
  font-size: 18px;
  border-bottom: 1px solid black;
  padding: 0 0 5px 0;
  margin: 0 0 20px 0;
}

.selectMonthlyPaymentModal__quote {
  font-size: 14px;
  margin: 0 0 20px 0;
}

.selectMonthlyPaymentModal__buttonContainer {
  width: 46%;
  text-align: center;
  display: inline-block;
  margin: 0 2%;
}

.selectMonthlyPaymentModal__buttonContainer--fullWidth {
  width: 100%;
}

.selectMonthlyPaymentModal__buttonContainer--fullWidth:first-child {
  margin: 0 0 10px 0;
}

.selectMonthlyPaymentModal__button {
  margin: 0 auto;
  max-width: 200px;
}
