.quotePanel {
  padding: 20px;
  overflow: auto;
}

.quotePanel__legal {
  clear: both;
  margin: 0;
  padding: 10px 0 0 0;
  font-size: 13px;
  color: #7a7a7a;
}
