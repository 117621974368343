$breakpoint-mobileSm: 400px;
$breakpoint-mobileXl: 600px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

.pushDealActivityContainer {
  display: flex;
  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }
}

.pushDealActivityContainer__loading {
  margin: 130px auto;
  display: flex;
  justify-content: center;
}

.pushDealActivityContainer__dealSummary {
  width: 350px;
  margin: 0 20px 0 0;

  .panel__outer {
    margin: 0 0 20px;
  }

  @media (max-width: $breakpoint-tablet) {
    margin: 0;
    width: 100%;
  }
}

.pushDealActivityContainer__dealActivity {
  width: calc(100% - 350px - 20px);
  @media (max-width: $breakpoint-tablet) {
    width: 100%;
  }
}

.pushDealActivityContainer__activity {
  padding: 0 0 3px;
}

.pushDealActivityContainer__header {
  justify-content: space-between;
  display: flex;
  align-items: center;
  @media (max-width: 850px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: $breakpoint-tablet) {
    flex-direction: row;
    align-items: center;
  }
  @media (max-width: $breakpoint-mobileSm) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.pushDealActivityContainer__ButtonOuter {
  width: 100%;
}

.pushDealActivityContainer__ViewApplicationButton {
  margin: 20px 0px 0px 0px;
  max-width: 240px;
  min-width: 180px;
}

.pushDealActivityContainer__headerButton {
  width: 190px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 850px) {
    justify-content: flex-start;
    margin: 0 0 20px;
  }
  @media (max-width: $breakpoint-tablet) {
    justify-content: flex-end;
    margin: 0;
  }
  @media (max-width: $breakpoint-mobileSm) {
    justify-content: flex-start;
    margin: 0 0 20px;
  }
}

.pushDealActivityContainer__activityTimeline {
  margin: 30px -27px 0;
  @media (max-width: 700px) {
    margin: 30px -11px 0;
  }
}
