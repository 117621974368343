.panelContent {
  width: 100%;
  background-color: #fff;
  padding-bottom: 20px;
}

.panelContent--padding {
  padding: 30px 0;
  border-radius: 0 0 14px 14px;
}
