@import '../../../../styles/themes/themes.scss';

.address {
  text-align: center;
  padding: 0 10px;

  @media (max-width: 597px) {
    text-align: left;
    padding: 0;
  }
}

.address__inner {
  display: inline-block;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
}

.address__icon {
  width: 50px;
  height: auto;
  margin: 11px 10px 0 0;
  font-size: 45px;
  float: left;
  color: #5e6977;
}

.address__addressOuter {
  display: flex;
  margin-top: 8px;
}

.address__text {
  text-align: left;
  align-self: center;
  font-size: 1.4rem;
  max-width: 100%;
}

.address__bottom {
  font-size: 1.2rem;
  text-align: left;
  color: #abacb0;

  @media (max-width: 550px) {
    display: none;
    margin: 0;
  }
}
