.signedOutLayout {
  font-family: 'Open Sans', Arial, sans-serif;
  overflow: scroll;
  padding: 16px 24px;
  display: table;
  width: 100%;
  height: 100vh;
  background-color: #f3f4f9;
}

.signedOutLayout__inner {
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
}

.signedOutLayout__innerInner {
  margin: 0 auto;
  width: 100%;
  height: 556px;
  max-width: 768px;
  border-radius: 14px;
  overflow: hidden;
  box-shadow: 0px 20px 30px rgba(188, 194, 204, 0.6);

  @media (max-width: 900px) {
    height: auto;
    max-width: 384px;
    margin: 10px auto;
  }

  @media (max-width: 418px) {
    max-width: none;
    width: 100%;
  }
}

.signedOutLayout__leftOuter {
  float: left;

  @media (max-width: 900px) {
    float: none;
  }
}

.signedOutLayout__left {
  width: 384px;
  height: 556px;
  border-radius: 14px 0 0 14px;
  background-color: #30364e;

  @media (max-width: 900px) {
    height: 150px;
    border-radius: 14px 14px 0 0;
  }

  @media (max-width: 414px) {
    width: 100%;
  }
}

.signedOutLayout__ivendiContainer {
  width: 230px;
  height: auto;
  margin: 0 auto;
  padding: 198px 0 0 0;

  @media (max-width: 900px) {
    width: auto;
    height: 110px;
    padding: 30px 0 0 0;
  }
}

.signedOutLayout__loginForm {
  width: 100%;
  height: 556px;
}

.signedOutLayout__rightOuter {
  overflow: hidden;
}

.signedOutLayout__right {
  width: 384px;
  height: 556px;
  margin: 0;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 0 14px 14px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 900px) {
    border-radius: 0;
  }
  @media (max-width: 414px) {
    width: 100%;
  }
}

.signedOutLayout__languageToggle {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signedOutLayout__languageToggleBtn {
  all: unset;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  &--mobile {
    border: 1px solid #e4ebef;
    padding: 7px 40px 7px 7px;
    border-radius: 5px;
    background-image: url('../../../styles/icons/arrow-down.png');
    background-repeat: no-repeat;
    background-position: right center;
    background-color: transparent;

    &:hover,
    &:focus-visible {
      border-color: #999999;
    }
  }
}
