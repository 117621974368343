.panelFooter {
  border-top: solid 1px #e4ebef;
  padding: 30px 0;
  display: flex;
  justify-content: space-between;

  &__cancel,
  &__save {
    width: fit-content;
  }
}
