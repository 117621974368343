@import '../../styles/themes/themes.scss';

.quoteItem__funderLogo {
  max-width: 200px;
  padding-top: 7px;
  @media (max-width: 1000px) {
    padding-top: 5px;
    text-align: center;
    max-width: 100%;
  }
}

.quoteItem {
  width: 100%;
  font-size: 12px;
  text-align: left;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  margin: 30px 0;
  border: 1px solid #e9e9e9;
  border-top: 2px solid black;
  background: #ffffff;
  box-shadow: 0 5px 15px rgba(188, 194, 208, 0.4);

  @include theme(
    (
      border-top-color: bodyMain
    )
  );

  display: flex;
  justify-content: space-between;
  padding: 30px 20px 30px 10px;
  @media (max-width: 1000px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
}

.quoteItem__quoteInfo--product {
  width: 120px;
  position: relative;
  @media (max-width: 1000px) {
    width: initial;
  }
}

.quoteItem__quoteInfo--productName {
  line-height: 16px;
}

.quoteItem__quoteInfo--header {
  color: #9e9e9e;
  font-weight: 400;
  padding: 0 0 5px 0;
  overflow-wrap: break-word;
  @media (max-width: 1000px) {
    width: 50%;
    align-items: center;
    flex-direction: row;
    float: left;
    justify-content: flex-start;
  }
}

.quoteItem__quoteInfo--figure {
  font-weight: 700;
  align-self: flex-end;
  height: 30px;
  @media (max-width: 1000px) {
    width: 50%;
    align-items: center;
    flex-direction: row;
    float: left;
    justify-content: flex-end;
    text-align: right;
  }
}

.quoteItem__quoteInfo--spacer {
  border-right: 1px solid #e4ebef;
  min-height: 40px;
  height: 100%;
  width: 1px;
  margin: 5px;
  @media (max-width: 1000px) {
    width: 100%;
    min-height: 2px;
    height: 4px;
    border-right: none;
    border-bottom: 2px solid #e4ebef;
  }
}
.quoteItem__quoteInfo--subItem {
  @media (max-width: 1000px) {
    display: flex;
    margin: 5px 30px;
    transform: translateY(33%);
  }
  @media screen and (max-width: 400px) {
    margin: 5px 10px;
  }
}
