@import '../../../../styles/themes/themes.scss';

.consumerVehicleImage {
  height: 110px;
  overflow: hidden;
  margin-right: 14px;
  border-radius: 12px;
  background-size: 100%;
  background-repeat: no-repeat;
  border: 1px solid #e4ebef;
}
