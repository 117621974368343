.tabItem {
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 14px 10px 20px 10px;
  text-align: center;
  height: 59px;
}

.tabItem__icon {
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  position: relative;
  top: 3px;
  margin: 0 5px 0 0;
  font-size: 26px;
}

.tabItem__text {
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
}

.tabItem__text--noIcon {
  padding: 6px 0 0 0;
}
