.settingsProductTabs {
  position: relative;
  margin: 0 0 -1px 0;
  z-index: 20;
  overflow: hidden;
}

.settingsProductTabs__outer {
  overflow-y: hidden;
  overflow-x: auto;
  position: relative;
}

.settingsProductTabs__inner {
  white-space: nowrap;
  float: left;
  vertical-align: middle;
}
