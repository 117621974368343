@import './ordersItem.scss';

.sentDealsItem {
  @extend .ordersItem;
}

.sentDealsItem__date {
  position: absolute;
  top: -25px;
  left: 0;
  display: flex;
}

.sentDealsItem__day {
  font-weight: 600;
}

.sentDealsItem__month {
  margin: 0 0 0 4px;
}

.sentDealsItem__top {
  display: flex;
  padding: 0 0 20px;

  @media (max-width: 980px) {
    flex-wrap: wrap;
  }
}

.sentDealsItem__dealDetails {
  display: flex;
  justify-content: flex-start;
  width: calc((100% - 250px - 250px - 20px - 20px));
  min-width: 360px;

  @media (max-width: 980px) {
    width: calc((100% - 20px) * 0.6);
  }

  @media (max-width: 831px) {
    width: 100%;
  }

  @media (max-width: 500px) {
    justify-content: center;
    text-align: center;
  }

  @media (max-width: 450px) {
    min-width: auto;
    flex-direction: column;
    align-items: center;
  }
}

.sentDealsItem__imageOuter {
  width: 130px;
  margin: 0 20px 0 0;

  @media (max-width: 500px) {
    margin: 0 20px 20px 0;
  }
  @media (max-width: 450px) {
    margin: 0 0 20px 0;
  }
}

.sentDealsItem__deal {
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 800px) {
    width: 100%;
    flex-wrap: wrap;
  }
}

.sentDealsItem__customer {
  display: flex;
  @media (max-width: 500px) {
    justify-content: center;
  }
}

.sentDealsItem__customerName {
  display: flex;
  justify-content: flex-start;
  @media (max-width: 500px) {
    justify-content: center;
  }
}

.sentDealsItem__multipleDeals {
  display: flex;
  margin: 0 0 0 10px;
  background-color: rgb(217, 233, 249);
  height: 20px;
  border-radius: 8px;
}

.sentDealsItem__dealIcon {
  color: rgb(0, 108, 216);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.sentDealsItem__dealTotal {
  margin: 0 5px 0 0;
  color: rgb(0, 108, 216);
}

.sentDealsItem__vehicle {
  margin: 0 0 10px 0;
  font-size: 1.4rem;
  color: #646c76;
}

.sentDealsItem__dealStatus {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 250px;
  margin: 0 20px;

  @media (max-width: 1100px) {
    flex-wrap: wrap;
  }

  @media (max-width: 980px) {
    width: calc((100% - 20px) * 0.4);
    margin: 0 0 0 20px;
    align-items: center;
  }

  @media (max-width: 831px) {
    width: 100%;
    margin: 10px 0 0 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  @media (max-width: 500px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0;
  }
}

.sentDealsItem__dealStatus--inner {
  width: 100%;
  &:first-of-type {
    margin: 0 0 20px;
  }

  @media (max-width: 831px) {
    width: 50%;
    &:first-of-type {
      margin: 0;
    }
  }
}

.sentDealsItem__statusTitle {
  display: flex;
  justify-content: flex-start;
  color: #979797;
  font-weight: 600;
  margin: 0 10px 5px 0;
  @media (max-width: 831px) {
    margin: 0 10px 5px 0;
  }
  @media (max-width: 500px) {
    justify-content: flex-start;
  }
}

.sentDealsItem__statusTagOuter {
  display: flex;
  justify-content: flex-start;

  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
}

.sentDealsItem__statusUserEmail {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  padding-left: 5px;
  color: #979aa6;
}

.sentDealsItem__buttonOuter {
  width: 250px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 980px) {
    width: 100%;
    margin: 10px 0 0;
    justify-content: center;
  }

  @media (max-width: 500px) {
    width: 100%;
    margin: 0;
  }
}

.sentDealsItem__button {
  width: 200px;
  margin: 0 0 10px 0;

  @media (max-width: 980px) {
    margin: 10px 0;
  }

  @media (max-width: 500px) {
    width: 100%;
    margin: 0 0 10px 0;
  }
}

.sentDealsItem__expandButton {
  width: 25px;
  height: 25px;
  border: 1px solid #e6e6e6;
  background-color: #fafafa;
  color: #999999;
  border-radius: 4px;
  position: absolute;
  right: 40px;
  transform: translateY(-50%);
  z-index: 10;
  bottom: -25px;
}

.sentDealsItem__expandButtonArrow {
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: flex;
  position: relative;
  justify-content: center;
  transform: rotate(0deg);
  transition: 0.3s transform ease-in-out;
  font-size: 14px;
}

.sentDealsItem__expandButtonArrow--rotate {
  transform: rotate(180deg);
  transition: 0.3s transform ease-in-out;
}

.sentDealsItem__expandButtonArrow--outer {
  position: absolute;
  top: 4px;
}

.sentDealsItem__bottom {
  overflow: hidden;
  pointer-events: none;
  width: 100%;
  margin: 0;
  max-height: 0px;
  padding: 0;
  visibility: hidden;
  opacity: 0;
  position: relative;
  transition: max-height 0.5s ease-in-out, visibility 0.5s ease-in-out, opacity 0.5s ease-in-out,
    padding 0.5s ease-in-out;
}

.sentDealsItem__bottom--open {
  overflow: auto;
  pointer-events: auto;
  padding: 20px 0;
  visibility: visible;
  opacity: 1;
  border-radius: 0 0 12px 12px;
  border-top: solid 1px #edeeef;
}

.sentDealsItem__expandedConnectorLine {
  min-height: calc(100% - 40px);
  width: 0px;
  border-left: dotted #c7dcf5 2px;
  position: absolute;
  top: 5px;
  left: 4px;
}

.sentDealsItem__price {
  display: flex;
  flex-wrap: wrap;
  font-weight: 600;
  color: rgb(48, 54, 77);

  @media (max-width: 500px) {
    justify-content: center;
  }
}

.sentDealsItem__offerPrice {
  margin: 0 5px 0 0;
}

.sentDealsItem__vehiclePrice {
  text-decoration: none;
}

.sentDealsItem__vehiclePrice--discounted {
  text-decoration: line-through;
}

.sentDealsItem__orderRef {
  font-weight: 600;
  margin-right: 2rem;
  width: fit-content;
}

.sentDealsItem__link {
  color: #262b3e;
}

.sentDealsItem__status {
  width: 200px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  font-size: 14px;
  margin: 0 20px 25px 0;

  @media (max-width: 800px) {
    margin: 10px 0;
    width: auto;
  }
}

.sentDealsItem__header {
  @extend .ordersItem__header;
}

.sentDealsItem__orderIcon {
  @extend .ordersItem__orderIcon;
}

.sentDealsItem__content {
  @extend .ordersItem__content;
}

.sentDealsItem__vehicle {
  @extend .ordersItem__vehicle;
}

.sentDealsItem__info {
  @extend .ordersItem__info;
}

.sentDealsItem__infoItem {
  @extend .ordersItem__infoItem;
}

.sentDealsItem__customerName {
  @extend .ordersItem__customerName;
}

.sentDealsItem__otherInfo {
  @extend .ordersItem__otherInfo;
}

.sentDealsItem__payment {
  @extend .ordersItem__payment;
}

.sentDealsItem__subHeader {
  @extend .ordersItem__subHeader;
}

.sentDealsItem__sentBy {
  display: flex;
  padding: 0 0 0 20px;
}
