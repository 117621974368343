@import '../../../modules/Stock/styles/stockStyles.module.scss';

.OrderSummaryTable__Customer {
  display: none;
  @media print {
    display: block;
  }
}

.OrderSummaryTable__MultiFinance {
  font-weight: 700;
  padding: 1rem 0 2rem 1rem;
  color: $PrimaryDarkblue100;
  @media print {
    padding-left: 20px;
  }
}
