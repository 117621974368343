.linkedUsersModal__header {
  font-weight: bold;
  font-size: 2rem;
  border-bottom: solid #ebebeb 1px;
  padding: 20px;
  margin: 0 0 20px 0;
}

.linkedUsersModal__header {
  padding-top: 0;
}

.linkedUsersModal__main {
  padding: 0 20px;
}

.linkedUsersModal__bottom {
  padding: 20px 0 0 0;
  margin: 20px 0 0 0;
  border-top: solid #ebebeb 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.linkedUsersModal__bottom--btn {
  width: 250px;
}
