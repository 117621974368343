.cardInformationItem {
  width: 100%;
  padding: 6px 10px 6px 50px;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #e4ebef;
}

.cardInformationItem__left {
  display: inline-block;
  text-align: left;
  width: 50%;
}

.cardInformationItem__right {
  display: inline-block;
  text-align: right;
  width: 50%;
  vertical-align: top;
}
