$text-color: #5e6977;
$border-color: #e4ebef;

.searchBox__searchBar {
  color: $text-color;
  font-size: 10px;
  font-weight: 800;
  margin: 0 0 5.2rem;

  @media (min-width: 768px) {
    display: block;
  }
}

.searchBox__SearchBarParagraph {
  font-weight: 800;
  color: #000;
  font-size: 16px;
  margin-top: 20px;
}
