.cfcVehicleSummaryTable__panel {
  padding: 10px 20px 1px 20px;
  font-size: 1.4rem;
  line-height: 25px;
}

.cfcVehicleSummaryTable__tableHeading {
  color: #8c888b;
}

.cfcVehicleSummaryTable__tableValue {
  color: #000;
  font-weight: 400;
}
