.smallVrm {
  border-radius: 1px;
  background-color: rgba(255, 243, 107, 0.4);
  width: 100%;
  max-width: 87px;
  height: 20px;
  text-align: center;
  font-weight: 600;
  position: relative;
  text-transform: uppercase;
  padding: 0 0 0 8px;
  display: inline-table;
  color: #000000;
}

.smallVrm__regBlue {
  width: 8px;
  height: 100%;
  background: rgba(77, 141, 245, 0.5);
  position: absolute;
  left: 0px;
  top: 0px;
}

.smallVrm__vrm {
  display: table-cell;
  font-size: 1.2rem;
  letter-spacing: 0.05rem;
  vertical-align: middle;
  color: #4a4a4a;
}
