.quoteLegalText--print {
  display: none;

  @media print {
    display: flex;
    flex-direction: column;

    padding-top: 10px;
  }
}

