.tileLayout__layout {
  margin: 0 0 20px 0;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 850px) {
    width: 100%;
  }
}

.tileLayout__child {
  margin: 5px 10px;
}
