.sendDealToExistingCorporateCustomer {
  margin: 10px 0 0 0;
  padding: 10px;
  border: solid 1px;
  text-align: center;
  color: #6bc348;
  font-size: 1.6rem;
}

.sendDealToExistingCorporateCustomer__text {
  color: #6bc348;
  margin: 0 0 10px 0;
}

.sendDealToExistingCorporateCustomer__customer {
  font-weight: bold;
  margin: 0 0 10px 0;
}

.sendDealToExistingCorporateCustomer__button {
  display: inline-block;
  margin: 0 auto;
}
