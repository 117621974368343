.applicationPage {
  padding: 0px 0 30px 40px;
  @media (max-width: 1000px) {
    padding-left: 0;
  }
  margin-top: 20px;
}

.applicationPage__inner {
  margin: 0 0 25px 0;
}

.applicationPage__title {
  font-family: 'Montserrat', 'Open Sans', Arial, sans-serif;
  font-size: 2rem;
  margin: 5px 0 14px 0;
  padding: 0;
  text-align: center;
}

.applicationPage__subTitle {
  text-align: center;
  margin: 0 0 35px 0;
  color: #000;
}
