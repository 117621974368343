@import './sentDealsItem.scss';

.sentDealMultipleDealsItem {
  width: 100%;
  margin: 0 0 10px 0;
  padding: 0 0 0 20px;
  position: relative;
  font-size: 14px;
  overflow: hidden;
}

.sentDealMultipleDealsItem__blueDot {
  height: 0;
  width: 0;
  border: solid 5px #c7dcf5;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 5px;
}

.sentDealMultipleDealsItem__date {
  margin: 0 0 5px 0;
}

.sentDealMultipleDealsItem__inner {
  display: flex;
  overflow: hidden;

  @media (max-width: 980px) {
    flex-wrap: wrap;
  }
}

.sentDealMultipleDealsItem__dealDetails {
  @extend .sentDealsItem__dealDetails;
  gap: 20px;
  min-width: 340px;
  @media (max-width: 980px) {
    width: calc((100% - 20px - 20px) * 0.6);
  }
  @media (max-width: 831px) {
    width: 100%;
  }
  @media (max-width: 450px) {
    min-width: auto;
  }
}

.sentDealMultipleDealsItem__vehicle {
  @extend .sentDealsItem__deal;
  gap: 5px;
  justify-content: initial;
  margin: 0;
}

.sentDealMultipleDealsItem__price {
  @extend .sentDealsItem__price;
}

.sentDealMultipleDealsItem__offerPrice {
  @extend .sentDealsItem__offerPrice;
}

.sentDealMultipleDealsItem__vehiclePrice {
  @extend .sentDealsItem__price;
}

.sentDealMultipleDealsItem__vehiclePrice--discounted {
  @extend .sentDealsItem__vehiclePrice--discounted;
}

.sentDealMultipleDealsItem__details {
  @extend .sentDealsItem__dealStatus;
}

.sentDealMultipleDealsItem__status {
  @extend .sentDealsItem__dealStatus--inner;
  @extend .sentDealsItem__statusTagOuter;
}

.sentDealMultipleDealsItem__sentBy {
  @extend .sentDealsItem__dealStatus--inner;
  @extend .sentDealsItem__statusUserEmail;
}

.sentDealMultipleDealsItem__buttonOuter {
  @extend .sentDealsItem__buttonOuter;
  justify-content: center;
}

.sentDealMultipleDealsItem__button {
  @extend .sentDealsItem__button;
  width: 100px;
}
