.RHFValidationWrapper {
  @media (max-width: 650px) {
    margin-bottom: 20px;
  }

  align-items: flex-start;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 0;
  margin-top: 10px;
}

.RHFValidationWrapper__label {
  padding: 0 25px 0 0;
  font-weight: 600;
  max-width: 40%;
  @media (max-width: 700px) {
    max-width: 40%;
  }
}

.RHFValidationWrapper__input {
  width: 60%;
  input {
    text-transform: uppercase;
  }
  #attentionGrabber {
    text-transform: unset;
  }
  textarea {
    height: 70px;
  }
}

.RHFValidationWrapper__error {
  width: 100%;
  margin: 10px 0 0 0;
  position: relative;
  @media (max-width: 650px) {
    width: 100%;
    margin: 0;
  }
}
.RHFValidationWrapper__errorMessage {
  padding: 5px 0 0 0;
  color: #e66c6c;
  font-weight: 600;
  position: relative;
  min-height: 25px;
  font-size: 13px;
  z-index: 1;
  @media (max-width: 650px) {
    padding: 0;
    margin: 0;
  }
}

.RHFValidationWrapper__icon {
  position: absolute;
  width: 20px;
  height: 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  right: 3px;
  top: 0;
  font-size: 20px;
}

.RHFValidationWrapper__iconMedia {
  display: none;
  @media (max-width: 650px) {
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    right: 0;
    top: 0;
  }
}
.RHFValidationWrapper__tick {
  color: #6bc348;
  position: absolute;
  top: 10px;
  right: -22px;
  font-size: 20px;
}

.RHFValidationWrapper__cross {
  color: #e66c6c;
  position: absolute;
  top: 10px;
  right: -22px;
  font-size: 20px;
}

.RHFValidationWrapper__information {
  color: #055bd4;
}

