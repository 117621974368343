@import '../../styles/themes/themes.scss';

.splitButton__wrapper {
  position: relative;
}

.splitButton__arrow {
  transition: background-color 0.3s;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 40px;
  width: 40px;
  line-height: 0;
  border: 0;
  padding: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0;
  user-select: none;
  cursor: pointer;

  @include themeDarken(
    (
      background: bodyMain
    )
  );

  &:hover {
    @include themeDarkest(
      (
        background: bodyMain
      )
    );
  }

  &:focus {
    outline: 0;
  }
}

.splitButton__arrowOpen {
  @include themeDarken(
    (
      background: bodyMain
    )
  );
}

.splitButton__arrowInner {
  user-select: none;
  font-size: 20px;
  text-align: center;
  margin: 0;
}

.splitButton__optionsWrapper {
  margin: 0;
  list-style: none;
  border: 1px solid #c8c8c8;
  background: #fff;
  font-size: 1.4rem;
  font-weight: 700;
  color: #383838;
  border-radius: 3px;
  padding: 0;
  overflow: hidden;
  z-index: 100;
  white-space: nowrap;
}

.splitButton__hideArrow {
  display: none;
}

.splitButton__hideOptionsWrapper {
  display: none;
}

.splitButton__arrow--disabled {
  background-color: grey !important;
}
