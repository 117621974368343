.quoteResults__cancelButton {
  display: inline-block;
  margin: 20px 0;
  @media (max-width: 1166px) {
    margin: 0;
  }
}

.quoteResults__loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 30vh;
}
