.incompleteReservationTooltip {
  position: absolute;
  background-color: #ffffff;
  top: 38px;
  font-size: 1.2rem;
  width: 200px;
  z-index: 1;

  @media (max-width: 1100px) {
    top: 50px;
  }

  @media (max-width: 880px) {
    top: 40px;
    left: -20px;
  }
}

.incompleteReservationTooltip__triangle {
  content: '';
  display: block;
  position: absolute;
  left: 70px;
  top: -10px;
  width: 24px;
  height: 10px;
  background-image: url('../../../styles/icons/tooltip-triangle-up.png');
  background-repeat: no-repeat;
  background-size: 24px 10px;

  @media (max-width: 880px) {
    left: 85px;
  }
}

.incompleteReservationTooltip__inner {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
  padding: 10px;
}
