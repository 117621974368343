@import '../../../styles/themes/themes.scss';

.applicationFormlayout__applicationNumber {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  font-weight: 600;
  padding: 5px 0;
}

.application__outer {
  display: flex;
  margin-top: 20px;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
}

.applicationFormlayout__applicationNumberCount {
  @include theme(
    (
      color: bodyMain
    )
  );
}
