.wrapper {
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  margin: 19px 0;

  @media (min-width: 769px) {
    font-size: 16px;
  }
}

.label {
  font-weight: 700;
  margin-right: 8px;
  text-transform: uppercase;
}

.value {
  font-weight: 400;
}
