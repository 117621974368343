.checkboxLabel {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 1.2rem;
  padding: 10px 0;
  display: inline-block;
  position: relative;
}

.checkboxLabel__checkbox {
  position: absolute;
}

.checkboxLabel__label {
  display: block;
  margin: 1px 0 0 28px;
}
