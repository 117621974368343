@import '~/styles/themes/themes.scss';

.broadcastStockListItem {
  border-top: 1px solid #e4ebef;

  &:first-of-type {
    border-top: none;

    .broadcastStockListItem__content:first-child {
      margin-top: 0;
    }
  }
  padding-bottom: 10px;
}

.broadcastStockListItem__content:first-child {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 12px;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
}

.broadcastStockListItem__name {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  padding-top: 20px;
}

.broadcastStockListItem__vrm {
  position: relative;
  display: flex;
  margin: 22px auto;
}

.broadcastStockListItem__update_tag_container {
  display: flex;
  margin-top: 17px;
}

.broadcastStockListItem__updateTag {
  min-width: 200px;
  height: 30px;
  border-radius: 10px;
  font-weight: 600;
  justify-content: space-around;
  display: flex;
  align-items: center;
  padding: 0px 4px 0px 4px;
  margin-top: 8px;
  background-color: #e4ebef;
  color: #a1a1a1;
  border: solid #e4ebef 1px;
  cursor: pointer;
}

.broadcastStockListItem__updateTagSpinner {
  width: 14px;
  height: 14px;
  position: relative;
  display: flex;
}

.broadcastStockListItem__updateTag__text {
  white-space: nowrap;
  padding: 0 4px 0 4px;
  font-size: 14px;
}

.broadcastStockListItem__quickInfo {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 25px;
  max-width: 420px;
}

.broadcastStockListItem__highlight {
  font-size: 14px;
  font-weight: 600;
  color: #a1a1a1;
  font-style: italic;
  flex: 1 1 auto;
  margin-top: 16px;
  line-height: 26px;
  position: relative;
  display: flex;
  align-items: flex-end;
}

.broadcastStockListItem__highlightItem {
  display: flex;
  align-items: center;

  .icon {
    display: inline-block;
  }

  span {
    margin-right: 5px;
  }
}

.broadcastStockListItem__highlightItem--alert {
  color: #ea544e;
  align-items: baseline;
}

.broadcastStockListItem__leftSide {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  @media screen and (max-width: 480px) {
    margin: auto;
  }
}

.broadcastStockListItem__stars_and_image {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media screen and (max-width: 480px) {
    margin-bottom: 12px;
  }
}

.broadcastStockListItem__image {
  position: relative;
  font-size: 14px;
  min-width: 130px;

  .vehicleImage {
    height: 150px;
    width: 200px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .stockListVehicleImage__placeholder {
    outline: none;
  }

  @media screen and (min-width: 992px) {
    .vehicleImage {
      width: 200px;
    }
  }
}

.broadcastStockListItem__imageHighlight {
  display: flex;
  justify-content: space-between;
  position: absolute;
  padding: 4px 8px;
  width: 100%;
  z-index: 1;
}

.broadcastStockListItem__numImages {
  font-weight: 600;
  line-height: 16px;
  background: rgba(255, 255, 255, 0.6);
  padding: 2px;
  border-radius: 4px;

  .icon {
    display: inline-block;
    font-size: 16px;
  }
}

.broadcastStockListItem__vehicleColour {
  font-weight: 600;
  line-height: 16px;
  background: rgba(255, 255, 255, 0.6);
  padding: 2px;
  border-radius: 4px;
  text-transform: capitalize;

  .colourSwatch {
    margin-right: 4px;
  }
}

.broadcastStockListItem__extraInfo {
  @media screen and (min-width: 992px) {
    flex-direction: column;
    align-items: center;
    min-width: 200px;
    display: flex;
    justify-content: flex-start;
  }

  button.button {
    border-radius: 13px;
    max-width: 170px;
    height: 40px;
  }
}

.broadcastStockListItem__price {
  color: #2c2c2c;
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: bold;
  display: flex;
  padding-top: 20px;

  @media screen and (min-width: 992px) {
    justify-content: center;
    font-weight: 600;
    margin-bottom: 25px;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    max-width: 107px;
  }
}

.broadcastStockListItem__priceNoVrm {
  color: #2c2c2c;
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: bold;
  display: flex;
  padding-top: 0px;

  @media screen and (min-width: 992px) {
    justify-content: center;
    font-weight: 600;
    margin-bottom: 25px;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    max-width: 107px;
  }
}

.broadcastStockListItem__vehicleName {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
  line-height: 35px;

  span {
    display: inline;
  }

  @media screen and (min-width: 992px) {
    font-size: 24px;

    span {
      display: none;
    }
  }

  .broadcastStockListItem__derivative {
    padding: 0;
  }
}

.broadcastStockListItem__derivative {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  padding: 0 0 20px 0;
  text-transform: capitalize;
}

.broadcastStockListItem__vehicleInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
  @media screen and (max-width: 991px) {
    padding-right: 0;
  }
}

.broadcastStockListItem__vehicleCheckBox {
  position: absolute;
  top: 10px;
  left: -30px;
}

.broadcastStockListItem__vehicleCheckBoxNoVrm {
  margin-right: 12px;
  position: relative;
  margin-bottom: 8px;
  @media screen and (max-width: 991px) {
    padding: 0px 20px;
  }
}

.broadcastStockListItem__description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.broadcastStockListItem__extraInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.broadcastStockListItem__dataAccuracy {
  .stockListItemDataAccuracy_text {
    font-size: 16px;
    color: #a1a1a1;
  }

  .stockListItemDataAccuracy_star {
    font-size: 22px;
  }

  .starRating__container {
    margin-top: 0;
  }

  .starRating__star--fill {
    color: #ffd700;
  }
}

.broadcastStockListItem__vrmPlate {
  border: 2px solid #000000;
  border-radius: 4px;
  width: 150px;
}
