@import '../../../modules/Stock/styles/stockStyles.module.scss';
$information: #d3e9f0;
$success: #f2fff2;

.card {
  display: flex;
  padding: 16px;
  align-items: baseline;
  gap: 16px;
  border-radius: 8px;
  color: $black-001;

  :global(.icon) {
    position: relative;
    top: 3px;
    font-size: 20px;
    color: $black-001;
  }
}

.card--alert {
  background: $alert-002;
  :global(.icon) {
    color: $alert-001;
  }
}
.card--warning {
  background: $warning-001;
}
.card--success {
  background: $success;
}

.card--saved {
  background: $success;
  :global(.icon) {
    color: $primaryGreen;
  }
}
.card--information {
  background: $information;
  color: $PrimaryDarkblue100;

  :global(.icon) {
    color: $PrimaryDarkblue100;
  }

  ul {
    margin: 0 0 1rem 0;
    padding-left: 2.2rem;
    font-weight: 500;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.title {
  color: inherit;
  text-align: left;
  margin: unset;
  font-weight: 700;
  font-size: 14px;
}
.paragraph {
  color: inherit;
  margin: unset;
}

.link {
  color: $alert-001;
  text-decoration: underline;
  cursor: pointer;
}
