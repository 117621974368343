@import '../../../styles/themes/themes.scss';
@import '../../../styles/utility/browsers.scss';

.quickQuoteBar {
  display: inline;
  float: right;
  height: 69px;
  width: 80px;
  z-index: 10000;
}

.quickQuoteBar__mobileButtonOuter {
  display: none;
  @media (max-width: 1100px) {
    display: inline;
    float: right;
    height: 69px;
    width: 80px;
    z-index: 10000;
  }
}

.quickQuoteBar__mobileButton {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  transition: background-color 0.2s ease;
}

.quickQuoteBar__mobileButton:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.quickQuoteBar__mobileButton--active {
  background-color: rgba(0, 0, 0, 0.2);
}

.quickQuoteBar__mobileButtonImage {
  width: 60%;
  margin: 0 auto 2px;
  font-size: 27px;

  @include theme(
    (
      color: headerForeground
    )
  );
}

.quickQuoteBar__mobileButtonText {
  font-weight: 700;
  font-size: 1.2rem;

  @include theme(
    (
      color: headerForeground
    )
  );
}

.quickQuoteBar__enterVrmContainer {
  width: 280px;
  float: right;
  padding: 8px 0;
  @media (max-width: 1100px) {
    height: 112px;
    width: 280px;
    position: fixed;
    right: -375px;
    margin: 0;
    top: 69px;
    border-radius: 0 0 0 12px;
    transition: right 500ms;
    box-shadow: 5px 5px 20px -3px rgba(0, 0, 0, 0.75);
  }

  @include theme(
    (
      background-color: headerBackground
    )
  );
}

.quickQuoteBar__enterVrmContainer__motorhome {
  @extend .quickQuoteBar__enterVrmContainer;
  padding: 0;
}

.quickQuoteBar__enterVrmContainer--open {
  @media (max-width: 1100px) {
    right: 0;
    box-shadow: -2px 3px 8px -3px rgba(0, 0, 0, 0.14);
    border-left: 1px solid #e4ebef;
    border-bottom: 1px solid #e4ebef;
  }
}

.quickQuoteBar__mobileHeader {
  display: none;
  @media (max-width: 1100px) {
    display: block;
    height: 38px;
  }
}

.quickQuoteBar__mobileHeaderText {
  float: left;
  color: #ffffff;
  font-size: 1.4rem;
  margin: 10px 0 0 15px;
  font-weight: 700;
  @include theme(
    (
      color: headerForeground
    )
  );
}

.quickQuoteBar__mobileHeaderCrossContainer {
  float: right;
  background: none;
  border: none;
  outline: none;
  height: 38px;
  width: 38px;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.quickQuoteBar__mobileHeaderCloseButton {
  height: 100%;
  width: 100%;
}

.quickQuoteBar__mobileHeaderTopSection {
  height: 34.5px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.quickQuoteBar__mobileHeaderForm {
  display: flex;
}

.quickQuoteBar__mobileHeaderBlueBit {
  width: 15px;
  height: 34.5px;
  background-color: #4e91f5;
  border-radius: 6px 0 0 6px;
}

.quickQuoteBar__vrmInputContainer {
  width: 145px;
  height: 34.5px;
  background-color: #f2f54e;
  border-right: solid 5px #d7d948;
  border-bottom: solid #d7d948 1px;
}

.quickQuoteBar__vrmInput {
  outline: none;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  font-size: 2rem;
  font-weight: bold;
  padding: 5px;
  text-align: center;
  text-transform: uppercase;
  @include ie-old-only {
    background-color: #f2f54e;
  }
}

.quickQuoteBar__buttonOuter {
  height: 100%;
  border-radius: 0 6px 6px 0;
}

.quickQuoteBar__button {
  border: none;
  outline: none;
  height: 35px;
  max-width: 70px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
  border-radius: 0 6px 6px 0;

  @include theme(
    (
      background-color: bodyMain
    )
  );
}

.quickQuoteBar__searchIcon {
  font-size: 24px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.quickQuoteBar__mobileHeaderBottomSection {
  height: 18px;
  width: 100%;
  padding: 6px 0 0 0;
  color: #fff;
}

.quickQuoteBar__mobileHeaderBottomSectionText {
  font-family: 'Montserrat', 'Open Sans', Arial, sans-serif;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  height: 100%;
  font-size: 1.2rem;
  cursor: pointer;

  @include theme(
    (
      color: headerForeground
    )
  );

  &:hover {
    text-decoration: underline;
  }

  &:active {
    text-decoration: underline;
  }

  @media (max-width: 1100px) {
    height: 30px;
  }
}

.quickQuoteBar__mobileHeaderBottomSectionIcon {
  height: 23px;
  width: 23px;
  float: left;
  margin: -2px 6px 0 0;
  font-size: 18px;
  @media (max-width: 1100px) {
    display: none;
  }
}
