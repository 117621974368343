.leagueTable__header {
  overflow: hidden;
  margin: 0 0 20px 0;

  @media (max-width: 500px) {
    padding: 0 20px;
  }
}

.leagueTable__headerText {
  font-size: 2.4rem;
  font-weight: bold;
  float: left;
  margin: 5px 0 0 0;
  width: calc(100% - 100px);

  @media (max-width: 500px) {
    font-size: 1.8rem;
  }
}

.leagueTable__headerIcon--outer {
  float: right;
  width: 80px;

  @media (max-width: 500px) {
    width: 60px;
  }

  @media (max-width: 500px) {
    margin: 10px 0 0 0;
  }
}

.leagueTable__headerIcon {
  width: 100%;
  height: 100%;
}

.leagueTable__selectOuter {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 20px 0;

  @media (max-width: 650px) {
    flex-wrap: wrap;
  }
}

.leagueTable__tileOuter {
  @media (max-width: 650px) {
    margin: 0 0 20px 0;
  }

  @media (max-width: 400px) {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
}

.leagueTable__titleOuter {
  float: left;
  overflow: hidden;
}

.leagueTable__selectInner {
  align-self: center;

  @media (max-width: 400px) {
    padding: 0 0 0 20px;
  }
}

.leagueTable__selectLabel {
  float: left;
  font-size: 1.4rem;
  font-weight: bold;
  margin: 4px 0 0 0;
}

.leagueTable__select {
  float: right;
}
