.leagueTableCell {
  padding: 5px;
  text-align: center;
  border-right: solid #cccccc 1px;

  &:first-child {
    border-left: solid #cccccc 1px;
    font-weight: bold;
  }
}
