.benefitsAndRisks__pageTitle {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.benefitsAndRisks__body {
  font-size: 1.4rem;
}

.benefitsAndRisks__bodyInner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.benefitsAndRisks__bodySection {
  width: 48%;

  @media (max-width: 1000px) {
    width: 100%;
  }
}

.benefitsAndRisks__disclaimerText {
  @media screen and (max-width: 500px) {
    font-size: 1.2rem;
  }
}

.benefitsAndRisks__title {
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0 0 5px 0;
  padding: 0 0 0 0;
  text-align: left;
  @media screen and (max-width: 1024px) {
    margin: 20px 0 5px 0;
  }

  @media screen and (max-width: 500px) {
    font-size: 1.6rem;
  }
}

.benefitsAndRisks__printTitle {
  font-size: 1.4rem;
  font-weight: 700;
  margin: 0 0 5px 0;
  padding: 0 0 0 0;
  text-align: left;
  @media screen and (max-width: 1024px) {
    margin: 20px 0 5px 0;
  }

  @media screen and (max-width: 500px) {
    font-size: 1.6rem;
  }
}

.benefitsAndRisks__heading {
  font-size: 1.6rem;
  font-weight: 400;
  margin: 0 0 5px 0;
  padding: 0 0 0 0;
  text-align: left;
  @media screen and (max-width: 500px) {
    font-size: 1.4rem;
  }
}

.benefitsAndRisks__printHeading {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 0 0 5px 0;
  padding: 0 0 0 0;
  text-align: left;
  @media screen and (max-width: 500px) {
    font-size: 1.4rem;
  }
}

.benefitsAndRisks__paragraph {
  color: #696666;
  text-align: justify;
}

.benefitsAndRisks__printParagraph {
  color: #696666;
  text-align: justify;
  font-size: 1.2rem;
}

.benefitsAndRisks__address {
  padding: 0;
  margin: 0 0 20px 0;
  list-style: none;
}

.benefitsAndRisks__checkbox {
  margin: 20px 0 20px 0;
  font-weight: 600;
}

.benefitsAndRisks__links {
  color: #56a3da;
  text-decoration: none;
  font-weight: 600;
  word-wrap: break-word;
}

.benefitsAndRisks__bold {
  font-weight: 600;
}

.benefitsAndRisks__buttons {
  width: 200px;
  display: inline-block;
  margin-right: 30px;
  @media screen and (max-width: 650px) {
    width: 40%;
    margin-right: 20%;
  }
}

.benefitsAndRisks__rightButton {
  float: right;
  margin-right: 0;
  @media screen and (max-width: 650px) {
    margin-right: 0;
  }
}

.benefitsAndRisks__divider {
  width: calc(100% - 20px);
  height: 1px;
  background-color: #ddd;
  margin: 20px 0;
}

.benefitsAndRisks__divider2 {
  width: 100%;
  height: 1px;
  background-color: #ddd;
  margin: 20px 0;
}

.benefitsAndRisks__checkboxWarning {
  color: #ff613d;
  font-weight: 600;
  margin: 10px 0 0 0;
  display: none;
}

.benefitsAndRisks__benefitsAndRisks {
  margin: 40px 0 0 0;
  color: #696666;
  padding: 0 0 10px 0;
}

.benefitsAndRisks__printBenefitsAndRisks {
  margin: 10px 0 0 0;
  color: #696666;
  padding: 0 0 10px 0;
  font-size: 1.2rem;
}

.benefitsAndRisks__benefitsAndRisksHeaders {
  padding: 0;
  font-weight: 600;
}

.benefitsAndRisks__benefits {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  @media (max-width: 600px) {
    width: 100%;
  }
}

.benefitsAndRisks__risks {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  @media (max-width: 600px) {
    width: 100%;
    margin: 20px 0 0 0;
  }
}

.benefitsAndRisks__benefitsUL {
  margin: 0;
  padding: 0 20px;
  line-height: 24px;
}

.benefitsAndRisks__risksUL {
  margin: 0;
  padding: 0 20px;
  line-height: 24px;
}

.benefitsAndRisks__video {
  margin: 0 auto;
  box-shadow: 1px 1px 10px 0 rgba(133, 133, 133, 1);
  font-size: 0; //Remove small white bar at bottom
  border-radius: 5px 5px 0 0;
}

.benefitsAndRisks__footerText {
  clear: both;
  padding: 0 0 10px 0;
  font-size: 1.4rem;
}

.benefitsAndRisks__printFooter {
  clear: both;
  padding: 0 0 10px 0;
  font-size: 1rem;
}

.benefitsAndRisks__printProduct {
  padding: 0 20px;
}

.benefitsAndRisks__paragraph--novideo {
  text-align: left;
  display: block;
  width: 100%;
}
