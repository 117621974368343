.checkbox__container {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 1.2rem;
  padding: 0;
  display: inline-block;
  width: 18px;
  height: 18px;
  position: relative;

  &:hover {
    .checkbox:not(.checkbox__checked) {
      background-position: 0 -19px;
    }
  }
}

.checkbox__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.checkbox__disabled {
  cursor: default;
}

.checkbox {
  display: block;
  width: 18px;
  height: 18px;
  margin: 0;
  background-image: url('../../../styles/icons/checkbox-stages-ivendi-green.svg');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.checkbox__checked {
  background-image: url('../../../styles/icons/checkbox-stages-ivendi-green.svg');
  background-repeat: no-repeat;
  background-position: 0 -38px;
}