.settingsProduct__contentWrapper {
  padding: 10px;
}

.settingsProduct__tableWrapper {
  border: 1px solid #dfe0e2;
  padding: 30px;
}

.settingsProduct__divider {
  background: #d4d4d4;
  width: calc(100% - 20px);
  height: 1px;
  margin: 20px 10px;
}

.settingsProduct__footer {
  overflow: hidden;
  padding: 0 10px 10px 10px;
}

.settingsProduct__cancelButton {
  width: 100px;
  float: left;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.settingsProduct__saveButton {
  width: 100px;
  float: right;

  @media screen and (max-width: 500px) {
    width: 100%;
    margin: 10px 0 0 0;
  }
}

.settingsProduct__editButton {
  display: inline-block;
  float: right;
  margin: 0 20px 0 0;

  @media screen and (max-width: 500px) {
    width: 100%;
    margin: 10px 0 0 0;
  }
}
.settingsProduct__loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 20vh;
}
