.alert {
  font-size: 1.4rem;
  padding: 10px 10px 10px 32px;
  background-image: url('../../styles/icons/warning-white.svg');
  background-color: #e94b35;
  background-repeat: no-repeat;
  background-position: 0 2px;
  color: #fff;
  text-align: left;
  margin: 0 10% 0 10%;
}
