/*
Usage:
@include ie-old-only {
  color: cyan;
  background: red;
  ...
}
*/

@mixin ie-old-only {
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */
    & {
      @content;
    }
  }
}
/*
Not Tested any of these. Just grabbed them from a random thread.
*/

@mixin edge() {
  @supports (-ms-accelerator: true) {
    & {
      @content;
    }
  }
}

@mixin firefox() {
  & #{',  x:-moz-any-link'} {
    @content;
  }
}

@mixin chrome() {
  @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    & {
      @content;
    }
  }
}

@mixin safari() {
  @supports (overflow: -webkit-marquee) and (justify-content: inherit) {
    & {
      @content;
    }
  }
}

@mixin ios() {
  @supports (-webkit-text-size-adjust: none) and (not (-ms-accelerator: true)) and (not (-moz-appearance: none)) {
    & {
      @content;
    }
  }
}
