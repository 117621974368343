.onlineOrders__contactUs {
  text-align: center;
  max-width: 600px;
  margin: auto;
  font-size: 1.4rem;
  padding: 20px;
}

.onlineOrders__showMoreButton {
  cursor: pointer;
  color: #6bc348;
  font-size: 1.4rem;
}
