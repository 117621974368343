.valueAddedProductForms__taxType {
  font-size: 14px;
  color: #afafaf;
  text-align: left;
  padding: 0 0 10px 245px;
  transform: translateY(-20%);

  @media (max-width: 1100px) {
    padding-left: 0;
    text-align: center;
  }

  @media (max-width: 700px) {
    padding: 0 0 0 10px;
    transform: translateY(-140%);
    text-align: left;
  }
}

.valueAddedProductForms__form {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px 0 20px 0;

  @media (max-width: 600px) {
    padding-bottom: 0;
  }
}

.valueAddedProductForms__formFooter {
  display: flex;
  justify-content: space-between;
  margin: 0 50px;
  border-top: 1px solid #e4ebef;
  padding: 20px 0 40px 0;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    margin: 0;
  }
}

.valueAddedProductForms__formFooterButton {
  width: fit-content;
  @media (max-width: 500px) {
    padding: 5px;
    width: 100%;
  }
}

.valueAddedProductForms__formFooterError {
  font-size: 14px;
  font-weight: 600;
  color: #ff6600;
  text-align: center;
  padding: 0 20px 30px 20px;

  @media (max-width: 600px) {
    order: 3;
    padding: 10px;
  }
}

.valueAddedProductForms__informationCard {
  border: 1px solid #e4ebef;
  margin: 0 auto;
  padding: 20px;
  max-width: 500px;
  border-radius: 4px;
}

.valueAddedProductForms__loadingSpinner {
  padding: 60px 0 80px 0;
  margin: 0 auto;
}

.valueAddedProductForms__errorMessage {
  padding: 20px;
  font-size: 14px;
  color: #ff6600;
}

.valueAddedProductForms__modal {
  font-size: 1.6rem;
}

.valueAddedProductForms__modalText {
  margin: 0 0 40px 0;
}

.valueAddedProductForms__modalButtons {
  overflow: hidden;
}

.valueAddedProductForms__modalButtonAdd {
  float: right;
  width: 170px;

  @media (max-width: 450px) {
    width: 100%;
    float: none;
    margin: 0 0 10px 0;
  }
}

.valueAddedProductForms__modalButtonCancel {
  float: left;
  width: 170px;

  @media (max-width: 450px) {
    width: 100%;
    float: none;
  }
}
