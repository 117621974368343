.radioButton {
  position: relative;
  width: 100%;

  span {
    user-select: none;
  }
}

.radioButton__content {
  width: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: inline-flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
}

/* iOS fix */
.radioButton__content--selected-ios {
  border: solid 1px #e4ebef;
  border-color: #6bc348 !important;
}

.radioButton__input {
  opacity: 0;
  position: absolute;
}

.radioButton__dot {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  background-image: url('../../../styles/icons/radio-buttons-sprite.svg');
  background-repeat: no-repeat;
  background-position: 0 -20px;

  .radioButton__content--selected & {
    background-position: 0 0;
  }
}

// Inline
.radioButton__content--inline {
  padding: 10px;
  border: solid 1px #e4ebef;
  border-radius: 3px;
  transition: 0.4s ease;
  gap: 10px;
  white-space: nowrap;

  &:focus {
    outline: none;
  }

  &:hover {
    border-color: #999999;
  }

  .radioButton__input:checked + & {
    border-color: #6bc348;
  }

  .radioButton__input:focus-visible + & {
    border-color: #055bd4;
  }
}
