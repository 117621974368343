.settlementCompleteModalContents__loadingSpinner {
  padding: 40px 0 25px 0;
  height: 210px;
}

.settlementCompleteModalContents__primaryText {
  font-size: 20px;
  text-align: center;
  padding: 20px 0 5px 0;
}

.settlementCompleteModalContents__secondaryText {
  font-size: 14px;
  text-align: center;
  padding-bottom: 30px;
}

.settlementCompleteModalContents__footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e4ebef;
  padding: 20px 0 0 0;
}

.settlementCompleteModalContents__footer--singleButton {
  @extend .settlementCompleteModalContents__footer;
  justify-content: space-around;
}

.settlementCompleteModalContents__button {
  min-width: 150px;
}

.settlementCompleteModalContents__icon {
  height: 70px;
  width: 70px;
  font-size: 50px;
  color: #e4ebef;
  border: 1px solid #e4ebef;
  border-radius: 40px;
  padding: 8px 0 0 5px;
  margin: 20px auto 10px auto;
}

.settlementCompleteModalContents__input {
  max-width: 150px;
  margin: 20px auto 10px auto;
}

.settlementCompleteModalContents__expiry {
  text-align: center;
  padding: 5px 0 25px 0;
  font-style: italic;
  color: #afafaf;
}
