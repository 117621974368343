@import '~/styles/webshops-styles.scss';

.vehicleCard__vehicleImage {
  width: 100%;
  height: 100%;
  vertical-align: top;
  color: transparent;
  background: $light-background;
}

