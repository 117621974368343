.addressLine__container {
  overflow: hidden;
  position: relative;
  @media (max-width: 1024px) {
    float: left;
  }
}

.addressLine__address {
  text-transform: capitalize;
}
