.percentageInput {
  width: 100%;
  vertical-align: middle;
  height: 40px;
  position: relative;
}

.percentageInput__input {
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  float: left;
  height: 40px;
  padding: 10px;
  margin: 0;
  border: 1px solid #e4ebef;
  border-radius: 4px;
  background-color: #fff;
  font-size: 1.4rem;
  outline: none;
  background-position: right;
  background-repeat: no-repeat;
}
.percentageInput__input--hover {
  border: 2px solid #999999;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
}

.percentageInput__input--focus {
  border: 2px solid blue;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
}

.percentageInput__suffix--hover {
  border-left: 2px solid #999999;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
}

.percentageInput__suffix--focus {
  border-left: 2px solid #055bd4;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
}

.percentageInput__input--pushDeal {
  @extend .percentageInput__input;
  text-align: right;
  height: 37px;
  padding: 0px 40px 0px 10px;
  &.percentageInput__input--hover {
    border: 1px solid #999999;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  }

  &.percentageInput__input--focus {
    border: 1px solid blue;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  }

  &.percentageInput__suffix--hover {
    border-left: 1px solid #999999;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  }

  &.percentageInput__suffix--focus {
    border-left: 1px solid #055bd4;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  }
}

.percentageInput__input--small {
  height: 30px;
  padding: 0 10px 0 10px;
}

.percentageInput__invalid {
  border: 1px solid #e66c6c;

  &:focus {
    border: 1px solid #055bd4;
  }
}

.percentageInput__disabled {
  background-color: #eaeaea;
}

.percentageInput__suffix {
  position: absolute;
  right: 2px;
  top: 2px;
  height: 36px;
  border-left: 1px solid #e4ebef;
  border-radius: 0 4px 4px 0;
  background-color: #f9fafc;
  padding: 0 10px;
  text-align: center;
  vertical-align: middle;
  line-height: 34px;
  font-size: 14px;
}

.percentageInput__suffix--invalid {
  border-left: 2px solid #e66c6c;
}

.percentageInput__suffix--small {
  height: 26px;
  line-height: 26px;
  font-size: 14px;
}

.percentageInput__suffix--pushDeal {
  @extend .percentageInput__suffix;
  height: 33px;
  &.percentageInput__suffix--hover {
    border-left: 1px solid #a8a8a8;

    &.percentageInput__suffix--valid {
      border-left: 1px solid #a8a8a8;
    }

    &.percentageInput__suffix--invalid {
      border-left: 1px solid #a8a8a8;
    }
  }
  &.percentageInput__suffix--focus {
    border-left: 1px solid #055bd4;

    &.percentageInput__suffix--valid {
      border-left: 1px solid #055bd4;
    }

    &.percentageInput__suffix--invalid {
      border-left: 1px solid #055bd4;
    }
  }

  &.percentageInput__suffix--invalid {
    border-left: 1px solid #e66c6c;
  }
}
