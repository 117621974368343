.editQuoteCommissionsForm__modalHeader {
  text-align: left;
  font-weight: 700;
  font-size: 1.8rem;
  color: #000;
}

.editQuoteCommissionsForm__divider {
  background: #d4d4d4;
  width: calc(100% - 20px);
  height: 1px;
  margin: 20px 10px;
}

.editQuoteCommissionsForm__applyTo {
  background: #e6f1f7;
  border: 1px solid #bce8f1;
  border-radius: 4px;
  padding: 15px;
  color: #31708f;
}

.editQuoteCommissionsForm__radioButton {
  margin-bottom: 15px;
}

.editQuoteCommissionsForm__formFooter {
  overflow: hidden;
}

.editQuoteCommissionsForm__applyButton {
  float: right;
  width: 120px;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.editQuoteCommissionsForm__cancelButton {
  float: left;
  width: 120px;

  @media screen and (max-width: 500px) {
    width: 100%;
    margin: 10px 0 0 0;
  }
}
