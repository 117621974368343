.closeDeal__top {
  padding: 70px 20px 20px 20px;
  text-align: center;
}

.closeDeal__buttonContainer {
  background-color: #fbfcf7;
  padding: 20px;
  margin: 20px 0 0 0;
  border-top: solid #ebebeb 1px;
  overflow: auto;
}

.closeDeal__reopen {
  width: 150px;
  float: right;
  @media (max-width: 450px) {
    width: 100%;
    margin: 0 0 20px 0;
  }
}

.closeDeal__cancel {
  width: 150px;
  float: left;
  @media (max-width: 450px) {
    width: 100%;
  }
}
