.fieldGroupRhf {
  width: 100%;
  max-width: 450px;
  margin: 24px auto 0px;

  &.fullWidth {
    max-width: unset;
    margin: 24px 0 0;
  }

  &.no-margin {
    margin-top: 0;
  }
  &__helperText {
    padding-bottom: 10px;
    font-size: 12px;
  }

  &__label {
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-weight: 400;
      font-style: italic;
      font-size: 13px;
    }
  }

  &__input {
    padding: 5px 0 0 0;
    font-size: 13px;
  }
}
