@import '../../styles/themes/themes.scss';

.cancelApplicationModal {
  font-size: 1.6rem;
}

.cancelApplicationModal__header {
  font-weight: bold;
  margin: 0 0 8px 0;
}

.cancelApplicationModal__subheader {
  padding: 0 0 20px 0;
  border-bottom: solid 2px #f5f5f5;
}

.cancelApplicationModal__consumer {
  margin: 20px 0;
  border: solid 2px #f5f5f5;
  border-radius: 3px;
  display: flex;
  padding: 15px;
}

.cancelApplicationModal__consumerImage {
  font-size: 3rem;
  height: 38px;
  width: 38px;
  border-radius: 50%;
  color: #c3c9cf;
  border: solid 2px #c3c9cf;
  align-self: center;
  margin: 0 16px 0 0;
}

.cancelApplicationModal__consumerData {
}

.cancelApplicationModal__name {
  font-weight: bold;
}

.cancelApplicationModal__tel {
}

.cancelApplicationModal__quote {
  border: solid 2px #f5f5f5;
  border-radius: 3px;
  padding: 16px;
  margin: 0 0 20px 0;
}

.cancelApplicationModal__quoteTop {
  border-bottom: solid 2px #f5f5f5;
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  align-items: center;

  @media (max-width: 650px) {
    flex-wrap: wrap;
  }
}

.cancelApplicationModal__quoteData {
  display: flex;
  align-items: center;

  @media (max-width: 650px) {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    text-align: center;
  }
}

.cancelApplicationModal__funder {
  width: 150px;
  margin: 0 16px 0 0;

  @media (max-width: 650px) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.cancelApplicationModal__product {
  @media (max-width: 650px) {
    width: 100%;
    margin: 20px 0 0 0;
  }
}

.cancelApplicationModal__status {
  @media (max-width: 650px) {
    margin: 20px auto;
  }
}

.cancelApplicationModal__quoteBottom {
  padding: 16px 0 0;
  display: flex;
  font-size: 1.4rem;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
}

.cancelApplicationModal__quoteFigure {
  width: 25%;

  @media (max-width: 600px) {
    width: 50%;
    &:nth-child(3),
    &:nth-child(4) {
      margin: 10px 0 0 0;
    }
  }
}

.cancelApplicationModal__figureName {
  margin: 0 0 10px 0;
}

.cancelApplicationModal__figureValue {
  font-weight: bold;
}

.cancelApplicationModal__buttons {
  display: flex;
  justify-content: space-between;
  border-top: solid 2px #f5f5f5;
  padding: 30px 0 16px 0;

  @media (max-width: 500px) {
    flex-wrap: wrap;
  }
}

.cancelApplicationModal__button {
  width: 200px;

  @media (max-width: 500px) {
    width: 100%;
    margin: 0 0 20px 0;
  }
}

.cancelApplicationModal__loading {
  margin: 30px auto 40px;
  text-align: center;
}

.cancelApplicationModal__loadingSpinner {
  margin: 0 auto 30px;
  width: 150px;

  @include theme(
    (
      border-color: bodyMain
    )
  );
}

.cancelApplicationModal__success {
  margin: 30px auto 40px;
  text-align: center;
  font-size: 1.6rem;

  @include theme(
    (
      color: bodyMain
    )
  );
}

.cancelApplicationModal__successIcon {
  max-width: 200px;
  margin: 0 auto 20px;
}

.cancelApplicationModal__successHeader {
  font-weight: bold;
  margin: 0 0 8px 0;
}

.cancelApplicationModal__successText {
  color: #000000;
  margin: 0 0 30px 0;
}

.cancelApplicationModal__successButton {
  max-width: 300px;
  margin: 0 auto;
}
