.applicationStatus__status {
  padding: 50px 10%;
  min-height: 60vh;
  @media (max-width: 500px) {
    padding: 50px 10px;
  }
}

.applicationStatus__button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 10px;
}

.applicationStatus__combinedTitle {
  margin: 3rem 0 1.5rem;
  text-align: left;
  font-size: 1.5rem;
  font-weight: bold;
}

.applicationStatus__modal {
  font-size: 1.6rem;
  padding: 25px;
}

.applicationStatus__extraLoans {
  border: 1px solid #d2d3d5;
  border-radius: 12px;
  position: relative;
  margin-top: 2rem;
}

.applicationStatus__infoMessage {
  margin: 10px 0;

  h2:first-child {
    font-weight: bold;
    font-size: 1.8rem;
  }
  ul {
    padding-left: 20px;
  }
  ul li {
    margin-bottom: 10px;
  }
  .icon:first-child {
    font-size: 3rem;
  }
}
.applicationStatus__form--submit-btn {
  margin-top: 20px;
}
.applicationStatus__form--error {
  color: #e66c6c;
}
.applicationStatus__combinedInfoMessage {
  margin: -10px 0 30px 0;
  padding: 10px;
  border: 1px solid #d2d3d5;
  border-radius: 5px;
  box-shadow: -5px 5px 5px #e6e6e6, 5px 5px 5px #e6e6e6;
}
.applicationStatus__loadingSpinner {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 25px 0 0 0;
  background: rgba(100, 100, 100, 0.1);
}

.applicationStatus__loadingLoan {
  opacity: 0.3;
  pointer-events: none;
}

.applicationStatus__modal--title {
  font-weight: bold;
  padding: 0 0 10px 0;
}

.applicationStatus__modal--info {
  border: 1px solid #e4ebef;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.applicationStatus__modal--links {
  padding: 5px 0;
  font-size: 14px;
}

.applicationStatus__modal--link {
  padding: 5px 0;
}

.applicationStatus__modal--text {
  padding: 5px 0 15px 0;
  font-size: 14px;
  border-bottom: 1px solid black;
}

.applicationStatus__modal--redText {
  color: red;
  font-weight: bold;
}

.applicationStatus__modal--subtitle {
  font-weight: bold;
  padding: 10px 0;
}

.wotDoHeader {
  font-family: 'Montserrat', 'Open Sans', Arial, sans-serif;
  margin: 25px 0 25px 0;
  font-size: 1.8rem;
  text-align: center;
}

.applicationStatus__documentExpiryWarning {
  margin-top: 40px;
}

.applicationStatus__esignButton {
  color: #055bd4;
  cursor: pointer;
  display: inline-block;
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  font-family: 'Open Sans', Arial, sans-serif;
}

.applicationStatus__esignButton--completed {
  color: #b6b6b6;
  cursor: initial;
}

.applicationStatus__esignLoadingSpinner {
  display: inline-block;
  padding: 0 10px;
  transform: translateY(5px);
}

.applicationStatus__esignCompletedTick {
  color: #64bc46;
  display: inline-block;
  padding: 0 10px;
  transform: translateY(3px);
  font-size: 20px;
}

.applicationStatus__modal--error {
  padding: 10px 0;
}

.applicationStatus__modal--popups {
  padding: 10px 0;
  font-size: 14px;
}

.applicationStatus__modal--LinkContainer {
  display: inline-flex;
  cursor: pointer;
}

.applicationStatus__modal--link {
  color: blue;
  text-decoration: underline;
  word-break: break-all;
}

.applicationStatus__modal--CoptyIcon {
  padding-top: 4px;
}

.applicationStatus__alert {
  margin-bottom: 3rem;

  a {
    text-decoration: underline;
    color: #060a0d;
  }
}

.applicationStatus__paragraph--additional {
  margin-top: 1rem;
}
