@import '../../styles/themes/themes.scss';

.breadcrumbsItem__only {
  margin: 0;
}

.breadcrumbsItem__li {
  height: 34px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding: 6px 30px 6px 14px;
  overflow: visible;
  max-width: 250px;
  color: #818181;
}

.breadcrumbsItem__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.breadcrumbsItem__isFirst {
  min-width: 80px;
  position: relative;
  color: #818181;
}

.breadcrumbsItem__isLast {
  padding: 6px 15px 6px 15px;
  text-decoration: none;
  font-weight: 600;
}

.breadcrumbsItem__arrow {
  height: 36px;
  width: 7px;
}

.breadcrumbsItem__homeIcon {
  font-size: 28px;
  position: absolute;
  right: 31px;
  top: calc(50% - 15px);

  @include theme(
    (
      color: bodyMain
    )
  );
}

.breadcrumbsItem__isLast {
  padding: 6px 17px 6px 15px;
  color: #707070;
  text-decoration: none;
  font-weight: 600;
}

.breadcrumbsItem__chevron {
  position: absolute;
  right: 0;
  top: calc(50% - 8px);
}

.breadcrumbsItem__link {
  text-decoration: none;
  color: #818181;
}

.breadcrumbsItem__isCollapsed {
  max-width: 250px;
}
