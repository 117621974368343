.assignLeadForm__userList {
  margin: 20px 0 0 0;
}

.assignLeadForm__dividerTop {
  margin: 10px 0 15px 0;
}

.assignLeadForm__dividerBottom {
  margin: 15px 0 10px 0;
}

.assignLeadForm__title {
  font-size: 2rem;
  font-weight: 700;
  margin: 0 0 5px 0;
  padding: 0 0 0 0;
  text-align: left;
  @media (max-width: 500px) {
    font-size: 1.6rem;
  }
}

.assignLeadForm__divider {
  margin: 10px 0;
  height: 1px;
  width: 100%;
  background: #e0e0e0;
}

.assignLeadForm__customerNameTitle {
  font-weight: 700;
  display: block;
  margin: 0 0 5px 0;
}

.assignLeadForm__customerName {
  font-weight: 400;
  display: block;
  margin: 10px 0 10px 0;
}

.assignLeadForm__vrm {
  display: inline-block;
  vertical-align: middle;
}

.assignLeadForm__makeModel {
  display: inline-block;
  vertical-align: text-top;
}

.assignLeadForm__subHeading {
  text-align: left;
  color: #000;
  font-weight: 600;
  font-size: 1.5rem;
}

.assignLeadForm__noAssignedUsers {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  color: #979a9c;
}
