.tagWithIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: nowrap;
  border-radius: 22px;
  padding: 4px 5px;
  font-size: 12px;
  background: #c9c9c9;
  color: black;

  .iconStart {
    display: flex;
    margin-right: 8px;
    font-size: 16px;
  }

  .tagText {
    margin-right: 5px;
  }

  .iconEnd {
    font-size: 12px;
  }

  &.clickable {
    cursor: pointer;
  }

  &.error {
    background: #ffc8cc;
    color: #980008;
  }

  &.warning {
    background: #f4ba52;
    color: black;
  }

  &.info {
    background: #d3e9f0;
    color: #30364e;
  }

  &.success {
    background: #367f14;
    color: white;
  }

  &.bold {
    font-weight: bold;
  }
}
