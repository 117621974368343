.optionContainer {
  display: flex;
  padding: 4px 8px;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 8px;
  overflow: hidden;
  gap: 5px;
  &:last-child {
    margin-bottom: unset;
  }
}
