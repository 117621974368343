@import '../../styles/themes/themes.scss';
.attentionPanel {
  border: solid #f4f4f4 1px;
  padding: 20px;
  max-width: 760px;
  width: 100%;
  margin: 20px auto;
  font-size: 1.4rem;
  font-weight: bold;
  border-radius: 5px;
}

.attentionPanel__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.attentionPanel__headerIcon {
  width: 30px;
  height: 30px;
  font-size: 30px;
  margin: 0 10px 0 0;
}
.attentionPanel__content {
  font-weight: normal;
  margin-top: 10px;
  margin-left: 40px;
}

.attentionPanel__content__link {
  @include theme(
    (
      color: bodyMain
    )
  );
  transition: color 0.3s linear;
  margin: 0 6px;
  &:hover {
    @include themeDarken(
      (
        color: bodyMain
      )
    );
  }
}
