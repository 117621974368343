@import '../../../../styles/themes/themes.scss';

.circleConsumerImage {
  text-align: center;
  border: 2px solid;

  width: 90px;
  height: 90px;
  border-radius: 50%;
  font-size: 65px;
  color: #c3c9d0;
}

.circleConsumerImage--consumer {
  padding: 8px 0 0 0;
}

.circleConsumerImage--corporate {
  padding: 8px 0 0 0;
}

.circleConsumerImage--small {
  width: 50px;
  height: 50px;
  font-size: 35px;
}

.circleConsumerImage--small.circleConsumerImage--consumer {
  padding: 5px 0 0 0;
}

.circleConsumerImage--small.circleConsumerImage--corporate {
  padding: 5px 0 0 0;
}
