.vehicleNotesModal__modal {
  width: 400px;
  padding: 0;
  @media (max-width: 768px) {
    width: 400px;
  }
  @media (max-width: 450px) {
    width: 95%;
  }
}

.vehicleNotesModal__container {
  position: relative;
  height: 100%;
}

.vehicleNotesModal__header {
  font-weight: bold;
  font-size: 2rem;
  border-bottom: solid #ebebeb 1px;
  padding: 20px;
  margin: 0 0 20px 0;
}

.vehicleNotesModal__commentsSection {
  padding: 0 20px;
}

.vehicleNotesModal__addCommentSection {
  margin: 0 0 20px 0;
  overflow: hidden;
}

.vehicleNotesModal__initials {
  font-size: 2rem;
  font-weight: bold;
  border: solid #9e9e9e 1px;
  border-radius: 2px;
  color: #9e9e9e;
  padding: 9px 0;
  text-align: center;
  width: 45px;
  height: 45px;
  background-color: #ffffff;
  float: left;
}

.vehicleNotesModal__textareaContainer {
  float: right;
  width: calc(100% - 55px);
}

.vehicleNotesModal__form {
  padding: 20px;
  border-top: solid #b3b3b3 1px;
  background-color: #fbfcf7;
}

.vehicleNotesModal__formFooter {
  overflow: auto;
}

.vehicleNotesModal__commentButton {
  float: right;
  width: 150px;
  @media (max-width: 450px) {
    width: 100%;
    margin: 0 0 10px 0;
  }
}

.vehicleNotesModal__cancel {
  float: left;
  width: 150px;
  @media (max-width: 450px) {
    width: 100%;
    margin: 0 0 10px 0;
  }
}

.vehicleNotesModal__textarea {
  width: 100%;
  height: 45px;
  resize: none;
  outline: none;
  border-style: solid;
  border-width: 2px;
  border-color: #e4ebef;
  border-radius: 2px;
  font-size: 1.4rem;
  padding: 12px 35px 0 10px;
  background-image: url('../../../styles/icons/note-light.svg');
  background-repeat: no-repeat;
  background-position: 98% center;
  background-size: 25px;
  :hover {
    border-color: #a8a8a8;
  }
  :focus {
    border-color: #055bd4;
  }
}

.vehicleNotesModal__noComments {
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;
  color: #a3a3a3;
  margin: 30px 0;
}
