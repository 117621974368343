.quoteCostsForm {
  margin: 20px 0 20px 0;
}

.editPriceForm__section {
  padding: 0 0 10px 0;
}

.editPriceForm__vatQualifyingOptions {
  font-size: 1.2rem;
}

.editPriceForm__vatMarginScheme {
  display: inline-block;
  vertical-align: middle;
  margin: 0 20px 0 0;
}

.editPriceForm__vatQualifying {
  display: inline-block;
  vertical-align: middle;
}

.editPriceForm__vatCheckbox {
  text-align: right;
}
.editPriceForm__buttonWrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 430px;
  margin: 20px auto;

  @media (max-width: 700px) {
    justify-content: center;
    margin: 0 auto 20px;
  }
}
