.pushDeals__container {
  display: flex;
  flex-direction: column;
}
.pushDeals__deals {
  width: 100%;
}
.pushDeals__timeline {
  width: 15%;
  padding: 20px;
  @media (max-width: 1100px) {
    display: none;
  }
}
.pushDeals__timelineValues {
  margin: 10px auto;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  width: 50px;
  text-align: center;
}
.pushDeals__dateLine {
  height: 118px;
  border-left: solid #eaeaea 2px;
  border-radius: 5px;
  border-right: solid #eaeaea 2px;
  width: 1px;
  margin: 10px auto;
}

.pushDeals__noDeals {
  text-align: center;
}
.pushDeals__message {
  text-align: center;
  max-width: 600px;
  margin: auto;
  font-size: 1.4rem;
  padding: 20px;
}

.pushDeals__showMoreButton {
  cursor: pointer;
  color: #6bc348;
  font-size: 1.4rem;
}
