.panelHeader__wrapper {
  position: relative;
  margin: 0;
  background-color: white;
  border-radius: 14px 14px 0 0;
}

.panelHeader__title {
  font-family: 'Montserrat', 'Open Sans', Arial, sans-serif;
  display: flex;
  font-size: 1.6rem;
  color: #4a4a4a;
  line-height: 65px;
  border-bottom: 1px solid #e4ebef;
}

.panelHeader__title.no-border {
  border-bottom: none;
}

.panelHeader__inner {
  width: 100%;
}
