.vehicleForm {
  margin: 20px 0 0 0;
}

.vehicleForm__inner {
  width: 85%;
  margin: 0 auto;
  @media (max-width: 1100px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
}

.vehicleForm__vehicleSuggestionsTitle {
  font-weight: 600;
  margin-bottom: 2px;
}

.vehicleForm__vehicleSuggestionsOuter {
  font-size: 13px;
  padding-bottom: 5px;
}

.vehicleForm__vehicleSuggestionLink {
  display: block;
  color: #7ac3f7;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.vehicleForm__combinedDealWarning {
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin: 20px;
  box-shadow: -5px 5px 5px #e6e6e6, 5px 5px 5px #e6e6e6;
}
