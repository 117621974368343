.cfcPersonalDetailsSummaryTable__panel {
  padding: 10px 20px 1px 20px;
  font-size: 1.4rem;
  line-height: 25px;
}

.cfcPersonalDetailsSummaryTable__email {
  word-wrap: break-word;
}

.cfcPersonalDetailsSummaryTable__tableHeading {
  color: #8c888b;
}

.cfcPersonalDetailsSummaryTable__tableValue {
  color: #000;
  font-weight: 400;
}
