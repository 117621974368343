.tableHeader {
  width: 100%;
}

.tableHeader__trAlt {
  background-color: #f8f9fc;
}

.tableHeader__tr {
  background-color: #f8f9fc;
}
