.quoteTabItem {
  overflow: hidden;
  position: relative;
  height: 50px;
  padding: 8px 20px 8px 10px;
  vertical-align: bottom;

  @media (max-width: 950px) {
    width: 200px;
    padding: 8px 10px;
    height: 70px;
  }
}

.quoteTabItem--fluid {
  width: auto;
}

.quoteTabItem__iconOuter {
  float: left;

  @media (max-width: 950px) {
    float: none;
  }
}

.quoteTabItem__icon {
  height: 35px;
  width: 30px;
  margin: 2px 10px 0 0;
  font-size: 28px;
  color: #5e6977;

  @media (max-width: 950px) {
    width: 100%;
    text-align: center;
    height: 25px;
    font-size: 25px;
  }
}

.quoteTabItem__textOuter {
  overflow: hidden;
}

.quoteTabItem__text {
  font-size: 1.4rem;
  margin: 6px 0 0 0;

  @media (max-width: 950px) {
    margin: 0;
    text-align: center;
  }
}

.quoteTabItem__count {
  @media (max-width: 835px) {
    display: none;
  }
}
