.cfcCheckDetailsButtons__outer {
  overflow: auto;
  margin: 10px 0 0 0;
}

.cfcCheckDetailsButtons__button {
  width: 150px;
  @media (max-width: 600px) {
    margin: 0 auto 20px;
    width: 70%;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
}

.cfcCheckDetailsButtons__apply {
  float: right;
  margin: 0 0 0 30px;
  @media (max-width: 600px) {
    float: none;
  }
}

.cfcCheckDetailsButtons__print {
  float: right;
  @media (max-width: 600px) {
    float: none;
  }
}

.cfcCheckDetailsButtons__cancel {
  float: left;
  @media (max-width: 600px) {
    float: none;
  }
}
