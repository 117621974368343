.lenderUpdates {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

.lenderUpdates__sortContainer {
  padding: 8px 10px 8px 0;
  overflow: auto;
  border-bottom: solid 1px #e4ebef;
}

.lenderUpdates__sort {
  float: right;
  margin: 0 0 0 10px;
}

.lenderUpdates__sortText {
  font-size: 1.4rem;
}

.lenderUpdates__selectWrapper {
  display: inline;
  max-width: 150px;
}

.lenderUpdates__updateContainer {
  position: relative;
}

.lenderUpdates__paginationContainer {
  padding-bottom: 5px;
}
