@import '../../../../styles/themes/themes.scss';

.vehicleDetailsInformation__container {
  line-height: 30px;
  @media screen and (max-width: 1110px) {
    width: 100%;
  }
}

.vehicleDetailsInformation__noPrice {
  padding: 0 30px 0 10px;
  @media screen and (max-width: 1110px) {
    padding: 0 30px 0 10px;
  }
}

.vehicleDetailsInformation__makeModel {
  font-weight: 600;
  font-size: 1.6rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @media screen and (max-width: 1110px) {
    font-size: 1.5rem;
  }
}

.vehicleDetailsInformation__derivative {
  color: #646c76;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.vehicleDetailsInformation__price {
  font-size: 1.6rem;
  font-family: 'Open Sans';
  font-weight: 500;
  letter-spacing: 1.1px;
  margin-top: 5px;
}

.vehicleDetailsInformation__latestText {
  font-size: 1rem;
  letter-spacing: 0;
  white-space: nowrap;
}
