.dealerAccessDeniedPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 40px 20px;
  max-width: 800px;
}

.dealerAccessDeniedPage__message {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px 30px;
}

.dealerAccessDeniedPage__heading {
  font-size: 2rem;
  margin: 20px 0 20px 0;
}

.dealerAccessDeniedPage__text {
  font-size: 1.4rem;
  margin: 20px 0;
}

.dealerAccessDeniedPage__returnToHomePageButton {
  max-width: 200px;
  margin: 0 0 20px 0;
}

