.compareView__changeDetails {
  float: right;

  @media (max-width: 400px) {
    float: none;
    width: 100%;
    margin: 0 0 10px 0;
  }
}

.compareView__returnToList {
  float: right;
  margin: 0 10px 0 0;

  @media (max-width: 400px) {
    float: none;
    width: 100%;
  }
}
