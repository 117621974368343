.table__table {
  border-collapse: collapse;
  width: 100%;
}

.table__tbody {
  width: 100%;
  position: relative;
}
.table__tbody__buttonWrapper {
  margin: 10px 0;
}

.table__td {
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  padding: 0 10px;
}

.table__tableCell {
  font-weight: bold;
}
