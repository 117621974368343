@import '../../../styles/themes/themes.scss';

.customerList {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 30px;
  transition: opacity 0.2s;
  content: '';
  min-width: 600px;
  font-size: 1.3rem;
  opacity: 1;
  @media (max-width: 650px) {
    border: none;
  }
}

.customerList--loading {
  opacity: 0.5;
}

.customerList__loadingSpinner {
  width: 100%;
  text-align: center;
  padding: 25px 0 0 0;
  @include theme(
    (
      color: bodyMain
    )
  );
}

.customerList__noPanelDataContainer {
  width: 100%;
  background-color: #fff;
  padding-bottom: 5px;
}
