@import '../../../styles/themes/themes.scss';

.cardOuter {
  width: 100%;
  margin: 0 0 20px 0;
  position: relative;
  box-shadow: 0 2px 4px rgba(154, 192, 216, 0.16);
  transition: box-shadow 0.3s ease-out, transform 0.3s ease-out, opacity 0.2s ease-out;
  border-radius: 5px;
  &:hover,
  &:focus {
    box-shadow: 0 5px 15px rgba(188, 194, 208, 0.4);
  }
}

.cardOuter__inner {
  padding: 0 10px 10px 10px;
  border-top: solid #dedede 1px;
  border-right: solid #dedede 1px;
  border-left: solid #dedede 1px;
  border-bottom: solid #dedede 1px;
  transition: border-color 200ms ease-in;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.cardOuter__inner--checked {
  border-top: solid 1px #6bc348;
  border-left: solid 1px #6bc348;
  border-right: solid 1px #6bc348;
  border-bottom: solid #dedede 1px;
  transition: border-color 200ms ease-in;
}

.cardOuter__funderLogo {
  width: 150px;
  margin: 15px auto 0;
}

.cardOuter__smallPrint {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f8f9fc;
  padding: 10px;
  border-bottom: solid #dedede 1px;
  border-right: solid #dedede 1px;
  border-left: solid #dedede 1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: border-color 200ms ease-in;
}
.cardOuter__smallPrintButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 460px) {
    flex-direction: column;
    justify-content: center;
  }
}

.cardOuter__smallPrint--selected {
  border-bottom: solid 1px #6bc348;
  border-right: solid 1px #6bc348;
  border-left: solid 1px #6bc348;
  transition: border-color 200ms ease-in;
}
.cardOuter__combinedQuoteMessage {
  max-width: 60%;
  @media (max-width: 700px) {
    min-height: initial;
    width: 184px;
  }
  @media (max-width: 460px) {
    min-height: initial;
    width: 100%;
  }
}
.cardOuter__smallPrintButtonWrap {
  @media (max-width: 460px) {
    margin-left: 26px;
  }
}
.cardOuter__smallPrint-animate-enter {
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.3s ease-in-out;
}

.cardOuter__smallPrint-animate-enter-active {
  transform: scaleY(1);
  transform-origin: top;
  transition: transform 0.3s ease-in-out;
}

.cardOuter__smallPrint-animate-exit {
  transform: scaleY(1);
  transform-origin: top;
  transition: transform 0.3s ease-in-out;
}

.cardOuter__smallPrint-animate-exit-active {
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.3s ease-in-out;
}

.cardOuter__rbpLabel {
  width: 500px;
  font-size: 14px;
  margin: 10px 0 10px 0;
}

.cardOuter__warningOuter {
  display: flex;
  margin: 5px -5px 5px -5px;
  color: #f55e5e;
  padding: 3px 0;
  align-items: center;
  cursor: pointer;
}

.cardOuter__warning {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.cardOuter__warningIcon {
  font-size: 18px;
}

.cardOuter__modalOuter {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.cardOuter__modalTop {
  color: #f55e5e;
  font-size: 18px;
  margin-top: 5px;
}

.cardOuter__modalBottom {
  margin-top: 30px;
}

.cardOuter__warningOuter {
  font-size: 1.4rem;
  display: flex;
  margin: 5px -5px 5px -5px;
  color: #f55e5e;
  padding: 3px 0;
  align-items: center;
}

.cardOuter__warningIcon {
  font-size: 18px;
}
