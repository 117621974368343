.hiddenField {
  margin-bottom: 20px;
}

.hiddenField__inner {
  position: relative;
  font-size: 1.4rem;
  clear: both;
  overflow: hidden;
  zoom: 1;

  @media (max-width: 750px) {
    margin-bottom: 10px;
  }
}

.hiddenField__label {
  position: relative;
  white-space: normal;
  word-wrap: normal;
  min-height: 1px;
  color: #2a2a2a;
  text-align: right;
  width: 30%;
  float: left;
  font-size: 1.4rem;
  font-weight: 600;
  margin: 10px 0 7px 0;
  padding: 0 10px 0 0;
  line-height: 1.82rem;

  @media (max-width: 650px) {
    float: none;
    width: calc(100% - 25px);
    max-width: calc(100% - 25px);
    display: block;
    text-align: left;
    margin-bottom: 0px;
  }
}

.hiddenField__input {
  @media (max-width: 650px) {
    width: 100%;
    float: none;
    overflow: hidden;
  }
}

.hiddenField__inputInner {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 10px;
  margin: 0;
  border: 1px solid #e4ebef;
  border-radius: 4px;
  background-color: #fff;
  font-size: 1.4rem;

  &:hover {
    border: 1px solid #999999;
  }
}
