.label {
  display: block;
  white-space: normal;
  word-wrap: normal;
  min-height: 1px;
  font-size: 15px;
  margin: 0 0 5px 0;
  padding: 0 0 0 0;
  line-height: 1.82rem;
  @media (max-width: 650px) {
    float: none;
    width: 100%;
    max-width: 100%;
    display: block;
    text-align: left;
  }

  // Need to deprecate
  :global(.subLabel) {
    font-weight: 500;
    color: #707070;
  }
}

.label--small {
  font-size: 14px;
}
