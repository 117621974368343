.container {
  width: 100%;
}

.section {
  padding: 10px 0;
  margin-right: 20px;
  border-bottom: solid #e4ebef 1px;
  color: #5e6977;
  @media (max-width: 1200px) {
    margin: 0;
  }
}
.section:last-of-type {
  @media (min-width: 1200px) {
    border-bottom: none;
  }
}

.header {
  display: flex;
  justify-content: space-between;

  button.reset {
    box-shadow: unset;
    margin-right: 0;
    margin-bottom: 0;
    background-color: #3c3c3c !important;
    color: white !important;

    &:hover {
      background-color: lighten(#3c3c3c, 20);
    }
  }

  button.resetSmall {
    margin-right: 0;
    margin-bottom: 0;
    background-color: #3c3c3c;
    color: white;
    padding-left: 10px;
    padding-right: 10px;

    &:hover {
      background-color: lighten(#3c3c3c, 20);
    }
  }

  @media (max-width: 1200px) {
    margin: 0;
    align-items: center;
    cursor: pointer;

    button.button.reset {
      margin-right: 14px;
    }
  }
}

.topText {
  font-weight: 400;
  font-size: 1.8rem;
  color: #5e6977;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}

.button {
  display: none;
  @media (max-width: 1200px) {
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../styles/icons/arrow-down.png');
    transition: all 0.5s ease;
  }
}

.buttonActive {
  @media (max-width: 1200px) {
    transform: rotateZ(-180deg);
  }
}

.reset {
  width: auto;
  display: none;
  border: none;
  background: none;
  outline: none;
  margin: 10px 0 0 0;
  cursor: pointer;
  padding: 5px 0;
  font-size: 1.2rem;
  &:hover {
    color: #3f7af2;
  }

  @media screen and (min-width: 1201px) {
    display: block;
  }
}

.main {
  @media (max-width: 1200px) {
    visibility: hidden;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transform-origin: top;
    transform: scale(0.95);
    transition: visibility 0s ease 0.5s, opacity 0.5s ease, transform 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95),
      height 0.5s ease;
  }
}

.mainOpen {
  @media (max-width: 1200px) {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    min-height: 275px;
    height: auto;
    transition-delay: 0s;
  }
}

.bottomText {
  font-weight: 400;
  font-size: 1.4rem;
  color: #5e6977;
  display: flex;
  align-content: center;
}

.sectionTitle {
  font-weight: 400;
  font-size: 1.6rem;
  color: #5e6977;
  margin: 0 0 4px;
}

.numberOfVehicles {
  width: 35px;
  height: 23px;
  display: flex;
  background-color: #e4ebef;
  border-radius: 15px;
  font-weight: 700;
  align-items: center;
  justify-content: center;
}

.optionContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  cursor: pointer;
  flex-direction: row;
}

.stars {
  flex: 1 0;
  margin-left: 4px;
  margin-right: 4px;
  display: flex;
  font-size: 16px;
}

.dataAccuracyInformation {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.textOptionContainer {
  display: flex;
  align-items: center;
}

.textOptionTitle {
  margin-left: 5px;
  margin-top: 0;
  margin-bottom: 0;
}

.section {
  @media (max-width: 1200px) {
    margin: 0;
  }
}

.dataAccuracyInformation {
  position: relative;
  display: inline-block;
  display: flex;
  align-items: center;
  color: #4e91f5;
  font-size: 20px;
}

