.platformUsage__outer {
  border-radius: 0 0 6px 6px;
  overflow: hidden;
}

.platformUsage__td {
  font-size: 1.8rem;
  font-weight: bold;
}

.platformUsage__lenders {
  width: 100%;
  background-color: #ffffff;
  overflow: hidden;
  padding: 15px 15px 26px 15px;
  font-size: 1.6rem;
  font-weight: bold;
  position: relative;
}

.platformUsage__eachLender {
  width: 50%;
  padding: 10px 30px;
  float: left;
  height: auto;

  @media (max-width: 1100px) {
    width: 25%;
  }

  @media (max-width: 550px) {
    width: 50%;
  }
}

.platformUsage__show {
  border: none;
  background: #f5f4f2;
  outline: none;
  width: 100%;
  position: absolute;
  cursor: pointer;
  bottom: 0;
  left: 0;
  padding: 8px 0;
  font-weight: bold;
}
