@keyframes slideIn {
  from {
    -webkit-transform: translateX(300px);
    -moz-transform: translateX(300px);
    -ms-transform: translateX(300px);
    transform: translateX(300px);
  }

  to {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes sidebarIn {
  from {
    -webkit-transform: translateX(-300px);
    -moz-transform: translateX(-300px);
    -ms-transform: translateX(-300px);
    transform: translateX(-300px);
  }

  to {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(150px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(150px);
    opacity: 0;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-150px);
    opacity: 1;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
