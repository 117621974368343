.reservePaymentTag {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.8rem;
  height: 27px;
  margin: 0 0 0 20px;
  padding: 5px 10px;
  border-radius: 13px;

  @media (max-width: 1100px) and (min-width: 881px) {
    margin: 10px 0 0 0;
  }
}

.reservePaymentTag--blue {
  color: #3676d9;
  background-color: #e1eaf9;
}

.reservePaymentTag--green {
  color: #5cbb38;
  background-color: lighten(#5cbb38, 40%);
}

.reservePaymentTag--red {
  color: #ea8d7c;
  background-color: #fce7e8;
}

.reservePaymentTag--yellow {
  color: #f4bb52;
  background-color: #fef4e1;
}

.reservePaymentTag__text {
  font-size: 1.4rem;
  margin: 0 0 0 5px;
  font-weight: 600;
  white-space: nowrap;
}
