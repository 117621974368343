.sentDealTag__outer {
  display: flex;
  align-items: center;
  color: #646c76;
  font-size: 1.3rem;

  @media (max-width: 420px) {
    display: block;
    text-align: center;
  }
}

.sentDealTag {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.8rem;
  height: 27px;
  margin: 0 5px 0 0;
  padding: 5px 10px 5px 5px;
  border-radius: 13px;

  @media (max-width: 420px) {
    margin: 0 0 5px 0;
  }
}

.sentDealTag--Sent {
  background-color: rgb(217, 233, 249);
  color: rgb(0, 108, 216);
}

.sentDealTag--Viewed {
  background-color: rgb(255, 242, 224);
  color: rgb(255, 167, 0);
}

.sentDealTag--ApplicationSubmitted {
  background-color: rgb(230, 245, 229);
  color: rgb(100, 188, 70);
}

.sentDealTag__text {
  font-size: 1.4rem;
  margin: 0 0 0 5px;
  font-weight: 600;
}
