@import '../../../styles/themes/themes.scss';

.select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #e4ebef;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 1.4rem;
  line-height: 40px;
  outline: none;
  cursor: pointer;
  background-position: right;
  background-repeat: no-repeat;
  background-image: url('../../../styles/icons/arrow-down.png');

  &:hover {
    border-color: #a8a8a8;
  }

  &:focus {
    border-color: #055bd4;
  }
}

.select::-ms-expand {
  display: none;
}

.select--alt {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  margin: 0;
  padding: 0 25px 0 5px;
  border: none;
  height: 27px;
  font-size: 1.5rem;
  font-weight: 400;
  background-image: url('../../../styles/icons/arrow-down.png');
  background-repeat: no-repeat;
  background-position: right center;
  background-color: transparent;
  cursor: pointer;

  @include theme(
    (
      color: bodyMain
    )
  );
}

.select--alt::-ms-expand {
  display: none;
}

.select--isInvalid {
  border-color: #ff613d;

  &:hover {
    border-color: #ff613d;
  }

  &:focus {
    border-color: #055bd4;
  }
}

.select--small {
  height: 31px;
  line-height: 30px;
  font-size: 12px;
}
