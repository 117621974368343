@import '../../styles/themes/themes.scss';

.userHeader {
  min-width: 240px;
  max-width: 240px;
  height: 69px;
  margin: 0 1px 0 0;
  padding: 15px 20px 17px;
  overflow: hidden;
  display: flex;
  @media (max-width: 1000px) {
    display: none;
  }
}

.userHeader__initialContainer {
  border: 2px solid;
  padding: 5px;
  font-size: 1.4rem;
  font-weight: bold;
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 2.6rem;
  border-radius: 50%;
  text-align: center;
  margin: 0 10px 0 0;

  @include theme(
    (
      border-color: headerForeground,
      color: headerForeground
    )
  );
}

.userHeader__nameContainer {
  overflow: hidden;
  text-overflow: ellipsis;
}

.userHeader__name {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 3px 0 0 0;
  text-transform: capitalize;
  @include theme(
    (
      color: headerForeground
    )
  );
}

.userHeader__dealer {
  font-size: 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include theme(
    (
      color: headerForeground
    )
  );
}

