@import '../../../styles/themes/themes.scss';

.marketingPage__outer {
  overflow: hidden;
}

.marketingPage__header {
  font-size: 2.4rem;
  font-weight: bold;
  margin: 0 0 20px 0;
}

.marketingPage__image {
  float: left;
  width: 300px;
  height: auto;
  margin: 0 20px 0 0;

  @media (max-width: 650px) {
    width: 100%;
    max-width: 300px;
    float: none;
    margin: 0 auto;
    height: auto;
  }

  & img {
    width: 100%;
    height: 100%;
  }
}

.marketingPage__questions {
  font-size: 1.8rem;
  line-height: 1.5;
  float: left;
  width: calc(100% - 320px);

  @media (max-width: 650px) {
    width: 100%;
  }
}

.marketingPage__bottom {
  margin: 50px 0 0 0;
  font-size: 1.8rem;
  line-height: 1.5;
}

.marketingPage__ul {
  margin: 0;
  padding: 0 0 0 20px;

  & li {
    line-height: 2;
  }
  color: #6bc348;
}

.marketingPage__span {
  color: #000000;
}

.marketingPage__number {
  font-size: 2.4rem;
  font-weight: bold;
  margin: 20px 0 0 0;
  color: #6bc348;
}
