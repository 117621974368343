.lenderUpdatesFilter__sort {
  float: right;
}

.lenderUpdatesFilter__sortText {
  font-family: 'Open Sans';
  font-size: 1.4rem;
  font-weight: 300;
}

.lenderUpdatesFilter__selectWrapper {
  display: inline;
  max-width: 150px;
}
