@import './panel.scss';

.collapsiblePanel__outer {
  @extend .panel__outer;
  overflow: hidden;
  margin: 0 0 20px;
  padding: 0;
}

.collapsiblePanel__isDisabled {
  @extend .panel__isDisabled;
}

.collapsiblePanel__title {
  font-family: 'Montserrat', 'Open Sans', Arial, sans-serif;
  display: flex;
  font-size: 1.6rem;
  color: #4a4a4a;
  line-height: 65px;
}

.collapsiblePanel__heading {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  padding: 0px 30px;
}

.collapsiblePanel__expandButton {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(0deg);
  transition: transform 0.2s ease-out;
}

.collapsiblePanel__expandButton--up {
  transform: rotate(180deg);
}

.collapsiblePanel__content {
  transition: max-height 0.4s ease;
  padding: 0 30px;
  overflow-y: auto;
  height: 100%;
}

.collapsiblePanel__content--close {
  transition: max-height 0.4s ease;
  max-height: 0;
  padding: 0 30px;
}
