.formFooter {
  border-top: 1px solid #e1e1e1;
  text-align: center;
  padding: 20px 0px 00px;
  margin: 20px 0 0 0;
  overflow: auto;
}

.formFooter__errors {
  margin: 0 auto 20px;
}

.formFooter__button {
  display: block;
  min-width: 190px;
  margin: 0 10px;

  @media (max-width: 768px) {
    margin: 10px auto;
    width: 50%;
  }

  @media (max-width: 520px) {
    width: 100%;
    margin: 10px auto;
  }
}

.formFooter__right {
  float: right;

  @media (max-width: 768px) {
    float: none;
  }
}

.formFooter__left {
  float: left;

  @media (max-width: 768px) {
    float: none;
  }
}
