.generalCreditInformationCard {
  width: 100%;
  height: 100%;
  min-height: 446px;
  padding: 10px;
  border: solid 1px #d8d8d8;
}

.generalCreditInformationCard__title {
  font-weight: bold;
  font-size: 1.6rem;
  text-align: center;
  padding: 0 0 10px 0;
}

.generalCreditInformationCard__subText {
  font-size: 1.4rem;
  text-align: center;
  font-weight: bold;
  color: #7b7b7b;
  padding: 0 10px;
}
