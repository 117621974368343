.confirmMonthlyPaymentsTermModal__header {
  font-size: 18px;
  border-bottom: 1px solid #e4ebef;
  padding: 0 0 5px 0;
  margin: 0 0 20px 0;
  font-weight: bold;
}

.confirmMonthlyPaymentsTermModal__message {
  font-size: 14px;
  margin: 40px 0;
}

.confirmMonthlyPaymentsTermModal__buttonContainer {
  width: 46%;
  text-align: center;
  display: inline-block;
  margin: 0 2%;
}

.confirmMonthlyPaymentsTermModal__button {
  margin: 0 auto;
  max-width: 200px;
}
