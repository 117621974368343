.loansBreakdownView__header {
  font-size: 18px;
  padding-top: 10px;
  font-weight: 700;
}

.loansBreakdownView__figuresContainer {
  width: 100%;
  border: 1px solid #e4ebef;
  padding: 20px;
  font-size: 14px;
  margin-bottom: 20px;
}

.loansBreakdownView__figuresTable {
  width: 100%;
  border: 1px solid #e4ebef;
  margin: 20px 0;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.loansBreakdownView__figuresHeader {
  background: #e9e9e9;
  font-weight: 600;
  width: 100%;
  padding: 15px;
}

.loansBreakdownView__figuresContents {
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.loansBreakdownView__figuresContents--left {
  width: 50%;
  padding: 0 10px 0 0;
}

.loansBreakdownView__figuresContents--right {
  width: 50%;
  padding: 0 0 0 10px;
}

.loansBreakdownView__figuresWarning {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 10px;
  margin-top: 10px;
}

.loansBreakdownView__buttonsContainer {
  display: flex;
  justify-content: space-between;
  padding: 30px 20px 10px 20px;
}

.loansBreakdownView__button {
  width: 170px;
}

.loansBreakdownView__quoteSteps {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.loanBreakdownView__applicationSteps {
  font-size: 15px;
  margin-bottom: 15px;
}

.loanBreakdownView__applicationStepsSummary {
  font-size: 15px;
  margin: 30px 0 50px 0;
}
