.addressHistoryPanel {
  padding: 20px;
}

.addressHistoryPanel__inner {
  clear: both;
  overflow: auto;
  margin: 0 0 10px 0;
}

.addressHistoryPanel__item {
  margin: 0 0 10px 0;
}

.addressHistoryPanel__itemHeader {
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0 0 10px 0;
}
