.forgotPassword__header {
  margin-top: 50px;
  font-size: 3rem;
  font-weight: 300;
  text-align: center;
  @media (max-width: 400px) {
    margin-top: 60px;
  }
}

.forgotPassword__inputArea {
  margin-top: 40px;
  text-align: left;
}

.forgotPassword__actions {
  margin-top: 30px;
}

.forgotPassword__passwordLink {
  color: #84bd00;
}

.forgotPassword__backToLogin {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin: 20px 0;
}

.forgotPassword__error {
  padding: 0;
}

.forgotPassword__text {
  color: #666666;
  text-align: center;
}
