.globalLayout {
  font-family: 'Open Sans', Arial, sans-serif;
}

.globalLayout__overlay {
  @media (max-width: 768px) {
    background: #000;
    z-index: 15;
    opacity: 0.2;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.globalLayout__overlay--hidden {
  z-index: -1;
}

.globalLayout__content {
  overflow: hidden;
  background-color: #f3f4f9;
  position: relative;
  margin: 0 0 0 70px;
  z-index: 0;
  -webkit-overflow-scrolling: touch;
  border-radius: 22px 22px 0 0;

  @media screen and (max-width: 768px) {
    margin: 0;
  }
}

.globalLayout__content-inner {
  overflow-y: auto;
  height: calc(100vh - 70px);
  height: calc(100vh - env(safe-area-inset-bottom) - 70px);
}

.visually-hidden {
  position: absolute;
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
