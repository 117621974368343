.cardItem {
  width: 100%;
  vertical-align: middle;
  display: inline-block;
  margin: 0 0 15px 0;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
}

.cardItem__label {
  vertical-align: top;
  display: block;
  font-size: 1.4rem;
  font-weight: 400;
  color: #adadad;
  text-align: center;
  margin: 0 0 5px 0;
  text-transform: uppercase;
}
.cardItem__content--centered {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 40.5px;
}
