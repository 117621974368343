.container {
  table {
    min-width: 700px;

    thead {
      th {
        color: black;
        font-weight: bold;
        button {
          visibility: hidden;
        }
      }
    }

    thead,
    tbody {
      tr {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        td,
        th {
          padding: 8px 15px;
          flex: 1;
          display: flex;
          align-items: center;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;

          &:first-of-type {
            flex: unset;
            width: unset;
            padding-right: 15px;
          }

          &:nth-of-type(3) {
            flex: 2;
          }

          &:not(:last-of-type) {
            border-right: 1px solid #e4ebef;
          }

          &:last-of-type {
            justify-content: center;
            align-items: center;
            padding: 8px 0;
          }
        }
      }
    }

    @media screen and (max-width: 819px) {
      thead {
        th {
          &:last-of-type {
            display: none;
          }
        }
      }
    }
  }
}

.iconButton {
  background: white;
  width: 40px;
  height: 40px;
  box-shadow: -8px 4px 18px -9px rgba(0, 0, 0, 0.75);
}

.mobileTable,
.desktopTable {
  width: 100%;
}

.mobileTable {
  overflow-x: scroll;
}

.primaryButtonColor {
  color: black;
  background-color: #84dbb1 !important; // overwriting theme button
  &:hover {
    background-color: darken(#84dbb1, 20) !important;
  }
}

.editButton {
  width: unset;
}

.mobileEditButton {
  background: unset;
  border: unset;
  position: sticky;
  right: 0;
}
