@import '../../styles/themes/themes.scss';

.eligibilitySummaryButton {
  overflow: auto;
  padding: 15px;
  @media (max-width: 1024px) {
    margin: 0;
  }
}

.eligibilitySummaryButton__button {
  border: none;
  background-color: transparent;
  float: right;
  width: 215px;
  cursor: pointer;
  outline: none;
  margin: 0;
  color: #6bc348;
  font-family: 'Open Sans', Arial, sans-serif;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    width: 210px;
  }
}

.eligibilitySummaryButton__buttonIcon {
  height: 25px;
  width: 25px;
  margin-top: 2px;
  float: left;
  font-size: 25px;
}

.eligibilitySummaryButton__buttonText {
  font-size: 1.6rem;
  margin: 4px 0 0 0;
  font-weight: 600;
  color: #6bc348;
}
