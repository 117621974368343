@import '../../styles/themes/themes.scss';
@import '../../styles/utility/browsers.scss';
.vehicleImage {
  position: relative;
  overflow: hidden;
  // margin: 0;
}

.vehicleImage__icon {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 13px 22px 15px 22px;
  color: #c3c9d0;
  @include ie-old-only {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.vehicleImage__placeholder {
  @include ie-old-only {
    min-height: 50px;
    max-height: 200px;
  }
}

.vehicleImage__spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: fadeIn 0.5s;
  line-height: 1;
  font-size: 31px;
  color: #6bc348;
}

.vehicleImage__imageContainer {
  position: relative;
  width: 100%;
  height: calc(100% + 1px);
}

.vehicleImage__image {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: 100%;
  transform: translate(-50%, -50%);
}
