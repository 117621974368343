.outer {
  display: flex;
  flex-direction: column;
  padding: 10px 20px 50px 20px;
}
.section {
  padding: 5px 0;
  display: flex;
  align-items: center;
}
.text {
  font-weight: 400;
  font-size: 1.4rem;
  color: #a1a1a1;
}
.title {
  font-weight: 400;
  font-size: 1.8rem;
  color: #5e6977;
}

.starRatingContainer {
  padding: 8px;
  height: 25px;
  display: flex;
  align-items: center;
  margin: 10px;
  background-color: #f0f0f0;
  box-shadow: -1px 4px 7px 0px rgba(0, 0, 0, 0.15);
}

.starRating {
  :global(.starRating__star--fill) {
    color: #30364e;
  }
}
