.gdprAwarenessModal__outer {
  position: fixed;
  top: 0;
  height: 100vh;
  z-index: 9998;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 0 0 0 0;
  font-size: 1.4rem;
  width: 100vw;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.gdprAwarenessModal__inner {
  margin: 50px auto;
  width: 100vw;
  max-width: 800px;
  min-height: 200px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
  padding: 20px;

  @media (max-width: 840px) {
    width: calc(100% - 40px);
    max-width: none;
  }
}

.gdprAwarenessModal__title {
  font-size: 1.8rem;
  font-weight: bold;
  padding: 10px 0;
}

.gdprAwarenessModal__content {
  margin: 0 0 20px 0;

  & p {
    font-size: 1.6rem;
    line-height: 1.4;
  }

  & ul {
    margin: 0 0 0 20px;
    padding: 0;
    font-size: 1.4rem;
  }

  & li {
    margin: 0 0 5px 0;
  }
}

.gdprAwarenessModal__button {
  width: 100%;
  max-width: 300px;
  margin: 40px auto 20px;

  @media (max-width: 450px) {
    max-width: none;
  }
}

.gdprAwarenessModalOuter-enter {
  opacity: 0;
}

.gdprAwarenessModalOuter-enter-active {
  opacity: 1;
  transition: all 400ms ease-in-out;
}

.gdprAwarenessModalOuter-exit-active {
  opacity: 0;
  transition: all 400ms ease-in-out;
}
