.selectInput {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #e4ebef;
  border-radius: 4px;
  background-color: #fff;
  font-size: 1.4rem;
  outline: none;
  background-position: right;
  background-repeat: no-repeat;
  background-image: url('../../../styles/icons/arrow-down.png');
  transition: 0.4s ease;

  &:hover {
    border-color: #c6c6c6;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  }

  &:focus {
    border-color: #055bd4;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  }
}

.selectInput--small {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  margin-bottom: 10px;
}

.selectInput::-ms-expand {
  display: none;
}

.selectInput--invalid {
  border-color: #e66c6c;

  &:focus {
    border-color: #055bd4;
  }
}

.selectInput--loading {
  line-height: 45px;
  background: none;
}
