.monthlyPriceViewerPaymentsRow__termCell {
  width: 170px;
  min-width: 170px;
  max-width: 170px;
  height: 34px;
  font-weight: 600;
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
  padding: 7px 0 7px 20px;
  cursor: pointer;

  :hover > & {
    background-color: #9f9f9f;
    cursor: pointer;
  }
}

.monthlyPriceViewerPaymentsRow {
  &:nth-child(odd) {
    background-color: #f5f5f5;
  }
}

.monthlyPriceViewerPaymentsRow__termCell:hover,
.monthlyPriceViewerPaymentsRow__paymentCell:hover,
.monthlyPriceViewerPaymentsRow:hover {
  background-color: #9f9f9f;
  cursor: pointer;
}

.monthlyPriceViewerPaymentsRow__paymentCell {
  height: 33px;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  padding: 7px 0 7px 20px;
  font-weight: 600;
  border-right: 1px solid #e3e3e3;
}

.monthlyPriceViewerPaymentsRow__payment {
  cursor: pointer;
}

.monthlyPriceViewerPaymentsRow__lastItem {
  border-bottom: 1px solid #e3e3e3;
}

.monthlyPriceViewerPaymentsRow__lastTerm {
  height: 35px;
}
