@keyframes needle_anim {
  from {
    rotate: -115deg;
  }

  to {
    rotate: var(--needle, 0);
  }
}

.gauge {
  width: 100%;
  height: 100%;
}

.needle {
  rotate: -115deg;
  animation: needle_anim 1s forwards cubic-bezier(0.175, 0.885, 0.075, 1.4);
  animation-delay: 0.5s;
}
