.permissionGroupItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 15px 0;
  padding-left: 4px;

  &:first-child {
    padding-top: 7.5px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #e4ebef;
  }
  &:last-child {
    padding-bottom: 0;
  }
  &:only-child {
    padding: 0;
  }
}

.permissionGroupItem__icon {
  width: 30px;
  justify-self: left;
  font-size: 25px;
  margin-right: 5px;
}

.permissionGroupItem__toggle {
  margin-left: auto;
}
.permissionsGroup__header__toggle__chevron {
  border: 0;
  background: none;
  transition: transform 0.8s ease-in-out;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  right: 18px;
}
.permissionsGroup__header__toggle__chevron--open {
  transform: rotate(180deg);
}
