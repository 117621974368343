.detailsTable__container {
  &--dark {
    background-color: #e1e1e1;
  }

  color: #30364e;
  padding: 14px 16px;

  @media (min-width: 800px) {
    margin: 0px 8px 0px 8px;
    padding: 14px 8px 0px;
    border-radius: 6px;
  }

  header {
    font-weight: bold;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: #a6a6a6;
    }

    span {
      white-space: nowrap;
      padding: 0px 7px;
      text-align: center;
      font-size: 14px;
    }

    h1 {
      font-weight: bold;
    }
  }

  dl {
    @media (min-width: 800px) {
      padding: 0px;
      column-count: 2;
      column-rule-color: #bfbfbf;
      column-rule-width: 1px;
      column-rule-style: solid;
      column-gap: 60px;
      border-radius: 6px;
    }

    @media (min-width: 1064px) {
      column-count: 3;
    }
  }
  &--dark dl {
    padding: 0 20px;
  }
  &--single-column {
    dl {
      column-count: 1;
    }
  }
}
