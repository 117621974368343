.erroredQuote {
  color: #444;
  font-size: 1.2rem;
  padding: 10px 0;
  position: relative;

  @media (max-width: 1200px) {
    display: block;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-bottom: 20px;
  }
}

.erroredQuote__border {
  height: calc(100% + 2px);
  width: 1px;
  background-color: #dedede;
  position: absolute;
  right: -1px;
  top: -1px;
  @media (max-width: 1166px) {
    display: none;
  }
}
