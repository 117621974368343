.scrollableTable {
  position: relative;
}

.scrollableTable__scrollIndicator {
  text-align: right;
  padding: 10px 5px 10px 0;
}

.scrollableTable__inner {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: auto;
}
