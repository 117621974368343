.pushDealProgress__container {
  width: 100%;
  height: 80px;
  border-bottom: solid #e5e7ec 5px;
  display: flex;
  position: relative;
  border-top: 1px solid #e4ebef;
}
.pushDealProgress__progressStatus {
  content: ''; /* This is necessary for the pseudo element to work. */
  display: block; /* This will put the pseudo element on its own line. */
  padding-top: 21px; /* This creates some space between the element and the border. */
  border-bottom: solid #6bc348 4px;
  position: absolute;
  top: 54px;
  left: 0; /* ie11 doesn't default to 0, defaults to 100% */
}
