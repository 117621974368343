.homePage {
  max-width: unset;
  padding: 48px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  > h1 {
    font-size: 4rem;
    font-weight: 700;
    color: #30364e;
    margin: 0px;
  }
  > p {
    font-size: 1.4rem;
    font-weight: 400;
    color: #5a5a5a;
    margin: 0px;
  }
  .grid {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    align-self: flex-start;
    gap: 16px;

    .funnelReport {
      grid-column: 1 / 2;
    }

    .funnelReport__image {
      grid-column: 2 / 3;
      height: auto;
      width: 100%;
      object-fit: cover;
    }

    @media screen and (min-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    // @media screen and (min-width: 1440px) {
    //   grid-template-columns: repeat(4, 1fr);
    // }
  }
}
