.inlineWarning {
  color: #ea6a66;
  font-size: 1.4rem;
  width: auto;
  display: table;
  margin: 0 auto;
}

.inlineWarning__tableRow {
  display: table-row;
}

.inlineWarning__imageContainer {
  display: table-cell;
  width: 20px;
  vertical-align: middle;
}

.inlineWarning__image {
  height: 20px;
  width: 20px;
}

.inlineWarning__body {
  display: table-cell;
  padding: 10px 10px 10px 5px;
  vertical-align: middle;
  font-weight: 700;
}
