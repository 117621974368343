.card {
  background: white;
  color: #4a4a4a;
  border-radius: 8px;
  padding: 12px 16px;
  min-height: 125px;
  width: 300px;
  text-wrap: wrap;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  &--active {
    border: 1px #2d68b1 solid;
  }
}

.statTitle {
  background-color: #d9d9d9;
  width: 180px;
  height: 18px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.metric {
  font-size: 10px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.metricIcon {
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  height: 35px;
  width: 35px;
}

.bigValue {
  background-color: #d9d9d9;
  margin-top: 0.8rem;
  width: 120px;
  height: 18px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.percentageChange {
  background-color: #d9d9d9;
  margin-top: 2rem;
  width: 80px;
  height: 18px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.statTitle::before,
.bigValue::before,
.percentageChange::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to right, #d9d9d9 0%, rgba(0, 0, 0, 0.07) 20%, #d9d9d9 40%, #d9d9d9 100%);
  background-repeat: no-repeat;
  background-size: 450px 400px;
  animation: shimmer 1s linear infinite;
}

.metricIcon::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to right, #d9d9d9 0%, rgba(0, 0, 0, 0.07) 20%, #d9d9d9 40%, #d9d9d9 100%);
  background-repeat: no-repeat;
  background-size: 450px 400px;
  animation: shimmer 1s linear infinite;
  animation-delay: 0.22s;
}

@keyframes shimmer {
  0% {
    background-position: -450px 0;
  }
  100% {
    background-position: 450px 0;
  }
}

//inspired by: https://codepen.io/gatoledo1/pen/zYamZMw
