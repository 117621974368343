.customerVehicleTab {
  width: 200px;
  height: 80px;
}

.customerVehicleTab__top {
  display: flex;
  justify-content: center;
}

.customerVehicleTab__time {
  margin: 0 7.5% 0 7.5%;
}

.customerVehicleTab__reg {
  border-radius: 1px;
  background-color: #fff36b;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 0 0 7px;
  margin: 0 5% 0 0;
  width: 40%;
  height: 17px;
  position: relative;
}

.customerVehicleTab__regBlue {
  width: 7px;
  height: 100%;
  background: #4d8df5;
  position: absolute;
  left: 0;
  top: 0;
}

.customerVehicleTab__makeModel {
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  margin: 10px 0;
  padding: 0 5px;
}

.customerVehicleTab__price {
  text-align: center;
}
