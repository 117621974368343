@import '../../styles/themes/themes.scss';

.header {
  height: 70px;
  background-size: 100% 70px;
  background-position: center;
  background-repeat: no-repeat;
  position: sticky;
  top: 0;
  right: 0;
  width: calc(100% - 70px);
  left: 70px;
  z-index: 10;
  display: flex;

  @media screen and (max-width: 768px) {
    width: 100%;
    left: 0;
  }

  @include theme(
    (
      background-color: headerBackground,
      border-bottom: headerBorderBottom
    )
  );
}

.header__logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header__ivendiLogo {
  margin: 0;
  padding: 5px 15px 5px;
  float: left;
  @media (max-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 0 0 55px;
  }
}

.header__ivendiLogoInner {
  width: 120px;
  display: block;
  height: 58px;
  background-image: url(../../styles/icons/ivendi_smlr.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 80px auto;
  margin: 0 0 0 20px;

  @media (max-width: 400px) {
    background-size: 80px auto;
    background-position: left;
    margin: 0;
    width: 80px;
  }
}

.header__motonovoLogo {
  margin: 0 0 0 20px;
  padding: 3px 15px 5px;
  float: left;

  @media (max-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 0 0 55px;
  }

  @media (max-width: 450px) {
    padding: 0;
    margin: 5px 0 0 55px;
  }
}

.header__motonovoLogoInner {
  display: block;
  float: left;
  width: 120px;
  height: 43px;
  background-image: url('../../styles/icons/motonovo.svg');
  background-repeat: no-repeat;
  background-size: 100%;

  @media (max-width: 450px) {
    margin: 2px 0 0 0;
    background-position: center top;
    background-size: 100px auto;
    height: 39px;
  }
}

.header__poweredBy {
  max-width: 300px;
  margin: -15px 0 0 0;
}

.header__poweredByText {
  font-size: 1.2rem;
  margin: 14px 0 0;
  color: #666666;
}

.header__poweredByLink {
  transition: all 0.3s ease;
  font-weight: 700;
  color: #666666;

  &:hover {
    @include theme(
      (
        color: bodyMain
      )
    );
  }
}

.header__logoLink {
  display: block;
  width: 100%;
  height: 100%;
}

.header__bnppLogo {
  margin: 0 0 0 20px;
  padding: 3px 15px 5px;
  float: left;

  @media (max-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 0 0 55px;
  }

  @media (max-width: 450px) {
    padding: 0;
    margin: 5px 0 0 55px;
  }
}

.header__bnppLogoInner {
  display: block;
  float: left;
  width: 160px;
  height: 43px;
  background-image: url(../../styles/icons/bnpp_logo.png);
  background-repeat: no-repeat;
  background-size: 100%;

  @media (max-width: 450px) {
    margin: 2px 0 0 0;
    background-position: center top;
    background-size: 100px auto;
    height: 39px;
  }
}

.header__bdkLogo {
  margin: 0 0 0 20px;
  padding: 3px 15px 5px;
  float: left;

  @media (max-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 0 0 55px;
  }

  @media (max-width: 450px) {
    padding: 0;
    margin: 5px 0 0 55px;
  }
}

.header__bdkLogoInner {
  width: 100px;
  display: block;
  height: 43px;
  background-image: url(../../styles/icons/bdk_logo.svg);
  background-repeat: no-repeat;
  background-position-y: center;
  background-size: 100%;

  @media (max-width: 450px) {
    margin: 2px 0 0 0;
    background-position: center top;
    background-size: 100px auto;
    background-position-y: center;
    height: 43px;
  }
}

.header__logoAnimationStart {
  transform: translateX(0);
  transition: all 0.3s ease-out;
}
.header__logoAnimationEnd {
  @media (min-width: 768px) {
    transform: translateX(142px);
    transition: all 0.3s ease-out;
  }
}

button:focus {
  outline: 0;
}

.header__centerSection {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}
