.activityTimelineContent {
  display: flex;
  flex-direction: column;
}

.activityTimelineContent__date {
  margin: 0 0 10px;
}

.activityTimelineContent__text {
}
