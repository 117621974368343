.stockVideoUpload__uploadButton {
  width: auto;
}

.stockVideoUpload__label {
  margin-bottom: 1em;
  display: block;
  font-weight: bold;
}

.stockVideoUpload__textarea {
  margin-bottom: 2em;
}
