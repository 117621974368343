$error-color: #e66c6c;

.hits__loadingIndicator {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 200px;
}
.hits__loadingIndicator > .loadingIndicator {
  position: static;
  display: inline-flex;
}

.hits__Error {
  margin: auto 0;
  text-align: center;
}
.hits__ErrorMessage {
  color: $error-color;
  font-size: 20px;
}
