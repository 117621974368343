.currencyInput {
  width: 100%;
  vertical-align: middle;
  height: 40px;
  position: relative;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  border: 1px solid #e4ebef;
  padding: 1px;
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;

  &:where(:hover) {
    border: 1px solid #a8a8a8;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  }

  &:focus-within {
    border: 1px solid #055bd4;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  }

  &--disabled {
    background-color: #eaeaea;
    pointer-events: none;
  }

  &--invalid {
    border: 1px solid #e66c6c;
  }

  &--small {
    height: 30px;
  }
}

.currencyInput__input {
  all: unset;
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  margin: 0;
  font-size: 1.4rem;
  outline: none;
  background-position: right;
  background-repeat: no-repeat;
  text-align: left;

  .currencyInput--small & {
    height: 26px;
    padding: 0 10px;
  }
}

.currencyInput__addon {
  display: flex;
  align-items: center;
  height: 36px;
  border: 0;
  border-right: 1px solid;
  border-color: inherit;
  border-radius: 4px 0 0 4px;
  background-color: #f9fafc;
  padding: 0 10px;
  text-align: center;
  vertical-align: middle;
  line-height: 34px;
  font-size: 1.96rem;
  color: inherit;
  transition: color 0.2s ease;

  &:not(:first-child) {
    border-radius: 0;
  }
  .borderless {
    border: 0;
  }

  &--suffix {
    border-right: 0;
    border-left: 1px solid;
    border-color: inherit;
    border-radius: 0 4px 4px 0;

    &:not(:last-child) {
      border-radius: 0;
    }
  }
  .currencyInput--small & {
    height: 26px;
    font-size: 1.6rem;
  }
}

.currencyInput__btn {
  cursor: pointer;

  &:focus {
    background: #eaeaea;
  }

  &:disabled {
    color: #989898;
    cursor: default;
  }

  &--edit:hover {
    color: #115ed1;
  }

  &--remove:hover {
    color: #e88d7e;
  }
}

.currencyInput__total {
  text-align: center;
  vertical-align: middle;
  font-size: 1.3rem;
  border-left: 1px solid;
  border-color: inherit;
  background-color: #f9fafc;
  pointer-events: none;
  user-select: none;

  &--disabled {
    p {
      font-size: 1.3rem;
    }
  }
}
