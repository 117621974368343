.paidOutForm {
  margin: 10px 0;
}

.paidOutForm__inner {
  padding: 0 20px;
}

.paidOutForm__infoWarning {
  padding: 0 0 20px 0;
  border-bottom: solid 1px #e0e0e0;
}

.paidOutForm__vehicleDetails {
  border-bottom: solid 1px #e0e0e0;
}

.paidOutForm__inputs {
  padding: 20px 0;
  margin: 0 0 20px 0;
  border-bottom: solid 1px #e0e0e0;
}

.paidOutForm__dateInputs {
  overflow: auto;
}

.paidOutForm__dateLeft {
  width: 50%;
  float: left;
  height: 100%;
  padding: 0 10px 0 0;
  border-right: solid 2px #e0e0e0;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0;
    border-right: none;
  }
}

.paidOutForm__dateRight {
  width: 50%;
  height: 100%;
  float: right;
  padding: 0 0 0 10px;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 10px 0 0 0;
  }
}

.paidOutForm__asterisk {
  font-size: 1.2rem;
  margin: 0 0 10px 0;
}

.paidOutForm__mediaDivider {
  @media (max-width: 1000px) {
    width: 100%;
    border-bottom: solid #e0e0e0 1px;
    margin: 10px auto 20px;
  }
}

.paidOutForm__divider {
  width: 100%;
  border-bottom: solid #e0e0e0 1px;
  margin: 10px auto 20px;
}

.paidOutForm__bottom {
  clear: both;
}

.paidOutForm__checkbox {
  text-align: center;
}

.paidOutForm__checkbox {
  text-align: center;
}

.paidOutForm__buttons {
  clear: both;
  margin: 10px 0;
}

.paidOutForm__proceedButton {
  float: right;
  width: 150px;

  @media (max-width: 500px) {
    float: none;
    width: 100%;
    margin: 0 0 10px 0;
  }
}

.paidOutForm__formLeft {
  float: left;
  width: 50%;
  border-right: solid 1px #e0e0e0;

  @media (max-width: 1000px) {
    float: none;
    width: 100%;
    border-right: none;
  }
}

.paidOutForm__formRight {
  float: right;
  width: 50%;
  @media (max-width: 1000px) {
    float: none;
    width: 100%;
  }
}

.paidOutForm__vapsButton {
  width: 200px;
  margin: 30px auto;
}
