.toggleButtonWithIcon {
  border: none;
  background: none;
  overflow: hidden;
  font-size: 14px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  color: #6bc348;

  @media (max-width: 400px) {
    font-size: 12px;
  }
}

.toggleButtonWithIcon__icon {
  float: left;
  font-size: 17px;
  margin: 0 3px 0 0;
}

.toggleButtonWithIcon__text {
  float: left;

  &:hover {
    text-decoration: underline;
  }
}
