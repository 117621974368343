.selfServiceDealItem {
  padding: 10px;
  width: 100%;
}

.selfServiceDealItem__header {
  font-family: 'Montserrat', 'Open Sans', Arial, sans-serif;
  font-size: 1.6rem;
  text-decoration: underline;
  text-align: center;
  margin: 20px 0;
}

.selfServiceDealItem__tableOuter {
  margin: 20px 0 0 0;
  display: flex;
  border: solid #e4ebef 1px;
  flex-direction: row;
  width: 100%;

  @media (max-width: 1110px) {
    flex-wrap: wrap;
  }
}

.selfServiceDealItem__item {
  flex-grow: 1;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  border-right: solid #e4ebef 1px;
}

.selfServiceDealItem__dealCode {
  @media (max-width: 1110px) {
    width: 33.333%;
    border-bottom: solid #e4ebef 1px;
  }

  @media (max-width: 900px) {
    width: 100%;
    border-right: none;
  }
}

.selfServiceDealItem__code {
  color: #5ec1ff;
  letter-spacing: 2px;
}

.selfServiceDealItem__date {
  @media (max-width: 1110px) {
    width: 33.333%;
    border-bottom: solid #e4ebef 1px;
  }

  @media (max-width: 550px) {
    width: 50%;
  }
}

.selfServiceDealItem__cashPrice {
  @media (max-width: 1110px) {
    width: 33.333%;
    border-right: none;
    border-bottom: solid #e4ebef 1px;
  }

  @media (max-width: 900px) {
    border-right: solid #e4ebef 1px;
  }

  @media (max-width: 550px) {
    width: 50%;
    border-right: none;
  }
}

.selfServiceDealItem__deposit {
  @media (max-width: 1110px) {
    width: 25%;
  }

  @media (max-width: 900px) {
    width: 33.333%;
    border-right: none;
    border-bottom: solid #e4ebef 1px;
  }

  @media (max-width: 550px) {
    width: 50%;
    border-right: solid #e4ebef 1px;
  }
}

.selfServiceDealItem__partExchange {
  @media (max-width: 1110px) {
    width: 25%;
  }

  @media (max-width: 900px) {
    width: 33.333%;
  }

  @media (max-width: 550px) {
    width: 50%;
    border-right: none;
    border-bottom: solid #e4ebef 1px;
  }
}

.selfServiceDealItem__settlement {
  @media (max-width: 1110px) {
    width: 25%;
  }

  @media (max-width: 900px) {
    width: 33.333%;
  }

  @media (max-width: 550px) {
    width: 50%;
  }
}

.selfServiceDealItem__noBorder {
  border-right: none;
}

.selfServiceDealItem__balance {
  @media (max-width: 1110px) {
    width: 25%;
  }

  @media (max-width: 900px) {
    width: 33.333%;
  }

  @media (max-width: 550px) {
    width: 50%;
  }
}

.selfServiceDealItem__tablesOuter {
  padding: 10px;
}
