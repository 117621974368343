@import '~SM/styles/stockStyles.module.scss';

.subModal {
  padding-top: 26px;
  border-top: $grey-001 1px solid;
  color: $black;
}

.title {
  font-size: 16px;
  font-family: 'Montserrat', 'Open Sans', Arial, sans-serif;
  font-weight: 700;
  margin: 0 0 16px;
  color: $black;
}

.paragraph {
  font-size: 12px;
  line-height: 24px;
}

.actionButtons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 19px;
}
