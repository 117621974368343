@import '../../../styles/themes/themes.scss';

.settingsProductTabsItem__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  opacity: 0.46;
  background-image: linear-gradient(
    90deg,
    rgb(236, 236, 236) 0%,
    rgb(236, 236, 236) 20%,
    rgb(246, 246, 246) 39%,
    rgb(255, 255, 255) 100%
  );
  visibility: visible;
  transition: visibility 0.2s, opacity 0.2s;
}

.settingsProductTabsItem__overlay--hidden {
  opacity: 0;
  visibility: hidden;
}

.settingsProductTabsItem__container--base {
  width: fit-content;
  height: 53px;
  display: inline-block;
  vertical-align: middle;
  border-left: 1px solid #dfe0e2;
  border-right: 1px solid #dfe0e2;
  border-top: 1px solid #dfe0e2;
  font-size: 1.2rem;
  cursor: pointer;
  position: relative;
  overflow: visible;
  margin: 0;
  border-radius: 10px 10px 0 0;
}

.settingsProductTabsItem__container--active {
  background: #fff;
  z-index: 11;
  border-top: 1px solid #6bc348;
  border-left: 1px solid #e4ebef;
  border-right: 1px solid #e4ebef;
}

.settingsProductTabsItem__container--inactive {
  background: #f6f7f8;
  box-shadow: inset 0px -7px 10px 0px rgba(44, 44, 44, 0.1);
  color: #7f8080;
  border-bottom: 1px solid #dfe0e2;
  opacity: 0.5;
  margin-bottom: -5px;
  transition: 0.2s all ease-out;

  &:hover {
    opacity: 1;
    margin-bottom: 0;
    color: #4a4a4a;
  }
}

.settingsProductTabsItem__text {
  display: flex;
  justify-content: center;
  font-size: 1.4rem;
  margin-top: 14px;
  padding: 0 20px;
}
