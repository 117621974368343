.changePassword__breadcrumbs {
  margin: 0 0 20px 0;
}

.changePassword__notice {
  border-bottom: 1px solid #ddd;
  padding: 0 0 10px 0;
  margin: 0 0 20px 0;
}

.changePassword__strengthIndicator {
  margin-bottom: 10px;
  position: relative;
  font-size: 1.4rem;
}
