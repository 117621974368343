.organisationContactsForm__form {
  margin: 30px 0;

  @media (max-width: 650px) {
    margin: 20px;
  }
}

.organisationContactsForm__removeContainer {
  width: 100%;
  padding: 20px;
  overflow: auto;
}

.organisationContactsForm__remove {
  width: 100px;
  float: right;
}

.organisationContactsForm__remove {
  width: 140px;
  padding: 5px 0 0 0;
}

.organisationContactsForm__buttonWrapper {
  overflow: hidden;
}

.organisationContactsForm__addContactButton {
  float: right;
}

.miniPanel__wrapper {
  .exclamationWarning {
    margin-bottom: 16px;
  }
}
