.icon {
  width: 20px;
  height: 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  font-size: 20px;
}

.tick {
  color: #6bc348;
  font-size: 20px;
}

.cross {
  color: #e66c6c;
  font-size: 20px;
}

