.personalDetailsForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}
.personalDetailsForm__vat {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.personalDetailsForm__assignToSelf {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.personalDetailsForm__assignToSelf__label {
  margin-left: 6px;
  font-size: 1.4rem;
}
.personalDetailsForm__vatButton {
  width: 15px;
  height: 15px;
  margin: 1px 0 0 4px;
  background-image: url('../../../styles/icons/help-icon.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: white !important;
  border: none;
  cursor: pointer;
  position: absolute;
  left: 100%;
}
.personalDetailsForm__modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 33px;
}
