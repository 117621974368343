.activityTimelineItem {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  position: relative;

  &:first-of-type {
    background-color: rgba(100, 188, 70, 0.075);
    border-radius: 10px;
    .activityTimelineItem__headerIconOuter {
      background-color: rgba(48, 54, 77, 0.2);
    }
    .activityTimelineItem__headerIconOuter--inProgress {
      background-color: transparent;
    }
    .activityTimelineItem__headerIconOuter--orderSummary {
      background-color: transparent;
    }
  }

  &:last-of-type {
    .activityTimelineItem__line {
      display: none;
    }
  }
}

.activityTimelineItem__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 20px 32px;
}

.activityTimelineItem__headerIconOuter {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.activityTimelineItem__headerIconInner {
  width: 18px;
  height: 18px;
  font-size: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(48, 54, 77, 1);
  color: #ffffff;
}

.activityTimelineItem__headerIconInner--inProgress {
  @extend .activityTimelineItem__headerIconInner;
  font-size: 30px;
  background-color: transparent;
  color: black;
}

.activityTimelineItem__headerIconInner--orderSummary {
  @extend .activityTimelineItem__headerIconInner;
  font-size: 32px;
  background-color: transparent;
  color: black;
}

.activityTimelineItem__headerTitle {
  margin: 0 0 0 16px;
  font-weight: bold;
  pointer-events: none;
}

.activityTimelineItem__headerArrow {
  font-size: 20px;
  margin: 0 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(0deg);
  transition: transform 0.2s ease-out;
}

.activityTimelineItem__headerArrow--up {
  transform: rotate(180deg);
}

.activityTimelineItem__content {
  margin: 0;
  padding: 0 30px 0 66px;
  max-height: 0;
  overflow: hidden;
  transition-property: max-height, margin;
  transition-timing-function: ease-out;
  transition-duration: 0.4s;
}

.activityTimelineItem__content--open {
  margin: 0 0 20px;
  max-height: 200px;
  transition-property: max-height, margin;
  transition-timing-function: ease-out;
  transition-duration: 0.4s;
}

.activityTimelineItem__line {
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: rgba(48, 54, 77, 1);
  left: 42px;
  top: 32px;
  z-index: 2;
}
