.dashboardFilter__filter {
  position: relative;
  border-radius: 0;
  outline: none;
  border-top: none;
  border-left: none;
  border-bottom: none;
  border-right: none;
  background-color: #ffffff;
  height: 38px;
  padding: 0;
  width: 20%;
  text-align: center;
  font-size: 1.2rem;
  cursor: pointer;
  font-weight: bold;
  color: #a1a6aa;

  &:hover {
    font-weight: bold;
    color: #000;
  }

  @media screen and (max-width: 400px) {
    min-width: 60px;
  }
}

.dashboardFilter__filter--active {
  background-color: #ededed;
  font-weight: bold;
  color: #000;
}

.dashboardFilter__bar {
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.dashboardFilter__bar-- {
  background: #b3e9ff;
}

.dashboardFilter__bar--high {
  background: #6bc348;
}

.dashboardFilter__bar--medium {
  background: #f4ba52;
}

.dashboardFilter__bar--low {
  background: #e66c6c;
}

.dashboardFilter__bar--none {
  background: #c1c1c1;
}
