@import '../../../styles/themes/themes.scss';

.applicationProgressBar {
  margin: 5px auto;
  overflow: auto;
  width: 100%;
}

.applicationProgressBar__number {
  text-align: left;
  color: black;
  font-size: 1.2rem;

  @media (max-width: 1000px) {
    float: none;
    text-align: center;
    font-size: 1rem;
  }

  @media (max-width: 600px) {
    display: none;
  }
}

.applicationProgressBar__numberComplete {
  @media (max-width: 800px) {
    font-size: 0.9rem;
  }
}

.applicationProgressBar__barOuter {
  width: 90%;
  margin: 8px 0 0 0;
  border-radius: 6px;
  z-index: 50;
  position: relative;
  overflow: hidden;
  float: left;

  @media (max-width: 1000px) {
    width: 90%;
    margin: 5px auto;
    float: none;
  }

  @media (max-width: 600px) {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    border-radius: 0;
  }
}

.applicationProgressBar__back {
  height: 7px;
  margin: 0 auto;
  background-color: #dfe0df;

  @media (max-width: 600px) {
    opacity: 0.3;
  }
}

.applicationProgressBar__progressBar {
  opacity: 0.8;
  z-index: 100;
  height: 10px;
  width: 100%;
  position: absolute;
  top: 0;
  transition: all 0.5s ease-in-out;
}

.applicationProgressBar__progressBar--red {
  background-color: red;
}

.applicationProgressBar__progressBar--orange {
  background-color: orange;
}

.applicationProgressBar__progressBar--green {
  @include theme(
    (
      background-color: bodyMain
    )
  );
}
