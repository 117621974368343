.reservationSetupForm {
  max-width: 900px;
  margin: 30px auto;
}

.reservationSetupForm__termsError {
  margin-bottom: 25px;
}

.reservationSetupForm__stripeWarning {
  padding: 10px;
  padding-bottom: 20px;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
  max-width: 900px;
  margin: auto;
}

.reservationSetupForm--loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reservationSetupForm__smallLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  span {
    margin: 5px 0;
  }
}

.reservationSetupForm__buttonOuter {
  margin: 40px 0 0 0;
  border-top: solid 1px #e4ebef;
  padding: 30px 0 0 0;
  display: flex;
  justify-content: space-between;
}

.reservationSetupForm__button {
  min-width: 150px;
}
.reservationSetupForm__button--link {
  border: none;
  background: none;
  overflow: hidden;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  color: #6bc348;
  text-decoration: none;
  padding: 0;
  margin: 0 3px;
  line-height: initial;
  height: auto;
  width: auto;
  font-size: 14px;
  &:hover,
  &:focus {
    outline: inherit;
    text-decoration: underline;
  }
}

.reservationSetupForm__checkboxLabel {
  margin: 0 10px;
}
.reservationSetupForm__checkboxOuter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.reservationSetupForm__toggleOuter {
  overflow: hidden;
  margin: 0 auto;
  max-width: 450px;
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 700px) {
    width: 100%;
    max-width: none;
    padding: 0 10px;
  }
}
