.tabList {
  position: relative;
  z-index: 20;
  overflow: hidden;
}

.tablist__default--background {
}

.tablist__large--background {
  background-color: #f8f9fc;
  margin: 20px 0;
  border-radius: 12px 12px 0 0;
}

.tablist__largeCfc--background {
  background-color: #ffffff;
}

.tabList__outer {
  overflow-y: hidden;
  overflow-x: auto;
  position: relative;
}

.tabList__inner {
  white-space: nowrap;
  float: left;
  position: relative;
  min-width: 100%;
}

.tablist__default--bottomBorder {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: #e4ebef;
}

.tablist__large--bottomBorder {
}

.tablist__largeCfc--bottomBorder {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: #e4ebef;
}

.tabList__tab {
  display: inline-block;
  vertical-align: top;
}

.tabList__prevArrow {
  position: absolute;
  z-index: 30;
  top: calc(50% - 20px);
  left: 10px;
  width: 40px;
  box-shadow: 0px 6px 20px rgba(188, 194, 204, 0.34);
  border-radius: 20px;
  height: 40px;
  bottom: 0;
  // background: rgba(255, 255, 255, 0.6);
  border: 1px solid #e6e6e6;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 4rem;
  cursor: pointer;
}

.tabList__nextArrow {
  position: absolute;
  top: calc(50% - 20px);
  right: 0;
  width: 40px;
  box-shadow: 0px 6px 20px rgba(188, 194, 204, 0.34);
  border-radius: 20px;
  height: 40px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 4rem;
  cursor: pointer;
}

.tabList__arrowIcon {
  position: absolute;
  top: 20px;
  left: 21px;
  margin: -10px 0 0 -10px;
  width: 15.5px;
  -webkit-user-select: none;
  box-shadow: 0px 6px 20px rgba(188, 194, 204, 0.34);
}

.tabList__hasShadowSpace {
  padding: 0 15px;
}
