.manageUsersTable {
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
}

.manageUsersTable__table {
  border-collapse: collapse;
  width: 100%;
  border: 0px solid #efefef;
}

.manageUsersTable__tableHeader {
  font-weight: 400;
  text-align: left;
  padding: 10px 10px;
}

.manageUsersTable__headerRow {
  width: 10px;
  margin: 0 auto;
  font-size: 1.4rem;
  background: #fafafa;
  border-top: 0px solid #efefef;
  border-bottom: 1px solid #efefef;
}
