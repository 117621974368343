@import '../../../../styles/themes/themes.scss';

.vehicleDetailsMileage {
  line-height: 30px;
  text-align: center;
  display: block;
  font-size: 38px;

  @media screen and (max-width: 1110px) {
    text-align: center;
    clear: left;
  }
}

.vehicleDetailsMileage--small {
  line-height: 30px;
  height: 73px;
  text-align: center;
  padding: 0 10px;
  display: inline-block;
  font-size: 35px;

  @media screen and (max-width: 1110px) {
    text-align: center;
    clear: left;
  }

  color: #5e6977;
}

.vehicleDetailsMileage__icon {
  margin: 0 auto;
  display: block;
  position: relative;
  color: #5e6977;
}

.vehicleDetailsMileage__text {
  font-size: 1.4rem;
}
