.resetPassword {
  overflow-y: auto;
}

.resetPassword__header {
  text-align: left;
  font-size: 1.6rem;
  font-weight: 300;

  @media (min-width: 415px) {
    font-size: 2rem;
  }

  @media (min-width: 651px) {
    font-size: 2.4rem;
  }
}

.resetPassword__text {
  color: #666666;
  text-align: left;
}

.resetPassword__passwordStrengthText {
  margin: 0;
}

.resetPassword__actions {
  margin-top: 5px;
  padding-bottom: 5px;
}

.resetPassword__passwordField {
  @media (max-width: 650px) {
    margin-bottom: 0px;
  }
}
