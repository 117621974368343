.container {
  width: 100%;
  position: relative;
  display: flex;
}

.searchIcon {
  font-size: 30px;
  color: black;
  position: absolute;
  top: 18px;
  left: 8px;
  z-index: 2;
  transform: rotateZ(180deg) rotateX(180deg);
  @media (min-width: 500px) {
    left: 16px;
  }
}

.searchInputAndIconsContainer {
  width: 100%;
  position: relative;
}

.input {
  width: 100%;
  height: 68px;
  font-size: 18px;
  font-weight: 600;
  padding: 15px 15px 15px 40px;
  border-radius: 0 40px 40px 0;
  outline: none;
  border: 1px solid #282c3e;
  @media (min-width: 500px) {
    padding: 15px 15px 15px 68px;
  }

  &:focus {
    .searchSuggestions {
      display: block;
    }
  }
}

.crossIcon {
  display: none;
  position: absolute;
  right: 32px;
  top: 18px;
  opacity: 0.7;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  &--active {
    display: block;
  }
}

