.businessTag {
  background: #616161;
  border-radius: 3px;
  padding: 3px 6px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 900;
  color: white;
  margin: 5px 0 0 0;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  &__icon {
    width: 16px;
    height: 16px;
  }

  &__text {
    margin-left: 7px;
  }

  &--small {
    padding: 2px;
    margin: 0;
  }

  &--small &__icon {
    width: 12px;
    height: 12px;
  }
}
