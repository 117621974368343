.digitalReport__applicationDetailsLayout {
  overflow: hidden;
  width: 100%;
  margin: 0 0 20px 0;

  & > div:first-child {
    float: left;
    width: 48%;

    @media (max-width: 635px) {
      float: none;
      width: 100%;
      margin: 0 0 20px 0;
    }
  }

  & > div:last-child {
    float: right;
    width: 48%;

    @media (max-width: 635px) {
      float: none;
      width: 100%;
    }
  }
}

.digitalReport__table {
  border-collapse: collapse;
  width: 100%;
  font-size: 1.6rem;
}

.digitalReport__tr {
  background-color: #fffffe;

  &:nth-child(odd) {
    background-color: #f7f6f5;
  }
}

.digitalReport__th {
  font-weight: normal;
  padding: 15px;
  text-align: left;
}

.digitalReport__td {
  text-align: left;
  padding: 20px;
}

.digitalReport__layoutOuter {
  overflow: hidden;
}

.digitalReport__layoutLeft {
  float: left;
  width: 35%;
}

.digitalReport__layoutRight {
  float: right;
  margin: 0 0 0 20px;
  width: 60%;
}

.digitalReport__header {
  overflow: hidden;
  margin: 0 0 20px 0;

  @media (max-width: 500px) {
    padding: 0 20px;
  }
}

.digitalReport__headerText {
  font-size: 2.4rem;
  font-weight: bold;
  float: left;
  margin: 5px 0 0 0;
  width: calc(100% - 100px);

  @media (max-width: 500px) {
    font-size: 1.8rem;
  }
}

.digitalReport__headerIcon--outer {
  float: right;
  width: 80px;

  @media (max-width: 500px) {
    width: 60px;
  }

  @media (max-width: 500px) {
    margin: 10px 0 0 0;
  }
}

.digitalReport__headerIcon {
  width: 100%;
  height: 100%;
}

.digitalReport__selectOuter {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 20px 0;

  @media (max-width: 650px) {
    flex-wrap: wrap;
  }
}

.digitalReport__selectInner {
  align-self: center;

  @media (max-width: 500px) {
    padding: 0 0 0 20px;
  }
}

.digitalReport__tileOuter {
  @media (max-width: 650px) {
    margin: 0 0 20px 0;
  }

  @media (max-width: 400px) {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
}

.digitalReport__selectLabel {
  float: left;
  font-size: 1.4rem;
  font-weight: bold;
  margin: 4px 0 0 0;
}

.digitalReport__select {
  float: right;
}

.digitalReport__faq {
  height: 15px;
  width: 15px;
  margin: 0 0 0 5px;
  cursor: pointer;
}

.digitalReport__faqText {
  font-size: 1.6rem;
  padding: 10px;
  line-height: 1.2;
}

.digitalReport__faqModalContent {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  padding: 30px 0 0 0;
}
