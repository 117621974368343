.monthlyPriceViewer {
  padding: 20px;
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
}

.monthlyPriceViewer__quoteDetails {
  border: 1px #e3e3e3 solid;
  border-radius: 4px;
}

.monthlyPriceViewer__helpNotification {
  padding: 20px 0 0 0;
}

.monthlyPriceViewer__dropDownBalanceToChange {
  font-size: 1.4rem;
  font-weight: 600;
  padding: 10px 10px 10px 20px;
  background: #f5f5f5;
}

.monthlyPriceViewer__errorMessage {
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
}

.monthlyPriceViewer__cell {
  width: 170px;
  height: 112px;
  padding: 7px 0 20px 20px;
  border-top: 1px solid #e3e3e3;
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
  z-index: 1;
  position: relative;
}

.monthlyPriceViewer__columnTitle {
  padding: 7px;
  border-right: 1px solid #e3e3e3;
  border-top: 1px solid #e3e3e3;
  border-left: 1px solid #e3e3e3;
  text-transform: uppercase;
  color: #5ec1ff;
  font-weight: 700;
  text-align: center;
}

.monthlyPriceViewer__productHeader {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
}

.monthlyPriceViewer__termsLabel {
  left: 15px;
  position: absolute;
  bottom: -11px;
  background-color: #fff;
  padding: 3px 5px;
  z-index: 10;
  text-transform: uppercase;
  color: #5ec1ff;
}

.monthlyPriceViewer__loadingSpinner {
  padding: 20px 0 0 0;
}

.monthlyPriceViewer__newVehicleWarning {
  margin: 5px 0 0 0;
}
