.applicationLenderNotes__lenderNotes {
  margin: 32px 0 0 0;
}

.applicationLenderNotes__title {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 39px;
}

.applicationLenderNotes__noNotes {
  padding: 20px;
  font-size: 1.4rem;
}

.applicationLenderNotes__items {
  overflow: hidden;
}

.applicationLenderNotes__loadingButton {
  float: right;
}
