.permissionsGroup {
  position: relative;
  width: 100%;
  border: 1px solid #e4ebef;
  border-radius: 4px;
  padding: 0;
  margin: 20px 0;
}

.permissionsGroup__header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  padding-left: 24px;
  font-size: 1.4rem;
  font-weight: 700;
}

.permissionsGroup__header--readonly {
  cursor: initial;
  pointer-events: none;
}
.permissionsGroup--disabled {
  cursor: initial;
  pointer-events: auto;
}
.permissionsGroup__header__left {
  cursor: pointer;
  width: 100%;
  z-index: 0;
}

.permissionsGroup__header__right {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-weight: normal;
  right: 0;
  margin-right: 15px;
  min-width: 70px;
  z-index: 2;
  transition: 0.3s ease;
  @media (max-width: 399px) {
    flex-direction: column;
    align-items: flex-end;
    margin-right: 10px;
  }
}
.permissionsGroup__header__toggle {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.permissionsGroup__header__toggle__label {
  padding-right: 6px;
  font-size: 1.2rem;
  @media (max-width: 399px) {
    padding: 4px;
  }
}

.permissionsGroup__itemGroup {
  padding: 0 15px 10px;
  margin: 0;
  @media (max-width: 399px) {
    padding: 10px 10px;
  }
  &:empty {
    padding: 0;
  }
}
