@import '~/styles/webshops-styles.scss';

.vehicleFact {
  color: $text-color;
  float: left;
  position: relative;
  align-items: center;
  display: flex;
  font-size: 16px;
}

.vehicleFact__vehicleFactList--small {
  margin-bottom: 16px;
  min-width: 120px;
}

.vehicleFact__container--small {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  justify-content: center;
}

.vehicleFact__svg {
  left: 0;
  fill: $accent-color;
}

