.settlementModal__heading {
  font-size: 16px;
  font-weight: 600;
  margin: 0 auto;
  padding: 30px 0;
  text-align: center;
}

.settlementModal {
  max-width: 500px;
  margin: 0 auto;
}

.settlementModal__footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e4ebef;
  padding: 20px 0 0 0;

  @media (max-width: 500px) {
    flex-wrap: wrap;
  }
}

.settlementModal__button {
  min-width: 150px;

  @media (max-width: 500px) {
    padding: 5px 20px;
    width: 100%;
  }
}

.settlementModal__explanation {
  font-style: italic;
  padding: 5px 0 0 0;
}

.settlementModal__toggleSwitch {
  padding: 5px 0;
}

.settlementModal__listItem {
  display: flex;
  padding: 10px;
}

.settlementModal__listItemIcon {
  padding: 0 10px 0 0;
}

.settlementModal__information {
  border: 1px solid #e4ebef;
  border-radius: 4px;
  padding: 10px;
  margin: 0 0 20px 0;
}
