.dashboardPanelViewMore {
  position: relative;
}

.dashboardPanelViewMore__link {
  font-size: 1.4rem;
}

.dashboardPanelViewMore__button {
  position: absolute;
  top: -35px;
  height: 30px;
  width: 80px;
  background-color: #e4ebee;
  font-size: 1.4rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  color: #8d9399;
  border-radius: 5px;
  left: calc(50% - 40px);

  &:hover {
    color: darken(#8d9399, 10%);
  }
}
