.dashboardPanelLayout {
  width: 100%;
}

.dashboardPanelLayout__panel {
  width: calc(33.333% - 20px);
  float: left;
}

.dashboardPanelLayout__col0 {
  @media (max-width: 1342px) {
    width: calc(50% - 10px);
    float: left;
  }

  @media (max-width: 954px) {
    width: 100%;
  }
}

.dashboardPanelLayout__col1 {
  margin: 0 0 0 20px;

  @media (max-width: 1342px) {
    width: calc(50% - 10px);
    float: right;
  }

  @media (max-width: 954px) {
    width: 100%;
    margin: 0;
  }
}

.dashboardPanelLayout__col2 {
  margin: 0 0 0 20px;

  @media (max-width: 1342px) {
    width: 100%;
    margin: 20px 0 0 0;
  }
}
