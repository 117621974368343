@import '../../../styles/themes/themes.scss';

.tableRow__tr {
  width: 100%;
  position: relative;
}

.tableRow__border {
  border-bottom: solid #e4ebef 1px;
}

.tableRow__selectedBorder {
  border-top: solid 1px #6bc348;
}
