.furtherAdviceTitle {
  color: black;
  font-size: 1.8rem;
  text-align: left;
  margin-top: 50px;
  font-weight: bold;
}
.questionsContainer {
  max-width: 70%;
  margin: 40px auto;
}
.questionTitle {
  margin-bottom: 0;
  font-weight: bold;
  color: #4a4a4a;
}

.header {
  font-weight: bold;
  color: #4a4a4a;
  margin-bottom: 3rem;
}
