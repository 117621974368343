.alertCardWrapper {
  margin: 30px 0;
}
.funderLogo {
  max-width: 150px;
}
.sectionContainer {
  display: flex;
  gap: 20px;
  margin: 30px 0;
}
.sectionTitle,
.productName {
  color: black;
  font-size: 1.6rem;
  text-align: left;
  font-weight: bold;
  flex: 1 30%;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.productName {
  font-size: 1.8rem;
}

.sectionContent {
  flex: 1 70%;
}

.contactItems {
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
  gap: 1.7rem;
  margin-top: 3rem;
}

@media (width > 700px) {
  .contactItems {
    flex-direction: row;
    justify-content: stretch;
  }
}
