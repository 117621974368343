.valueAddedProductSettings__content {
  background: #ffffff;
  overflow-x: auto;
  border: 1px solid #e4ebef;
}

.valueAddedProductSettings__contentWrapper {
  padding: 10px 0;
}

.valueAddedProductSettings__toolbarRight {
  display: flex;
  justify-content: flex-end;
}

.valueAddedProductSettings__addNewVapButton {
  min-height: 40px;
}

.valueAddedProductSettings__emptyTableMessage {
  text-align: center;
  padding: 30px;
  font-size: 14px;
}

.valueAddedProductSettings__emptyTableMessageButton {
  margin: 0 auto;
  width: 150px;
}

.valueAddedProductSettings__headerRow {
  padding: 10px;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  background: white;
  border-bottom: 1px solid #e4ebef;
}

.valueAddedProductSettings__loadingSpinner {
  padding: 100px 0;
  margin: 0 auto;
}

.valueAddedProductSettings__modalButtons {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 40px 0 20px 0;
}

.valueAddedProductSettings__modalButton {
  min-width: 150px;
}

.valueAddedProductSettings__modalText {
  text-align: center;
  padding-top: 20px;
  font-size: 14px;
}

.valueAddedProductSettings__modalError {
  color: #ff6600;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  padding: 20px 0 0 0;
}

.valueAddedProductSettings__errorMessage {
  text-align: center;
  padding: 30px;
  font-size: 14px;
  color: #ff6600;
}

.valueAddedProductSettings__toolbarSaved {
  padding: 5px 15px;
  margin: 5px 15px;
  border-radius: 4px;
  font-weight: 600;
  color: #6bc348;
}

.valueAddedProductSettings__toolbarSavingError {
  @extend .valueAddedProductSettings__toolbarSaved;
  color: #ff6600;
}
