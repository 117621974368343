@import '../../../styles/themes/themes.scss';

.cfcSummary__divider {
  width: 100%;
  margin: 0 auto 20px;
  border-top: 1px solid #e1e1e1;
}

.cfcSummary__checkbox {
  margin: 0 0 20px 0;
  font-weight: 600;
  border: 2px solid #d8d8d8;
  padding: 0 10px;
}

.cfcSummary__checkboxWarning {
  color: #ff613d;
  font-size: 1.26rem;
  font-weight: 600;
  margin: 0 0 5px 27px;
  display: none;
}

.cfcSummary__highlight {
  color: #686868;
  font-weight: 700;
}

.cfcSummary__tnc {
  font-weight: 700;
  color: #6bc348;
}
