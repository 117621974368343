.pushDealVapsDetails {
  margin: 0 0 20px;
}

.pushDealVapsDetails__vapItem {
  .pushDealActivityContainerSection {
    font-weight: bold;
    margin-bottom: 5px;
  }
  margin-bottom: 20px;
}

.pushDealVapsDetails__name {
  font-size: 14px;
}

.pushDealVapsDetails__vapsTotal {
  padding: 20px 0 0;
  border-top: 1px solid rgba(48, 54, 77, 0.1);
  .pushDealActivityContainerSection {
    margin-bottom: 20px;
  }
}
