.deadLead__inner {
  padding: 0 20px;
}

.deadLead__infoWarning {
  border-bottom: solid 1px #e0e0e0;
  padding-bottom: 10px;
}

.deadLead__borderBottom {
  border-bottom: solid 1px #e0e0e0;
}

.deadLead__checkbox {
  text-align: center;
}

.deadLead__comment {
  margin: 20px 0 0 0;
}

.deadLead__buttons {
  clear: both;
  margin: 0 0 30px 0;
}

.deadLead__proceedButton {
  float: right;
  width: 150px;

  @media (max-width: 500px) {
    float: none;
    width: 100%;
    margin: 0 0 10px 0;
  }
}

.deadLead__cancelButton {
  float: left;
  width: 150px;

  @media (max-width: 500px) {
    float: none;
    width: 100%;
  }
}

