@import '~/styles/webshops-styles.scss';

.createVehicleForm__buttons,
.editVehicleForm__buttons {
  display: flex;
  justify-content: space-between;
  flex: 0 0 100%;
  border-top: 1px solid #e1e1e1;
  text-align: center;
  padding: 20px 0px 00px;
  margin: 20px 0 0 0;
  overflow: auto;
}

.createVehicleForm__buttons {
  min-height: 130px; // to allow for the split button dropdown.
}

.createVehicleForm__cancel-btn-cont,
.createVehicleForm__submit-btn-cont {
  display: block;
  min-width: 190px;
  margin: 0 10px;

  @media (max-width: 700px) {
    min-width: 140px;
  }
}

.createVehicleForm__cancel-btn-cont {
  float: left;
}

.createVehicleForm__submit-btn-cont {
  float: right;
}

