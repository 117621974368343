.deleteIddModal {
  padding: 20px 15px 0;
}

.deleteIddModal__text {
  margin: 0 0 40px;
  font-size: 16px;
}

.deleteIddModal__buttonOuter {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  @media (max-width: 550px) {
    flex-direction: column;
    gap: 20px;
  }
}

.deleteIddModal__button {
  width: 200px;

  @media (max-width: 550px) {
    width: 100%;
  }
}
