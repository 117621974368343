.employmentHistoryPanel {
  padding: 20px;
  overflow: auto;
}

.employmentHistoryPanel__div {
  clear: both;
  overflow: auto;
  margin: 0 0 10px 0;
}
