.modal__overlay {
  position: fixed;
  height: 100dvh;
  width: 100dvw;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  overflow: auto;
  scrollbar-gutter: stable;
  padding: 20px;
  &.modal__isVisible {
    opacity: 1;
  }
  @media (max-width: 760px) {
    padding: 20px;
  }
}

.modal__dialog {
  margin: 20px auto;
  max-width: 750px;
  position: relative;
  min-width: 300px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
  opacity: 0;
  transform: scale(0);
  transition: 300ms ease-in-out;
  background-color: #fff;
  overflow: visible;
  height: auto;
  &.modal__isVisible {
    opacity: 1;
    transform: scale(1);

    @media (max-width: 760px) {
      transform: translateX(0);
    }
  }
  &--width-auto {
    width: auto;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    &.no-padding {
      padding-bottom: 0;
    }

    .modal__title {
      flex-grow: 1;
      margin: 0;
      font-family: 'Montserrat', 'Open Sans', Arial, sans-serif;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 24px;
      color: #4a4a4a;
      .icon {
        display: inline-block;
        font-size: 19px;
        margin-right: 4px;
      }
    }

    .buttonWithIcon.buttonWithIcon--square {
      align-self: flex-start;
      width: 20px;
      height: 20px;
      &:hover,
      &.active {
        background: none;
      }

      .buttonWithIcon__icon {
        font-size: 23px;
        font-weight: 100;
        color: #6b6b6b;
        display: inline-block;
      }
    }
    .modal__close {
      margin-left: auto;

      &:hover {
        .buttonWithIcon__icon {
          transform: rotate(90deg);
          cursor: pointer;
          transition: transform 0.2s ease;
        }
      }
    }
  }

  .modal__button {
    padding: 10px 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    flex-wrap: wrap;
    margin: auto;
    min-width: 150px;
    max-width: fit-content;
    position: relative;
  }
  .modal__content {
    padding: 0px 30px 25px 30px;
    &--withBorder {
      padding: 16px 24px;
      border: 0px solid #c4c4c4;
      border-top-width: 1px;
    }
  }
}
