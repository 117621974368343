.wrapper {
  margin-bottom: 16px;
}
.pageHeader {
  margin: 2rem 0rem 2rem 1rem;
}
.title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.statTitle {
  color: #2d3748;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.floatingCards {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  max-width: 100vw;
  min-height: 125px;
  gap: 16px;
  margin: 0 9px 0 11px;
  padding: 4px;
  overflow-x: auto;
  @media screen and (max-width: 768px) {
    overflow-x: scroll;
    height: fit-content;
    margin: 0 1rem 0 1rem;
  }
}

.floatingCardsDashboard {
  width: 100%;
}

.card {
  background: white;
  color: #4a4a4a;
  border-radius: 8px;
  padding: 12px 16px 14px 16px;
  min-width: 300px;
  text-wrap: wrap;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  &--active {
    border: 1px #2d68b1 solid;
  }
}

.metric {
  font-size: 10px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.metricIcon {
  display: flex;
  justify-content: center;
  max-width: 25px;
  max-height: 25px;
}

.bigValue {
  font-size: 28px;
  font-weight: 700;
}

.percentageChange {
  display: flex;
  margin-top: 2rem;
  color: #6b6b6b;
  width: fit-content;
}

.paragraph {
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  margin-top: 0.5rem;
}

.flex {
  display: flex;
}

