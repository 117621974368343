.informationWarning {
  overflow: hidden;
}

.informationWarning__icon {
  width: 35px;
  height: 35px;
  margin: 0 15px 0 0;
  color: #055bd4;
  font-size: 35px;
  float: left;
}

.informationWarning__textOuter {
  min-height: 35px;
  width: calc(100% - 50px);
  display: inline-table;
  float: left;
}

.informationWarning__text {
  font-size: 1.4rem;
  display: table-cell;
  vertical-align: middle;
}

.informationWarning__children {
  font-size: 14px;
  padding-top: 8px;
}
