.lenderDocumentLink__modal--links {
  padding: 5px 0;
  font-size: 14px;
}

.lenderDocumentLink__modal--subtitle {
  font-weight: bold;
  padding: 10px 0;
}

.lenderDocumentLink__modal--link {
  padding: 5px 0;
}
