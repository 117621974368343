@import '../../../styles/themes/themes.scss';

.statusIcon {
  font-size: 30px;
  flex: 0 1 auto;
  color: #c3c9d0;

  @media (max-width: 800px) {
    padding: 6px;
  }
}

.statusIcon--tick {
  @include theme(
    (
      color: bodyMain
    )
  );
}

.statusIcon--cross {
  color: #e66c6c;
}

.statusIcon--pending-circle {
  color: #d2d3d5;
  font-size: 22px;
  padding-left: 6px;
  @media (max-width: 800px) {
    padding-top: 10px;
  }
}
