.quoteCostsFormDivider {
  width: 80%;
  margin: 0 auto 20px;
  border: solid #ececec 1px;
  @media (max-width: 750px) {
    margin: 20px auto;
  }
  @media (max-width: 650px) {
    width: 100%;
    margin: 20px auto 10px;
  }
}
