@import '../../../styles/themes/themes.scss';

.linkedUsersLink {
  cursor: pointer;
  display: flex;
  padding-top: 5px;
  color: #6bc348;
}

.linkedUsersLink__image {
  width: 20px;
  height: 20px;
  margin: 0 8px 0 0;
  font-size: 20px;
}

.linkedUsersLink__text {
  float: left;
  margin: 0 10px 0 0;
  &:hover {
    text-decoration: underline;
  }
}

.linkedUsersLink__count {
  color: #4a4a4a;
  float: left;
}
