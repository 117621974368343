.globalDefaultSettingsForm__inner {
  width: 60%;
  margin: 10px auto;
  @media (max-width: 1100px) {
    width: 80%;
  }
  @media (max-width: 650px) {
    width: 60%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
}

.globalDefaultSettingsForm__buttonContainer {
  padding: 20px;
  @media (max-width: 480px) {
    padding: 0;
  }
}
