.applicationNavigation {
  width: 200px;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 1.4rem;

  @media (max-width: 1000px) {
    width: 100%;
  }
}
