.emailLookup {
  &__input {
    position: relative;
  }

  &__loader {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  &__tooltip {
    top: 68px;
  }
}
