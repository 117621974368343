.vehicleDetailsButtonContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: stretch;
  align-items: center;

  > * {
    flex: 1 0 auto;
  }
}

.vehicleDetailsButtonContainer__smallSection {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  max-width: 100%;

  > * {
    flex: 1 0 auto;
  }
}
