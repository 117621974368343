.chip {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  gap: 4px;
  background-color: #d3e9f0;
  color: #30364e;
  font-family: 'Open Sans', Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  width: min-content;
  margin-left: auto;
  border-radius: 22px;

  .icon {
    font-size: 16px;
    min-width: 16px;
  }
}

.error {
  background-color: #ffc8cc;

  .icon {
    color: #980008;
  }
}

.warning {
  background-color: #f4ba52;
}

.pending {
  background-color: #c9c9c9;
}

.success {
  background-color: #f2fff2;

  .icon {
    color: #367f14;
  }
}
