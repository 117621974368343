.detailsTableItem {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d3d3d3;
  padding: 10px 0px;

  &:last-child {
    border-bottom: none;
  }

  @media (min-width: 800px) {
    border-bottom: none;
  }

  &__itemValue {
    font-weight: bold;
  }

  &__itemLabel {
    margin: 0;
  }
}

.detailsTable__container--dark {
  .detailsTableItem {
    border-bottom: 1px solid #9d9d9d;

    &:last-child {
      border-bottom: none;
    }

    @media (min-width: 800px) {
      border-bottom: none;
    }
  }
}
