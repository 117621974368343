$breakpoint-desktop: 1024px;

.pushDealItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
}

.pushDealItem--customerRecord {
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  margin: 28px 0;
  color: #262b3e;
  display: block;
  box-shadow: 0 2px 4px rgba(188, 194, 208, 0.34);
  overflow: visible;
  transition: all 0.2s ease-out;
  position: relative;
  &:hover,
  &:focus {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(188, 194, 208, 0.34);
  }
}

.pushDealItem__sentDeal {
  padding: 10px;
  display: inline-block;
  background-color: #eaeaea;
  border-radius: 10px;
  font-weight: bold;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 768px) {
    max-width: 400px;
  }
}

.pushDealItem__dealBreakdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 5px 2px 8px 3px #eaeaea;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;

  @media (max-width: 1100px) {
    flex-direction: column;
    width: 450px;
    padding: 0 20px 20px 20px;
  }
  @media (max-width: 750px) {
    flex-direction: column;
    width: 240px;
    padding: 0 20px 20px 20px;
  }
}

.pushDealItem__dealBreakdownItem {
  display: flex;
  flex-direction: column;
  width: 200px;
}

.pushDealItem__dealBreakdownItemHeader {
  color: rgb(122, 122, 122);
  margin-bottom: 20px;
  font-size: 12px;
  @media (max-width: 1100px) {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 20px;
  }
}

.pushDealItem__dealBreakdownItemInfo {
  font-size: 12px;
  @media (max-width: 1100px) {
    font-size: 15px;
  }
}

.pushDealItem__dealBreakdownItemDealStatus {
  color: #7baafc;
  padding: 5px;
  border-radius: 15px;
  background-color: #bcd4fe;
  width: 100px;
  font-weight: bold;
  font-size: 12px;
}

.pushDealItem__dealBreakdownButton {
  margin-top: 10px;
  @media (max-width: 1100px) {
    margin-top: 20px;
  }
}

.pushDealItem__header--customerRecord {
  border-bottom: 1px solid #e6e6e6;
  padding: 0 5px 10px 5px;
  margin: 5px 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  flex-wrap: wrap;
}

.pushDealItem__orderRef--customerRecord {
  font-weight: 600;
  padding-right: 5px;
}

.pushDealItem__orderDate {
  color: #979aa6;
  padding-left: 10px;
}

.pushDealItem__orderIcon--customerRecord {
  font-size: 20px;
  font-weight: 700;
  padding-right: 5px;
}

.pushDealItem__subHeader {
  color: #979aa6;
}

.pushDealItem__content--customerRecord {
  display: flex;
  justify-content: flex-start;
  padding: 20px 5px;
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
}

.pushDealItem__info--customerRecord {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  font-size: 14px;
  min-width: 300px;
  width: 80%;
  padding: 0px 5px;
  @media (max-width: $breakpoint-desktop) {
    width: 100%;
  }
  @media (max-width: 750px) {
    flex-direction: column;
  }
}

.pushDealItem__infoItem--customerRecord {
  display: flex;
  flex-direction: column;
  @media (max-width: 750px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0;
  }
}
