@import '../../styles/themes/themes.scss';

.closeDealOptionsButtonCircle {
  width: 26px;
  height: 26px;
  margin: 9px;
  float: left;
}

.closeDealOptionsButtonCircle__outerCircle {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #cccccc;
  position: relative;
}

.closeDealOptionsButtonCircle__outerCircle--active {
  background-color: #6bc348;
}

.closeDealOptionsButtonCircle__middleCircle {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: white;
  z-index: 2000;
}

.closeDealOptionsButtonCircle__innerCircle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  z-index: 2002;
  top: 7px;
  left: 7px;
  background-color: #ffffff;
}

.closeDealOptionsButtonCircle__innerCircle--active {
  background-color: #6bc348;
}
