.selectedFacets__container {
  display: flex;
  background-color: transparent;
  height: 32px;
  margin: 10px 5px 10px 0;
  align-items: center;
  padding: 0px 10px 0px 10px;
  border: 1px solid;
  border-color: #a58e8e;
  cursor: pointer;
  border-radius: 0px;
  flex-wrap: nowrap;
}

.selectedFacets__curvedBorder {
  border-radius: 40px;
}

.selectedFacets__facetName {
  white-space: nowrap;
  color: #4a4a4a;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.selectedFacets__close {
  display: inline-block;
  margin-left: 6px;
  margin-top: 2px;
  justify-content: center;
  align-items: center;
  width: 13px;
}
