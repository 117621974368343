.distanceModalConsumerLayout {
  margin: 20px 0 0 0;
  display: flex;
  align-items: center;

  @media (max-width: 920px) {
    flex-wrap: wrap;
  }
}

.distanceModalConsumerLayout__col0 {
  flex: 1 1;

  @media (max-width: 920px) {
    width: 100%;
    flex: auto;
  }
}

.distanceModalConsumerLayout__col1 {
  width: 250px;
  border-left: solid 2px #f2f3f3;

  @media (max-width: 920px) {
    width: 50%;
    border-left: none;
  }

  @media (max-width: 550px) {
    width: 100%;
  }
}

.distanceModalConsumerLayout__col2 {
  width: 210px;
  border-left: solid 2px #f2f3f3;

  @media (max-width: 920px) {
    width: 50%;
  }

  @media (max-width: 550px) {
    width: 100%;
    border-left: none;
  }
}
