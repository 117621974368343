@media print {
  body *:not(#printSection):not(#printSection *) {
    display: none !important;
  }
  #printSection,
  #printSection * {
    page-break-inside: avoid;
    font-size: 8pt;
    line-height: 10pt;
    margin: 0;
    gap: 10px;
    .orderSummaryTable__section {
      min-height: unset;
      padding: 4pt 16pt 1pt 16pt;
      text-align: center;
    }
    .orderSummaryTable__title {
      padding: 8pt 8pt 2pt 8pt;
    }
    .orderSummaryTable__header {
      flex-direction: row-reverse;
      padding-top: 4pt;
    }
    .orderSummaryTable__productName {
      font-weight: 700;
    }
    .orderSummaryTable__quoteSmallPrint {
      padding: 8pt 16pt 0 16pt;
      margin-top: 4pt;
    }
  }
  #printSection {
    page-break-before: avoid;
    height: 90%;
  }
  button {
    display: none !important;
  }
  .formFooter {
    display: none !important;
  }
}
