.repExampleVehiclePriceForm {
  margin: 20px 0 20px 0;
}

.repExampleVehiclePriceForm__section {
  padding: 0 0 10px 0;
}

.repExampleVehiclePriceForm__repButton {
  border-top: 1px solid #e1e1e1;
  text-align: center;
  padding: 20px 0px 00px;
  margin: 20px 0 0 0;
  overflow: auto;
}

.repExampleVehiclePriceForm__repButtonRight {
  display: block;
  min-width: 190px;
  margin: 0 10px;
  float: right;

  @media (max-width: 768px) {
    margin: 10px auto;
    width: 50%;
    float: none;
  }

  @media (max-width: 520px) {
    width: 100%;
    margin: 10px auto;
  }
}

.repExampleVehiclePriceForm__repButtonLeft {
  display: block;
  min-width: 190px;
  margin: 0 10px;
  float: left;

  @media (max-width: 768px) {
    margin: 10px auto;
    width: 50%;
    float: none;
  }

  @media (max-width: 520px) {
    width: 100%;
    margin: 10px auto;
  }
}
