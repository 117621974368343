.selfServicedApplicationTag {
  width: 0;
  height: 0;
  overflow: visible;
  position: relative;
}

.selfServicedApplicationTag__tag {
  background: #ffa832;
  text-transform: uppercase;
  display: table;
  color: #fff;
  font-weight: 700;
  font-size: 1rem;
  white-space: nowrap;
  border: 1px solid #f9c88a;
}

.selfServicedApplicationTag__topLeft {
  position: absolute;
  top: -28px;
  left: -16px;
}

.selfServicedApplicationTag__icon {
  width: 23px;
  height: 20px;
  vertical-align: middle;
  padding: 3px 0 4px 10px;
}

.selfServicedApplicationTag__text {
  width: calc(100% - 20px);
  display: table-cell;
  vertical-align: middle;
  padding: 0 10px 0 5px;
}
