.cfcMatchRateTile {
  display: flex;
  justify-content: flex-start;
  border-style: solid;
  border-width: 3px;
  border-radius: 3px;
  width: 48%;
  margin: 0 0 20px 0;
  min-width: 130px;
  font-size: 1.2rem;

  @media (max-width: 430px) {
    width: 100%;
    margin: 0 0 20px 0;
  }
}

.cfcMatchRateTile--high {
  border-color: #6bc348;
}

.cfcMatchRateTile--medium {
  border-color: #f4ba52;
}

.cfcMatchRateTile--low {
  border-color: #e66c6c;
}

.cfcMatchRateTile--unknown {
  border-color: #c1c1c1;
}

.cfcMatchRateTile__name {
  color: #ffffff;
  width: 50%;
  font-weight: bold;
  text-transform: capitalize;
  text-align: center;
  padding: 10px 0;
}

.cfcMatchRateTile__name--high {
  background: #6bc348;
}

.cfcMatchRateTile__name--medium {
  background: #f4ba52;
}

.cfcMatchRateTile__name--low {
  background: #e66c6c;
}

.cfcMatchRateTile__name--unknown {
  background: #c1c1c1;
}

.cfcMatchRateTile__value {
  width: 50%;
  padding: 10px 0;
  text-align: center;
  font-size: 1.4rem;
  background: #ffffff;
}

.cfcMatchRateTile__current {
  font-weight: bold;
}

.cfcMatchRateTile__previous {
  font-size: 1.2rem;
}
