.repExampleSelectQuoteForm__buttonSelected {
  border: none;
  background: none;
  outline: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.repExampleSelectQuoteForm__buttonSelected--mobile {
  @media (min-width: 876px) {
    position: relative;
    right: -25px;
    top: 20px;
  }
  @media (max-width: 875px) and (min-width: 768px) {
    position: relative;
    height: 25px;
    bottom: 10px;
  }
}

.repExampleSelectQuoteForm__noResults {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.repExampleVehiclePriceForm__menu {
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.repExampleVehiclePriceForm__quoteToggle {
  max-width: 200px;
}
