.applicationSummary__radio {
  margin: 10px 0 0 0;
  padding: 20px 0 0 0;
  border-top: solid 1px #e4ebef;
  font-size: 1.4rem;
  font-weight: 300;
}
.applicationSummary__loading {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}
.applicationSummary__checkboxWarning {
  color: #ff613d;
  font-size: 1.4rem;
  margin: 10px 0 0 0;
  display: none;
}

.applicationSummary__distanceSale {
  border-top: solid 1px #e4ebef;
  padding: 20px 0 10px 0;
  margin: 10px 0 0 0;
}

.applicationSummary__Toggle {
  margin-top: 10px;
}
