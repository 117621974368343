.timelineItem {
  width: 25px;
  height: 25px;
  position: relative;
}

.timelineItem__circle {
  position: absolute;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  top: 6px;
  left: 7px;
  background-color: #c9c9c9;
  z-index: 2;
  &:hover {
    background-color: lighten(#c9c9c9, 5%);
  }
}

.timelineItem__circle--large {
  width: 15px;
  height: 15px;
  top: 5px;
  left: 5px;
  background-color: #808080;
  &:hover {
    background-color: #c9c9c9;
    opacity: 1;
  }
}

.timelineItem__line {
  position: absolute;
  width: 3px;
  left: 11px;
  top: 5px;
  height: 73px;
  background-color: #c9c9c9;
  opacity: 0.5;
  z-index: 1;
  @media (max-width: 1000px) {
    height: 135px;
  }

  @media (max-width: 500px) {
    height: 188px;
  }
}
