.donut {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.donut-ring {
  stroke: #ebebeb;
}

.donut-segment {
  transform-origin: center;
  animation: donut 2s;
}

.donut-text {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  z-index: 10;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
}

.donut-text-icon {
  width: 50px;

  img {
    vertical-align: middle;
  }
}

@keyframes donut {
  0% {
    stroke-dasharray: 0, 100;
  }
  100% {
    stroke-dasharray: var(--dasharray);
  }
}
