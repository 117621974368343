.addressHistoryForm {
  margin: 30px 0;
  @media (max-width: 650px) {
    margin: 20px;
  }
}

.addressHistoryForm__removeOuter {
  width: 100%;
  padding: 20px;
  overflow: auto;
}

.addressHistoryForm__remove {
  width: 100px;
  float: right;
}
