.cardLayout__flexyTime {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
}

.cardLayout__item {
  flex-grow: 1;
  @media (max-width: 1000px) {
    width: 33.333%;
    margin: 0 0 10px 0;
  }

  @media (max-width: 500px) {
    width: 50%;
    margin: 0 0 10px 0;
  }
}

.cardLayout__item0 {
  order: 0;
  width: 155px;
}

.cardLayout__item1 {
  order: 1;
}

.cardLayout__item2 {
  order: 2;
}

.cardLayout__item3 {
  order: 3;
}

.cardLayout__item4 {
  order: 4;
}

.cardLayout__item5 {
  order: 5;
  @media (max-width: 1000px) {
    order: 1;
  }
}
