.impressum {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.impressum__button {
  max-width: 320px;
}
