.customerListPage__addButton {
  display: inline-block;
}

.customerListPage__customerList {
  @media screen and (max-width: 650px) {
    overflow: scroll;
  }
}

.customerListPage__loadingSpinner {
  width: 100%;
  text-align: center;
  top: 0;
  bottom: 0;
  left: -10px;
  padding: 25px 0 0 0;
}
