@import '../../styles/themes/themes.scss';

.breadcrumbs__outer {
  zoom: 1;
  padding: 0;
  font-size: 0;
  margin: 0 0 20px 0;
  overflow: hidden;
}

.breadcrumbs__ul {
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }

  height: 48px;
  border-radius: 6px;
  background: white;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08);
  display: inline-block;
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: visible;
  white-space: nowrap;
  font-size: 1.4rem;
}

.breadcrumbs__collapsed {
  width: 40px;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background: white;
  border-right: 1px solid #e6e6e6;
  border-radius: 6px 0 0 6px;
  overflow: visible;
  cursor: pointer;
  color: #818181;
}

.breadcrumbs__backButton {
  font-size: 20px;
  color: #5e6977;
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
}
