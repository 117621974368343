.container {
  padding: 0px 20px 20px 20px;
  border-radius: 9px 9px 0px 0px;
  min-height: max-content;
  height: 100%;
}

.chartWrapper {
  height: 400px;
}

.mainContent {
  height: 100%;
  border: 1px solid #d3d3d3;
  background: white;
  padding: 15px 20px 20px 20px;
  border-radius: 9px 9px 0px 0px;
}

