.settingsProductTable {
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
}

.settingsProductTable__table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #efefef;
}

.settingsProductTable__headerRow {
  width: 10px;
  margin: 0 auto;
  font-size: 1.4rem;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}

.settingsProductTable__li {
  border-right: 1px solid #efefef;
  text-align: center;
  color: #6b6e73;
  font-weight: 400;
  padding: 5px 6px;
}
