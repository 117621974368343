.assignLeadFormNonEditable__user-list {
  margin: 20px 0 0 0;
}

.assignLeadFormNonEditable__divider-top {
  margin: 10px 0 15px 0;
}

.assignLeadFormNonEditable__divider-bottom {
  margin: 15px 0 15px 0;
}

.assignLeadFormNonEditable__title {
  font-size: 2rem;
  font-weight: 700;
  margin: 0 0 5px 0;
  padding: 0 0 0 0;
  text-align: left;
  @media screen and (max-width: 500px) {
    font-size: 1.6rem;
  }
}

.assignLeadFormNonEditable__divider {
  margin: 10px 0;
  height: 1px;
  width: 100%;
  background: #e0e0e0;
}

.assignLeadFormNonEditable__customer-name-title {
  font-weight: 700;
  display: block;
  margin: 0px 0 5px 0;
}

.assignLeadFormNonEditable__customer-name {
  font-weight: 400;
  display: block;
  margin: 10px 0 10px 0;
}

.assignLeadFormNonEditable__vrm {
  display: inline-block;
  vertical-align: middle;
}

.assignLeadFormNonEditable__make-model {
  display: inline-block;
  vertical-align: text-top;
}

.assignLeadFormNonEditable__sub-heading {
  text-align: left;
  color: #000;
  font-weight: 600;
  font-size: 1.5rem;
}

.assignLeadFormNonEditable__search-wrapper {
}

.assignLeadFormNonEditable__no-assigned-users {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  color: #979a9c;
}
