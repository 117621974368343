@import '../../styles/themes/themes.scss';

.textQuickQuoteBar {
  height: 100%;
  float: right;

  @media (max-width: 1100px) {
    margin: 0;
  }
}

.textQuickQuoteBar__form {
  display: flex;
  justify-content: center;
  background: #ffffff;
}

.textQuickQuoteBar__button {
  height: 69px;
  font-family: 'Open Sans', Arial, sans-serif;
  border: none;
  border-radius: 0;
  outline: none;
  width: 70px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
  box-shadow: 2px 1px 5px 2px rgba(0, 0, 0, 0.5);

  @include theme(
    (
      background-color: bodyMain
    )
  );

  &:hover {
    @include themeDarken(
      (
        background-color: bodyMain
      )
    );
  }
}

.textQuickQuoteBar__icon {
  font-size: 26px;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #e4ebef;
  color: #5e6977;
  font-weight: bold;
  padding: 0 0 0 5px;
  box-shadow: 0 -6px 5px 3px rgba(0, 0, 0, 0.2);
  background: #ffffff;

  @media (max-width: 1100px) {
    border-top: 1px solid #e4ebef;
    box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.2);
  }
}

.textQuickQuoteBar__input {
  height: 70px;
  width: 270px;
  border: none;
  border-bottom: 1px solid #e4ebef;
  font-size: 18px;
  padding: 0 10px;
  color: #5e6977;
  font-weight: bold;
  outline: none;

  @media (max-width: 1100px) {
    width: 220px;
  }

  @media (max-width: 1200px) {
    border-top: 1px solid #e4ebef;
  }
}
