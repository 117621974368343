.markdown {
  h1 {
    font-size: 22px;
    line-height: 30px;
    font-weight: bold;
    text-align: left;
    margin: 30px 0 0 0;
  }

  h2 {
    font-size: 22px;
    line-height: 30px;
    font-weight: bold;
    text-align: left;
    margin: 30px 0 0 0;
  }

  h3,
  h4,
  h5,
  h6 {
    font-size: 22px;
    line-height: 30px;
    font-weight: bold;
    text-align: left;
    margin: 30px 0 0 0;
  }

  ul,
  ol {
    font-size: 1.4rem;
    list-style-position: inside;
    margin: 0;
    padding: 0;
  }

  li {
    padding: 5px 5px 5px 0;
  }

  p {
    font-size: 16px;
    line-height: 26px;
  }
}
