@import '~/styles/webshops-styles.scss';

.radioSwitch {
  display: flex;
  height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: $border;
  border-bottom: $border;
  align-items: center;
}
.radioOption {
  margin: 0 20px 0 0;
}
.VATRateGroup {
  display: flex;
  justify-content: flex-start;
  margin-top: 25px;
}
.VATRate {
  display: flex;
  max-width: 110px;
}

.percentageLabel {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-left: 10px;
}

.label {
  display: inline-flex;
}

.labelWithIcon {
  .label {
    display: inline-flex;
  }
}

.feedVehiclePanelHeader {
  display: flex;
  justify-content: space-between;
}

.feedVehiclePanelHeaderButton {
  width: fit-content;
}

.selectInput :is(input) {
  text-transform: none;
}

.includesVat {
  margin-top: 10px;
  margin-bottom: 30px;
}

.errorMessage {
  padding: 5px 0 0 0;
  color: #e66c6c;
  font-weight: 600;
  position: relative;
  min-height: 25px;
  font-size: 13px;
  z-index: 1;
  @media (max-width: 650px) {
    padding: 0;
    margin: 0;
  }
}

.vatRateValidationIcon {
  margin-left: auto;
  position: relative;
  left: 22px;
}
