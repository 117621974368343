.customerCreditModal {
  header {
    display: flex;
    gap: 10px;
    align-items: baseline;
    width: 100%;
    border-bottom: 1px solid #b1b1b1;
    margin-bottom: 25px;

    h1 {
      font-family: 'Montserrat', 'Open Sans', Arial, sans-serif;
      font-weight: 400;
      margin-bottom: 25px;
      font-size: 24px;
      line-height: 1.4em;
      color: #000000;
    }

    button:hover {
      cursor: default;
    }
  }

  p,
  li {
    line-height: 24px;
    color: #000000;
  }

  li:not(:last-child) {
    margin-bottom: 3px;
  }

  .nav {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
    justify-content: space-evenly;
    font-size: 1.1em;
  }

  .modal__close {
    width: 35px;
    height: 30px;
  }
}
