@import './themes/themes.scss';

@-ms-viewport {
  width: auto !important;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  font-size: 10px;
}

body {
  min-width: 320px;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4a4a4a;
  overflow-y: hidden;
  font-size: 1.4rem;
}

h1 {
  font-weight: 400;
  padding: 0;
  margin: 0;
  font-size: 1.8rem;
}
h2 {
  text-align: center;
  font-weight: 400;
  font-size: 1.4rem;
  color: #666;
  margin-top: 10px;
}
h3 {
  font-size: 1.82rem;
  font-weight: 400;
  padding: 0;
  color: #666;
}
p {
  font-size: 1.4rem;
  line-height: 1.96rem;
}
a {
  outline: none;
}

#app {
  height: 100%;
  overflow: hidden;
}

#root {
  @include theme(
    (
      background-color: headerBackground
    )
  );
}

.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
}
.clearfix:after {
  clear: both;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@each $themeName, $theme in $themes {
  body.theme-#{$themeName} {
    @each $rule, $value in $theme {
      --#{$rule}: #{$value};
    }
  }
}
