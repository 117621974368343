@import '~/styles/webshops-styles.scss';
$mobileBreakpoint: 585px;
$mobileBreakpoint2: 355px;

.stockCreateContainer__wrapper {
  display: flex;
  justify-content: space-between;
}

.stockCreateContainer__col0 {
  flex-grow: 1;
  margin-right: 20px;
  min-width: 220px;
  max-width: 300px;
}

.stockCreateContainer__col1 {
  flex-grow: 2;
  max-width: 840px;
}

.stockCreateContainer__panel {
  overflow: hidden;
}

.panel__outer1 {
  padding: 0;
}

.stockCreateContainer__vehicleCardpreviewWrapper {
  margin: 10rem auto;
  max-width: 400px;
}

.stockCreateContainer__confirmationModal {
  padding-left: 15px;
  min-height: 50px;
}

.stockCreateContainer__buttonsContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}
.stockCreateContainer__buttons {
  width: 80%;
  display: flex;
  justify-self: center;
  justify-content: space-between;
}

.stockCreateContainer__continueButton {
  display: flex;
  width: 220px;
}

.stockCreateContainer__cancelButton {
  display: flex;
  width: 100px;
}

.stockCreateContainer__editButton {
  width: 140px;
  margin-left: auto;
  margin-top: -56px;
  button {
    background: #fff;
  }
}

.stockCreateContainer__vat {
  display: flex;
  height: 50px;
  padding-top: 10px;
  border-top: $border;
  border-bottom: $border;
}
.stockCreateContainer__vatOption {
  margin: 0 20px 0 0;
}

.stockCreateContainer__editButton {
  width: 140px;
  margin-left: auto;
  margin-top: -56px;
  button {
    background: #fff;
  }
}

.stockCreateContainer__vat {
  display: flex;
  height: 50px;
  padding-top: 10px;
  border-top: $border;
  border-bottom: $border;
}
.stockCreateContainer__vatOption {
  margin: 0 20px 0 0;
}

