.monthlyPriceViewerProductHeader {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  border-right: 1px solid #e3e3e3;
  font-weight: 700;
  height: 111px;
  padding: 7px 0 20px 20px;
  border-top: 1px solid #e3e3e3;
  position: relative;
}

.monthlyPriceViewerProductHeader__productName {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 5px 0 10px 0;
}

.monthlyPriceViewerProductHeader__funderLogo {
  width: 120px;
}

.monthlyPriceViewerProductHeader__label {
  position: absolute;
  bottom: -11px;
  background-color: #fff;
  padding: 3px 5px;
  z-index: 10;
  text-transform: uppercase;
  color: #5ec1ff;
  left: 5px;
}
