.eligibilityMatch {
  position: relative;
  display: flex;
  justify-content: space-around;
  width: auto;
  min-width: 148px;
  padding: 10px 10px;
  margin: 0 auto;
  background-color: #ffffff;
  border: 1px solid;
  font-size: 12px;
  height: 28px;
  font-weight: bold;
  text-transform: capitalize;
  box-sizing: border-box;
  align-items: center;
  text-align: center;
  border-radius: 2.5px;
  @media (min-width: 1023px) {
    float: right;
    text-align: right;
  }
}
.eligibilityMatch__readonly {
  pointer-events: none;
}

.eligibilityMatch--button {
  cursor: pointer;
}

.eligibilityMatch__showroomPadding {
  cursor: pointer;
}

.eligibilityMatch__high {
  color: #6bc348;
  border-color: #6bc348;

  &:hover {
    color: #ffffff;
    background-color: #6bc348;
    transition: background-color 0.3s ease-in-out, color 0.5s ease-in-out;
  }
}
.eligibilityMatch__medium {
  color: #f4ba52;
  border-color: #f4ba52;

  &:hover {
    color: #ffffff;
    background-color: #f4ba52;
    transition: background-color 0.3s ease-in-out, color 0.5s ease-in-out;
  }
}

.eligibilityMatch__low {
  color: #e66c6c;
  border-color: #e66c6c;

  &:hover {
    color: #ffffff;
    background-color: #e66c6c;
    transition: background-color 0.3s ease-in-out, color 0.5s ease-in-out;
  }
}

.eligibilityMatch__unknown,
.eligibilityMatch__expired {
  color: #afafaf;
  border: 1px solid #afafaf;
  text-transform: none;
}
