.cfcCheckDetailsPanels__outer {
  border-bottom: solid #ebebeb 1px;
  font-size: 1.4rem;
}

.cfcCheckDetailsPanels__dealership {
  margin: 20px 0;
}

.cfcCheckDetailsPanels__dealerName {
  font-size: 1.6rem;
  font-weight: bold;
}

.cfcCheckDetailsPanels__dealerAddress {
  font-size: 1.4rem;
  margin: 10px 0 0 0;
}
