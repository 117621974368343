.collapsibleCardInformationItem {
  display: list-item;
  border-bottom: #e3e3e3 solid 1px;
  list-style-type: none;
  padding: 6px;
}

.collapsibleCardInformationItem__value {
  float: right;
  font-size: 14px;
}

.collapsibleCardInformationItem__subcategory {
  padding-left: 20px;
  font-size: 12px;
  border-bottom: none;

  &:last-child {
    border-bottom: #e3e3e3 solid 1px;
  }
}
