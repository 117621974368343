@import '~/styles/webshops-styles.scss';
@import '~/styles/themes/themes.scss';

$border-color: #e4ebef;

.placeholder-buttons {
  position: absolute;
  top: 0;
  right: 0;
  button {
    margin: 4px;
  }
}

.EditImageGallery__emptyState {
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 24px;
  text-align: left;
  color: #757575;
  opacity: 0.5;
}

.stockImageUpload__selectedText {
  color: $info-blue;
  font-weight: bold;
  margin-right: 16px;
  display: inline-block;
}

.stockImageUpload__rightSideActions {
  display: flex;
  align-items: center;
}

.StockImageUpload__uploadModalSlides {
  display: flex;
  position: relative;
  min-height: 70vh;
  transition: left ease-in-out 0.5s;
  left: 0;
}

.StockImageUpload__slide {
  min-width: 100%;
  padding: 4px;
}

.StockImageUpload__slideTitle {
  font-weight: bold;
  font-size: 20px;
}

.StockImageUpload__SlideButtons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 540px;
  margin: 32px auto 0;

  button {
    margin: 8px;
  }
}

.StockImageUpload__modalBackButton {
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
  padding-top: 16px;
  border-top: 1px solid $border-color;
  button {
    max-width: 240px;
    display: block;
    margin: 4px auto;
  }
}

.stockImageUpload__Dashboard {
  .uppy-Dashboard {
    height: 50vh;
  }

  .uppy-Informer-animated {
    // Uppy upload error tooltip override
    > p > span:after {
      min-width: 350px;
      white-space: pre-wrap !important;
      text-align: left;
    }
  }
  .uppy-Webcam-button {
    @include theme(
      (
        background-color: bodyMain
      )
    );
  }
}

.StockImageUpload__uploadModal {
  padding: 4px;
  overflow-x: hidden;
}

.StockImageUpload__uploadImperfectionsButton--fr {
  display: inline-block;
  text-align: center;
}

