.modalGross__header {
  text-align: center;
  border-bottom: solid #e4ebef 1px;
  padding: 10px 0;
  font-weight: bold;
  color: #84bd00;
  font-size: 1.3em;
}

.modalGross__listHeader {
  font-weight: bold;
  margin-top: 20px;
}
