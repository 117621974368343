.dashboardGroupLead__leads {
  padding: 5px 10px;
  background-color: #eaeaea;
  display: inline-block;
}

.dashboardGroupLead__dealerName {
  font-weight: bold;
  color: #4c4c4c;
  font-size: 12.5px;
}
