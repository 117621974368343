.page__outer {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  transition: 0.1s opacity;

  @media (max-width: 500px) {
    padding: 20px 0;
  }
}

.page__isLoading {
  opacity: 0;
}
