.dealerDetails__outer {
  background-color: #ffffff;
  border-radius: 0 0 6px 6px;
  @media (max-width: 1100px) {
    overflow: hidden;
  }
}

.dealerDetails__row {
  min-height: 100px;
  display: flex;
  justify-content: flex-start;

  @media (max-width: 1100px) {
    width: 100%;
    float: left;
  }

  @media (max-width: 800px) {
    width: 100%;
    float: none;
  }
}

.dealerDetails__imageOuter {
  width: 30%;
  align-self: center;
}

.dealerDetails__image {
  margin: 0 auto;
  font-size: 55px;
  text-align: center;
  align-self: center;
}

.dealerDetails__dataOuter {
  width: 70%;
  min-height: 100px;
  line-height: 1.4;
  padding: 15px;
  border-left: solid 1px #e4ebef;
  font-size: 1.6rem;
  display: inline-table;
}

.dealerDetails__groupName {
  display: table-cell;
  vertical-align: middle;
  font-size: 2rem;
  font-weight: bold;
}

.dealerDetails__address {
  display: table-cell;
  vertical-align: middle;
}
