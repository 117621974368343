.container {
  display: flex;
}
.star {
  margin: 0 1px;
  color: #c4c4c4;
}
.starFill {
  color: #30364e;
  margin: 0 1px;
}
