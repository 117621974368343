@import '../../../styles/themes/themes.scss';

.default__container {
  // outline: 1px dotted #f60;
  // display: inline-block;
  // vertical-align: top;
  // float: left;
  margin-left: 1px;
  position: relative;
  cursor: pointer;
}

.default__tab {
  margin: 6px -1px 0 -1px;
  padding: 0 0 0 0;
  border-top: 1px solid #e4ebef;
  border-left: 1px solid #e4ebef;
  border-right: 1px solid #e4ebef;
  // border-bottom: solid #e4ebef; 1px;
  background-color: rgba(243, 244, 249, 0.58);
  border-radius: 10px 10px 0 0;
  opacity: 0.5;
  transition: 0.2s all ease-out;
  &:hover {
    background: rgba(255, 255, 255, 1);
    transform: translateY(-2px);
    opacity: 0.9;
  }
}

.default__tabActive {
  margin: 2px -1px 0 -1px;
  padding: 4px 0 0 0;
  background-color: #ffffff;
  opacity: 1;
  border-top: 1px solid #6bc348;
  border-left: 1px solid #e4ebef;
  border-right: 1px solid #e4ebef;

  @include theme(
    (
      border-top-color: bodyMain
    )
  );
}

.large__container {
  position: relative;
  cursor: pointer;
}

.large__tab {
  padding: 15px 0 0 0;
  width: 200px;
  height: 110px;
  transform: translateY(4px);
  border-radius: 10px 10px 0 0;
  vertical-align: middle;
  border-top: 1px solid #dfe0e2;
  font-size: 1.2rem;
  cursor: pointer;
  position: relative;
  overflow: visible;
  margin: 0 0 0px 0;
  background: rgba(247, 247, 247, 1);
  box-shadow: inset 0px -7px 10px 0px rgba(44, 44, 44, 0.1);
  color: #7f8080;
  border-bottom: 1px solid #dfe0e2;
  transition: 0.2s all ease-out;
  &:hover {
    background: rgba(255, 255, 255, 1);
    transform: translateY(0px);
  }
}

.large__tabActive {
  background: #fff;
  box-shadow: none;
  color: #000;
  border-radius: 10px 10px 0 0;
  transform: translateY(0px);
  //box-shadow: 7px 0px 0px 0px rgba(44, 44, 44, 0.1), -7px 0px 0px 0px rgba(44, 44, 44, 0.1);
  z-index: 11;
  border-bottom: none;
  border-top: 1px solid #6bc348;
}

.large__topBarActive {
  @include theme(
    (
      background-color: bodyMain
    )
  );
}

.large__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  opacity: 0.46;
  border-radius: 10px 10px 0 0;
  background-image: linear-gradient(
    90deg,
    rgb(236, 236, 236) 0%,
    rgb(236, 236, 236) 20%,
    rgb(246, 246, 246) 39%,
    rgb(255, 255, 255) 100%
  );
  transition: visibility 0.2s, opacity 0.2s;
}

.largeCfc__container {
  position: relative;
  cursor: pointer;
  border-radius: 10px;
}

.largeCfc__inner {
  position: relative;
  margin: 20px 10px;
  border-radius: 10px;
}

.largeCfc__triangle {
  position: absolute;
  width: 40px;
  height: 20px;
  background-image: url('../../../styles/icons/selected-card-arrow.svg');
  background-repeat: no-repeat;
  background-position: 100%;
  z-index: 13;
  left: 95px;
  bottom: -25px;
}

.largeCfc__topBar {
  position: absolute;
  z-index: 12;
  border-radius: 10px;
  top: 0;
  left: 0;
  border-bottom: #6bc348;
  margin: 0 0 10px 0;
  background: #a3a3a3;
}

.largeCfc__topBarActive {
  border-radius: 10px;
}

.largeCfc__tab {
  padding: 10px 0 0 0;
  border-radius: 10px;
  width: 230px;
  height: 110px;
  vertical-align: middle;
  font-size: 1.2rem;
  box-shadow: 0px 6px 20px rgba(188, 194, 204, 0.34);
  cursor: pointer;
  position: relative;
  overflow: visible;
  background: #f9f9f9;
  color: #7f8080;
  transition: 0.2s all ease-out;

  &:hover {
    background: white;
    transform: translateY(-2px);
    box-shadow: 0px 20px 30px rgba(188, 194, 204, 0.6);
  }
}

.largeCfc__tabActive {
  background: #fff;
  border-top: 1px solid #6bc348;
  border-radius: 10px;
  color: #000;
  box-shadow: 0px 20px 30px rgba(188, 194, 204, 0.6);
  z-index: 11;
}
