.exclamationWarningWithButton {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}

.exclamationWarningWithButton__table {
  color: #ff6003;
  font-size: 1.4rem;
  border: solid 1px #ff6003;
  width: auto;
  display: table;
  margin: 0 auto;

  @media screen and (max-width: 1080px) {
    display: block;
    min-height: 55px;
  }
}

.exclamationWarningWithButton__tableRow {
  display: table-row;

  @media screen and (max-width: 1080px) {
    display: block;
  }
}

.exclamationWarningWithButton__imageOuter {
  display: table-cell;
  padding: 10px 10px 10px 20px;
  width: 55px;
  vertical-align: middle;

  @media screen and (max-width: 1080px) {
    display: block;
    position: absolute;
    padding: 10px 0 10px 10px;
  }
}

.exclamationWarningWithButton__image {
  height: 35px;
  width: 35px;
}

.exclamationWarningWithButton__body {
  display: table-cell;
  padding: 10px 20px 10px 10px;
  vertical-align: middle;
  @media screen and (max-width: 1080px) {
    display: block;
    width: auto;
    margin-left: 45px;
  }
}

.exclamationWarningWithButton__title {
  font-weight: 700;
  display: block;
  margin: 0 0 5px 0;
}

.exclamationWarningWithButton__buttonOuter {
  display: table-cell;
  white-space: nowrap;
  padding: 10px;
  vertical-align: middle;

  @media screen and (max-width: 1080px) {
    display: block;
    clear: both;
    text-align: center;
    max-width: 220px;
    margin: 0 auto;
  }
}
