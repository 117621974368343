.applicationSummaryPanelHeader {
  position: relative;
  margin: 0;
  font-weight: 700;
  font-size: 1.4rem;
  overflow: hidden;
  padding: 10px 50px 10px 20px;
  background: #f5f7fa;
}

.applicationSummaryPanelHeader__title {
  font-size: 1.6rem;
  display: block;
  position: relative;
  top: 10px;
  font-weight: 600;
}

.applicationSummaryPanelHeader__title--error {
  top: 0;
}

.applicationSummaryPanelHeader__subTitle {
  font-weight: bold;
}

.applicationSummaryPanelHeader__child {
  position: absolute;
  top: 10px;
  right: 0;
  line-height: 0;
}

.applicationSummaryPanelHeader__tickCross {
  margin: 0px 10px 0 0;
  width: 45px;
  height: auto;
  position: absolute;
  right: 0;
  font-size: 38px;
}

.applicationSummaryPanelHeader__tickCross--isValid {
  color: #6bc348;
}

.applicationSummaryPanelHeader__tickCross--isInvalid {
  color: #e66c6c;
}

.applicationSummaryPanelHeader__bankDetails {
  width: 162px;
  position: absolute;
  right: 50px;
  top: 10px;

  @media (max-width: 400px) {
    right: 35px;
    top: 13px;
  }
}
