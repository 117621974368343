.keyInformationSection_panel {
  position: relative;
}

.keyInformationSection__editButton {
  width: 140px;
  margin-left: auto;
  margin-top: -56px;
  button {
    background: #fff;
  }
}

.keyInformationSection__QuickTipButton {
  width: 140px;
  margin-left: auto;
  margin-top: -56px;
  display: flex;
  justify-content: flex-end;
}

.keyInformationSection__confirmationModal {
  padding-left: 15px;
  min-height: 50px;
}

.keyInformationSection__heading {
  font-family: 'Montserrat', 'Open Sans', Arial, sans-serif;
  text-align: left;
  font-weight: bold;
  margin-bottom: 32px;
  margin-top: 0;
  font-size: 18px;
}
