@import '../../../../styles/themes/themes.scss';

.dealershipDistance {
  padding: 0 10px;
  text-align: center;

  @media (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: 597px) {
    text-align: left;
    padding: 0;
  }
}

.dealershipDistance__inner {
  display: inline-block;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
}

.dealershipDistance__icon {
  width: 50px;
  height: auto;
  margin: 11px 10px 0 0;
  font-size: 45px;
  float: left;
  color: #5e6977;
}

.dealershipDistance__miles {
  margin-top: 6px;
  font-size: 1.4rem;
  text-align: left;
}

.dealershipDistance__text {
  font-size: 1.4rem;
  text-align: left;
  margin-top: 4px;
}

.dealershipDistance__map {
  cursor: pointer;
  color: #6bc348;

  &:hover {
    text-decoration: underline;
  }
}

.dealershipDistance__bottom {
  font-size: 1.2rem;
  text-align: left;
  color: #abacb0;

  @media (max-width: 550px) {
    display: none;
    margin: 0;
  }
}
