.warningTooltip {
  transition-duration: 0.5s;
}

.warningTooltip__triangle {
  position: absolute;
  margin-left: -14px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 14px 15px 14px;
  border-color: transparent transparent #f46231 transparent;
  bottom: -15px;
  box-shadow: 5px 5px 30px -10px rgba(0, 0, 0, 0.75);
}

.warningTooltip__messageContainer {
  position: absolute;
  width: 1000px;
  bottom: -80px;
}

.warningTooltip__message {
  font-family: 'Open Sans', Arial, sans-serif;
  display: inline-block;
  position: relative;
  background-color: #f46231;
  border-radius: 20px;
  padding: 0 20px 0 50px;
  font-size: 1.5rem;
  color: #fff;
  z-index: 90;
  max-width: 500px;
}

.warningTooltip__icon {
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -13px;
  width: 25px;
  height: 25px;
  background-image: url('../../styles/icons/i_white.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.warningTooltip__text {
  line-height: 20px;
  padding: 10px 0;
}
