.markAsNotAvailable__inner {
  padding: 0 20px;
}

.markAsNotAvailable__vehicleDetails {
  border-bottom: solid 1px #e0e0e0;
}

.markAsNotAvailable__comment {
  margin: 20px 0 0 0;
}

.markAsNotAvailable__infoWarning {
  border-bottom: solid 1px #e0e0e0;
  padding-bottom: 10px;
}

.markAsNotAvailable__checkbox {
  text-align: center;
}

.markAsNotAvailable__buttons {
  clear: both;
  margin: 0 0 30px 0;
}

.markAsNotAvailable__proceedButton {
  float: right;
  width: 150px;

  @media (max-width: 500px) {
    float: none;
    width: 100%;
    margin: 0 0 10px 0;
  }
}

.markAsNotAvailable__cancelButton {
  float: left;
  width: 150px;

  @media (max-width: 500px) {
    float: none;
    width: 100%;
  }
}
