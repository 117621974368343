.eligibilityResultModal__wrapper {
  .eligibilityModalGaugeSection__gaugeWrapper {
    margin-bottom: 16px;
    @media (max-width: 600px) {
      display: flex;
      justify-content: center;
    }
  }
  footer {
    border-top: solid 1px #d2d2d2;
    padding: 16px 0 0 0;
    margin-top: 16px;

    button {
      font-weight: 700;
      border-radius: 3px;
      width: 138px;
      height: 35px;

      @media (max-width: 600px) {
        width: 100%;
      }
    }
  }
}
