.deleteCustomer {
  max-width: 768px;
  width: 100%;
  margin: 0 auto;
  font-size: 1.6rem;
}

.deleteCustomer__content {
  padding: 10px 20px;
}

.deleteCustomer__title {
  margin: 10px 0;
}

.deleteCustomer__name {
  text-transform: capitalize;
}

.deleteCustomer__assignee {
  margin: 10px 0 0 0;
}

.deleteCustomer__alias {
  text-transform: capitalize;
  font-weight: bold;
  margin: 0 0 10px 0;

  &:last-child {
    margin: 0;
  }
}

.deleteCustomer__innerPanel {
  background: #f0f0ed;
  border: solid 2px #e4ebef;
  border-radius: 4px;
  padding: 20px;
  margin: 20px 0;
}

.deleteCustomer__innerPanelSmallprint {
  font-size: 1.4rem;
  margin: 20px 0 0 0;
}

.deleteCustomer__inputText {
  margin: 0 0 5px 0;
}

.deleteCustomer__buttons {
  margin: 20px 0 0 0;
  border-top: solid 1px #e4ebef;
  padding: 20px 0 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 450px) {
    flex-direction: column;
    height: 132px;
  }
}
