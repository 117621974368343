@import '../../styles/themes/themes.scss';
@import '../../styles/mixins.scss';

.languageSelectModal {
  display: flex;
  flex-direction: column;
  &__button {
    @include disableUserSelect;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: solid 1px #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 6px 10px;
    &:last-child {
      margin-bottom: 0;
    }
    transition: all ease-in-out 0.2s;
    &:hover,
    &:focus,
    &:active {
      border-color: #adadad;
    }
    &--active {
      .languageSelectModal__label {
        color: #ffffff;
      }
      @include theme(
        (
          border-color: bodyMain,
          background-color: bodyMain
        )
      );
    }
  }
  &__label {
    margin-left: 15px;
    font-family: 'Montserrat', 'Open Sans', Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #434a54;
    transition: all ease-in-out 0.2s;
  }
}
