.exclamationWarning {
  color: #ff6003;
  font-size: 1.4rem;
  border: solid 1px #ff6003;
  width: auto;
  display: table;
  margin: 0 auto;
}

.exclamationWarning__tableRow {
  display: table-row;
}

.exclamationWarning__imageContainer {
  display: table-cell;
  padding: 10px 10px 10px 20px;
  width: 55;
  vertical-align: middle;
}

.exclamationWarning__image {
  height: 35px;
  width: 35px;
}

.exclamationWarning__body {
  display: table-cell;
  padding: 10px 20px 10px 10px;
  vertical-align: middle;
}

.exclamationWarning__title {
  font-weight: 700;
  display: block;
  margin: 0 0 5px 0;
}
