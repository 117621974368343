.pushDealSelectCustomer__container {
  padding: 20px 60px;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    padding: 10px;
  }
}

.pushDealSelectCustomer__customerSection {
  width: 70%;
  border: solid #e2e2e1 1px;
  border-radius: 4px;
  margin: 20px auto;
  @media screen and (max-width: 1024px) {
    width: 80%;
  }
  @media screen and (max-width: 900px) {
    width: 90%;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    border: 0px;
    margin: 0px;
  }
}

.pushDealSelectCustomer__sectionInner {
  width: 90%;
  max-width: 700px;
  min-height: 174px;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  margin: 20px auto;
  @media screen and (max-width: 465px) {
    width: 100%;
    border: none;
    padding: 0px;
    margin: 0px;
  }
}

.pushDealSelectCustomer__customerSearch {
  padding-top: 20px;
  @media screen and (max-width: 900px) {
    padding-top: 0px;
  }
}

.pushDealSelectCustomer__formContainer {
  width: 100%;
  display: flex;
  font-size: 24px;
  font-weight: 600px;
  margin-bottom: 50px;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 900px) {
    align-items: normal;
    flex-direction: column;
    padding: 8px 0px;
  }
}

.pushDealSelectCustomer__label {
  font-size: 15px;
  padding-right: 15px;
  white-space: nowrap;
  line-height: 1.82rem;
  margin: 0 0 5px;
}

.pushDealSelectCustomer__inputSection {
  width: 100%;
  font-size: 2rem;
  font-weight: 600;
  position: relative;
  z-index: 2;
  transition: all 0.3s ease-in-out;
  @media screen and (max-width: 900px) {
    float: left;
  }

  .textInput__pushDealSelectCustomer {
    padding-left: 45px;
    border: 2px solid #ddd;
    transition: all 0.3s ease-in-out;
  }

  .textInput__pushDealSelectCustomer--hover {
    border: 2px solid #a8a8a8;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease-in-out;
  }

  .textInput__pushDealSelectCustomer--focus {
    border: 2px solid #055bd4;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease-in-out;
  }
}

.pushDealSelectCustomer__searchIcon {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 40px;
  border: 2px solid #ddd;
  border-radius: 4px 0px 0px 4px;
  height: 40px;
  background: #f9fafc;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  .icon {
    font-size: 30px;
    color: #4e91f5;
    position: absolute;
    top: 3px;
    left: 2px;
  }
}

.pushDealSelectCustomer__searchIcon--hover {
  border: 2px solid #a8a8a8;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease-in-out;
}

.pushDealSelectCustomer__searchIcon--focus {
  border: 2px solid #055bd4;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease-in-out;
}

.pushDealSelectCustomer__removeIcon {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 2rem;
  border: 2px solid #ddd;
  border-radius: 0 2px 2px 0;
  height: 40px;
  width: 40px;
  padding: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.pushDealSelectCustomer__removeIcon--hover {
  color: #e88d7e;
  border: 2px solid #a8a8a8;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease-in-out;
}

.pushDealSelectCustomer__removeIcon--focus {
  background: #eaeaea;
  border: 2px solid #055bd4;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease-in-out;
}

.pushDealSelectCustomer__suggestionsContainer {
  position: absolute;
  width: 100%;
  height: auto;
  max-height: 193px;
  min-height: 32px;
  border-radius: 0 0 4px 4px;
  border: 2px solid #dddddd;
  border-top: none;
  top: 37px;
  right: 0px;
  background-color: white;
  vertical-align: middle;
  overflow: auto;
  z-index: 100;
}

.pushDealSelectCustomer__suggestedCustomerEmail {
  font-size: 15px;
  font-weight: 500;
  color: #5f5f5f;
  word-break: break-all;
}

.pushDealSelectCustomer__noCustomersFound {
  color: #5e6977;
  font-size: 1.6rem;
  padding: 5px 15px;
}

.pushDealSelectCustomer__listItem {
  color: #5e6977;
  font-size: 1.6rem;
  padding: 5px 15px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0ed;
  }
}

.pushDealSelectCustomer__customerName {
  font-size: 15px;
  font-weight: 700;
  color: #464646;
  word-break: break-all;
}

.pushDealSelectCustomer__customerEmail {
  padding: 10px 0px;
  font-size: 15px;
  font-weight: 500;
  color: #5f5f5f;
  word-break: break-all;
}

.pushDealSelectCustomer__addNewCustomerListItem {
  color: #6bc348;
  font-size: 1.6rem;
  padding: 5px 15px;
  border-bottom: solid #e2e2e1 1px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0ed;
  }
}

.pushDealSelectCustomer__customerCard {
  width: 100%;
  margin: 20px 0px 50px;
  position: relative;
  border: solid #e2e2e1 1px;
  border-radius: 4px;
  font-size: 18px;
  box-shadow: -2px 4px 8px 0px rgba(188, 194, 208, 0.62);
}

.pushDealSelectCustomer__newCustomerCard {
  width: 100%;
  margin: 20px 0px 50px;
  position: relative;
  border: solid #e2e2e1 1px;
  border-radius: 4px;
  font-size: 1.6rem;
  box-shadow: -2px 4px 8px 0px rgba(188, 194, 208, 0.62);
}

.pushDealSelectCustomer__newCustomerInfo {
  width: 95%;
  padding: 25px 25px;
}

.pushDealSelectCustomer__nameFields {
  display: flex;
  padding-bottom: 30px;
  justify-content: space-between;
}

.pushDealSelectCustomer__nameInput {
  width: 48%;
  height: 63px;
  color: #808080;
}

.pushDealSelectCustomer__emailInput {
  margin: auto;
  color: #808080;
}

.pushDealSelectCustomer__useExistingCustomer {
  display: flex;
  justify-content: space-between;
}

.pushDealSelectCustomer__useExistingCustomerButton {
  height: 40px;
}

.pushDealSelectCustomer__binIcon {
  margin: 10px 10px;
  font-size: 21px;
  position: absolute;
  top: 0px;
  right: 0px;
  color: #808080;
  &:hover {
    cursor: pointer;
  }
}

.pushDealSelectCustomer__customerInfo {
  padding: 25px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 400;
  color: #707070;
}

.pushDealSelectCustomer__customerExists {
  padding: 40px 0;
}

.pushDealSelectCustomer__error {
  color: #ff6600;
  padding: 10px 20px;
  margin: 0 auto;
  text-align: center;
  font-size: 2rem;
}

.pushDealSelectCustomer__customerAssign {
  max-width: 150px;
}
