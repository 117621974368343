@import '~/styles/webshops-styles.scss';

.stockUploadedImages__panelSubTitle {
  font-size: 14px;
  font-weight: 800;
  margin-top: 32px;
}

.stockUploadedImages__explanation {
  display: flex;
  margin-bottom: 32px;
  div {
    display: inline-flex;
    font-size: 35px;
    align-self: flex-start;
    margin-top: -6px;
    color: $info-blue;
  }
  p {
    margin: 0;
  }
}

.stockUploadedImages__discardButton {
  margin-right: 8px;
}

.stockUploadedImages__editImageGallery {
  width: 100%;
  min-height: 170px;
  padding: 16px 0;
  background: #fafafa;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  user-select: none;
  margin-bottom: 32px;
}

.stockUploadedImages__dragHeroLabel {
  padding: 0 6px;
  height: 24px;
  position: absolute;
  top: 4px;
  left: 4px;
  background: $info-blue;
  color: white;
  font-family: 'Montserrat', 'Open Sans', Arial, sans-serif;
  opacity: 1;
  line-height: 24px;
  border-radius: 3px;
}

.stockUploadedImages__imperfectionTitle {
  font-family: 'Montserrat', 'Open Sans', Arial, sans-serif;
  font-size: 16px;
  color: #4a4a4a;
  position: absolute;
}

.stockUploadedImages__imperfectionTitle--hiddenMobile {
  @media (max-width: 480px) {
    display: none;
  }
}

.stockUploadedImages__actionBar {
  height: 50px;
  padding: 0 12px;
  border-bottom: 1px solid;
  border-color: #e4ebef;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.stockUploadedImages__uploadButton {
  width: auto;
}

.stockUploadedImages__sortButtons {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.stockUploadedImages__dragWrapper {
  margin: 8px;
  position: relative;
}
.stockUploadedImages__dragNumber {
  position: absolute;
  height: 24px;
  padding: 0 7px;
  background: white;
  box-shadow: 0 2px 5px #00000030;
  line-height: 24px;
  border-radius: 3px;
  right: 4px;
  top: 4px;
  min-width: 24px;
  text-align: center;
  &:hover {
    color: transparent;
    cursor: pointer;
    background-image: url('../../../../../../../../../../../../../../styles/icons/cross_red.svg');
    background-size: 70% 70%;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.stockUploadedImages__dragImage {
  width: 200px;
  height: 150px;
  background-size: cover;
  display: block;
  border-radius: 6px;
  box-shadow: $medium-shadow;
  @media (max-width: 465px) {
    width: 150px;
    height: 100px;
  }
}
