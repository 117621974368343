@import '../../../../styles/themes/themes.scss';

.corporateCustomer {
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;
}

.corporateCustomer__body {
  font-size: 1.4rem;
  overflow: hidden;
}

.corporateCustomer__imageOuter {
  margin: 0 10px 0 0;
}

.corporateCustomer__name {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0 0 5px 0;
  overflow: hidden;
}

.corporateCustomer__company {
  float: left;
  margin: 7px 10px 0 0;
}

.corporateCustomer__businessTag {
  float: left;
  width: 100%;
}

.corporateCustomer__editProfile {
  margin: 5px 0 0 0;
  cursor: pointer;
  color: #6bc348;

  &:hover {
    text-decoration: underline;
  }
}
