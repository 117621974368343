.repExampleCustom__repExampleContainer {
  display: flex;
  flex-direction: column;
}

.repExampleCustom__selectRepVehicle {
  display: flex;
  font-size: 1.4rem;
  flex-direction: column;
  padding: 10px;
}

.repExampleCustom__selectQuoteDetails {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  padding: 10px;
  margin-top: 30px;
}

.repExampleCustom__selectRepQuote {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  padding: 10px;
  margin-top: 30px;
}

.repExampleCustom__title {
  margin: 10px auto;
  font-size: 16px;
  font-weight: bolder;
}

.repExampleCustom__goBackQuotes {
  margin: 10px 0 0 0;
  float: left;
  width: 200px;
}

.repExampleCustom__error {
  color: #ff6600;
  font-size: 12px;
  text-align: center;
  padding: 10px;
}
.repExampleCustom__loader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
