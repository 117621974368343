.applicationFormWrapper {
  background-color: #fff;
  width: calc(100% - 201px);
  min-height: 550px;
  margin: 0 0px 20px 0;
  padding: 0;
  border-radius: 0 0 12px 12px;

  @media (max-width: 1000px) {
    width: 100%;
    min-height: 0;
  }
}
