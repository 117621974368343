.summaryTable {
  width: 100%;
  padding: 20px;
  text-align: left;
  border-collapse: collapse;
}

.summaryTable__td {
  font-weight: 600;
  padding: 0 0 5px 0;
}

.summaryTable__th {
  color: #989598;
  padding: 0 5px 5px 0;
  font-weight: normal;
  text-align: left;
  @media print {
    // th colour when printed via Safari is too light
    color: black;
  }
}

.summaryTable__tr {
  margin: 0 0 5px 0;
}

