.orders {
  margin-top: 10px;
  padding: 0 15px;
}

.orders__noOrders {
  text-align: center;
}
.orders__contactUs {
  text-align: center;
  max-width: 600px;
  margin: auto;
  font-size: 1.4rem;
  padding: 20px;
}
