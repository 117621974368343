.selectedLabel__container {
  display: flex;
  background-color: #1bdbaf;
  color: white;
  width: 95px;
  height: 30px;
  padding: 5px 8px 7px 5px;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.3s ease;
  position: absolute;
  top: 0px;
  right: 0px;

  @media (min-width: 875px) {
    right: 20px;
    top: unset;
    bottom: 20px;
  }
  @media (max-width: 765px) {
    border-radius: 0 4px 0;
  }
}

.selectedLabel__container--hovered {
  @extend .selectedLabel__container;
  background-color: #ff6600;
}

.selectedLabel__tick {
  padding-top: 1px;
  font-size: 18px;
  margin-top: 3px;
}
