.helpNotification {
  display: table;
  width: 100%;
  table-layout: fixed;
  background: #efefef;
  border-radius: 4px;
}

.helpNotification__questionIcon {
  display: table-cell;
  vertical-align: middle;
  height: 50px;
  width: 60px;
  max-width: 60px;
  min-width: 60px;
  padding: 5px 10px;
  position: relative;
  top: 2px;
  font-size: 35px;
  text-align: center;
  color: #95989a;
}

.helpNotification__text {
  display: table-cell;
  vertical-align: middle;
  font-size: 1.4rem;
  font-weight: 600;
  color: #95989a;
  padding: 10px 20px 10px 0;
}
