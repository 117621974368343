.quickQuoteApply {
  display: flex;
  margin: 20px 0 0 0;
  padding: 0 0 10px 0;

  @media (max-width: 1000px) {
    display: block;
  }
}

.quickQuoteApply__form {
  margin: 20px 0 0 0;
}
