.addConsumerForm {
  display: flex;
  flex-wrap: wrap;
}

.addConsumerForm__personalDetails {
  flex: 0 0 49%;
  border-right: 1px solid #e1e1e1;

  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    border-right: none;
  }
}

.addConsumerForm__addressDetails {
  flex: 0 0 49%;
  @media (max-width: 768px) {
    flex: 0 0 100%;
  }
}

.addConsumerForm__header {
  font-family: 'Montserrat', 'Open Sans', Arial, sans-serif;
  font-size: 1.6rem;
  padding: 15px 80px;
  margin: 0;
  color: #000;
  text-align: left;

  @media (max-width: 768px) {
    padding: 30px 0;
  }
}

.addConsumerForm__personalDetailsHeader {
}

.addConsumerForm__fields {
  padding: 45px 80px 15px 80px;

  @media (max-width: 768px) {
    padding: 30px 0 0 0;
  }
}

.addConsumerForm__footer {
  flex: 0 0 100%;
}
