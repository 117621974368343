@import '../../styles/themes/themes.scss';

.sidebar {
  position: fixed;
  z-index: 20;
  width: 70px;
  top: 0;
  bottom: 0;
  left: 0;
  transition: all 0.3s ease-out;
}

.sidebar--open {
  @media (max-width: 768px) {
    width: 300px;
    left: 0;
  }
}

.sidebar--closed {
  @media (max-width: 768px) {
    width: 300px;
    left: -300px;
  }
}

.sidebar__button--open {
  display: none;
  @media (max-width: 768px) {
    position: absolute;
    display: inline;
    right: 13px;
    top: 3px;
    border: none;
    z-index: 20000;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    color: #fff;
    font-size: 30px;
    width: 30px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

.sidebar__button--closed {
  display: none;
  @media (max-width: 768px) {
    position: fixed;
    display: inline;
    left: -10px;
    top: 0;
    height: 70px;
    width: 80px;
    font-size: 28px;
    border: none;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    text-align: center;
    padding: 0 0 0 8px;

    @include theme(
      (
        color: headerForeground
      )
    );

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.sidebar__inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 70px;
  overflow: hidden;
  margin: 0;
  z-index: 20;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-overflow-scrolling: touch;
  transition: all 0.3s ease-out;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 300px;
  }

  @include theme(
    (
      background-color: sidebarBackground
    )
  );
}

.sidebar__hover {
  width: 240px;

  @media (max-width: 768px) {
    width: 300px;
  }
}

.sidebar__buttonContainer {
  width: 240px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  top: 0;
  bottom: 0;
  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    width: 300px;
  }
}

.sidebarItem__textHide {
  transform: translateX(-30px);
  transition: all 0.3s ease-out;
  opacity: 0;

  @media (max-width: 768px) {
    opacity: 1;
    transform: none;
  }
}

.sidebarItem__textShow {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.3s ease-out;

  @media (max-width: 768px) {
    transform: none;
  }
}

.sidebar__rounder--open {
  transform: translateX(170px) !important;
  transition: 0.3s all ease-out;
}

.sidebar__rounder {
  position: relative;
  top: 70px;
  left: 70px;
  width: 22px;
  height: 22px;
  transform: translateX(0);
  transition: 0.3s all ease-out;
}
