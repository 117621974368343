.carFinanceChecksTabsItem {
  width: 100%;
  position: relative;
}

.carFinanceChecksTabsItem__consumer {
  clear: both;
  font-weight: bold;
  text-align: center;
  padding: 0px 10px;
  background-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.carFinanceChecksTabsItem__table {
  border: solid #e1e1e1 1px;
  border-radius: 3px 5px;
  margin: 7px 10px 0px 10px;
}

.carFinanceChecksTabsItem__row {
  width: 100%;
  overflow: auto;
  padding: 2px;
  background-color: white;
}

.carFinanceChecksTabsItem__tableLeft {
  float: left;
  color: #7b7c7e;
  font-weight: bold;
}

.carFinanceChecksTabsItem__tableRight {
  float: right;
  font-weight: bold;
}

.carFinanceChecksTabsItem__rowBottom {
  background-color: #f9f9f9;
  border-top: solid 1px #e1e1e1;
}

.carFinanceChecksTabsItem__bottomLine {
  color: #777675;
  font-weight: bold;
  padding: 5px 10px 0 10px;
}

.carFinanceChecksTabsItem__date {
  float: left;
  font-size: 1.3rem;
}

.carFinanceChecksTabsItem__status {
  float: right;
  font-size: 1.3rem;
  margin: 1px 0 0 0;
}

.carFinanceChecksTabsItem__circle {
  width: 13px;
  height: 13px;
  float: left;
  border-radius: 50%;
  margin: 0.5px 3px 0 0;
}

.carFinanceChecksTabsItem__high {
  background-color: #6bc348;
}

.carFinanceChecksTabsItem__medium {
  background-color: #f4ba52;
}

.carFinanceChecksTabsItem__low {
  background-color: #e66c6c;
}

.carFinanceChecksTabsItem__expired,
.carFinanceChecksTabsItem__unknown {
  background-color: #afafaf;
}

.carFinanceChecksTabsItem__source {
  float: left;
}
