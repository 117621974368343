.sendDealModalContents__customerEmail {
  font-weight: 700;
}

.sendDealModalContents__noProductsWarning {
  font-weight: 700;
}

.sendDealModalContents__distanceDealWarning {
  border: 1px solid #d2d3d5;
  padding: 20px 15px;
  font-weight: 500;
  margin: 25px 0;
}

.sendDealModalContents__spinner {
  display: block;
  margin: 0 auto 30px auto;
}

.sendDealModalContents__sendingHeader {
  text-align: center;
  font-size: 1.8rem;
  color: #303030;
}

.sendDealModalContents__sendingText {
  text-align: center;
  font-size: 1.4rem;
  color: #6c6c6c;
}

.sendDealModalContents__resultIcon {
  width: 240px;
  height: 200px;
  margin: 0 auto;
}

.sendDealModalContents__footer {
  text-align: center;
}

.sendDealModalContents__customerRecordButton {
  display: inline-block;
}

.sendDealModalContents__consent {
  padding: 10px 0 5px 0;
}

.sendDealModalContents__consentText {
  display: inline-block;
  vertical-align: top;
  padding-left: 10px;
}
