.container {
  display: flex;
  position: relative;
  width: calc(50% - 20px);
  min-width: 280px;
  border: solid #e4ebef 1px;
  border-radius: 6px 6px 0 0;
  background-color: rgba(107, 107, 107, 0.1);
  overflow: hidden;
  flex-grow: 1;

  &:first-of-type {
    margin-right: 12px;
  }
}

.heading {
  margin: 0;
  padding: 14px;
  font-size: 16px;
  font-weight: 600;
  background-color: #30364e;
  color: white;
}

.inner {
  display: flex;
  flex-direction: column;
  color: #5e6977;
  width: 100%;
}

.content {
  padding: 16px 12px 0;
}
