@import '../../../styles/themes/themes.scss';

.error__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 3rem 3rem 0rem;

  @media (min-width: 768px) {
    padding: 6rem 6rem 0rem;
  }

  .error__info {
    width: 100%;
    color: #282c3e;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    @media (min-width: 768px) {
      max-width: 60%;
      margin: 0rem 4rem;
    }
  }

  h1 {
    font-weight: 700;
    font-size: 4.2rem;
    text-align: left;
    margin-bottom: 3rem;
    line-height: 4.8rem;
    @include theme(
      (
        color: bodyMain
      )
    );
  }

  h2 {
    margin: 0;
    font-weight: 700;
    text-align: left;
    font-size: 3.2rem;
    line-height: 3.6rem;
  }

  .error__statusText {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.6rem;
    padding-right: 10rem;
  }

  .error__back {
    display: inline-block;
    width: auto;
  }

  .error__imageWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .error__image {
    @media (min-width: 768px) {
      margin-top: -140px;
      width: 50%;
      justify-content: flex-end;
    }
  }
}
