@import '../../../styles/themes/themes.scss';

.saveQuickQuoteExistingCustomer__outer {
  margin: -13px 0 20px 0;
  padding: 10px;
  border: solid 1px;
  text-align: center;
  @media (max-width: 750px) {
    margin: -5px 0 10px 0;
  }

  color: #6bc348;
}

.saveQuickQuoteExistingCustomer__text {
  color: #6bc348;
  margin: 0 0 10px 0;
}

.saveQuickQuoteExistingCustomer__customer {
  font-weight: bold;
  margin: 0 0 10px 0;
}

.saveQuickQuoteExistingCustomer__button {
  display: inline-block;
  margin: 0 auto;
}
