@import '../../../styles/themes/themes.scss';

.compareQuotesTable {
  position: relative;
  overflow-x: auto;
  z-index: 1000;
  border-bottom: 1px solid #e3e3e3;
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
}

.compareQuotesTable__print {
  display: none;
}

.compareQuotesTable__table {
  min-width: 100%;
  font-size: 1.4rem;
  border-collapse: collapse;
  overflow: hidden;

  @media (max-width: 460px) {
    font-size: 1.2rem;
  }

  padding: 0 20px 0 0;
}

.compareQuotesTable__smallPrint {
  padding: 5px 10px 5px 0;
  font-size: 13px;
  color: #4a4a4a;
  vertical-align: top;
  position: relative;
}

.compareQuotesTable__smallPrintInner {
  max-width: 300px;
}

.compareQuotesTable__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  padding: 160px 5px 1500px 5px;
  background: rgba(57, 69, 80, 0.8);
  color: white;
  line-height: 3rem;
  text-align: center;
}

.compareQuotesTable__exclamation {
  display: block;
  margin: 0 auto;
}

.compareQuotesTable__zebra {
  background-color: #f6f9fc;
}

.compareQuotesTable__buttonRow {
  padding: 10px 10px 10px 0;
}

.compareQuotesTable__button {
  position: relative;
  left: -24px;
  display: block;
  width: auto;
  margin: 5px 0;
  border: none;
  background: none;
  font-size: 1.3rem;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  text-decoration: underline;
}

.compareQuotesTable__removeButton {
  color: #5e6977;
}

.compareQuotesTable__printButton {
  color: #5e6977;
  left: -27px;
}

.compareQuotesTable__icon {
  margin: 0 3px 0 0;
  vertical-align: bottom;
}

.compareQuotesTable__proceedContainer {
  margin: 20px 0;
  display: inline-block;
  vertical-align: middle;
  min-width: 200px;
}

.compareQuotesTable__videoContainer {
  width: 100%;
}

.compareQuotesTable__video {
  padding: 0 10px 10px 0;
}

.compareQuotesTable__videoInner {
  width: 200px;
  height: auto;
  min-width: 200px;

  @media (max-width: 1125px) {
    width: 160px;
  }
}
