.marketingPreferences {
  & > p {
    font-size: 13px;
  }
}

.marketingPreferences__checkbox {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  font-size: 13px;
}

.marketingPreferences__title {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}
