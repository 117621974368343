.toastNotification__container {
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  z-index: 999999;
}

.toastNotification__position {
  bottom: 12px;
  right: 8px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
  margin-left: 20px;
}

.toastNotification__notification {
  background: #fff;
  transition: 0.3s ease;
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  margin-bottom: 15px;
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 0 10px #999;
  color: #fff;
  opacity: 0.9;
  background-position: 15px;
  background-repeat: no-repeat;

  width: auto;
  padding: 20px 15px 10px 10px;

  &:hover {
    box-shadow: 0 0 12px #fff;
    opacity: 1;
    cursor: pointer;
  }
}

.toastNotification__notificationTitle {
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 6px;
  height: 18px;
}

.toastNotification__notificationMessage {
  margin: 0;
  text-align: left;
  margin-left: -1px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.toastNotification__notification--success {
  background: #5cb85c;
}

.toastNotification__notification--danger {
  background: #d9534f;
}

.toastNotification__notification--info {
  background: #f0ad4e;
}

.toastNotification__button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-weight: 700;
  color: #fff;
  outline: none;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.8;
  line-height: 1;
  font-size: 16px;
  padding: 2px 8px;
  cursor: pointer;
  background: 0 0;
  border: 0;
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
