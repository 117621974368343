.webFinanceApplications {
  margin: 0 0 20px 0;
  -webkit-overflow-scrolling: touch;
}

.webFinanceApplications__filter {
  width: 375px;
  margin-left: auto;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.webFinanceApplications__applicant {
  // background-color: #ecf1f4;
}

.webFinanceApplications__noApplications {
  background-color: #fff;
  font-size: 1.4rem;
  padding: 20px;
  text-align: center;
}

.webFinanceApplications__paginationContainer {
  padding-bottom: 5px;
}
