.accountSettings__breadcrumbs {
  margin: 0 0 20px 0;
}

.accountSettings__container {
  padding: 10px;
  // min-height: 60vh;
}
.accountSettings__loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
  min-height: 340px;
}
