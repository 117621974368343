.financeNavigatorResults {
  &__panel {
    .fetchingResults {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 400px;
    }
    .fetchingMoreResults {
      text-align: center;
    }
  }
}
