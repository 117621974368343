.valueAddedProductsTableRow__td {
  padding: 16px 10px;
  font-size: 14px;
  max-width: 330px;
}

.valueAddedProductsTableRow__tr {
  &:nth-child(even) {
    background: #f7f7f7;
  }
}

.valueAddedProductsTableRow__icon {
  text-align: left;
  cursor: pointer;
  color: #000000;
  text-decoration: none;
}

.valueAddedProductsTableRow__icon--edit {
  font-size: 28px;
  &:hover {
    color: #115ed1;
  }
}

.valueAddedProductsTableRow__icon--delete {
  font-size: 28px;
  &:hover {
    color: #e88d7e;
  }
}
