.quoteFunderLogo {
  width: 100%;
}

.quoteFunderLogo__inner {
  padding: 0 5px 0 10px;
  width: 105px;
  margin: 0 auto;

  @media (max-width: 1023px) {
    width: 150px;
  }
}
