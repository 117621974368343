.postcodeLookup {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0px;

  &__error {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
  }

  &__field {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    max-width: 450px;
    margin: 0 auto;

    > label {
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--input {
      display: flex;
      gap: 8px;
      align-items: center;

      :first-child {
        flex-grow: 1;
      }

      > button {
        width: auto;
      }
    }

    &--select {
      appearance: none;
      box-sizing: border-box;
      width: 100%;
      height: 40px;
      padding: 10px 30px 10px 10px;
      border: 1px solid #e4ebef;
      border-radius: 4px;
      background-color: #fff;
      font-size: 1.4rem;
      outline: none;
      background-position: right;
      background-repeat: no-repeat;
      transition: 0.2s;
      background-image: url('../../../styles/icons/arrow-down.png');

      &:hover {
        border: solid 2px #c6c6c6;
      }

      &:focus {
        border: solid 2px #49a3df;
      }
    }

    &--inline {
      padding: 0px 10px;
      max-width: unset;

      @media screen and (min-width: 700px) {
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: space-between;

        > label {
          text-align: right;
          display: block;
          flex: 1 0 20%;
          padding: 0 25px 0 0;
        }

        > :nth-child(2) {
          max-width: 430px;
          flex: 1 1 auto;
          width: 100%;
        }
      }
    }

    &--spacer {
      display: none;

      @media screen and (min-width: 700px) {
        flex: 1 0 18%;
        display: block;
      }
    }
  }
}
