.passwordStrength {
  width: 100%;
  height: 16px;
  background-color: #ccc;
  border-radius: 4px;
}

.passwordStrength--simple {
  background-color: #ffaaaa;
  background-image: linear-gradient(to right, #ffaaaa 0%, #ff0004 100%);
}

.passwordStrength--medium {
  background-color: #eac69d;
  background-image: linear-gradient(to right, #eac69d 0%, #ea8c00 100%);
}

.passwordStrength--strong {
  background-color: #d0f2b8;
  background-image: linear-gradient(to right, #d0f2b8 0%, #17c900 100%);
}
