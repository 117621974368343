@import '~/styles/webshops-styles.scss';

.quickTip__button {
  white-space: nowrap;
  height: 24px;
  border: none;
  background: #e5f0fb;
  border-radius: 6px;
  padding: 0 12px 0 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  color: $info-blue;

  &:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
  }
  > div:nth-child(1) {
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    padding: 8px;
    background: $info-blue;
    color: white;
    margin-right: 8px;
  }
}

.quickTip__hoverSectionContainer {
  position: relative;
  display: flex;
}

.quickTip__text {
  display: flex;
}

.quickTip__hoverModal {
  position: absolute;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 26px 0 0 0;
  line-height: 24px;
  min-width: 200%;
  width: fit-content;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
  transition: 300ms ease-in-out;
  z-index: 1;
  right: 0;
}

.quickTip__hoverModalText {
  padding: 20px;
  font-weight: 400;
  font-size: 16px;
  color: #a1a1a1;
}
