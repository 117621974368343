.mobxFieldGroup {
  margin: 0 0 10px 0;
  position: relative;
  @media (max-width: 650px) {
    margin-bottom: 20px;
  }
}
.mobxFieldGroup--pushDeal {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.mobxFieldGroup--small {
  display: flex;
  margin-bottom: 20px;
  height: 30px;
  width: 100%;
}
.mobxFieldGroup__label {
  padding: 0 25px 0 0;
  font-weight: 600;
}
.mobxFieldGroup__label--small {
  display: inline-flex;
  flex: 1 1 50%;
  padding: 2px 10px 0 0;
  align-self: center;
  font-size: 10px;
  width: 100%;
}
.mobxFieldGroup__input {
  width: 100%;
  @media (max-width: 650px) {
    width: 100%;
  }
}
.mobxFieldGroup--pushDeal__input {
  width: 45%;
}
.mobxFieldGroup--pushDeal__label {
  max-width: 50%;
}
.mobxFieldGroup__error {
  width: 100%;
  margin: 10px 0 0 0;
  position: relative;
  @media (max-width: 650px) {
    width: 100%;
    margin: 0;
  }
}
.mobxFieldGroup__errorMessage {
  padding: 5px 0 0 0;
  color: #e66c6c;
  font-weight: 600;
  position: relative;
  min-height: 25px;
  font-size: 13px;
  z-index: 1;
  @media (max-width: 650px) {
    padding: 0;
    margin: 0;
  }
}
.mobxFieldGroup--pushDeal__errorMessage {
  @extend .mobxFieldGroup__errorMessage;
  margin: 0 0 10px 0;
}
.mobxFieldGroup__icon {
  position: absolute;
  width: 20px;
  height: 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  right: 3px;
  top: 0;
  font-size: 20px;
}
.mobxFieldGroup__icon--small {
  bottom: 0;
  top: 30px;
  @media (max-width: 500px) {
    top: 5px;
    right: -20px;
  }
}
.mobxFieldGroup__iconMedia {
  display: none;
  @media (max-width: 650px) {
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    right: 0;
    top: 0;
  }
}
.mobxFieldGroup--pushDeal__tick {
  position: absolute;
  top: 10px;
  right: -22px;
  font-size: 20px;
}
.mobxFieldGroup__cross {
  color: #e66c6c;
}
.mobxFieldGroup--pushDeal__cross {
  color: #e66c6c;
  position: absolute;
  top: 10px;
  right: -22px;
  font-size: 20px;
}
.mobxFieldGroup__inline {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  @media (max-width: 700px) {
    flex-wrap: wrap;
  }
}
.mobxFieldGroup__label--inline {
  order: 0;
  flex: 1 0 20%;
  text-align: right;
  margin: 15px 0 0 0;
  height: 100%;
  overflow: hidden;
  @media (max-width: 700px) {
    text-align: left;
    margin: 0;
    align-self: center;
    padding-left: 10px;
  }
}
.mobxFieldGroup__input--inline {
  order: 1;
  flex: 1 1 auto;
  padding: 5px 10px;
  max-width: 450px;
  @media (max-width: 700px) {
    max-width: 100%;
    padding: 0 10px;
    order: 2;
  }
}
.mobxFieldGroup__icon--inline {
  order: 2;
  position: relative;
  padding: 0;
  flex: 0 0 3%;
  margin: 15px 0 0 0;
  height: 100%;
  @media (max-width: 700px) {
    padding-right: 25px;
    flex: 0 0 4%;
    order: 1;
    margin: 0;
    align-self: center;
  }
}
.mobxFieldGroup__errorMessage--inline {
  order: 3;
  flex: 1 0 15%;
  font-size: 13px;
  height: 100%;
  padding: 17px 0 0 0;
  text-align: left;
  @media (max-width: 700px) {
    padding: 4px 0 0 10px;
  }
}

.mobxFieldGroup__inlineWide {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 0;
  @media (max-width: 700px) {
    flex-wrap: wrap;
  }
}
.mobxFieldGroup__label--inlineWide {
  order: 0;
  flex: 1 0 20%;
  margin: 15px 0 0 0;
  height: 100%;
  overflow: hidden;
  @media (max-width: 700px) {
    text-align: left;
    margin: 0;
    align-self: center;
    padding-left: 10px;
  }
}
.mobxFieldGroup__input--inlineWide {
  order: 1;
  flex: 1 1 auto;

  @media (max-width: 700px) {
    max-width: 100%;
    padding: 0 10px;
    order: 2;
  }
}
.mobxFieldGroup__icon--inlineWide {
  order: 2;
  position: relative;
  padding: 0;
  flex: 0 0 3%;
  margin: 15px 0 0 0;
  height: 100%;
  @media (max-width: 700px) {
    padding-right: 25px;
    flex: 0 0 4%;
    order: 1;
    margin: 0;
    align-self: center;
  }
}
.mobxFieldGroup__errorMessage--inlineWide {
  order: 3;
  flex: 1 0 15%;
  font-size: 13px;
  // height: 100%;
  padding: 0 0 0 1rem;
  text-align: left;
  @media (max-width: 700px) {
    padding: 4px 0 0 10px;
  }
}
.mobxFieldGroup__errorMessage--small {
  position: absolute;
  left: 34%;
  bottom: 0;
  top: 25px;
  @media (max-width: 700px) {
    padding-top: 5px;
    font-size: 12px;
  }
}
.mobxFieldGroup__information {
  color: #055bd4;
}
.mobxFieldGroup--stockUploadStyles {
  align-items: flex-start;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 0;
  margin-top: 10px;
  .mobxFieldGroup__label {
    max-width: 40%;
    @media (max-width: 700px) {
      max-width: 40%;
    }
  }
  .mobxFieldGroup__tick {
    position: absolute;
    top: 10px;
    right: -22px;
    font-size: 20px;
  }
  .mobxFieldGroup__cross {
    color: #e66c6c;
    position: absolute;
    top: 10px;
    right: -22px;
    font-size: 20px;
  }
  .mobxFieldGroup__input {
    width: 60%;
  }
  input {
    text-transform: uppercase;
  }
  #attentionGrabber {
    text-transform: unset;
  }
  textarea {
    height: 70px;
  }
}
