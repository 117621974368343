$green: #64bc46;
$gray: #464646;

.StockVehicleDetailsSidebar {
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  @media (min-width: 419px) and (max-width: 919px) {
    flex-direction: row;

    .StockVehicleDetailsSidebar__image {
      max-width: 230px;
      margin-right: 24px;
    }
    .StockVehicleDetailsSidebar__vehicleDetails {
      text-align: left;
    }
    .StockVehicleDetailsSidebar__vehicleReg {
      justify-content: flex-start;
    }
    .StockVehicleDetailsSidebar__vehicleInfo {
      margin-top: 0;
    }
  }
}

.StockVehicleDetailsSidebar__image {
  width: 100%;
  height: 100%;
}

.StockVehicleDetailsSidebar__vehicleDetails {
  width: 100%;
}

.StockVehicleDetailsSidebar__vehicleInfo {
  display: flex;
  flex-direction: column;
  margin: 20px 0 0;
}

.StockVehicleDetailsSidebar__vehicleMake {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}
.StockVehicleDetailsSidebar__vehicleDerivative {
  color: $gray;
  font-size: 14px;
}

.StockVehicleDetailsSidebar__vehiclePricing {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  font-weight: bold;
}

.StockVehicleDetailsSidebar__vehiclePrice--strikeout {
  text-decoration: line-through;
}

.StockVehicleDetailsSidebar__vehicleOfferPrice {
  margin: 0 0 0 10px;
  color: $green;
}
