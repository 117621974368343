.customerEmail {
  padding-top: 5px;
  padding-right: 20px;
}
.customerEmail__link {
  color: #4a4a4a;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
