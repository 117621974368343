.quoteCostsForm {
  margin: 20px 0 20px 0;
}

.quoteCostsForm__vatCheckbox {
  text-align: right;
}

.quoteCostsForm__vatMarginScheme {
  display: inline-block;
  vertical-align: middle;
  margin: 0 20px 0 0;
}

.quoteCostsForm__vatQualifying {
  display: inline-block;
  vertical-align: middle;
}

.quoteCostsForm__valueAddedProducts {
  text-align: center;
  padding: 0 0 10px 0;
}

.quoteCostsForm__addVapButton {
  vertical-align: middle;
  margin: 10px 0 0 0;
}

.quoteCostsForm__noVaps {
  font-size: 1.4rem;
  line-height: 1.6;
}

.quoteCostsForm__configureVap {
  margin: 0 0 20px 0;
}

.quoteCostsForm__section {
  padding: 0 0 10px 0;
}

.quoteCostsForm__settlementPdf {
  font-size: 14px;
  cursor: pointer;
  color: #6bc348;
  text-decoration: none;
  display: block;
  margin: 0 0 20px 0;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 700px) {
    margin: 0;
  }
}
.quoteCostsForm__buttonWrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 430px;
  margin: 20px auto;

  @media (max-width: 700px) {
    justify-content: center;
    margin: 0 auto 20px;
  }
}

.quoteCostsForm__customerCreditTooltip {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  align-items: center;
  font-size: 12px;

  button {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: #2d68b1;

    .icon {
      font-size: 20px;
    }
  }
  button:last-of-type {
    text-decoration: underline;
  }
}

.quoteCostsForm__alert {
  margin-top: 1rem;
}
