@import '~SM/styles/stockStyles.module.scss';

$filter-boarder-radius: 10px 10px 0 0;

.filterButton {
  display: flex;
  user-select: none;
  cursor: pointer;
  gap: 4px;
  color: white;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  background: #282c3e;
  left: 0;
  padding: 0 4px;
  height: 68px;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  min-width: 70px;
  @media (min-width: 500px) {
    min-width: 100px;
  }
}

.filterIcon {
  position: relative;
  top: 2px;
  transform: scale(1.5);
  right: -4px;
  @media (min-width: 500px) {
    transform: scale(1);
    right: 0;
  }
}

.filterButtonTitle {
  display: none;
  @media (min-width: 500px) {
    display: block;
  }
}

.chevronIcon {
  position: relative;
  top: 2px;
  margin-left: 4px;
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
  display: none;
  @media (min-width: 500px) {
    display: block;
  }

  &--open {
    transform: rotate(-180deg);
  }
}

.container {
  position: relative;
}

/* Floating Filters   */

.floatingFilterContainer {
  min-width: 280px;
  position: absolute;
  z-index: 99;
  opacity: 1;
  left: 0px;
  top: 68px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: $filter-boarder-radius;
}

.floatingFilterContainer--closed {
  opacity: 0;
  top: 0px;
  user-select: none;
  pointer-events: none;
}

.floatingFilters {
  background: white;
  border-radius: $filter-boarder-radius;
}

.filterHeader {
  border-radius: $filter-boarder-radius;
  display: flex;
  min-height: 50px;
  min-width: 280px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  background: #30364e;
  color: white;
}

.headerTitle {
  font-weight: 700;
  font-size: 14px;
}

.clearButton {
  border-radius: 5px;
  background: white;
  padding: 5px 16px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 900;
  border: unset;
  cursor: pointer;
}

.floatingFilterContentContainer {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.floatingFilterContent {
  padding: 10px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #e1e1e1;
  &:last-child {
    border-bottom: unset;
    margin-bottom: unset;
  }
}

.floatingFilterTitle {
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 10px;
  display: flex;
  justify-self: center;
  align-items: center;
}

.starsContainer {
  flex: 1 0;
  margin-left: 4px;
  margin-right: 4px;
  display: flex;
  font-size: 16px;
}

.numberOfVehicles {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding: 5px 10px;
  gap: 10px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  min-width: 40px;
  height: auto;
  background: rgba(48, 54, 78, 0.2);
  border-radius: 16px;
  font-size: 12px;
  line-height: 24px;
  color: #30364e;
}

.optionContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  cursor: pointer;
  flex-direction: row;
}

.checkboxAndText {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: $grey-003;
  font-size: 1.4rem;
}

.checkboxText {
  display: block;
  font-size: 1.4rem;
  font-weight: 400;
  color: #30364e;
}

.clickOutsideContainer {
  display: none;
  background: transparent;
  z-index: 90;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  &--open {
    display: block;
  }
}

