.yearsMonthsField {
  display: flex;
  gap: 20px;
}

.yearsMonthsField__years {
  width: 100%;
}

.yearsMonthsField__months {
  width: 100%;
}
