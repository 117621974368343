.search__outer {
  overflow: auto;
  position: relative;
  margin-bottom: 3px;
  @media (max-width: 1023px) {
    margin-bottom: 10px;
  }
}

.search__outer--noMargin {
  overflow: auto;
  position: relative;
}

.search__input {
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  float: left;
  padding: 10px;
  border: 1px solid #e4ebef;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 1.4rem;
  outline: none;
  transition: 0.2s;

  &:hover {
    border: solid 1px #c6c6c6;
  }

  &:focus {
    border: solid 1px #49a3df;
  }
}

.search__button {
  border: none;
  background-color: transparent;
  height: 38px;
  width: 40px;
  margin: 0px;
  outline: none;
  padding: 2px 0 0 0;
  font-size: 28px;
  color: #d5d5d5;
  // background-image: url('../../styles/icons/search_icon.svg');
  // background-repeat: no-repeat;
  // background-position: 5px center;
  // background-size: 25px;
  position: absolute;
  right: 0px;
  cursor: pointer;
}
