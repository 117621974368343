.searchDate {
  display: inline-block;
}

.searchDate__selectContainer {
  display: block;
  width: 100%;
  height: 43px;
  left: 0;
  z-index: 1;
}

.searchDate__inputForm {
  display: block;
  width: 100%;
  height: 43px;
  top: 30px;
  left: 0;
  z-index: 1;
  margin-top: 20px;
}

.searchDate__inputContainer {
  position: absolute;
  width: 50%;
  left: 0;
  @media (max-width: 970px) {
    width: 100%;
  }
}

.searchDate__inputInner {
  width: calc(100% - 60px);
  float: left;
}

.searchDate__input {
  width: 48%;
  position: relative;
}

.searchDate__inputOne {
  float: left;
  margin: 0 0 0 10px;
}

.searchDate__inputTwo {
  float: right;
}

.searchDate__button {
  font-size: 35px;
  color: #d5d5d5;
  background: none;
  width: 40px;
  height: 35px;
  border: none;
  margin: -2px 25px 0 -5px;
  cursor: pointer;
  padding: 0 6px;

  &:hover {
    color: #c6c6c6;
  }
}
