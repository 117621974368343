$invalid: #e66c6c;
$valid: #055bd4;

.IconButton {
  height: 37px;
  width: 37px;
  border: 1px solid #cacaca;
  color: #cacaca;
  background: #fbfbfb;
  border-radius: 4px;
  font-size: 2.5rem;
  padding: 0;
  cursor: pointer;
  position: relative;
  transition: color 0.1s ease-in-out;
}

.IconButton:hover {
  color: $valid;
}

.IconButton--danger:hover {
  color: $invalid;
}
