$mobile-end: 768px;
$tablet-end: 1199px;
$tablet-start: $mobile-end + 1px;
$desktop-start: $tablet-end + 1px;

$white: #ffffff;
$black: #000000;

$black-001: #060a0d;
$black-002: #2c2c2c;
$grey-001: #b1b1b1;
$grey-002: #e1e1e1;
$grey-003: #6b6b6b;

$warning-001: #f4ba52;
$warning: #d10000;
$alert-001: #980008;
$alert-002: #ffc8cc;

$PrimaryDarkblue100: #30364e;
$primaryGreen: #64bc46;
$primaryGreenHover: #4c982a;

:export {
  tabletStart: $tablet-start;
}
