.visVehicleCard__container {
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease-out;
  border: 1px solid black;
  background-color: #f1f1f1;

  &:hover {
    transform: translatey(-1px);
    box-shadow: rgba(45, 45, 45, 0.02) 0px 2px 2px, rgba(49, 49, 49, 0.02) 0px 4px 4px,
      rgba(42, 42, 42, 0.02) 0px 8px 8px, rgba(32, 32, 32, 0.02) 0px 16px 16px, rgba(49, 49, 49, 0.02) 0px 32px 32px,
      rgba(35, 35, 35, 0.02) 0px 64px 64px;
    z-index: 3;
  }

  @media (max-width: 425px) {
    padding: 20px 10px;
  }

  @media (max-width: 800px) {
    flex-wrap: wrap;
    position: relative;
  }
}
.visVehicleCard__container--selected {
  @extend .visVehicleCard__container;
  transform: translatey(-1px);
  margin-bottom: 0px;
  z-index: 2;
}
.visVehicleCard__bottom {
  display: flex;
  font-size: 12px;
  border-radius: 8px;
  color: black;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;

  @media (min-width: 768px) {
    justify-content: flex-start;
  }
}

.visVehicleCard__top {
  align-items: center;
  color: #c3c9d0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.visVehicleCard__bottomSection {
  display: flex;
  flex-direction: column;
  padding: 0px;
  font-size: 1.5rem;
  line-height: 2em;
  width: 20%;
  border-right: solid black 1px;
  font-weight: 700;

  &:last-child {
    border-right: none;
  }
  @media (min-width: 320px) {
    padding: 0;
  }

  @media (min-width: 768px) {
    width: unset;
    margin-right: 10px;
  }
}

.visVehicleCard__vehicleIconContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  font-size: 1.8rem;
  width: 100%;
}

.visVehicleCard__vehicleIconText {
  margin-left: 0px;
  font-size: 16px;
  color: black;
  padding: 10px 0 5px;

  @media (min-width: 320px) {
    font-size: 16px;
    margin-left: 0;
    padding: 10px 0 5px;
  }
}

.visVehicleCard__vehicleValues {
  line-height: 1em;
  padding-right: 10px;

  @media (min-width: 768px) {
    line-height: 2em;
  }
}

.visVehicleCard__derivativeTitle {
  color: black;
  width: 100%;
  border-bottom: solid 1px #898989;
  padding-bottom: 20px;
  font-size: 1.5rem;
  font-weight: 700;
}

.visVehicleCard__vehicleIcon {
  display: none;

  @media (min-width: 768px) {
    display: block;
    padding: 2px 0 0 1px;
    font-size: 30px;
    border: solid black 2px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    right: 0px;
    top: 20px;
    color: black;
  }
}
