.dashboardBusinessTag {
}

.dashboardBusinessTag__cont {
  float: left;
  height: 18px;
}

.dashboardBusinessTag__briefcase {
  width: 20px;
  margin: 0px 3px 0 0;
}

.dashboardBusinessTag__image {
  width: auto;
  height: 20px;
}
