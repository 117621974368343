@import '../../../styles/themes/themes.scss';

.consumerStatus {
  border: 1px solid #d2d3d5;
  border-radius: 12px;
  background: white;
  overflow: hidden;
  padding: 15px 25px;
}

.consumerStatus__top {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;

  @media screen and (min-width: 840px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > *:first-child {
      order: 2;
    }
  }
}

.consumerStatus__logo {
  height: 45px;
  margin-bottom: 15px;
}

.consumerStatus__logo-inner {
  object-fit: contain;
}

.consumerStatus__info {
  text-align: center;

  @media screen and (min-width: 840px) {
    text-align: left;
  }

  p,
  div {
    margin: 0 0 5px;
  }
}

.consumerStatusAlt__lenderStatus {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.consumerStatus__businessName {
  font-size: 1.4rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  gap: 5px;

  @include theme(
    (
      color: bodyMain
    )
  );

  &:hover {
    text-decoration: underline;
  }
}

.consumerStatus__businessContact {
  font-size: 1.4rem;
}

.consumerStatus__businessNumber {
  font-size: 1.4rem;
  display: block;
  height: 33%;
}

.consumerStatus__consumerName {
  font-size: 1.6rem;
  font-weight: 700;
  display: block;
  line-height: 1.5;
  @include theme(
    (
      color: bodyMain
    )
  );

  &:hover {
    text-decoration: underline;
  }
}

.consumerStatus__phoneNumber {
  font-size: 1.4rem;
  display: block;
  line-height: 1.5;
}

.consumerStatus__removeFromHomeWarning {
  margin: 0px 0 10px 0;
  text-align: center;
}

.consumerStatus__applicationDetails {
  margin-bottom: 10px;
  font-weight: 600;

  td:first-child {
    padding-right: 10px;
  }
}

.consumerStatus__applicationInformation {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #d2d3d5;
  padding-top: 20px;
  justify-content: space-between;

  @media screen and (min-width: 840px) {
    flex-direction: row;
  }
}

.consumerStatus__productName {
  @media screen and (min-width: 840px) {
    padding: 0px 20px;
  }
}
