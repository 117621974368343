.groupReport__header {
  overflow: hidden;
  margin: 0 0 20px 0;

  @media (max-width: 500px) {
    padding: 0 20px;
  }
}

.groupReport__headerText {
  font-size: 2.4rem;
  font-weight: bold;
  float: left;
  margin: 5px 0 0 0;
  width: calc(100% - 100px);

  @media (max-width: 500px) {
    font-size: 1.8rem;
  }
}

.groupReport__headerIcon--outer {
  float: right;
  width: 80px;

  @media (max-width: 500px) {
    width: 60px;
  }

  @media (max-width: 500px) {
    margin: 10px 0 0 0;
  }
}

.groupReport__headerIcon {
  width: 100%;
  height: 100%;
}

.groupReport__selectOuter {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 20px 0;

  @media (max-width: 650px) {
    flex-wrap: wrap;
  }
}

.groupReport__selectInner {
  align-self: center;

  @media (max-width: 400px) {
    padding: 0 0 0 20px;
  }
}

.groupReport__tileOuter {
  @media (max-width: 650px) {
    margin: 0 0 20px 0;
  }

  @media (max-width: 400px) {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
}

.groupReport__selectLabel {
  float: left;
  font-size: 1.4rem;
  font-weight: bold;
  margin: 4px 0 0 0;
}

.groupReport__select {
  float: right;
}

.groupReport__layoutOuter {
  overflow: hidden;
}

.groupReport__layoutLeft {
  float: left;
  width: 35%;
}

.groupReport__layoutRight {
  float: right;
  margin: 0 0 0 20px;
  width: 60%;
}

.groupReport__applicationDetailsLayout {
  overflow: hidden;
  width: 100%;
  margin: 0 0 20px 0;

  & > div:first-child {
    float: left;
    width: 48%;

    @media (max-width: 635px) {
      float: none;
      width: 100%;
      margin: 0 0 20px 0;
    }
  }

  & > div:last-child {
    float: right;
    width: 48%;

    @media (max-width: 635px) {
      float: none;
      width: 100%;
    }
  }
}

.groupReport__faq {
  height: 15px;
  width: 15px;
  margin: 0 0 0 5px;
  cursor: pointer;
}
