@import '../../styles/themes/themes.scss';

.squareActionButton {
  text-align: center;
  position: relative;
  width: 180px;
  border-radius: 15px;
  padding: 16px 10px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #4a4a4a;
  box-shadow: 0px 6px 20px rgba(188, 194, 204, 0.34);
  background-color: #ffffff;
  border: solid 2px;
  border-color: #5e6977;
  font-weight: 700;
  transform: translateY(0px);
  transition: all 0.2s ease-out;

  &__actionText {
    font-size: 1.4rem;
    font-weight: 400;
    display: flex;
    justify-content: center;
    min-height: 38px;
  }

  &__actionText--bold {
    font-weight: 700;
  }

  &__iconOuter {
    border: 0;
    width: 100%;
    height: 48px;
    margin: 10px auto;
    font-size: 38px;
    color: #30364e;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  &__iconOuter--e-sign {
    border: 0;
    width: 100%;
    height: 48px;
    margin: 10px 0 10px 7px;
    font-size: 38px;
    color: #30364e;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  &__button {
    height: 30px;
    width: 100%;
    margin: 0 auto;
    color: #fff;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 30px;
    border-radius: 3px;
  }

  &:not(&--disabled) {
    .squareActionButton__button {
      @include theme(
        (
          background-color: bodyMain
        )
      );
    }
  }

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0px 20px 30px rgba(188, 194, 204, 0.6);

    @include theme(
      (
        border-color: bodyMain
      )
    );
  }

  &--disabled {
    pointer-events: none;
    user-select: none;

    .squareActionButton__button {
      background: #f0f0f0;
      color: #a6a6a6;
    }
    &:hover {
      transform: none;
    }
  }
}
