.consumerRecordLayout {
  display: flex;
  align-items: flex-start;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
}

.consumerRecordLayout__col0 {
  padding: 0 0 0 10px;
  flex: 1 1;

  @media (max-width: 1200px) {
    width: 100%;
    flex: auto;
  }
}

.consumerRecordLayout__col1 {
  width: 300px;
  padding: 0 10px;
  border-left: solid 2px #f2f3f3;

  @media (max-width: 1200px) {
    width: 50%;
    border-left: none;
    margin-top: 20px;
  }

  @media (max-width: 597px) {
    width: 100%;
    padding: 0;
  }
}

.consumerRecordLayout__col2 {
  padding: 0 10px;
  width: 310px;
  border-left: solid 2px #f2f3f3;

  @media (max-width: 1200px) {
    width: 50%;
    margin-top: 20px;
  }

  @media (max-width: 597px) {
    width: 100%;
    padding: 0;
    border-left: none;
  }
}
