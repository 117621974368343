.textArea {
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 150px;
  margin: 0;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #e4ebef;
  background-color: '#ffffff';
  font-size: 1.4rem;
  outline: none;
  background-position: right;
  background-repeat: no-repeat;
  transition: 0.3s border-color;
  border-radius: 4px;
  font-family: 'Open Sans', Arial, sans-serif;
  font-weight: 400;
  resize: none;

  &:hover {
    border-color: #999999;
    outline: none;
  }

  &:focus {
    border-color: #055bd4;
    outline: none;
  }

  &.is-valid {
    border: 1px solid #6bc348;
  }

  &.is-invalid {
    border: 1px solid #e66c6c;

    &:focus {
      border-color: #055bd4;
    }
  }

  &.is-disabled {
    background-color: #eaeaea;
  }
}
