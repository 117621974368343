.closeDealBanner {
  margin-top: 5px;
}

.closeDealBanner__text {
  width: fit-content;
  max-width: 100%;
  font-size: 1.25rem;
  line-height: 1;
  background-color: #d05b5b;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 999px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
