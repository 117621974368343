.viewLenderNotes__notes {
  margin: 10px 0 0 0;

  h2,
  h3 {
    font-size: 1.4rem;
    font-weight: bold;
    color: black;
    padding: 20px;
    margin: 0;
    background-color: #f7fcfc;
    text-align: left;
  }

  h3 {
    margin-top: 30px;
  }

  p {
    margin: 0;
    padding: 10px 20px;
  }

  p:nth-child(odd) {
    background-color: #f7fcfc;
  }
}
