.textInput {
  width: 100%;
  vertical-align: middle;
  height: 40px;
  position: relative;
}

.textInput__input {
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 10px;
  margin: 0;
  border: 1px solid #e4ebef;
  border-radius: 4px;
  background-color: #fff;
  font-size: 1.4rem;
  outline: none;
  background-position: right;
  background-repeat: no-repeat;
  transition: 0.4s ease;
  &:hover {
    border: 1px solid #999999;
  }

  &:focus {
    border: 1px solid #055bd4;
  }
}

.textInput__invalid {
  border: 1px solid #e66c6c;

  &:focus {
    border: 1px solid #055bd4;
  }
}

.textInput__disabled {
  background-color: #eaeaea;
}
