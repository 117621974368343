.webFinanceApplicationsItem {
  overflow: auto;
  font-size: 1.2rem;
  cursor: pointer;
  border: 1px solid #e9e9e9;
  border-radius: 12px;
  padding: 14px;
  box-shadow: 0 2px 4px rgba(154, 192, 216, 0.16);
  min-height: 64px;
  background-color: #ffffff;
  transform: translateY(0);
  margin-bottom: 20px;
  transition: 0.2s all ease-out;

  @media (max-width: 800px) {
    margin: 0 0 10px;
  }

  &:first-child {
    margin-top: 20px;
  }

  &:hover {
    box-shadow: 0px 5px 15px rgba(188, 194, 204, 0.4);
    transform: translateY(-2px);
  }
}
