.compareQuotesTableItem {
  border-collapse: collapse;

  &:nth-child(odd) {
    background-color: #f6f9fc;
  }
}

.compareQuotesTableItem__header {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  font-weight: bold;
  text-align: left;
  padding: 10px;

  @media (max-width: 968px) {
    width: 50%;
  }

  @media (max-width: 460px) {
    width: 10%;
  }
}

.compareQuotesTableItem__logoContainer {
  width: 100px;
}

.compareQuotesTableItem__productName {
  display: inline-block;
  vertical-align: middle;
  margin: 1px 5px 0 0;
}

.compareQuotesTableItem__productButtonGroup {
  float: right;
}
