@import '../../../styles/themes/themes.scss';
$breakpoint-desktop: 1024px;

.ordersItem {
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  margin: 28px 0;
  color: #262b3e;
  display: block;
  box-shadow: 0 2px 4px rgba(188, 194, 208, 0.34);
  overflow: visible;
  transition: all 0.2s ease-out;
  position: relative;

  &:hover,
  &:focus {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(188, 194, 208, 0.34);
  }
}

.ordersItem__header {
  border-bottom: 1px solid #e6e6e6;
  padding: 0 5px 10px 5px;
  margin: 5px 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  flex-wrap: wrap;
}

.ordersItem__orderIcon {
  font-size: 20px;
  font-weight: 700;
  padding-right: 5px;
}

.ordersItem__orderRef {
  padding-right: 10px;
  font-weight: 600;
}

.ordersItem__orderTime {
  padding: 0 5px;
  color: #979aa6;
}

.ordersItem__orderHeaderItem {
  text-transform: 'capitalize';
  color: #979aa6;
  padding-right: 30px;
}

.ordersItem__content {
  padding: 5px;
  display: flex;
  justify-content: flex-start;

  @media (max-width: 900px) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.ordersItem__vehicle {
  padding: 5px;

  @media (max-width: 600px) {
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

.ordersItem__info {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  font-size: 14px;
  padding: 0 30px;
  min-width: 300px;

  @media (max-width: 800px) {
    min-width: 0;
  }
  @media (max-width: 600px) {
    min-width: none;
    padding: 0;
    width: 100%;
    text-align: center;
  }
}

.ordersItem__info--customerRecord {
  @extend .ordersItem__info;
  flex-direction: row;
  width: 80%;
  padding: 0px 5px;
  justify-content: space-between;
  @media (max-width: $breakpoint-desktop) {
    width: 100%;
  }
  @media (max-width: 750px) {
    flex-direction: column;
  }
}

.ordersItem__content--customerRecord {
  @extend .ordersItem__content;
  padding: 20px 5px;
}

.ordersItem__infoItem--customerRecord {
  display: flex;
  flex-direction: column;
  @media (max-width: 750px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0;
  }
}

.ordersItem__otherInfo {
  display: flex;
  justify-content: space-around;
  font-size: 14px;
  padding: 0 30px;
  min-width: 300px;
  width: 100%;

  @media (max-width: 800px) {
    min-width: 0;
  }

  @media (max-width: 600px) {
    min-width: none;
    padding: 0;
    max-width: 100%;
    display: flex;
    justify-content: space-around;
  }
}

.ordersItem__infoItem {
  padding: 5px 0;
  min-width: 350px;

  @media (max-width: 900px) {
    min-width: auto;
  }
}

.ordersItem__customerName {
  font-weight: bold;
  text-transform: capitalize;
  @media (max-width: 600px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.ordersItem__payment {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  font-size: 14px;
  margin: 0 20px 25px 0;
  @media (max-width: 800px) {
    margin: 10px 0;
  }

  @media (max-width: 600px) {
    width: 100%;
    text-align: center;
  }
}

.ordersItem__sentBy {
  @extend .ordersItem__payment;
  flex-direction: row;
  justify-content: flex-start;
}

.ordersItem__subHeader {
  color: #979aa6;
}
