.whatsNewSidebarLink {
  height: 70px;
  width: 240px;
  color: #fff;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 0;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    width: 300px;
    text-align: center;
  }
}

.whatsNewSidebarLink__inner {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.whatsNewSidebarLink__iconContainer {
  width: 70px;
  min-width: 70px;
  max-width: 70px;
  height: 70px;
  margin: 0 10px 0 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.whatsNewSidebarLink__icon {
  font-size: 36px;
}

.whatsNewSidebarLink__indicator {
  position: absolute;
  top: 8px;
  right: 8px;
}

.whatsNewSidebarLink__header {
  font-size: 1.4rem;
  font-weight: 600;
}
