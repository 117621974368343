.container {
  label {
    display: block;
    white-space: normal;
    word-wrap: normal;
    min-height: 1px;
    font-size: 15px;
    margin-bottom: 5px;
    padding: 0;
    line-height: 1.82rem;
  }
}

.recipientOptions {
  margin-bottom: 16px;
}

.selectInput {
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border: 1px solid #e4ebef;
  border-radius: 4px;
  background-color: #fff;
  font-size: 1.4rem;
  outline: none;
  background-position: 100%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAKxJREFUeNrslE0NgDAMRgcK8DADSAAJOEAaDpAAEjAwD0igkB4Ih9F+wIV8L2l66c/LtiwEQggh5FuKu4KUUiVplGgkZokuxri+3VcaZHsdFjRPuuROYrr09bmeEjjFOidzkqg9Qy0ig8RikclILDoHfyOGBe1+95aaxyIGmU4fJiThEgHu3yzhFnHIuCQgEYOMWwIWychAEug/cqDLWv01g2ZIghBCyK/ZBBgAS+tWiAnta3IAAAAASUVORK5CYII=);
  transition: 0.4s ease;
  display: block;
  min-height: 42px;
  margin-right: 12px;
  min-width: 150px;
}

.titleRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.filterOptions {
  background-color: #f3f4f9;
  padding: 16px;
  border-radius: 10px;

  > div {
    margin-bottom: 16px;
  }
}

$classFilterMobileBreakpoint: 570px;

.stockFilterSwitch {
  display: flex;
  flex-direction: column;
}

.classFilters {
  margin: 16px 0;

  @media (min-width: $classFilterMobileBreakpoint) {
    display: flex;
    gap: 24px;
  }
}

.classSwitch {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 8px 0;

  @media (min-width: $classFilterMobileBreakpoint) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: unset;
  }
}

.rangeFilter {
  > div {
    display: flex;
    flex-direction: row;
    gap: 40px;
  }
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.button {
  width: unset;

  // style doesn't apply if targeting <Button .> classNames
  [data-th='Icon'] {
    margin-right: 4px;
  }

  > div > div {
    // style doesn't apply if targeting <Button .> classNames
    justify-content: center;
  }
}

.inputError {
  color: red;
}

.fpaUrlTemplate {
  margin-bottom: 4rem;

  input {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #e4ebef;
    border-radius: 4px;
    background-color: #fff;
    font-size: 1.4rem;
    outline: none;
    min-height: 42px;
  }
}

.highlightedText {
  border: 1px solid #e4ebef;
  border-radius: 4px;
  line-height: 35px;
  padding: 10px;
}

.labelWithButton {
  display: flex;
  align-items: center;
}

.modalButton {
  display: inline;
  background: unset;
  border: unset;
  font-size: 30px;
  position: relative;
  top: -4px;
  left: -8px;
  cursor: pointer;
  color: #4d4d4d;
}

.requiredStar {
  color: red;
  margin-left: 4px;
}

.dealerDownloadLocationWrapper {
  border: 1px solid #e4ebef;
  padding: 8px;
  margin-bottom: 4rem;
  margin-top: -2rem;
}

.dealerDownloadLocation {
  padding: 10px;
  width: 100%;
}
.copyButton {
  width: fit-content;
}

.dealerDownloadLocationInputButton {
  display: flex;
  gap: 4px;
}
