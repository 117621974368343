@import '../../../styles/themes/themes.scss';

.dashboardLeadsFilter__wrapper {
  overflow: hidden;
  margin: 0 0 20px;
}

.dashboardLeadsFilter__outer {
  font-size: 1.4rem;
  @media (max-width: 450px) {
    float: none;
    width: 100%;
  }
}

.dashboardLeadsFilter__viewText {
  float: left;
  margin: 5px 10px 0 0;
  @media (max-width: 450px) {
    display: none;
  }
}

.dashboardLeadsFilter__buttonContainer {
  @media (max-width: 450px) {
    float: none;
    width: 100%;
  }
}

.dashboardLeadsFilter__button {
  margin: 0;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 5px 10px;
  background-color: #f5f5f5;
  border-top: solid 1px #e4e4e1;
  border-left: solid 1px #e4e4e1;
  border-right: solid 1px #e4e4e1;
  border-bottom: solid 3px #bebebe;
  border-radius: 0;
  color: #a0a3a4;
  outline: none;
  cursor: pointer;
  @media (max-width: 450px) {
    width: 33.333%;
    padding: 7px 0;
    text-align: center;
    font-size: 1.2rem;
  }
}

.dashboardLeadsFilter__filterActive {
  color: #000000;
  background-color: #ffffff;
  border-bottom: solid 3px #6bc348;
  @include theme(
    (
      border-bottom-color: bodyMain
    )
  );
}
