.checkEligibility__panelHeader {
  position: relative;
  padding: 16px 0;
  font-size: 1.6rem;
  margin: 0;
  height: 45px;
}

.checkEligibility__navContainer {
  // float: left;
  @media (max-width: 1000px) {
    // float: none;
  }
}

.checkEligibility__cfcLogo {
  position: absolute;
  top: 8px;
  left: 10px;
  height: 30px;
  width: auto;
}

.checkEligibility__formContainer {
  width: calc(100% - 201px);
  min-height: 550px;
  margin: 0 0 20px 0;
  padding: 0;
  @media (max-width: 1000px) {
    width: 100%;
    min-height: 0;
  }
}
.checkEligibility__loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
}
