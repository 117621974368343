@import '~SM/styles/stockStyles.module.scss';

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(500px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(500px);
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  animation: fadeOut 0.25s ease-in-out;
}

.overlayOpen {
  animation: fadeIn 0.25s ease-in-out;
}

.dialog {
  width: 100%;
  max-width: 726px;
  margin-left: auto;
  margin-right: auto;
  background: $white;
  padding: 26px;
  outline: none;
  position: relative;
  box-shadow: 1px 23px 20px rgba(0, 0, 0, 0.25);
  top: 50vh;
  border-radius: 10px;
  animation: slideOut 0.25s ease-in-out, fadeOut 0.25s ease-in-out;

  @media (min-width: $mobile-end) {
    top: unset;
    margin-top: 10%;
  }
}

.dialogOpen {
  animation: slideIn 0.25s ease-in-out, fadeIn 0.25s ease-in-out;
}

.closeCross {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 22px;
  padding: 4px;
  border: unset;
  background: unset;
  cursor: pointer;

  transition: transform 0.8s ease-in-out;
  :hover {
    transform: translateY(-1px);
  }
}

.header {
  display: flex;
  position: relative;
  align-items: baseline;
  &::after {
    content: ' ';
    position: absolute;
    height: 1px;
    background: $grey-001;
    bottom: 0;
    left: -20px;
    right: -20px;
  }
}

.title {
  font-family: 'Montserrat', 'Open Sans', Arial, sans-serif;
  text-align: left;
  font-weight: 700;
  color: $black;
  margin: unset;
  margin-right: 18px;
  margin-bottom: 26px;
  font-size: 16px;
  @media (min-width: $mobile-end) {
    font-size: 24px;
  }
}

.icon {
  color: $alert-001;
  font-size: 24px;
  margin-right: 12px;
}

.content {
  font-family: 'Open sans';
  padding: 40px 026px;
  color: $black;
  > p {
    font-size: 16px;
  }
}
