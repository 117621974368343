.permissionDeniedMessage {
  margin: 0 auto;
  padding: 40px 20px;
  max-width: 800px;
}
.permissionDeniedMessage__message {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 30px;
}

.permissionDeniedMessage__heading {
  font-size: 2rem;
  margin: 20px 0 20px 0;
}

.permissionDeniedMessage__body {
  font-size: 1.4rem;
  margin: 20px 0;
}
.permissionDeniedMessage__button {
  max-width: 250px;
}
