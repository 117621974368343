.financeCalculatorStatistics__row {
  display: flex;
  justify-content: flex-start;
  border-bottom: solid 1px #e4ebef;
  position: relative;

  &:last-child {
    border-bottom: none;
  }
}

.financeCalculatorStatistics__iconOuter {
  width: 20%;
  align-self: center;
  font-size: 65px;
  text-align: center;

  @media (max-width: 400px) {
    font-size: 50px;
  }
}

.financeCalculatorStatistics__icon {
  width: 100%;
  height: 100%;
}

.financeCalculatorStatistics__quoteIcon {
  position: relative;
  left: 6px;
}

.financeCalculatorStatistics__data {
  width: 80%;
  min-height: 90px;
  padding: 15px 20px 15px 40px;
  border-left: solid 1px #e4ebef;
}

.financeCalculatorStatistics__dataTop {
  overflow: hidden;
}

.financeCalculatorStatistics__figure {
  font-size: 3rem;
  float: left;
  margin: 0 10px 0 0;
}

.financeCalculatorStatistics__comparison {
  float: left;
  font-size: 2rem;
  margin: 7px 0 0 0;
}

.financeCalculatorStatistics__dataBottom {
  font-size: 1.6rem;
}
