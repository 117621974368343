.addVapModal__header {
  font-weight: bold;
  font-size: 2rem;
  border-bottom: solid #ebebeb 1px;
  padding: 0 0 20px 0;
  margin: 0 0 20px 0;
}

.addVapModal__footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-grow: 0;
  border-top: solid #ebebeb 1px;
  padding: 20px 0 0 0;
  margin: 20px 0 0 0;
}

.addVapModal__duplicateWarning {
  border: 1px solid #e4ebef;
  border-radius: 4px;
  padding: 20px;
  max-width: 450px;
  margin: 20px auto;
}

.addVapModal__duplicateWarningButton {
  width: 150px;
  margin: 20px auto 0 auto;
}
