.outerLeft {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  @media not print {
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
