.stockEditRefinementSearch__comparison--outer {
  padding: 0px 5px 20px;

  @media (min-width: 845px) {
    padding: 0 20px 20px;
  }
}

.stockEditRefinementSearch__visResults {
  position: relative;
  border-radius: 8px;
  margin-left: 10px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  margin-left: 0;
}

.stockEditRefinementSearch__buttonContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-top: 24px;

  @media (max-width: 425px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
  }
}

.stockEditRefinementSearch__button {
  min-width: 150px;

  @media (max-width: 425px) {
    width: 100%;
    :first-of-type {
      margin-bottom: 10px;
    }
  }
}

.stockEditRefinementSearch__confirmVehicleText {
  width: 100%;
  margin-top: 25px;
  font-size: 18px;
  font-weight: 400;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}
