.quoteStepsItem__inner {
  border-left: solid 2px;
  padding: 0 0 20px 40px;
  position: relative;

  &:last-of-type {
    border-left: solid 2px transparent;
    padding: 0 0 20px 40px;
    position: relative;
  }
}

.quoteStepsItem__dot {
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: solid 2px #000;
  position: absolute;
  left: -8px;
  top: 0;
}

.quoteStepsItem__header {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;
  transform: translateY(-4px);
}

.quoteStepsItem__text {
  font-size: 14px;
  transform: translateY(-4px);
}
