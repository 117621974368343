.pushDealActivityContainerSection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  color: #464646;
  font-size: 15px;
}

.pushDealActivityContainerSection__value {
  font-weight: bold;
  text-align: right;
}
