.quoteInfoCard {
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
}

.quoteInfoCard__row {
  text-align: center;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
}

.quoteInfoCard__funderLogo {
  width: 140px;
  margin: 0 auto;
}

.quoteInfoCard__cell {
  width: 50%;
}

.quoteInfoCard__bottom {
  padding: 10px 20px;
}
