.dropdown {
  margin-right: 2rem;
  display: flex;
  gap: 24px;
  margin-bottom: 15px;
}

.selectContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selectLabel {
  margin: unset;
  text-align: left;
  width: 100%;
  font-size: 16px;
  margin-bottom: 4px;
}

