.stockEditContainer__panelTitleContainer {
  position: relative;
  width: fit-content;
}

.stockEditContainer__tipButtonPosition-en {
  position: absolute;
  right: -130px;
  max-width: 250px;
  top: 20px;
}

.stockEditContainer__tipButtonPosition-de {
  position: absolute;
  right: -145px;
  max-width: 250px;
  top: 20px;
}

.stockEditContainer__tipButtonPosition-es {
  position: absolute;
  right: -168px;
  max-width: 250px;
  top: 20px;
}

.stockEditContainer__tipButtonPosition-it {
  position: absolute;
  right: -202px;
  max-width: 250px;
  top: 20px;
}

.stockEditContainer__tipButtonPosition-fr {
  position: absolute;
  right: -148px;
  max-width: 130px;
  top: 20px;
}
