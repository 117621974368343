.inlineExclamationWarning {
  color: #ff6003;
  font-size: 1.4rem;
  width: auto;
  display: table;
  padding: 5px 0 5px 0;
}

.inlineExclamationWarning__imageContainer {
  display: table-cell;
  vertical-align: top;
}

.inlineExclamationWarning__image {
  height: 25px;
  width: 25px;
}

.inlineExclamationWarning__body {
  display: table-cell;
  padding: 0 20px 0 10px;
  vertical-align: middle;
}

.inlineExclamationWarning__title {
  font-weight: 700;
  display: block;
  margin: 0 0 5px 0;
}
