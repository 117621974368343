.inputTooltip {
  position: absolute;
  display: none;
  background-color: #fff;
  left: 0;
  width: 100%;
  z-index: 100;

  &.inputTooltip--isVisible {
    display: block;
  }
}

.inputTooltip-animation-enter {
  transform: translate3d(40px, 0, 0);
  opacity: 0;
}

.inputTooltip-animation-enter-active {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  transition: all 400ms ease-in-out;
}

.inputTooltip-animation-exit-active {
  transform: translate3d(40px, 0, 0);
  opacity: 0;
  transition: all 400ms ease-in-out;
}

.inputTooltip__inner {
  padding: 23px 20px 10px;
  margin: 16px 0 0 0;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
}

.inputTooltip__cross {
  all: unset;
  position: absolute;
  right: 5px;
  top: 20px;
}

.inputTooltip__crossImage {
  height: 20px;
  cursor: pointer;
}

.inputTooltip__triangle {
  content: '';
  display: block;
  position: absolute;
  left: 20px;
  top: 0;
  width: 34px;
  height: 16px;
  background-image: url('../../styles/icons/tooltip-triangle-up.png');
  background-repeat: no-repeat;
  background-size: 34px 16px;
}
