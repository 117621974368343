.sidebarLink {
  height: 62px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  color: #30364e;
  padding: 0px 15px;
  font-weight: 400;
  line-height: 24px;
  font-size: 14px;

  .icon-wrapper .icon {
    font-size: 74px;
  }

  &:hover,
  &.active {
    background-color: #f3f4f9;
  }

  .iconWrapper {
    font-size: 22px;
    color: #30364e;
  }
}

.container {
  padding-top: 30px;

  .financeReports {
    margin-bottom: 30px;
  }

  .title {
    padding: 0 20px;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 10px;
  }
}
