.container {
  line-height: 24px;
  color: #000000;

  .title {
    font-weight: 700;
    font-size: 16px;
  }

  .details {
    font-weight: 400;
    font-size: 12px;
  }
}

