.quoteResultsToolbar {
  border-bottom: 1px solid #efefef;
  padding: 5px 5px 8px 5px;
  display: flex;
  flex-direction: column;
  row-gap: 13px;
}

.quoteResultsToolbar__buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 7.5px;
  overflow-x: auto;
  padding: 5px 0;

  .button {
    min-width: min-content;
    white-space: nowrap;
  }
}

.quoteResultsToolbar__toggles {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;

  span {
    font-weight: bold;
    font-size: 1.2rem;
    white-space: nowrap;
    line-height: 24px;
    margin: 0 8px 0 0;
    text-align: left;
  }
}
