.dealerInfo {
  margin: 0 0 10px 0;
  line-height: 25px;
  @media print {
    padding: 20px;
  }
}

.dealerInfo__name {
  font-family: 'Montserrat', 'Open Sans', Arial, sans-serif;
  font-size: 1.8rem;
}

.dealerInfo__address {
  font-size: 1.2rem;
  font-weight: 400;
}
