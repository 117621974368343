.quoteInfo {
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
}

.quoteInfo__top {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(204, 204, 204);
  padding: 10px 20px;
}

.quoteInfo__bottom {
  padding: 10px 20px;
}

.quoteInfo__fields {
  align-self: center;
  display: table;
  width: calc(100% - 105px);
}

.quoteInfo__labels,
.quoteInfo__values {
  display: table-row;
}

.quoteInfo__labels {
  display: table-row;
}

.quoteInfo__labels > .quoteInfo__cell {
  padding: 0 3px 10px 3px;
}

.quoteInfo__values > .quoteInfo__cell {
  padding: 0 3px;
}

.quoteInfo__cell {
  display: table-cell;
  text-align: center;
  font-weight: 700;
}

.quoteInfo__funderLogoCell {
  display: table;
  height: 100%;
  align-self: center;
  width: 105px;
}

.quoteInfo__funderLogo {
  display: table-cell;
  vertical-align: middle;
}
