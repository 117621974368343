@import '../../../styles/themes/themes.scss';

.largeMenuButton {
  padding: 0;
  text-align: center;
  position: relative;
  width: 170px;
  height: 170px;
  border-radius: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
}

.largeMenuButton__inner {
  border: 2px solid #5e6977;
  height: 170px;
  padding: 10px 0px 10px 0;
  border-radius: 15px;
  box-shadow: 0px 6px 20px rgba(188, 194, 204, 0.34);
  transform: translateY(0px);
  transition: all 0.2s ease-out;
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0px 20px 30px rgba(188, 194, 204, 0.6);

    @include theme(
      (
        border-color: bodyMain
      )
    );
  }
}

.largeMenuButton__textOuter {
  display: table;
  width: 100%;
  line-height: 1.3rem;
}

.largeMenuButton__text {
  font-size: 1.3rem;
  text-align: center;
  padding: 0;
  height: 30px;
  display: table-cell;
  vertical-align: middle;
  font-weight: 600;
  color: #4a4a4a;
}

.largeMenuButton__icon {
  height: 48px;
  width: 65px;
  margin: 15px auto;
  font-size: 48px;
  color: #5e6977;
}

.largeMenuButton__button {
  font-size: 1.4rem;
  margin: 10px auto 0;
  padding: 5px 10px;
  width: 85%;
  text-align: center;
  font-weight: bold;
  border-radius: 3px;
  color: #ffffff;

  @include theme(
    (
      background-color: bodyMain
    )
  );
}
