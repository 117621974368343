.container {
  p {
    line-height: 24px;
    margin: 0;
  }

  h1 {
    font-size: 1.8rem;
    font-weight: 700;
    margin: 0;
    line-height: 16px;
  }

  .tip {
    margin: 16px 0;
    font-size: 12px;
  }

  main {
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    padding: 20px 25px;
    width: 100%;
  }
  .upper {
    display: flex;
    flex-direction: column;
    :global {
      .fieldGroupRhf {
        margin-top: 0;
      }
    }
  }
  .dividingText {
    font-size: 14px;
    color: #000;
    line-height: 16px;
    margin: 20px auto;
  }

  form {
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    padding: 10px 25px 20px 25px;

    .customerDetails {
      :global {
        .fieldGroupRhf__label {
          font-weight: 400;
        }
      }
    }
    .customerConfirm {
      display: block;
      margin: 24px 0 0 0;

      > div {
        &:first-of-type {
          padding-top: 20px;
        }
        &:not(:last-of-type) {
          padding-bottom: 20px;
        }
        border-top: 1px solid #b5b5b5;
      }

      h4 {
        font-size: 15px;
        font-weight: 600;
        margin: 0 0 15px 0;
        line-height: 1.82rem;
      }
    }
    .assignOptions {
      display: flex;
      justify-content: space-between;
      font-size: 1.4rem;
      align-items: baseline;
    }
    .assignToUser {
      display: flex;
      align-items: center;
      gap: 10px;

      span {
        margin-right: 7px;
        font-size: 1.4rem;
        color: #4a4a4a;
      }

      button {
        font-size: 1.2rem;
        font-family: 'Open Sans', Arial, sans-serif;
        height: 27px;
        border-radius: 6px;
      }
    }

    :global {
      .formFooter {
        margin: 30px 0 0;

        .formFooter__button {
          margin: 0;
        }
      }
    }
  }
}
