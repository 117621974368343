@import '~/styles/webshops-styles.scss';
$mobileBreakpoint: 585px;
$mobileBreakpoint2: 355px;

.stockEditContainer__wrapper {
  display: flex;
  justify-content: space-between;
}

.stockEditContainer__col0 {
  margin-right: 20px;
  min-width: 300px;
  max-width: 300px;
}

.stockEditContainer__col1 {
  flex-grow: 1;
  max-width: 840px;
}

.stockEditContainer__panel {
  overflow: hidden;
}

.panel__outer1 {
  padding: 0;
}

.stockEditContainer__confirmationModal {
  padding-left: 15px;
  min-height: 50px;
}

.stockEditContainer__buttons {
  flex: 0 0 100%;
}

.stockEditContainer__editButton {
  width: 140px;
  margin-left: auto;
  margin-top: -56px;
  button {
    background: #fff;
  }
}

.stockEditContainer__vat {
  display: flex;
  height: 50px;
  padding-top: 10px;
  border-top: $border;
  border-bottom: $border;
}
.stockEditContainer__vatOption {
  margin: 0 20px 0 0;
}

