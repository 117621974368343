.leagueTableHeaderItem {
  border-right: solid #cccccc 1px;
  padding: 5px;
  position: relative;

  &:hover {
    cursor: pointer;
    background-color: #cccccc;
  }
}

.leagueTableHeaderItem__arrow {
  position: absolute;
  right: 0;
  bottom: 0;
}
