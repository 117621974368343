.assignLeadInitials__container {
  font-size: 1.2rem;
  white-space: nowrap;
  display: block;
  color: #000;
  background: none;
  border-style: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  :focus {
    outline: 0;
    border-style: none;
  }
}

.assignLeadInitials__count {
  font-style: italic;
  padding: 0 5px 0 5px;
}

.assignLeadInitials__initials {
  font-size: 1.2rem;
  margin: 0;
  font-weight: 700;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  border-radius: 50%;
  text-decoration: none;
  letter-spacing: -1.1px;
  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  width: 40px;
  height: 40px;
  line-height: 38px;
}

.assignLeadInitials__initials--color-1 {
  color: #dfdfdf;
  border: 1px solid #dfdfdf;

  &:hover {
    background: #dfdfdf;
    color: #fff;
  }
}

.assignLeadInitials__initials--color0 {
  color: #dfdfdf;
  border: 1px solid #dfdfdf;

  &:hover {
    background: #dfdfdf;
    color: #fff;
  }
}

.assignLeadInitials__initials--color1 {
  color: #5ec1ff;
  border: 1px solid #5ec1ff;

  &:hover {
    background: #5ec1ff;
    color: #fff;
  }
}

.assignLeadInitials__initials--color2 {
  color: #00bf1f;
  border: 1px solid #00bf1f;

  &:hover {
    background: #00bf1f;
    color: #fff;
  }
}

.assignLeadInitials__initials--color3 {
  color: #b47bbc;
  border: 1px solid #b47bbc;

  &:hover {
    background: #b47bbc;
    color: #fff;
  }
}

.assignLeadInitials__initials--color4 {
  color: #8594f1;
  border: 1px solid #8594f1;

  &:hover {
    background: #8594f1;
    color: #fff;
  }
}

.assignLeadInitials__initials--color5 {
  color: #70dcb8;
  border: 1px solid #70dcb8;

  &:hover {
    background: #70dcb8;
    color: #fff;
  }
}

.assignLeadInitials__initials--small {
  width: 35px;
  height: 35px;
  line-height: 30px;
}
