.pushDealPricingDetails {
  margin: 0 0 30px;
  .pushDealActivityContainerSection {
    margin-bottom: 20px;
  }
}

.pushDealPricingDetails__quotes {
  .pushDealQuotePreview__showMore {
    margin: 20px 0;
    padding: 20px 0;
    max-height: 300px;
  }

  .pushDealQuotePreview__showLess {
    margin: 20px 0;
    padding: 20px 0;
  }

  .pushDealQuotePreview__quotePreviewButton {
    bottom: 6px;
  }

  .pushDealQuotePreview__header {
    flex-direction: column;
  }

  .pushDealQuotePreview__productName {
    margin: 10px 0 0;
  }

  .pushDealQuotePreview__section {
    font-size: 13px;
    min-height: 25px;
    padding: 5px 20px;
  }
}
