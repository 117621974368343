.consumerVehicleDetails {
  overflow: hidden;
  zoom: 1;
  width: 100%;
  font-size: 1.4rem;
  margin: 0;
  padding: 20px 0;
  position: relative;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1110px) {
    flex-wrap: wrap;
  }
}

.consumerVehicleDetails__leftContainer {
  width: 50%;
  padding: 0 0 0 10px;

  @media (max-width: 1110px) {
    width: 100%;
  }
}

.consumerVehicleDetails__divider {
  border: 0.5px solid #f0f0f0;

  @media screen and (max-width: 1110px) {
    display: none;
  }
}

.consumerVehicleDetails__middleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1 auto;
  align-self: stretch;

  > * {
    flex: 1;
  }

  @media (max-width: 1110px) {
    padding: 40px 0;
    width: 49%;
    text-align: center;
  }
}

.consumerVehicleDetails__iconDivider {
  border: 0.5px solid #f0f0f0;
  height: 100%;
  flex: 0;

  @media screen and (max-width: 1110px) {
    display: block;
  }
}

.consumerVehicleDetails__buttonContainer {
  width: 254px;

  @media (max-width: 1110px) {
    width: 100%;
  }
}
