.lenderNotesItem__wrapper {
  background-color: #ffffff;
  padding: 10px 20px;

  &:nth-child(even) {
    background: #fbfbfb;
  }

  &:last-child {
    border-bottom: 1px solid #d2d3d5;
  }
}

.lenderNotesItem__update-text {
  font-size: 1.4rem;
  display: block;
  padding: 20px 0 0 0;
}

.lenderNotesItem__date {
  font-size: 1.4rem;
  color: #b2b3b1;
  height: 40px;
  display: block;
  line-height: 20px;
}

.lenderNotesItem__moreLink {
  font-size: 1.4rem;
  margin: 0 0 0 5px;
  cursor: pointer;
  text-decoration: none;
  color: #055bd4;
  &:hover {
    text-decoration: underline;
  }
}
