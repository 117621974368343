.vehicleImageWithVrm {
  position: relative;
  border: solid 1px #e4ebef;
  border-radius: 8px;
  overflow: hidden;
}

.vehicleImageWithVrm__vrm {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  bottom: 0;
  height: 20px;
}

.vehicleImageWithVrm__blueBit {
  width: 10px;
  background-color: #4f8df2;
}

.vehicleImageWithVrm__yellowBit {
  background-color: #fff374;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 1px;
}
