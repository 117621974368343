.popularProduct__outer {
  height: 100%;
  position: relative;
  border-radius: 0 0 6px 6px;
  overflow: hidden;
}

.popularProduct__tr {
  background-color: #fffffe;

  &:nth-child(even) {
    background-color: #f7f6f5;
  }
}
