.crainDisclaimerModal {
  padding: 0 30px;
  @media (max-width: 600px) {
    padding: 0 5px;
  }
}
.crainDisclaimerModal__footer {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}

.crainDisclaimerModal__button {
  min-width: 150px;
}
