.helpVideoModal {
  display: flex;
  justify-content: space-around;
}

.helpVideoModal__topVideo {
  margin: 30px 0 0 0;
}

.helpVideoModal__header {
  color: #000000;
  font-weight: bold;
  font-size: 2rem;
  top: 25px;
  left: 25px;
}

.helpVideoModal__bottomSection {
  padding: 20px 0 0 0;
}

.helpVideoModal__headerSection {
  padding: 0 0 20px 0;
}

.helpVideoModal__subHeader {
  color: #5c5c5c;
  font-weight: bold;
  font-size: 1.6rem;
}

.helpVideoModal__buttonContainer {
  overflow: auto;
  padding: 20px 0 0 0;
  border-top: solid #eeeeee 1px;
}

.helpVideoModal__buttonOuter {
  width: 200px;
  float: right;
  margin-right: 50px;
}

.helpVideoModal__videoList {
  display: flex;
  flex-wrap: wrap;
}
