.panelToolBar__toolbar {
  position: relative;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

.panelToolBar__notPadded {
  padding: 0;
}

.panelToolBar__paddedX {
  padding-left: 30px;
  padding-right: 30px;
}

.panelToolBar__paddedY {
  padding-top: 10px;
  padding-bottom: 10px;
}

.panelToolBar__white {
  background-color: #ffffff;
  box-shadow: none;
}

.panelToolBar__notUnderlined {
  height: 58px;
  border-bottom: 0;
}

.panelToolBar__underlined {
  border-bottom: 1px solid #e4ebef;
}
