.tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tabList {
  display: flex;
}

.tab {
  all: unset;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 0;
  padding: 14px;
  font-size: 12px;
  font-weight: 600;
  color: #30364e;
  background: linear-gradient(90deg, #cbcbcb 0%, #e7e7e7 100%);
  border-radius: 6px 6px 0 0;

  @media screen and (min-width: 650px) {
    font-size: 18px;
  }

  &[data-state='active'] {
    background: #30364e;
    color: white;

    &:after {
      content: '';
      width: 100%;
      height: 3px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(90deg, #00736e 0%, #69b547 100%);
    }
  }
}
