.lenderPrivacy {
  padding: 0 2.4rem 1rem;
  margin: 0 1.5rem 4.5rem 1.5rem;
  border: 1px solid #b5b5b5;
  border-radius: 12px;

  p,
  li {
    line-height: 3rem;
    font-size: 1.5rem;
  }
}

.lenderPrivacy__sub-title {
  font-family: 'Montserrat', 'Open Sans', Arial, sans-serif;
  font-weight: 400;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 1.4em;
  text-align: left;
  color: #000000;
}
