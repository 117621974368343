.initialDiscloseDocumentSettings__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 118px;
}

.initialDiscloseDocumentSettings__fileUploader {
  border: 2px dashed #e6e6e6;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 8px;
  text-align: center;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.initialDiscloseDocumentSettings__actions {
  display: flex;
  justify-content: space-between;
  margin: 40px auto 0 auto;
  max-width: 500px;
  border-top: 1px solid #e4ebef;
  padding: 20px 0 0 0;
}

.initialDiscloseDocumentSettings__button {
  width: 120px;
}
