.bankDetailsForm__section--inner {
  width: calc(40% - 1px);
}

.bankDetailsForm__button {
  display: block;
  min-width: 190px;
  margin: 0 10px 10px;
  float: right;

  @media (max-width: 768px) {
    margin: 10px auto;
    width: 50%;
    float: none;
  }

  @media (max-width: 520px) {
    width: 100%;
    margin: 10px auto;
  }
}

.bankDetailsForm__detailsToggleOuter {
  width: 70%;
  overflow: hidden;
  margin: 20px 0 0 0;

  @media (max-width: 650px) {
    width: 100%;
  }
}

.bankDetailsForm__detailsToggle {
  float: right;
}

.bankDetailsForm__informationWarning {
  border: 1px solid #e4ebef;
  max-width: 600px;
  padding: 15px;
  border-radius: 5px;
  margin: 10px auto 20px auto;
}

.bankDetailsForm__informationList {
  margin: 0;
  padding: 10px 0 0 20px;
}
