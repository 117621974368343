@import '~/styles/webshops-styles.scss';
.iconTooltip__position {
}

.iconTooltip__button {
  margin-top: -8px;
  width: 24px;
  height: 24px;
  padding: 0;
  background: none;
  border: none;
  color: $info-blue;

  &:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
  }
  > div:nth-child(1) {
    display: inline-block;
    font-size: 26px;
    line-height: 24px;
  }
}

.iconTooltip__hoverSectionContainer {
  position: relative;
  display: flex;
}

.iconTooltip__hoverModal {
  position: absolute;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 26px 0 0 0;
  line-height: 24px;
  min-width: 240px;
  width: fit-content;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
  transition: 300ms ease-in-out;
  z-index: 1;
  right: -120px;
}

.iconTooltip__hoverModalText {
  padding: 20px;
  font-weight: 400;
  font-size: 16px;
  color: #a1a1a1;
}

