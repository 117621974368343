.deletedCustomerSuccessBanner {
  position: fixed;
  background: #2fdab0;
  top: 70px;
  width: 100%;
  z-index: 2;
  left: 70px;
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 10px;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    left: 0;
    width: 100%;
  }

  @media (max-width: 340px) {
    font-size: 1.2rem;
  }
}

.deletedCustomerSuccessBanner__TextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.deletedCustomerSuccessBanner__smallText {
  margin: 5px 0 0 0;
  font-weight: 400;
}

.deletedCustomerSuccessBanner__button {
  position: fixed;
  top: 70px;
  right: 0px;
  border: none;
  background: none;
  color: #ffffff;
  font-size: 40px;
  padding: 0;
  cursor: pointer;
}

.deletedCustomerSuccess-enter {
  transform: translateY(-70px);
}

.deletedCustomerSuccess-enter-active {
  transform: translateY(0px);
  transition: transform 300ms ease-in-out;
}

.deletedCustomerSuccess-exit-active {
  transform: translateY(-70px);
  transition: transform 200ms ease-in-out;
}
