$breakpoint-mobileSm: 400px;
$breakpoint-mobileXl: 600px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

.pushDealSentDetails {
  display: flex;
  margin: 20px 0 0;
  padding: 0 20px;
  border-radius: 10px;
  box-shadow: 0px 6px 20px rgba(188, 194, 208, 0.2);
  @media (max-width: $breakpoint-desktop) {
    flex-direction: column;
  }
  @media (max-width: $breakpoint-tablet) {
    flex-direction: row;
  }
  @media (max-width: $breakpoint-mobileXl) {
    flex-direction: column;
  }
}

.pushDealSentDetails__item {
  width: calc((100% - 50px) / 2);
  padding: 20px 0;
  display: flex;
  align-items: center;
  border-bottom: none;
  overflow: hidden;
  @media (max-width: $breakpoint-desktop) {
    width: 100%;
    &:first-of-type {
      border-bottom: 1px solid rgba(48, 54, 77, 0.1);
    }
  }
  @media (max-width: $breakpoint-tablet) {
    width: calc((100% - 50px) / 2);
    &:first-of-type {
      border-bottom: none;
    }
  }
  @media (max-width: $breakpoint-mobileXl) {
    width: 100%;
    &:first-of-type {
      border-bottom: 1px solid rgba(48, 54, 77, 0.1);
    }
  }
}

.pushDealSentDetails__icon {
  font-size: 30px;
  background-color: rgba(48, 54, 77, 0.1);
  border-radius: 100%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px 0 0;
}

.pushDealSentDetails__text {
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.pushDealSentDetails__infoTitle {
  font-size: 15px;
  margin-bottom: 10px;
  margin-right: 10px;
  color: rgba(48, 54, 77, 0.6);
}

.pushDealSentDetails__infoName {
  font-weight: bold;
  font-size: 15px;
}

.pushDealSentDetails__customerName {
  color: #64bc46;
}

.pushDealSentDetails__infoEmail {
  font-size: 14px;
  overflow-wrap: break-word;
  hyphens: auto;
  overflow: hidden;
  max-width: 100%;
  color: rgba(48, 54, 77, 0.6);
}

.pushDealSentDetails__arrow {
  width: 30px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  @media (max-width: $breakpoint-mobileXl) {
    display: none;
  }
}

.pushDealSentDetails__arrowTop {
  height: 50%;
  width: 100%;
  background: linear-gradient(to top right, #fff calc(50% - 1px), rgba(48, 54, 77, 0.1), #fff calc(50% + 1px));
}

.pushDealSentDetails__arrowBottom {
  height: 50%;
  width: 100%;
  background: linear-gradient(to top left, #fff calc(50% - 1px), rgba(48, 54, 77, 0.1), #fff calc(50% + 1px));
}
