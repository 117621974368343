.stockList {
  position: relative;
  min-height: 100vh;
  padding: 0 0px 10px;

  @media (max-width: 400px) {
    padding: 0;
  }
}

.stockList .loadingIndicator {
  position: absolute;
  left: calc(50% - 45px);
  top: 100px;
}

.stockList__paginationSummary {
  font-size: 1.8rem;
  font-weight: 600;
  color: #5e6977;
  padding: 20px 0;
  margin: 0;
  border-bottom: 1px solid #e4ebef;
}

.stockList__vehiclesNotFound {
  font-size: 1.8rem;
  font-weight: 600;
  color: #5e6977;
  padding: 20px 10px;
}

.stockList__modalButton {
  width: 100%;

  @media (min-width: 500px) {
    width: fit-content;
  }
}

.stockList__listItemContainer {
  display: flex;
  flex-direction: column;
  gap: 6rem;
}

