@import '../../../../styles/themes/themes.scss';

.customer__customerOuter {
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;
}

.customer__image {
  margin: 0 10px 0 0;
}

.customer__body {
  font-size: 1.4rem;
  max-width: 100%;
}

.customer__name {
  font-size: 1.6rem;
  margin: 0 0 5px 0;
}

.customer__editProfile {
  margin: 5px 0 0 0;
  cursor: pointer;
  color: #6bc348;

  &:hover {
    text-decoration: underline;
  }
}
