@import '~SM/styles/stockStyles.module.scss';

.card {
  display: flex;
  padding: 16px;
  align-items: baseline;
  gap: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  color: $black-001;

  :global(.icon) {
    position: relative;
    top: 3px;
    font-size: 20px;
    color: $alert-001;
  }
}

.card--alert {
  background: $alert-002;
}
.card--warning {
  background: $warning-001;
  :global(.icon) {
    color: $black-001;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  color: inherit;
  text-align: left;
  margin: unset;
  font-weight: 700;
  font-size: 14px;
}
.paragraph {
  color: inherit;
  margin: unset;
}

