.imageCount {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  background: #060a0d82;
  color: white;
  font-size: 12px;
  padding: 2px 4px 2px 2px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.mainImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
  position: relative;
  flex-grow: 1;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.SingleMainImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  flex-grow: 1;
  height: 100%;
  @media (min-width: 901px) {
    max-height: 206px;
  }
}

.imageContent {
  width: 100%;
}

.imageReel {
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: space-between;
  width: 27%;
}

.miniImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 32%;
  flex: 1;
}

.aspectRatio {
  ::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 0;
    // possible aspect ratios calc(100% / (16/9)), 4/3 etc
    padding-bottom: calc(100% / 1.5);
  }
}

