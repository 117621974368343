.editNewDecision__options {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.editNewDecision__small {
  position: relative;
  top: 10px;
  color: #b2b3b1;
}
.editNewDecision__toolbar {
  position: relative;
  left: 6px;
}
.editNewDecision__optionsSelect,
.editNewDecision__optionsButton {
  margin: 0 10px;
}
.editNewDecision__optionsButton {
  max-width: 250px;
}
