.latestDealsCustomerInfoMedia {
  display: flex;
  flex-wrap: wrap;
}

.latestDealsCustomerInfoMedia__vehicleImage {
  border: solid 2px #c4c4c4;
  border-radius: 3px;
  background-color: white;
  margin: 0 10px 0 0;
  width: 70px;
  height: 50px;
}

.latestDealsCustomerInfoMedia__name {
  float: left;
  font-size: 1.4rem;
  font-weight: bold;
  color: #000000;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.latestDealsCustomerInfoMedia__vrm {
  clear: left;
  float: left;
  width: 100px;
  margin: 5px 10px 0 0;
  font-size: 1.4rem;
  letter-spacing: 0.5px;
}

.latestDealsCustomerInfoMedia__vehicleName {
  font-size: 1.4rem;
  font-weight: bold;
  color: #808080;
  margin: 10px 0 0 0;
  width: 100%;
}
