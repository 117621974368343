.searchBar__container {
  width: 100%;
  position: relative;

  .icon {
    font-size: 30px;
    color: #4e91f5;
    position: absolute;
    top: 10px;
    left: 12px;
  }
}

.searchBar__input {
  width: 100%;
  font-size: 18px;
  font-weight: 300;
  padding: 15px;
  padding-left: 52px;
  border-radius: 40px;
  border: 1px solid #e4ebef;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.08);
  outline: none;

  &:focus {
    .searchSuggestions {
      display: block;
    }
  }
}
