.splitButtonOption__option {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px 15px 10px 10px;
  transition: background-color 0.2s, visibility 0.4s;
  cursor: pointer;
  overflow: hidden;
  border: inherit;
  border: 0.5px;

  &:hover {
    background-color: #f5f5f5;
  }
}

.splitButtonOption__option[data-highlighted] {
  background-color: #f5f5f5;
}

.splitButtonOption__optionIcon {
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
  font-size: 40px;
  text-align: left;
}

.splitButtonOption__optionText {
  font-weight: 600;
  color: #383838;
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 1.4rem;
  display: inline-block;
  text-align: right;
  line-height: 36px;
}
