@import '../../../styles/themes/themes.scss';

.buttonWithIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-width: 95px;
  min-height: 30px;
  padding: 5px 8px 7px 5px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  color: black;
  cursor: pointer;

  .buttonWithIcon__icon {
    font-size: 20px;
    display: flex;
    align-items: center;
  }

  &.buttonWithIcon--square {
    min-width: unset;
    min-height: unset;
    width: 40px;
    height: 40px;
    padding: 0;
    color: #30364e;

    &:hover,
    &.active {
      background: #e1e1e1;
      @include theme(
        (
          color: bodyMain
        )
      );
    }
    .buttonWithIcon__icon {
      font-size: 35px;
    }
  }
}

.buttonWithIcon--red {
  background: none;
  border: 1px solid #f55e5e;
  color: #f55e5e;

  &:hover {
    background-color: darken(#f55e5e, 15%);
    border-color: darken(#f55e5e, 15%);
    color: #ffffff;
  }
}
.buttonWithIcon--primary {
  background: none;
  border: 1px solid;
  color: #ffffff;
  @include theme(
    (
      background: bodyMain,
      border-color: bodyMain
    )
  );

  &:hover {
    @include themeDarken(
      (
        background-color: bodyMain,
        border-color: bodyMain
      )
    );
  }
}
/* Colours */

.buttonWithIcon--primary {
  border: 2px solid;
  color: #ffffff;
  @include theme(
    (
      background: bodyMain,
      border-color: bodyMain
    )
  );

  &:hover {
    @include themeDarken(
      (
        background: bodyMain,
        border-color: bodyMain
      )
    );
  }

  .button__spinner {
    border-color: #fff;
  }
}

.buttonWithIcon--primaryDisabled {
  border: 2px solid #989898;
  color: #ffffff;
  background: #989898;

  .button__spinner {
    border-color: #fff;
  }
}

.buttonWithIcon--secondary {
  background: none;
  border: 2px solid;

  @include theme(
    (
      color: bodyMain,
      border-color: bodyMain
    )
  );

  &:hover {
    color: #ffffff !important;
    @include themeDarken(
      (
        background-color: bodyMain,
        border-color: bodyMain
      )
    );
  }

  .button__spinner {
    @include theme(
      (
        border-color: bodyMain
      )
    );
  }
}
.buttonWithIcon {
  &:disabled {
    pointer-events: none;
  }
}

.buttonWithIcon--secondaryDisabled {
  background: none;
  border: 2px solid #989898;
  color: #989898;

  .button__spinner {
    border-color: #989898;
  }
}

.buttonWithIcon--cancel {
  background: none;
  border: 2px solid #f55e5e;
  color: #f55e5e;
  text-align: center;

  &:hover {
    background-color: darken(#f55e5e, 15%);
    border-color: darken(#f55e5e, 15%);
    color: #ffffff;
  }

  .button__spinner {
    border-color: #f55e5e;
  }
}

/* end colours */

.button__spinner {
  border-color: #fff;
}

.buttonWithIcon--blue {
  background: none;
  border: 1px solid #4e91f5;
  color: #4e91f5;
  text-align: center;

  &:hover {
    background-color: darken(#4e91f5, 15%);
    border-color: darken(#4e91f5, 15%);
    color: #ffffff;
  }
}
