.deleteCustomerActivityItem {
  display: flex;
  margin: 10px 0;
}

.deleteCustomerActivityItem__icon {
  font-size: 28px;
}

.deleteCustomerActivityItem__number {
  margin: 3px 10px 0 10px;
  font-weight: bold;
}

.deleteCustomerActivityItem__text {
  margin: 3px 0 0 0;
}
