.pushDealViewOffer__container {
  width: 100%;
  border-radius: 20px;
  display: flex;
  box-shadow: 0px 10px 15px 3px rgba(188, 194, 208, 0.3);
  @media screen and (max-width: 550px) {
    display: block;
  }
}

.pushDealViewOffer__section {
  width: 33.3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 0 5px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.pushDealViewOffer__sectionDivide {
  border-right: 1px solid #e2e2e1;
  margin: 10px 10px;
  @media screen and (max-width: 550px) {
    padding: 10px 0px;
    border-right: none;
    border-bottom: 2px solid #e2e2e1;
  }
}

.pushDealViewOffer__highlightedValue {
  font-size: 1.6rem;
  font-weight: 700;
  color: #6bc348;
}

.pushDealViewOffer__strikeThroughValue {
  font-size: 1.6rem;
  font-weight: 700;
  text-decoration: line-through;
  text-decoration-style: solid;
}
