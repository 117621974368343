.panelItem {
  padding: 14px;
  cursor: pointer;
  background: white;
  border-radius: 12px;
  border: 1px solid #e9e9e9;
  box-shadow: 0 2px 4px rgba(154, 192, 216, 0.16);
  margin-bottom: 20px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  transition: all 0.2s ease-out;

  &:first-child {
    margin-top: 8px;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(188, 194, 208, 0.4);
  }
}

.panelItem__link {
  text-decoration: none;
}

.panelItem__inner {
  // padding: 0 0 15px 0;
}

.panelItem__borderBottom {
  border-bottom: solid #dddcdd 1px;
}

.panelItem__top {
  position: relative;
  height: 35px;
}

.panelItem__assignContainer {
  float: right;
  padding: 0 0 0 10px;
  margin: 0px 1px 0 0;
}

.panelItem__dates {
  padding: 3px 0 0 0;
  font-size: 1.3rem;
  color: #b7b7b7;
  clear: left;
}

.panelItem__main {
  margin: 10px 0 0 0;
  display: flex;
  overflow: hidden;
}

.panelItem__text {
  font-size: 1.4rem;
  margin: 0 0 0 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 85px);
}

.panelItem__name {
  font-weight: bold;
  color: #4c4c4c;
  float: left;
  margin: 0 0 3px 0;
  width: 100%;
}

.panelItem__alias {
  display: inline-block;
  margin: 0 0 0 5px;
  background-color: #f07d76;
  padding: 2px 3px;
  color: white;
  font-size: 1.2rem;
}

.panelItem__notesContainer {
  width: 20px;
  margin: -1px 0 0 5px;
  display: inline-block;
  transform: translateY(20%);
}

.panelItem__notesIcon {
}

.panelItem__flagContainer {
  float: right;
  margin: -1px 0 0 5px;
}

.panelItem__vehicle {
  font-size: 1.3rem;
  color: #4c4c4c;
  margin: 0 0 6px 0;
  clear: both;
}

.panelItem__ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.panelItem__derivative {
  font-size: 1rem;
  margin: 5px 0;
  color: #4c4c4c;
}

.panelItem__light {
  color: #b4b4b4;
  margin: 0 0 6px 0;
  clear: both;
  font-size: 1rem;
}

.panelItem__groupLead {
  margin-top: 10px;
}
