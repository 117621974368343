.pendingDataSpinner {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
  animation: rotate 1.1s infinite linear;
  border-color: inherit;
}

.pendingDataSpinner__track {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  font-size: 20px;
  text-indent: -9999em;
  border: 3px solid;

  opacity: 0.3;
}

.pendingDataSpinner__handle {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  font-size: 20px;
  text-indent: -9999em;
  border-top: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid;
  border-left-color: #57ddbb;
}
