@import '../../../styles/themes/themes.scss';

.quoteButtonWrapper__outer {
  position: relative;
  height: 100%;
  width: 100%;
  @media (max-width: 1166px) {
    max-width: 300px;
    margin: 0 auto;
  }
}

.quoteButtonWrapper__borderRight {
  height: calc(100% + 2px);
  width: 1px;
  position: absolute;
  background-color: #cccccc;
  right: -11px;
  top: -1px;
  @media (max-width: 1166px) {
    display: none;
  }
}

.quoteButtonWrapper__selectedBorderRight {
  height: calc(100% + 3px);
  width: 2px;
  position: absolute;
  right: -11px;
  top: -2px;
  background-color: #6bc348;

  @media (max-width: 1166px) {
    display: none;
  }
}
