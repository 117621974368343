@import '../../styles/themes/themes.scss';

.dealershipDropdown {
}

.dealershipDropdown__button {
  height: 69px;
  width: 108px;
  border: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;
  @media (max-width: 1000px) {
    width: 80px;
  }
  background: none;
  transition: background-color 0.2s ease;
}

.dealershipDropdown__button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.dealershipDropdown__button--active {
  background-color: rgba(0, 0, 0, 0.2);
}

.dealershipDropdown__buttonIconContainer {
  height: 22px;
  width: 100%;
  text-align: center;
  margin: 0 auto 5px;
  font-size: 22px;

  @include theme(
    (
      color: headerForeground
    )
  );
}

.dealershipDropdown__buttonText {
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;

  @include theme(
    (
      color: headerForeground
    )
  );
}

.dealershipDropdown__dealerList {
  position: fixed;
  width: 350px;

  @media (max-width: 1000px) {
    width: 320px;
  }

  right: 0;
  top: 69px;
  opacity: 0;

  transform: translate3d(100%, 0, 0);
  transition: transform 0.5s ease, opacity 0.5s ease;

  @include theme(
    (
      background-color: headerBackground,
      color: headerForeground
    )
  );
}

.dealershipDropdown__dealerList--open {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.dealershipDropdown__liHead {
  display: flex;
  justify-content: space-between;
  font-family: 'Montserrat', 'Open Sans', Arial, sans-serif;
  // height: 39px;
  z-index: 200;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  @media (max-width: 1000px) {
    width: 320px;
  }

  font-size: 1.4rem;
}

.dealershipDropdown__liHeadText {
  padding: 10px 0 10px 20px;
}

.dealershipDropdown__exitButton {
  width: 38px;
  height: 38px;
  background: none;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
}

.dealershipDropdown__list {
  // Scroll shadow
  background: linear-gradient(var(--headerBackground) 33%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), var(--headerBackground) 66%) 0 100%,
    linear-gradient(rgba(0, 0, 0, 0), var(--headerForeground)) 0 100%;
  background-color: var(--headerBackground);
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-size: 100% 99px, 100% 99px, 100% 33px, 100% 33px;

  margin: 0;
  padding: 0 0 0 0;
  list-style: none;
  font-size: 1.4rem;
  max-height: calc(100vh - 108px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
    //iPad portait
    max-height: calc(100vh - 308px); //1024 - 108px
  }

  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
    //iPad landscape
    max-height: calc(100vh - 308px); //768 - 108px
  }
}
