.applicationCardCorporate {
  border-radius: 3px;
  padding: 15px 5px 5px 15px;
  position: relative;
  border-top: 0px solid #e8e8e8;
}

.applicationCardCorporate__inner {
  overflow: hidden;
}

.applicationCardCorporate__containerAddress {
  border-top: 3px solid #e8e8e8;
}

.applicationCardCorporate__sectionHeader {
  font-size: 1.4rem;
  text-decoration: underline;
  margin: 0 5px 0 5px;
}

.applicationCardCorporate__required {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: #ff6003;
  color: white;
  padding: 3px 5px;
}

.applicationCardCorporate__address {
  min-height: 80px;
  padding: 10px 0 10px 5px;
  font-size: 1.4rem;
  width: 35%;
  overflow: auto;
  float: left;

  @media (max-width: 700px) {
    float: none;
    width: 100%;
    padding: 10px 0 0 5px;
  }
}

.applicationCardCorporate__right {
  min-height: 80px;
  width: 65%;
  float: right;
  display: table;
  vertical-align: middle;
  position: relative;

  @media (max-width: 700px) {
    margin: 0px 0 0 0;
    width: 100%;
    border-bottom: solid #d8d8da 1px;
    min-height: 0px;
    padding: 0 0 10px 5px;
  }
}

.applicationCardCorporate__time {
  min-height: 80px;
  display: table-cell;
  vertical-align: middle;
  width: 50%;
  text-align: center;
  font-size: 1.4rem;
  padding: 12px 0;

  @media screen and (max-width: 700px) {
    padding: 0;
    min-height: 0px;
    text-align: left;
  }
}

.applicationCardCorporate__number {
  font-size: 2.5rem;
}

.applicationCardCorporate__contactText {
  @media (max-width: 700px) {
    text-align: left !important;
  }
}

.applicationCardCorporate__noTime {
  padding: 0px 0 0 0;
  @media screen and (max-width: 700px) {
    padding: 0;
  }
}

.applicationCardCorporate__timeText {
  @media (max-width: 700px) {
    text-align: left !important;
  }
}

.applicationCardCorporate__buttonContainer {
  width: 50%;
  display: table-cell;
  vertical-align: middle;
  padding: 0 10px 0 0;

  @media (max-width: 1190px) {
    margin: 0;
    padding: 0 10px 0px 0;
  }
}

.applicationCardCorporate__button {
  float: right;
  width: 100px;
  margin: 0 0 0 0;
  display: block;
}

.applicationCardCorporate__removeButton {
  @media (max-width: 1190px) {
    clear: both;
  }
}

.applicationCardCorporate__editButton {
  display: block;
  margin: 0 10px 0 0;

  @media (max-width: 1190px) {
    margin: 10px 0 0 0;
    clear: both;
  }
}
