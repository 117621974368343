.dataProtectionNoticeModal {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0px 16px;
  h2 {
    font-size: 1.8rem;
    font-weight: 700;
    text-align: left;
    margin-top: 0px;
  }
  ul {
    padding-inline-start: 16px;
    font-size: 1.4rem;
  }
  &__consent {
    display: flex;
    align-items: center;
    gap: 16px;
    label {
      flex-grow: 1;
    }
  }
}
