.stockContainerLayout__outer {
  height: 100%;
  min-height: 100vh;
}

.stockContainerLayout {
  display: flex;
  justify-content: flex-start;
  position: relative;
}

.stockContainerLayout__main {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  position: absolute;
  right: 0;
  left: 0;
  transition: 0.3s right ease-in-out;

  @media (max-width: 500px) {
    padding: 20px 0;
  }
}

.stockContainerLayout__side {
  position: fixed;
  width: 300px;
  right: -320px;
  transition: 0.3s right ease-in-out;
}

.stockContainerLayout__side--shift {
  right: 0;
  transition: 0.3s right ease-in-out;
  width: 300px;
}
