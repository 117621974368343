.forgotPasswordRequested__header {
  margin-top: 30px;
  font-size: 3rem;
  font-weight: 300;
  text-align: center;
}

.forgotPasswordRequested__text {
  color: #666666;
  text-align: center;
}

.forgotPasswordRequested__list {
  padding: 12px 14px;
  text-align: left;
  color: #666666;
}

.forgotPasswordRequested__list > li {
  margin-bottom: 16px;
}

.forgotPasswordRequested__list > li:last-child {
  margin-bottom: 0;
}

.forgotPasswordRequested__actions {
  margin-top: 30px;
}
