.mediaDashboardVehicle {
  width: 75px;
  margin: 0 10px 0 0;
  padding: 0 0 5px;
  position: relative;
}

.mediaDashboardVehicle__imageContainer {
  height: 57px;
  border: 1px solid #e4ebef;
  overflow: hidden;
  margin: 0 auto 10px auto;
  border-radius: 3px;
  background-color: #ffffff;
}

.mediaDashboardVehicle__vrm {
  font-size: 1.1rem;
}

.mediaDashboardVehicle__count {
  position: absolute;
  top: 2px;
  right: 2px;
  text-align: center;
  padding: 2px 4px;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.3rem;
  background-color: #f07d76;
  border-radius: 0 1px 0 0;
  box-shadow: 0 2px RGBA(0, 0, 0, 0.16);
}
