.repExampleSetting__breadcrumbs {
  margin: 0 0 20px 0;
}

.repExampleSettings__container {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.repExampleSettings__title {
  margin-bottom: 20px;
  font-weight: bolder;
}

.repExampleSettings__info {
  margin-bottom: 20px;
  font-size: 15px;
}

.repExampleSettings__options {
  margin-bottom: 30px;
  font-size: 15px;
}

.repExampleSettings__optionsSubText {
  font-size: 12px;
  color: #999999;
  margin: 10px 0 0 25px;
}

.repExampleSettings__repExample {
  border: solid #e0e0e0 1px;
  border-radius: 5px;
  padding: 15px;
  margin: 0 20px;
}

.repExampleSettings__repExampleContainer {
  padding: 0 20px;
}

.repExampleSettings__repExampleTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.repExampleSettings__currentRepExample {
  margin-left: 30px;
  font-style: italic;
}

.repExampleSettings__previousUser {
  font-size: 14px;
  width: 100%;
  text-align: left;
  margin: 0 0 20px;
  color: #999999;
}

.repExampleSettings__error {
  color: #ff6600;
  font-size: 12px;
  text-align: center;
  padding: 10px;
}

.repExampleSettings__loadingSpinner {
  width: 100%;
  text-align: center;
  top: 0;
  bottom: 0;
  left: -10px;
  padding: 25px 0 0 0;
}

.repExampleSettings__notEnabled {
  font-size: 14px;
  padding: 20px;
  margin: 0 auto;
  max-width: 600px;
}

.repExampleSettings__notEnabled--button {
  max-width: 200px;
  margin: 0 auto;
}

.repExampleSettings__buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  max-width: 100%;

  &:only-child {
    justify-content: flex-end;
  }

  @media (max-width: 520px) {
    flex-wrap: wrap;

    & > :first-child {
      order: 2;
    }
  }
}
.repExampleSettings__buttonWrap {
  width: 200px;

  @media (max-width: 520px) {
    width: 100%;
    margin: 0 0 10px 0;
  }
}
.repExampleSettings__loader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
