.pushDealVehicleImage__outer {
  width: 100%;
}

.pushDealVehicleImage {
  width: 100%;
  border-radius: 10px;
}

.pushDealVehicleImage__outer--placeholder {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 75%;
  position: relative;
  border-radius: 10px;
  border: 1px solid rgba(48, 54, 77, 0.1);
}

.pushDealVehicleImage--placeholder {
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%;
}
