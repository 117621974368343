.dealershipDistanceCustomerInfo {
  width: 100%;
  position: relative;

  @media (max-width: 550px) {
    padding: 0 0 0 10px;
  }
}

.dealershipDistanceCustomerInfo__inner {
  display: flex;
  align-items: center;
  height: 100%;

  @media (max-width: 920px) {
    top: 0;
    margin: 0 0 10px 0;
  }
}

.dealershipDistanceCustomerInfo__image {
  margin: 0 10px 0 0;
}

.dealershipDistanceCustomerInfo__name {
  font-weight: bold;
}

.dealershipDistanceCustomerInfo__bottom {
  font-size: 1.2rem;
  text-align: center;
  width: 100%;
  margin: 15px 0 0 0;
  color: #abacb0;

  @media (max-width: 920px) {
    display: none;
    margin: 0;
  }
}
