.quoteFigureItem {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 5px 20px;
}

.quoteFigureItem__label {
}

.quoteFigureItem__value {
  font-weight: 600;
}
