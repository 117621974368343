.customerListItem {
  border-bottom: solid #e8e9e9 1px;
  background-color: #ffffff;
  display: table-row;
  padding: 0;
  &:nth-child(odd) {
    background-color: #f6f9fc;
  }

  &:hover {
    background-color: #f2f2f2;
  }
}

.customerListItem__name {
  display: block;
  font-weight: 600;
  width: 100%;
  overflow-wrap: break-word;
  padding-right: 20px;
}

.customerListItem__phoneNumber {
  display: block;
  font-weight: 400;
}

.customerListItem__linkedUsersWarning {
  margin: 8px 0 0 0;
  font-size: 1.2rem;
}

.customerListItem__td {
  position: relative;
  max-width: 132px;
  padding: 0 10px;
}

.customerListItem__vehicleMakeModel {
  font-weight: 600;
}

.customerListItem__vehicleDerivative {
  display: block;
  padding-right: 20px;
}

.customerListItem__assignLead {
  padding: 0 10px;
}

.customerListItem__link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: auto;
  width: 100%;
  min-height: 81px;
  vertical-align: middle;
  // padding: 20px 0 20px 10px;
  text-decoration: none;
  color: #000;
}
.customerListItem__link__assignLead {
  display: table-cell;
}

.customerListItem__link--business,
.customerListItem__link--hasAliases {
  padding: 20px 0;
}
