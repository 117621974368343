@import '~SM/styles/stockStyles.module.scss';

.container {
  width: 100%;
}
.top {
  display: flex;
  justify-content: center;
  position: relative;
}
.icon {
  width: fit-content;
  cursor: pointer;
  color: #2d68b1;
  font-size: 14px;
  display: block;
  align-items: center;
  position: relative;
  top: 7px;
  @media (min-width: $tablet-start) {
    top: 4px;
    left: 2px;
    font-size: 22px;
  }
}

.text {
  color: #000;
  margin: 5px 0 0 0;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  @media (min-width: $tablet-start) {
    font-size: 18px;
  }
}

.rating {
  display: flex;
  justify-content: center;
  padding: 5px;
}

.starRating {
  :global(.starRating__star) {
    color: #c4c4c4;
  }
  :global(.starRating__star--fill) {
    color: #30364e;
  }
  @media (min-width: $tablet-start) {
    margin-top: 18px;
    font-size: 22px;
  }
}

