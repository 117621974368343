.pushDealPricing__container {
  padding: 20px 60px;
  @media screen and (max-width: 1024px) {
    padding: 10px;
  }
}
.pushDealPricing__offerSection {
  width: 70%;
  margin: 20px auto;
  border: solid #e2e2e1 1px;
  border-radius: 14px;
  @media screen and (max-width: 1024px) {
    width: 80%;
  }
  @media screen and (max-width: 900px) {
    width: 90%;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    border: 0px;
    margin: 20px 0px;
  }
}

.pushDealPricing__DiscountContainer {
  height: 280px;
  padding-bottom: 30px;
  overflow: hidden;
  transition: height 0.4s ease;
  @media screen and (max-width: 710px) {
    height: 320px;
  }
  @media screen and (max-width: 550px) {
    height: 600px;
  }
}

.pushDealPricing__DiscountContainer--closed {
  height: 0px;
  border-radius: 14px;
  overflow: hidden;
  transition: height 0.4s ease;
}

.pushDealPricing__sectionInner {
  max-width: 700px;
  font-size: 15px;
  font-weight: 600;
  margin: 0 auto;
  padding: 20px 40px;
  @media screen and (max-width: 500px) {
    padding: 0;
  }
}

.pushDealPricing__sectionTitle {
  margin: 0 0 20px;

  &--center {
    text-align: center;
  }
}

.pushDealPricing__toggleField {
  display: flex;
  justify-content: space-between;
}

.pushDealPricing__toggleFieldInner {
  margin: 0 10px 0 0;
  padding-bottom: 20px;
  font-size: 15px;
  line-height: 25px;
  font-weight: 600;
}

.pushDealPricing__toggleOuter {
  display: flex;
  padding-right: 5px;
}

.pushDealPricing__viewOffer {
  padding: 0px 10px;
  margin: 0 auto;
  padding-bottom: 30px;
}

.pushDealPricing__financeSection {
  width: 70%;
  margin: auto;
  border: solid #e2e2e1 1px;
  border-radius: 14px;
  @media screen and (max-width: 1024px) {
    width: 80%;
  }
  @media screen and (max-width: 900px) {
    width: 90%;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    border: 0px;
    margin: 0px;
  }
}

.pushDealPricing__section {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  color: #464646;
  font-size: 15px;
  font-weight: 600;
  .pushDealConfirm__highlightedValue {
    color: #6bc348;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 425px) {
    border: none;
    padding: 5px;
  }
}

.pushDealPricing__vatOptionsContainer {
  display: flex;
  padding-top: 20px;
  font-size: 1.2rem;
  border-top: solid #e2e2e1 1px;
}

.pushDealPricing__vatOptions {
  margin: 0 20px 0 0;
}

.pushDealPricing__vatQualifyingContainer {
  padding-top: 20px;
  height: 280px;
  overflow: hidden;
  transition: height 0.4s ease;
  @media screen and (max-width: 500px) {
    border: none;
    height: 310px;
  }
}

.pushDealPricing__vatQualifyingContainer--closed {
  height: 0px;
  overflow: hidden;
  transition: height 0.4s ease;
}

.pushDealPricing__addVapContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.pushDealPricing__addVapButton {
  display: inline-block;
  vertical-align: middle;
  margin: 10px 0 0 0;
}

.pushDealPricing__noVaps {
  font-size: 1.4rem;
  line-height: 1.6;
}

.pushDealPricing__customerCreditTooltip {
  display: flex;
  align-items: self-end;

  button {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: #2d68b1;
  }
}
.pushDealPricing__negativeEquityMessage {
  display: flex;
  align-items: center;
  width: 39%;
  margin: auto;
  font-size: 13px;
  color: #1d2fff;
  .icon {
    line-height: 1;
    font-size: 22px;
  }
}
.pushDealPricing__totalSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.pushDealPricing__depositSection {
  width: 70%;
  margin: 20px auto;
  border: solid #e2e2e1 1px;
  border-radius: 14px;
  @media screen and (max-width: 1024px) {
    width: 80%;
  }
  @media screen and (max-width: 900px) {
    width: 90%;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    border: 0px;
    margin: 20px 0px;
  }
}

.pushDealPricing__Divide {
  margin: 10px auto;
  border-bottom: solid #e2e2e1 1px;
}

.pushDealPricing__balanceSection {
  width: 70%;
  margin: 20px auto;
  padding: 10px 5%;
  border: solid #e2e2e1 1px;
  border-radius: 14px;
  background: rgba(126, 126, 126, 0.06);
  @media screen and (max-width: 1024px) {
    width: 80%;
  }
  @media screen and (max-width: 900px) {
    width: 90%;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    border: 0px;
    margin: 0px;
  }
}

.pushDealPricing__customerCreditTooltip {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  align-items: center;
  font-size: 12px;

  button {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: #2d68b1;

    .icon {
      font-size: 20px;
    }
  }
  button:last-of-type {
    text-decoration: underline;
  }
}
