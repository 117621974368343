.quoteNotification {
  display: flex;
  align-items: center;
}

.quoteNotification__image {
  width: 37px;
  height: 37px;
  margin: 0 10px 0 0;
}

.quoteNotification__title {
  color: #f56d36;
  font-weight: bold;
}

.quoteNotification__message {
  margin-top: 4px;
}
