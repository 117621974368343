.panel__outer {
  width: 100%;
  background-color: #ffffff;
  border-radius: 14px;
  margin: 0 0 30px 0;
  padding: 0 30px;

  @media (max-width: 700px) {
    padding: 0 14px;
  }
}

.panel__isDisabled {
  opacity: 0.5;
}
