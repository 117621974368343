@import '../../../styles/themes/themes.scss';

.quickQuoteDummyNavigationItem__li {
  height: 90px;
  box-shadow: 0px 6px 20px rgba(188, 194, 204, 0.34);
  transform: translateX(0);
  background: white;
  position: relative;
  transition: 0.2s all ease-out;
  opacity: 0.6;
  border-radius: 12px;
  margin-bottom: 12px;

  @media (max-width: 1000px) {
    float: left;
    height: 150px;
    padding: 0 10px;
    width: 16.6666666667%;
  }

  @media (max-width: 600px) {
    height: 125px;
  }

  @media (max-width: 500px) {
    height: 80px;
    padding: 0;
  }
}

.quickQuoteDummyNavigationItem__liActive {
  background: #fff;
  box-shadow: 0px 20px 30px rgba(188, 194, 204, 0.6);
  transform: translateX(4px);
  padding: 0;
  height: 90px;
  opacity: 1;
  z-index: 2;
  border-radius: 12px;

  @media (max-width: 1000px) {
    float: left;
    height: 150px;
    width: 16.6666666667%;
  }

  @media (max-width: 600px) {
    height: 125px;
  }

  @media (max-width: 500px) {
    height: 80px;
  }
}

.quickQuoteDummyNavigationItem__disabled {
  opacity: 0.2;
}

.quickQuoteDummyNavigationItem__bottomLeftBorderRadius {
  border-radius: 0 0 0 8px;

  @media (max-width: 1000px) {
    border-radius: 0;
  }
}

.quickQuoteDummyNavigationItem__link {
  text-decoration: none;
  outline: 0;
  cursor: default;
}

.quickQuoteDummyNavigationItem__inner {
  overflow: hidden;
}

.quickQuoteDummyNavigationItem__iconContainer {
  float: left;

  @media (max-width: 1000px) {
    float: none;
  }
}

.quickQuoteDummyNavigationItem__iconOuter {
  width: 60px;
  height: 60px;
  font-size: 40px;
  margin: 10px 0px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5e6977;

  @media (max-width: 1000px) {
    margin: 8px auto;
  }

  @media (max-width: 500px) {
    width: 70%;
    margin: 8px auto;
  }
}

.quickQuoteDummyNavigationItem__text {
  text-align: left;
  margin: 12px 0 0 0;
  font-weight: bold;

  @media (max-width: 1000px) {
    font-size: 1.2rem;
    text-align: center;
  }

  @media (max-width: 850px) {
    font-size: 1.1rem;
  }

  @media (max-width: 500px) {
    display: none;
  }
}

.quickQuoteDummyNavigationItem__borderRightActive {
  width: 3px;
  height: 88px;
  position: absolute;
  top: 0;
  right: -2px;
  z-index: 2000;
  background-color: white;

  @media (max-width: 1000px) {
    height: 3px;
    width: 100%;
    bottom: -2px;
    top: auto;
    right: 0;
  }
}
