@import '../../../modules/Stock/styles/stockStyles.module.scss';

.consumerVehicleInfo {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.consumerVehicleInfo__detailsContainer {
  display: flex;
  flex-direction: column;
}

.consumerVehicleInfo__vrm {
  font-weight: 400;
  font-size: 18px;
  padding-left: 1rem;
}

.consumerVehicleInfo__makeModel {
  font-weight: 700;
  font-size: 18px;
  padding: 0.8rem 0 0 1rem;
}

.consumerVehicleInfo__details {
  display: flex;
  padding: 0.8rem 0 0 1rem;
  font-weight: 400;
  font-size: 14px;
  color: $PrimaryDarkblue100;
}

.consumerVehicleInfo__editVehicleCTA {
  align-self: start;
  text-decoration: underline;
  font-weight: 700;
  color: #64bc46;
  cursor: pointer;
}

// Old styles

@import '../../../styles/utility/browsers.scss';

.consumerVehicleInfoOld {
  display: flex;
  width: 100%;
  align-items: center;

  &__vehicleIdentifierOld {
    padding-right: 8px;
  }

  &__vinOld,
  &__makeModelOld {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
  }

  &__derivativeOld {
    font-size: 12px;
  }

  &__makeModelDerivativeOld {
    display: flex;
    gap: 8px;
  }
}
