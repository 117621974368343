@import '../../styles/themes/themes.scss';

.userIcon__outer {
  float: left;

  @media (max-width: 500px) {
    float: none;
  }
}

.userIcon__imageContainer {
  width: 140px;
  height: 140px;
  margin: 0 20px 0 0;
  @media (max-width: 500px) {
    margin: 0 20px 20px 0;
  }
}

.userIcon__image {
  width: 90px;
  height: 90px;
  margin: 10px auto;
  text-align: center;
}
