@import '../../styles/themes/themes.scss';

.quoteCard {
  display: block;
  position: relative;
  margin-bottom: 24px;
  padding-top: 16px;
  border: 1px solid #e9e9e9;
  border-radius: 6px;
  color: #30364e;
  background-color: white;
  box-shadow: 0 2px 4px rgba(154, 192, 216, 0.16);
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  &__importantFigure {
    font-weight: 700;
  }
  & .selfServicedApplicationTag__tag {
    border: none;
    top: -24px;
    left: -6px;
  }
  &__viewDetails {
    font-size: 14px;
    text-align: left;
    margin: 0;
    margin-top: 5px;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    color: #060a0d;
    text-decoration: underline;
  }
}

.quoteCard--withHover {
  &:hover,
  &:focus {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(188, 194, 208, 0.4);
  }
}

.quoteCard--isSelected {
  border: 1px solid #6bc348;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(188, 194, 208, 0.4);
}

.quoteCard--withCheckbox {
  padding-top: 28px;
}

.quoteCard--withTag {
  padding-top: 28px;
}

.quoteCard__checkbox {
  position: absolute;
  top: 6px;
  left: 6px;
}

.quoteCard__upper {
  @media (min-width: 1064px) {
    display: flex;
    justify-content: space-between;
  }
}

.quoteCard__tags {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 16px;
  @media (min-width: 1064px) {
    flex-direction: row;
  }
}

.quoteCard__header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 0px 16px;

  @media (min-width: 456px) {
    flex-direction: row;
    margin-bottom: 12px;
  }

  @media (min-width: 1064px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-right: 0;
    width: 192px;
  }
}

.quoteCard__logo {
  width: 116px;
  margin-bottom: 5px;

  @media (min-width: 456px) {
    margin-right: 16px;
  }

  @media (min-width: 1064px) {
    margin-bottom: 16px;
  }
}

.quoteCard__infoWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 6px;

  @media (min-width: 456px) {
    flex-direction: column;
    width: initial;
    margin-top: 0;
  }

  @media (min-width: 658px) {
    width: 100%;
    flex-direction: row;
    justify-content: end;
  }

  @media (min-width: 1064px) {
    flex-direction: column;
  }
}

.quoteCard__info {
  font-size: 12px;
  color: #1c1c2d;

  @media (min-width: 384px) {
    font-size: 14px;
  }

  @media (min-width: 456px) {
    &:first-of-type {
      margin-bottom: 6px;
    }
  }

  @media (min-width: 658px) {
    &:first-of-type {
      margin-bottom: 0;
      margin-right: 24px;
    }
  }

  @media (min-width: 1064px) {
    &:first-of-type {
      margin-bottom: 5px;
    }
  }
}

.quoteCard__infoTitle {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-right: 12px;
  font-weight: bold;
}

.quoteCard__productName {
  font-weight: normal;
}

.quoteCard__productName--action {
  cursor: pointer;
  text-decoration: underline;
}

.quoteCard__figures {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  margin: 0;
  margin-bottom: 16px;
  list-style: none;
  overflow: hidden;
  padding: 0px 16px;

  @media (min-width: 816px) {
    flex-wrap: nowrap;
  }

  @media (min-width: 1064px) {
    padding: 0px;
  }
}

.quoteCard__cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  border: 1px solid #ededed;
  box-sizing: border-box;
  width: 50%;

  &--highlight {
    background-color: #ededed;
  }

  &--eligibility-check,
  &--pre-approval {
    background-color: #f2fff2;
    border-color: #64bc46;
    .quoteCard__cellValue {
      color: #367f14;
    }
  }

  &:first-child {
    background-image: linear-gradient(90deg, #ededed 0%, #e3e3e3 100%);
  }

  @media (min-width: 816px) {
    width: 100%;
    border-right-width: 0;

    &:last-child {
      border-right-width: 1px;
    }
  }
}

.quoteCard__cellValue {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #30364e;

  @media (min-width: 1064px) {
    font-size: 18px;
  }

  @media (min-width: 1400px) {
    font-size: 18px;
  }
}

.quoteCard__cellDescription {
  margin: 0;
  font-size: 12px;
  color: #1c1c2d;
}

.quoteCard__statusButtons {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding: 0px 16px;
  min-width: 264px;

  @media (min-width: 1064px) {
    justify-content: flex-end;
    max-width: 264px;
  }
}

.quoteCard__buttonWrap {
  > button:first-of-type {
    margin-bottom: 10px;
  }

  > button:last-of-type {
    margin-bottom: 0;
  }
  > button {
    width: 100%;
  }

  @media (min-width: 600px) {
    display: flex;

    > button:first-of-type {
      margin-right: 10px;
    }

    > button:last-of-type {
      margin-right: 0;
    }
  }

  @media (min-width: 1064px) {
    flex-direction: column;
  }
}

.quoteCard__button--withMessage {
  height: auto;
  line-height: 1.2em;
  white-space: break-spaces;
}

.quoteCard__statusDate {
  margin: 0;
  margin-bottom: 10px;
  font-size: 12px;
  text-align: center;

  @media (min-width: 1064px) {
    text-align: left;
  }

  & span {
    white-space: nowrap;
  }
}

.quoteCard__applicationStatus--inline {
  display: none;

  @media (min-width: 1064px) {
    display: flex;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.quoteCard__applicationStatus--block {
  width: 100%;
  margin-bottom: 10px;

  @media (min-width: 1064px) {
    display: none;
  }
}

.quoteCard__table {
  padding: 14px 16px;

  @media (min-width: 800px) {
    padding: 0px 30px;
    column-count: 2;
    column-rule-color: #bfbfbf;
    column-rule-width: 1px;
    column-rule-style: solid;
    column-gap: 60px;
    border-radius: 6px;
  }

  @media (min-width: 1064px) {
    column-count: 3;
  }
}

.quoteCard__collapsibleTable {
  max-height: none;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;

  @media (min-width: 800px) {
    max-height: none;
  }
}

.quoteCard__collapsibleTable--isHidden {
  max-height: 0;
}

.quoteCard__tableHeader,
.quoteCard__divider {
  font-weight: bold;
  margin-bottom: 5px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #d3d3d3;
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #d3d3d3;
  }

  span {
    white-space: nowrap;
    padding: 0px 7px;
    text-align: center;
    font-size: 14px;
  }
}

.quoteCard__tableItem {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;
  padding: 10px 0px;
  @media (min-width: 800px) {
    border-bottom: none;
  }
}

.quoteCard__itemValue {
  font-weight: bold;
}

.quoteCard__itemLabel {
  margin: 0;
}

.quoteCard__tableFooter {
  padding: 16px;
  background-color: #f8f9fc;

  & p:last-of-type {
    margin-bottom: 0;
  }
}

.quoteCard__tableButton {
  display: flex;
  justify-content: center;
  padding: 20px 0px 16px;
  background-color: #f8f9fc;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.quoteCard__tableButton div {
  color: black;
  display: block;
  display: flex;
  justify-content: center;
}

.quoteCard__erroredQuote {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

  @media (min-width: 800px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.quoteCard__quoteInfo--productName {
  line-height: 16px;
  align-self: flex-start;
}

.quoteCard__quoteInfo--figure {
  font-weight: 700;
  flex: 0 1;
  height: 30px;
  flex-wrap: wrap;
}

.quoteCard__quoteInfo--erroredName {
  @extend .quoteCard__quoteInfo--figure;
  transform: translateY(0);
}

.quoteCard__errorLogoProduct {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 800px) {
    margin: 0 24px;
  }
}

.quoteCard__errorList {
  @media (min-width: 800px) {
    margin: 0 24px;
  }
}

.quoteCard__dealStickerContainer {
  display: flex;
  justify-content: center;
}

.quoteCard__dealSticker {
  position: absolute;
  top: -2px;
  left: 0;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 12px;
  color: white;
  width: 270px;
  text-align: center;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;

  @include theme(
    (
      background-color: bodyMain
    )
  );
}

// 'Indicative only' or 'New Rate' text in APR HeaderFigures cell
.quoteCard__alertTag {
  font-weight: 700;
  font-size: 12px;
  display: inline-block;
  &--small {
    font-size: 10px;
  }
}
