.assignLeadModalUserListItem__list {
  padding: 10px;
  border-bottom: 1px solid #cbcbcb;
  font-weight: 700;
  position: relative;
}

.assignLeadModalUserListItem__clickable {
  &:hover {
    background-color: #ecf2e4;
  }
}

.assignLeadModalUserListItem__name {
  margin: 0 10px 0 0;
}

.assignLeadModalUserListItem__email {
  color: #bfbfbf;
  font-weight: 400;
  font-size: 1.2rem;
}

.assignLeadModalUserListItem__hideButtonBorder {
  border-left: none;
}

.assignLeadModalUserListItem__redXButton {
  padding: 15px 15px 14px 15px;
  position: absolute;
  top: calc(50% - 20px);
  right: 0;
  cursor: pointer;
  border-style: none;
  background: none;
  border-left: 1px solid #cbcbcb;
  outline: 0;

  &:focus {
    outline: 0;
    background: #ecf2e4;
  }
}

.assignLeadModalUserListItem__redX {
  width: 10px;
  height: 10px;
  background-size: contain;
  background-image: url('../../../styles/icons/cross_red.svg');
}

.assignLeadModalUserListItem__noBottomBorder {
  border-bottom: none;
}

.assignLeadModalUserListItem__hide {
  display: none;
}
