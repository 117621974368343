.tileNumberOld__numCont {
  height: 30px;
  overflow: hidden;
}

.tileNumberOld__number {
  font-weight: bold;
  line-height: 20px;
  margin: 0 5px 0 0;
  vertical-align: top;
}
