@import 'theme-default';
@import 'theme-motonovo';
@import 'theme-bnpp';
@import 'theme-bdk';

$themes: (
  motonovo: $theme-motonovo,
  bnpp: $theme-bnpp,
  bdk: $theme-bdk,
  default: $theme-default
);

@mixin theme($ruleMap) {
  @each $themeName in map-keys($themes) {
    body.theme-#{$themeName} & {
      @each $rule in map-keys($ruleMap) {
        $theme: map-get($themes, $themeName);
        #{$rule}: map-get($theme, map-get($ruleMap, $rule));
      }
    }
  }
}

@mixin themeDarken($ruleMap) {
  @each $themeName in map-keys($themes) {
    body.theme-#{$themeName} & {
      @each $rule in map-keys($ruleMap) {
        $theme: map-get($themes, $themeName);
        #{$rule}: darken(map-get($theme, map-get($ruleMap, $rule)), 10%);
      }
    }
  }
}

@mixin themeDarkest($ruleMap) {
  @each $themeName in map-keys($themes) {
    body.theme-#{$themeName} & {
      @each $rule in map-keys($ruleMap) {
        $theme: map-get($themes, $themeName);
        #{$rule}: darken(map-get($theme, map-get($ruleMap, $rule)), 20%);
      }
    }
  }
}
