.searchBarWithResults__searchBar {
  flex-grow: 1;

  @media (max-width: 500px) {
    flex-direction: column;
    width: 100%;
    margin: 0 0 10px 0;
  }
}

.searchBarWithResults__addUserButton {
  margin-left: 10px;
  width: fit-content;

  @media (max-width: 500px) {
    width: 100%;
    margin: 0;
  }
}

.searchBarWithResults__container {
  display: flex;
  @media (max-width: 500px) {
    flex-direction: column;
  }
}
