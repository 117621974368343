.helpNoVideoBDK__header {
  color: #000000;
  font-size: 1.5rem;
  border-top: solid #eeeeee 1px;
  padding: 20px 0 0 0;
}

.helpNoVideoBDK__header__noborder {
  color: #000000;
  font-size: 1.5rem;
  border-top: 0;
  padding: 20px 0 0 0;
}

.helpNoVideoBDK__body {
  color: #000000;
  font-size: 1rem;
  padding: 10px 0;
}

