.platformUpdatesItem {
  max-width: 80%;
  margin: 0 auto;
  padding: 0rem 3rem;

  margin-bottom: 3rem;

  &:hover {
    transform: translateY(0);
    transition: all 0.2s ease-out;
  }
}

.platformUpdatesItem__title {
  font-size: 26px;
  cursor: pointer;
  line-height: 43.2px;
  font-weight: bold;
  color: #4a4a4a;
  margin: 0;
}

.platformUpdatesItem__footer {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.platformUpdatesItem__date {
  white-space: nowrap;
  color: #a4a6a8;
  margin-right: 10px;
}

.platformUpdatesItem__hr {
  flex-grow: 1;
  border: none;
  border-top: 1px solid #e4ebef;
}
