.vehicleInfoList {
  width: 100%;
  list-style: none;
  font-size: 16px;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;

  li {
    display: flex;
    margin-bottom: 10px;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
}
.vehicleInfoList__label {
  display: flex;
}
.vehicleInfoList__value {
  position: relative;
  display: flex;
  text-align: right;
  font-weight: 600;
}

.vehicleInfoList__labelContainer {
  display: flex;
  justify-content: flex-start;
  width: 50%;
}

.vehicleInfoList__valueContainer {
  display: flex;
  justify-content: flex-start;
  width: 50%;
}
