.container {
  display: grid;
  grid-template-columns: 275px auto;
  width: 100%;
  height: 100vh;
  font-weight: 600;
}

.sidebar {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  padding: 25px;
  height: 100%;
  box-sizing: border-box;
}

