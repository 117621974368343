.pushDealNavItem__container {
  color: #5e6977;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  text-align: center;
}
.pushDealNavItem__title {
  margin: 0 auto;
  color: #5e6977;
  font-size: 16px;
  font-weight: 500;
}

.pushDealNavItem__title--active {
  margin: 0 auto;
  color: #6bc348;
  font-size: 16px;
  font-weight: 700;
}

.pushDealNavItem__mobileIcons {
  font-size: 35px;
}
