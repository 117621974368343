@import '../../styles/themes/themes.scss';

.pagination__list {
  list-style-type: none;
  text-align: center;
  margin: 20px 0;
  padding-bottom: 20px;
}

.pagination__li {
  display: inline-block;
  margin: 0 4px;
}

.pagination__button {
  margin: 0;
  padding: 0;
  background-color: #fff;
  line-height: 34px;
  border: 1px solid #e4ebef;
  border-radius: 3px;
  width: 34px;
  height: 34px;
  outline: none;
  font-weight: bold;
  cursor: pointer;
  color: #6bc348;
  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

.pagination__currentButton {
  color: #ffffff;
  margin: 0;
  padding: 0;
  line-height: 34px;
  border: 1px solid #6bc348;
  border-radius: 3px;
  width: 34px;
  height: 34px;
  outline: none;
  font-weight: bold;
  cursor: pointer;
  background-color: #6bc348;
}
