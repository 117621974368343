@import '~/styles/webshops-styles.scss';

.stockModuleVehicleCard {
  box-shadow: $large-shadow;
}

.stockModuleVehicleCard__title {
  color: $accent-color;
}

.stockModuleVehicleCard__derivative {
  text-transform: uppercase;
  margin-bottom: 16px;
}

.stockModuleVehicleCard__price {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  flex-wrap: wrap;
}

.stockModuleVehicleCard__priceLabel {
  color: $text-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
}

.stockModuleVehicleCard__priceValue {
  color: $accent-color;
  font-weight: 700;
  letter-spacing: 0.03em;
  font-size: 16px;
}

/* card css */

.stockModuleVehicleCard__vehicle-info {
  position: relative;
  padding: 10px 24px;
  display: flex;
  flex-direction: column;
  color: black;
  flex: 1 1 auto;
  justify-content: space-between;
}

.stockModuleVehicleCard__description {
  font-weight: 400;
  font-size: 12px;
  color: $text-color;
  line-height: 17px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 20px;
}

.stockModuleVehicleCard__heartIcon {
  height: 32px;
  width: 32px;
  position: absolute;
  right: 20px;
  top: 16px;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.49 16.788"><path fill="lightgray" id="likeIcon1" d="M10,18.725H9.978a1.153,1.153,0,0,1-.676-.216c-.245-.174-6.05-4.314-8.225-7.866A5.732,5.732,0,0,1,9.99,3.576a5.732,5.732,0,0,1,8.925,7.048c-2.186,3.57-7.991,7.71-8.238,7.886A1.176,1.176,0,0,1,10,18.725Zm-.175-1.413h0ZM5.977,3.4A4.265,4.265,0,0,0,2.323,9.866c1.858,3.035,6.7,6.626,7.667,7.328.975-.707,5.81-4.3,7.662-7.319a4.264,4.264,0,0,0-7.071-4.75.762.762,0,0,1-1.179,0A4.291,4.291,0,0,0,5.977,3.4Z" transform="translate(-0.245 -1.937)"></path></svg>');
}

.stockModuleVehicleCard__attentionGrabberBackground {
  background: $light-background;
  border-radius: 50px;
  padding: 5px 8px;
  margin-bottom: 16px;
}

.stockModuleVehicleCard__attentionGrabberTextTruncation {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.stockModuleVehicleCard__attentionGrabberText {
  white-space: nowrap;
  font-size: 12px;
}

.stockModuleVehicleCard__vehicleTitleWrapper {
  width: 85%;
  display: flex;
  flex-direction: column;
}

.stockModuleVehicleCard__vehicleTitleMain {
  font-weight: 700;
  font-size: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

