@import '~/styles/themes/themes.scss';
@import '~/styles/webshops-styles.scss';

.sideNavigation__list {
  font-size: 16px;
  list-style: none;
  padding: 0;
}

.sideNavigation__listItem {
  line-height: 24px;
  padding: 16px;
  cursor: pointer;
  color: black;
  display: block;
  &:hover {
    background-color: $gray-fa;
  }
}

.sideNavigation__listItem--active {
  @include theme(
    (
      border-color: bodyMain
    )
  );
  border-left: 10px solid;
  cursor: default;
  font-weight: bold;
  background: $gray-fa;
  transition: all 0.3s ease-in-out;
}

