.compareQuotesVideos__wrapper {
  width: 100%;
  margin: 0 auto;
}

.compareQuotesVideos__video {
  overflow: hidden;
  padding: 0 0 0 0;
  box-shadow: 1px 1px 10px 0px rgba(133, 133, 133, 1);
  font-size: 0; //Remove small white bar at bottom
  border-radius: 5px 5px 0 0;
}
