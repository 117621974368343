.dailyStatistics__timeOuter {
  overflow: hidden;
  padding: 20px 0;
  max-width: 350px;
  margin: 0 auto;
}

.dailyStatistics__timeText {
  font-size: 1.6rem;
}

.dailyStatistics__time {
  font-size: 2.4rem;
}
