.monthlyFinanceConversion {
  display: flex;
  flex-direction: column;
  padding: 16px;
  min-height: 560px;
  gap: 8px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 1.4rem;
      margin: 0px;
      font-weight: 700;
      color: #30364e;
    }
  }

  &__input {
    align-self: flex-end;
    min-width: 192px;
  }

  &__inputLabel {
    font-size: 1.1rem;
    display: block;
    text-align: center;
  }

  &__chart {
    padding: 24px 0px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
  }

  &__chartItem {
    display: flex;
    justify-content: space-between;
    min-height: 72px;
    padding: 16px 32px;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      flex-grow: 1;
      gap: 8px;

      > h4 {
        font-size: 1.2rem;
        margin: 0px;
        font-weight: 700;
        color: #2d3748;
      }
      > span {
        font-size: 1.6rem;
        font-weight: 700;
        color: #30364e;
      }
    }

    &__lenders {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: space-around;

      > div {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 700;
      }
    }

    > .icon {
      flex-grow: 0;
    }

    &:nth-child(1) {
      width: 100%;
      background-color: #f3f4f9;
    }

    &:nth-child(2) {
      width: 90%;
    }

    &:nth-child(3) {
      width: 80%;
      background-color: #f3f4f9;
    }

    &:nth-child(4) {
      width: 70%;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 2px 2px 4px 1px #0000001a;
    }
  }

  .loadingIndicator {
    position: initial;
    width: auto;
    height: auto;
  }

  .errorApology {
    margin: auto;
  }
}

.monthlyFinanceConversionTooltip {
  max-width: 206px;
  padding: 8px;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 400;
}
