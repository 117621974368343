.pushSidebar {
  height: 100vh;
  background-color: #ffffff;
  border-radius: 40px 0 0 0;
  padding: 30px;
  position: relative;
  box-shadow: -9px 0px 9px 0px rgba(0, 0, 0, 0.15);
}

.pushSidebar__close {
  background-color: #e2e9eb;
  border: none;
  outline: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 30px;
  left: -20px;
  cursor: pointer;
  transform: rotate(270deg);
  font-size: 1.8rem;
}

.pushSidebar__header {
  font-size: 1.7rem;
  font-weight: bold;
  padding: 0 0 15px 0;
  border-bottom: solid 1px #e9e9e9;
  margin: 0 0 30px 0;
}

.pushSidebar__dataSection {
  border-bottom: solid 1px #e9e9e9;
}

.pushSidebar__dataOuter {
  font-size: 1.4rem;
  margin: 0 0 30px 0;
}

.pushSidebar__dataTitle {
  color: #abadb6;
  margin: 0 0 5px 0;
  display: flex;
  justify-content: space-between;
}

.pushSidebar__pending {
  width: 18px;
  height: 18px;
  position: relative;
}

.pushSidebar__dataMakeModel {
  font-weight: bold;
}

.pushSidebar__dataDerivative {
}

.pushSidebar__infoSection {
  margin: 20px 0;
}

.pushSidebar__infoHeader {
  font-size: 1.2rem;
  font-weight: bold;
}

.pushSidebar__infoHeaderQuestion {
  display: inline-block;
  font-size: 1.4rem;
  color: #4e91f5;
}

.pushSidebar__infoTextOuter {
  background-color: #e9e9e5;
  border-radius: 10px;
  margin: 20px 0 0 0;
  padding: 5px 15px;
}

.pushSidebar__infoText {
  font-size: 1.2rem;
}
