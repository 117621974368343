.editApplicationSummary {
  padding: 0 20px;
}

.editApplicationSummary__radio {
  margin: 10px 0 0 0;
  padding: 20px 0 0 0;
  border-top: solid 1px #e4ebef;
  font-size: 1.4rem;
  font-weight: 300;
}

.editApplicationSummary__checkboxWarning {
  color: #ff613d;
  font-size: 1.4rem;
  margin: 10px 0 0 0;
  display: none;
}
