.horizontalScrollIndicator {
  display: inline-block;
  vertical-align: middle;
}

.horizontalScrollIndicator__leftArrow {
  display: inline-block;
  vertical-align: middle;
  transform: rotate(90deg);
  font-size: 22px;
  cursor: pointer;
}

.horizontalScrollIndicator__indicatorContainer {
  display: inline-block;
  vertical-align: middle;
}

.horizontalScrollIndicator__rightArrow {
  display: inline-block;
  vertical-align: middle;
  transform: rotate(-90deg);
  font-size: 22px;
  cursor: pointer;
}

.horizontalScrollIndicator__indicator {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #dbdbdb;
}

.horizontalScrollIndicator__indicator--active {
  background: #8e8e8e;
}

.horizontalScrollIndicator__indicator:not(:last-child) {
  margin: 0 5px 0 0;
}
