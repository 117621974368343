@import '~SM/styles/stockStyles.module.scss';

.container {
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease-out;
  border: 1px solid #e1e1e1;
  background-color: #f1f1f1;

  &:hover {
    transform: translatey(-1px);
    box-shadow: rgba(45, 45, 45, 0.02) 0px 2px 2px, rgba(49, 49, 49, 0.02) 0px 4px 4px,
      rgba(42, 42, 42, 0.02) 0px 8px 8px, rgba(32, 32, 32, 0.02) 0px 16px 16px, rgba(49, 49, 49, 0.02) 0px 32px 32px,
      rgba(35, 35, 35, 0.02) 0px 64px 64px;
    z-index: 3;
  }

  @media (max-width: $mobile-end) {
    padding: 20px 20px;
  }

  @media (max-width: $mobile-end) {
    flex-wrap: wrap;
    position: relative;
  }
}

.containerSelected {
  transform: translatey(-1px);
  cursor: auto;
  margin-bottom: 0px;
  z-index: 2;
}
.bottom {
  display: flex;
  font-size: 12px;
  border-radius: 8px;
  color: black;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;

  @media (min-width: $tablet-start) {
    justify-content: flex-start;
  }
}

.top {
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.bottomSection {
  display: flex;
  flex-direction: column;
  padding: 0px;
  font-size: 1.5rem;
  line-height: 2em;
  width: 100%;
  margin-right: 10px;

  @media (min-width: $tablet-start) {
    width: unset;
  }
}

.vehicleTopContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  font-size: 1.8rem;
  width: 100%;
}

.vehicleMakeModelText {
  margin-left: 0px;
  font-size: 16px;
  color: black;
  padding: 10px 0 5px;
}

.vehicleValues {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;

  @media (min-width: $tablet-start) {
    margin-right: 20px;
  }
}

.derivativeTitle {
  font-family: 'Open Sans', Arial, sans-serif;
  text-transform: uppercase;
  color: black;
  width: 100%;
  border-bottom: solid 1px black;
  padding-bottom: 20px;
  font-size: 14px;
  padding-right: 25%;
}

.vehicleIcon {
  display: block;
  padding: 2px 0 0 1px;
  font-size: 30px;
  border: solid black 2px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  top: 10px;
  color: black;
}
