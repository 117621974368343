.tabContent {
  position: relative;
}

.tabContent__border {
  margin-top: 20px;
}

.tabContent__contentWrapper {
  position: relative;
  overflow: hidden;
}

.tabContent__panel {
  top: 0;
  left: 0;
  right: 0;
}

.tabContent__panelAnimation-enter {
  opacity: 0;
  position: absolute;
}

.tabContent__panelAnimation-enter-active {
  opacity: 1;
  transition: opacity 0.5s;
  transition-delay: 0.3s;
}

.tabContent__panelAnimation-exit {
  opacity: 1;
  transition: opacity 0.3s;
}

.tabContent__panelAnimation-exit-active {
  opacity: 0;
}
