.dashboardPanelNoItems {
  min-height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 1150px) {
    min-height: 150px;
    padding: 30px 0;
  }
}

.dashboardPanelNoItems__img {
  width: 148px;
  display: block;
  margin: 0 auto;
}

.dashboardPanelNoItems__content {
  font-size: 1.4rem;
  text-align: center;
  margin: 30px 0 0 0;
  width: 100%;
}
