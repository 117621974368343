.ordersAndDealsHelpModal {
}

.ordersAndDealsHelpModal__title {
  font-size: 2rem;
}

.ordersAndDealsHelpModal__subheader {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 20px 0 10px;
  text-align: left;
}

.ordersAndDealsHelpModal__text {
  font-size: 1.4rem;
  margin: 0 0 10px 0;
}
