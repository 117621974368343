.removeRecordModal__header {
  font-weight: 700;
  color: #000;
  text-align: left;
}

.removeRecordModal__divider {
  background: #d4d4d4;
  width: calc(100% - 20px);
  height: 1px;
  margin: 20px 0px;
}

.removeRecordModal__text {
  margin: 40px 0;
}

.removeRecordModal__buttonOuter {
  overflow: hidden;
}

.removeRecordModal__noButton {
  float: left;
  min-width: 100px;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.removeRecordModal__yesButton {
  float: right;
  min-width: 100px;

  @media screen and (max-width: 500px) {
    width: 100%;
    margin: 10px 0 0 0;
  }
}
