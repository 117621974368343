.financeNavigator {
  &__title {
    font-family: 'Montserrat', 'Open Sans', Arial, sans-serif;
    font-size: 2rem;
    margin: 5px 0 14px 0;
    padding: 0;
    text-align: center;
  }
  &__subTitle {
    text-align: center;
    margin: 0 0 35px 0;
    color: #000;
  }
}
