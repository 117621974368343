.editNegativeEquityLoan__termText {
  margin: 0px auto 20px;
  padding: 0 0 0 20px;
  font-size: 1.4rem;
  text-align: center;

  @media (max-width: 768px) {
    padding: 0;
  }
}
