@import '../../styles/themes/themes.scss';

.dealershipDropdownListItem {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-left: 4px solid #a6a6a6;
}

.dealershipDropdownListItem--active {
  border-left-color: #6bc348;
}

.dealershipDropdownListItem--first {
  border-top: none;
}

.dealershipDropdownListItem__button {
  width: 100%;
  padding: 15px 20px 15px 20px;
  border: none;
  border-radius: 0;
  outline: none;
  text-align: left;
  font-size: 1.4rem;
  cursor: pointer;
  background-color: transparent;

  @include theme(
    (
      color: headerForeground
    )
  );

  &:hover {
    @include themeDarken(
      (
        background-color: headerBackground
      )
    );
  }
}

