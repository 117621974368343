.settingsAccountViewDetails {
  overflow: hidden;
}

.settingsAccountViewDetails__inner {
  overflow: hidden;
  zoom: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.settingsAccountViewDetails__detailContainer {
  width: 33%;
  float: left;
  font-size: 1.4rem;
  margin: 10px 0 20px 0;
  word-wrap: break-word;

  @media screen and (max-width: 910px) {
    width: 50%;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.settingsAccountViewDetails__label {
  margin: 00 0 5px 0;
  color: #636363;
}

.settingsAccountViewDetails__changePassword {
  white-space: nowrap;
}

.settingsAccountViewDetails__permissionsHeader {
  font-size: 1.4rem;
  font-weight: 700;
}

.settingsAccountViewDetails__data--optional {
  opacity: 0.5;
}

.settingsAccountViewDetails__buttonContainer {
  padding: 20px 0 0 0;
  height: 50px;
  border-top: solid #e4ebef 1px;
  margin: 20px 0 0 0;
}

.settingsAccountViewDetails__cancel {
  float: left;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}
.settingsAccountViewDetails__permissionsDetail {
  width: 100%;
  padding-right: 20px;
}

.settingsAccountViewDetails__permissionsDesc {
  font-size: 1.4rem;
  margin: 10px 0;
}
