.vehicleMetric {
  display: flex;
  flex-direction: row-reverse; // flips icon position
  justify-content: center;
  align-items: center;
  word-wrap: none;

  font-size: 12px;
  gap: 4px;
  color: #6b6b6b;
  &:hover {
    color: #30364e;
  }
}

.vehicleMetricIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vehicleMetricValue {
  font-weight: 900;
}
