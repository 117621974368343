.repExampleInfoCard {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin: 0 20px;
  padding: 20px;

  @media (max-width: 800px) {
    margin: 0;
    width: 100%;
  }
}

.repExampleInfoCard__title {
  margin-bottom: 20px;
  font-weight: bolder;
  font-size: 16px;
}

.repExampleInfoCard__bulletPoints {
  padding-left: 1.3em;
}

.repExampleInfoCard__financeInfo {
  font-size: 14px;
  color: #999999;
}
.repExampleInfoCard__repTitle {
  @extend .repExampleInfoCard__title;
  margin-left: 20px;
}
