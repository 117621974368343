.businessAddressForm {
  margin: 30px 0;

  @media (max-width: 650px) {
    margin: 20px;
  }
}

.businessAddressForm__removeContainer {
  width: 100%;
  padding: 20px;
  overflow: auto;
}

.businessAddressForm__remove {
  width: 100px;
  float: right;
}
