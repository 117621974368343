.applicationSummaryTables__inner {
  width: 100%;
  padding: 0px 0 10px 0;
  font-size: 1.4rem;
}
.applicationSummaryTables__multiloansHeader {
  font-weight: 700;
  font-size: 14px;
  padding: 0px 0px 15px 20px;
  color: #30364e;
}
