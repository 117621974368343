.repExampleView {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__alert {
    p {
      margin: 0;
    }
    ul {
      padding-inline-start: 16px;
      margin: 8px 0px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 0px;
  }

  &__title {
    font-weight: bolder;
    font-size: 16px;
  }

  &__summary {
    margin: 0px;
  }

  &__example {
    border: solid #e0e0e0 1px;
    border-radius: 5px;
    padding: 15px;
  }

  &__dateSet,
  &__setBy {
    margin: 0px;
    font-weight: 700;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    .button {
      width: auto;

      &:first-child {
        margin-right: auto;
      }
    }
  }

  .repExampleInfoCard {
    margin: 0;
  }
}
