.stockListVehicleImage__border {
  border: solid #e4ebef 2px;
  overflow: hidden;
}

.stockListVehicleImage__upload-icon {
  font-size: 30px;
  > .icon-upload {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
