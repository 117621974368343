@import '~/styles/webshops-styles.scss';
@import '~/styles/themes/themes.scss';

.StockVehicleSidePanel__container {
  padding-bottom: 24px;
}

.StockVehicleSidePanel__buttons {
  border-top: $border;
  border-bottom: $border;
  > button {
    margin-top: 16px;
  }
}

.StockVehicleSidePanel__previewAd {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding: 16px 0;
  cursor: pointer;
  &:hover {
    transform: translatey(-2px);
    transition: transform 0.2s ease-in-out;
  }
  > div {
    display: inline;
    margin-right: 4px;
    font-size: 24px;
  }

  @include theme(
    (
      color: bodyMain
    )
  );
}

.StockVehicleSidePanel__vehicleCardPreviewWrapper {
  margin: 10rem auto;
  max-width: 400px;
}

