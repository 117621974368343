.searchResults__noResults {
  display: none;
}

.searchResults {
  list-style: none;
  padding: 0;
  margin: 20px 0 0 0;
  border: 1px solid #ced0cc;
  border-radius: 4px;
  height: 200px;
  overflow-y: scroll;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.searchResults__result {
  :hover {
    background: #ecf2e4;
  }
}

.searchResults__resultButton {
  color: #000;
  width: 100%;
  height: 100%;
  text-decoration: none;
  text-align: left;
  padding: 10px;
  font-size: 1.3rem;
  border-style: none;
  border-radius: 0;
  background: #fff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;

  :focus {
    outline: none;
  }
}

.searchResults__resultEmail {
  display: block;
}
