.stockListComparisonCardValues {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
  padding-bottom: 15px;
  padding-top: 10px;
  color: black;

  &:last-child {
    margin-bottom: 0;
  }

  &:not(:last-of-type) {
    border-bottom: 0.5px solid #6b6b6b;
  }
}

.title {
  margin-bottom: 2px;
  letter-spacing: 1px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
}

.value {
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;

  @media (min-width: 768px) {
    margin-bottom: 4px;
  }
}

.chip {
  margin: 0;
  width: 100%;
  text-align: right;
  white-space: nowrap;
}

.verticalSpace {
  height: 31px;
}
