.dashboardTableItem {
  overflow: auto;
  font-size: 1.2rem;
}

.dashboardTableItem__ul {
  list-style: none;
  margin: 0;
  padding: 2px 0;
  overflow: auto;
}

.dashboardTableItem__li {
  float: left;
  padding: 10px 10px 10px 0;
  min-height: 75px;
  overflow: hidden;
  text-align: left;
}

.dashboardTableItem__long {
  width: 40%;
  @media (max-width: 800px) {
    width: 100%;
    border-bottom: solid 1px #d2d3d5;
    border-right: none;
  }
}

.dashboardTableItem__vehicleImage {
  float: left;
}

.dashboardTableItem__content {
  overflow: hidden;
  text-align: left;
}

.dashboardTableItem__vehicle {
}

.dashboardTableItem__notesContainer {
  width: 20px;
  float: left;
  margin: -1px 0 0 0;
}

.dashboardTableItem__short {
  width: 20%;
  @media (max-width: 800px) {
    width: 33.333%;
    margin: 4px 0 2px;
  }
}

.dashboardTableItem__top {
  font-weight: bold;
  margin: 10px 0;
}

.dashboardTableItem__updateOuter {
  width: 12px;
  height: 12px;
  float: left;
  margin: 2px 5px 0 0;
}

.dashboardTableItem__last {
  border-right: none;
}

.dashboardTableItem__noCfc {
  margin: 10px 0 0 0;
  color: #a1a6aa;
  font-weight: bold;
}

.dashboardTableItem__status {
  font-weight: bold;
}

.dashboardTableItem__status--high {
  color: #6bc348;
}

.dashboardTableItem__status--medium {
  color: #f4b152;
}

.dashboardTableItem__status--low {
  color: #e66c6c;
}

.dashboardTableItem__noUpdates {
  color: #fa443c;
}

.dashboardTableItem__topAlias {
  font-weight: bold;
  margin: 5px 0;
}

.dashboardTableItem__ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
