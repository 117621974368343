@import '../../styles/themes/themes.scss';

.combinedQuoteMessage {
  @include theme(
    (
      color: bodyMain,
      border-color: bodyMain
    )
  );
  text-align: left;
  cursor: pointer;

  &:hover {
    @include themeDarken(
      (
        color: bodyMain
      )
    );
  }

  width: 100%;
  line-height: 18px;
  @media (max-width: 1166px) {
    padding-left: 2.5%;
  }
  @media (max-width: 460px) {
    text-align: center;
  }
}

.combinedQuoteMessage__icon {
  font-size: 13px;
  height: 24px;
  width: 24px;
  font-weight: bold;
  border: 1px solid;
  border-radius: 100%;
  margin: 10px -4px;
  display: inline-block;
  background: white;
  text-align: center;
  padding: 1px 0 0 0;
}

.combinedQuoteMessage__icon--grey {
  background: #f8f9fc;
}

.combinedQuoteMessage__text {
  display: inline-block;
  margin-left: 15px;
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  padding-bottom: 0.5em;
}
