.editConsumerForm {
  display: flex;
  flex-wrap: wrap;
}

.editConsumerForm__personalDetails {
  flex: 0 0 50%;

  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
  }
}

.editConsumerForm__addressDetails {
  flex: 0 0 50%;

  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
  }
}

.editConsumerForm__header {
  font-family: 'Montserrat', 'Open Sans', Arial, sans-serif;
  font-size: 1.6rem;
  padding: 15px 80px;
  margin: 0;
  border-bottom: 1px solid #e1e1e1;
  color: #000;
  text-align: left;

  @media (max-width: 768px) {
    padding: 30px 0;
    border-bottom: none;
  }
}

.editConsumerForm__personalDetailsHeader {
}

.editConsumerForm__fields {
  padding: 45px 80px 15px 80px;
  border-right: 1px solid #e1e1e1;

  @media (max-width: 768px) {
    border-right: none;
    padding: 0;
  }
}

.editConsumerForm__footer {
  flex: 0 0 100%;
  padding: 10px;
  margin: 0 0 50px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 450px) {
    padding: 0 10px;
    flex-direction: column;
    height: 92px;
  }
}
