.fieldGroupRhf__errorMessage {
  padding: 5px 0 0 0;
  color: #e66c6c;
  font-weight: 600;
  min-height: 25px;
  font-size: 13px;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
}
