.consumerRecordDetails__settlement {
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  background: #f7f7f7;
  padding: 20px;
  margin-bottom: 30px;
  align-items: center;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
}

.consumerRecordDetails__settlementSection {
  padding: 0 40px 0 0;

  @media (max-width: 960px) {
    padding-right: 10px;
  }

  @media (max-width: 600px) {
    width: 50%;
    margin: 0 0 5px 0;
  }
}

.consumerRecordDetails__settlementTitle {
  @media (max-width: 600px) {
    width: 100%;
  }
}

.consumerRecordDetails__settlementLinkOuter {
  background: #f7f7f7;
  align-self: center;
}

.consumerRecordDetails__settlementLink {
  font-size: 14px;
  cursor: pointer;
  color: #6bc348;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
