.dashboardListHeader {
  font-size: 1.2rem;
  overflow: auto;
  padding: 1px 0;
  border-bottom: 1px solid #e4ebef;
  color: #73777f;

  @media (max-width: 800px) {
    display: none;
  }
}

.dashboardListHeader__ul {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  color: #73777f;
}

.dashboardListHeader__li {
  font-weight: 600;
  padding: 5px 0 5px 0;
  text-align: left;
  display: inline-flex;
  justify-content: flex-start;
  align-content: center;
}

.dashboardListHeader__long {
  width: 40%;
}

.dashboardListHeader__first {
  border-left: none;
}

.dashboardListHeader__short {
  width: 20%;
}

.dashboardListHeader__last {
  border-right: none;
}
