.editApplicationAttentionPanel {
  border: solid #f4f4f4 1px;
  padding: 20px;
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
  font-size: 1.4rem;
  font-weight: bold;
}

.editApplicationAttentionPanel__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.editApplicationAttentionPanel__headerIcon {
  width: 30px;
  height: 30px;
  font-size: 30px;
  margin: 0 10px 0 0;
}

.editApplicationAttentionPanel__ul {
  padding: 0 0 0 24px;
  font-weight: normal;
}

.editApplicationAttentionPanel__li {
}
