.th {
  text-align: left;
  color: #6b6e73;
  font-weight: bold;
  padding: 16px 10px;
}

.th--notBold {
  font-weight: 400;
}

.th--isCentred {
  text-align: center;
}

.th--interactive {
  cursor: pointer;
  user-select: none;
  &:hover {
    color: #222;
  }
}
