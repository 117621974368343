.videoTop {
  width: 100%;
  padding: 7px 0 13px 10px;
  background: #6c7d84;
  border-radius: 5px 5px 0 0;
}

.videoTop__circle {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  float: left;
  margin: 0 3px 0 0;
}

.videoTop__red {
  background-color: #e76d6a;
}

.videoTop__yellow {
  background-color: #dfbc42;
}

.videoTop__green {
  background-color: #35d488;
}
