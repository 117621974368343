.helpVideoMenuSection {
  border-top: solid #eeeeee 1px;
  padding: 20px 0;
  overflow: auto;

  @media (max-width: 845px) {
    width: 90%;
    margin: 0 auto;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
}

.helpVideoMenuSection__title {
  font-weight: bold;
  font-size: 2rem;
}

.helpVideoMenuSection__vids {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media (max-width: 570px) {
    justify-content: space-around;
  }
}
