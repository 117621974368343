$gray: #30364e;

.MarqueeQuotes__wrapper {
  position: relative;
  min-width: calc(100% - 40px);
  margin-left: 12px;
  background: white;
  margin-bottom: 16px;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

  @media (min-width: 1343px) {
    margin-right: 40px;
  }
}

.MarqueeQuotes__headingContainer {
  position: relative;
  width: fit-content;
  display: flex;
}

.MarqueeQuotes__heading {
  margin-left: 20px;
  font-weight: bold;
  display: inline-block;
  font-size: 16px;
  color: $gray;
}

.MarqueeQuotes__carouselWrapper {
  overflow: hidden;
  position: relative;
  margin-left: 30px;
  margin-right: 30px;
}

.MarqueeQuotes__carouselArrow {
  position: absolute;
  height: 70%;
  width: 30px;
  bottom: 0;
  display: flex;
  align-items: center;
  color: $gray;
  font-size: 28px;
  cursor: pointer;
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  opacity: 0;
  transition: all 0.3s ease-out;

  &:hover {
    opacity: 1;
    transform: translateX(0);
  }
}

.MarqueeQuotes__carouselArrowLeft {
  left: -5px;
  transform: translateX(-5px);
}

.MarqueeQuotes__carouselArrowRight {
  right: 5px;
  transform: translateX(5px);
}

.MarqueeQuotes__carousel {
  display: flex;
  gap: 20px;
  margin-right: 20px;
  justify-content: space-between;
  padding: 10px 0 20px 0;
}

@keyframes yellowfade {
  from {
    background: yellow;
  }

  to {
    background: transparent;
  }
}

.MarqueeQuotes__fadein {
  animation: yellowfade 1s;
}

.MarqueeQuotes__pointerCursor {
  cursor: pointer;
}

.MarqueeQuotes__vehicle {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  min-height: 42px;
  flex-basis: 360px;
}

.MarqueeQuotes__img {
  position: relative;
  width: 100px;
  height: 70px;
  border: 1px solid #d2d3d5;
  margin-right: 4px;
  border-radius: 6px;
  background-color: #ffffff;
  user-select: none;

  .vehicleImage .vehicleImage__imageContainer {
    min-height: 70px;
  }
}

.MarqueeQuotes__timestamp {
  font-size: 12px;
  margin-top: auto;
}

.MarqueeQuotes__vehicleContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  white-space: nowrap;
  user-select: none;
}

.MarqueeQuotes__recent {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -20px;
  font-size: 14px;
  height: 20px;
  background: $gray;
  color: white;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-weight: bold;
}

.MarqueeQuotes__vrm {
  color: $gray;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  white-space: nowrap;
  user-select: none;
}

.MarqueeQuotes__id {
  color: $gray;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80px;
  white-space: nowrap;


}

.MarqueeQuotes__vehicleInfo {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  white-space: nowrap;
}

.MarqueeQuotes__pulsar {
  position: absolute;
  right: -8px;
  top: 7px;
}

.MarqueeQuotes__hidden {
  display: none;
}

.MarqueeQuotes__circle {
  width: 11px;
  height: 11px;
  background-color: #6bc348;
  border-radius: 50%;
  position: absolute;
  top: 7px;
}

.MarqueeQuotes__ringringNew {
  left: -7px;
  border: 3px solid #6bc348;
  border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  animation: pulsateNew 1s ease-out;
  animation-direction: reverse;
  opacity: 0;
}

.MarqueeQuotes__ringring {
  left: -7px;
  border: 3px solid #6bc348;
  border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  animation: pulsate 5s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
}

@keyframes pulsateNew {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
