@import '../../styles/utility/browsers.scss';

.pushDealContainer__vehicleInfoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;

  @include ie-old-only {
    @media (max-width: 600px) {
      flex-direction: column;
      justify-content: center;
    }
  }

  @media (max-width: 570px) {
    flex-wrap: wrap;
  }
}

.pushDealContainer__vehicleInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;

  @media (max-width: 570px) {
    width: 100%;
  }
}

.pushDealContainer__vehicleImageContainer {
  min-width: 130px;
  height: 100px;
  margin-right: 20px;
  overflow: hidden;
  border-radius: 14px;
  border: 1px solid #e4ebef;
}

.pushDealContainer__vehicleButton {
  width: 130px;
  height: 40px;
  margin-right: 20px;

  @include ie-old-only {
    @media (max-width: 600px) {
      margin: auto;
    }
  }

  @media (max-width: 570px) {
    margin: 20px 0 0 0;
  }
}
