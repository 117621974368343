.distanceMap {
  width: 100%;
  height: 100%;
  position: relative;
}

.distanceMap__header {
  font-weight: bold;
  font-size: 2rem;
  margin: 0 0 20px 0;
}

.distanceMap__map {
  position: relative;
  max-width: 740px;
  height: 500px;

  @media (max-width: 500px) {
    height: 300px;
  }
}
