.dateInput {
  display: flex;
}

.dateInput__input {
  width: 30%;
  flex: 1;

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.dateInput__slash {
  width: 3%;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
}
