.dashboardVehicle {
  width: 100px;
  margin: 0 10px 0 0;
  padding: 0 0 5px;
  position: relative;
}

.dashboardVehicle__imageContainer {
  width: 100px;
  height: 70px;
  border: 1px solid #d2d3d5;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 6px;
  background-color: #ffffff;
}

.dashboardVehicle__reg {
  border-radius: 1px;
  background-color: #fff36b;
  width: 100%;
  height: 23px;
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  position: relative;
  margin: 10px 0 0 0;
  text-transform: uppercase;
  padding: 3px 0 0 7px;
  color: #000000;
}

.dashboardVehicle__regBlue {
  width: 10px;
  height: 100%;
  background: #4d8df5;
  position: absolute;
  left: 0;
  top: 0;
}

.dashboardVehicle__count {
  position: absolute;
  top: 2px;
  right: 2px;
  text-align: center;
  padding: 2px 4px;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.3rem;
  background-color: #f07d76;
  border-radius: 0 1px 0 0;
  box-shadow: 0 2px RGBA(0, 0, 0, 0.16);
}
