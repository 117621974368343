.commentsButton {
  text-align: center;
}

.commentsButton__container {
  display: flex;
  width: 100%;
  height: 28px;
  color: #ffffff;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.commentsButton__iconOuter {
  width: 28px;
  height: 28px;
  padding: 3px 3px 0 2px;
  background-color: #d8b600;
  font-size: 22px;
  border-radius: 5px 0 0 5px;
}

.commentsButton__textOuter {
  background-color: #d9e1ec;
  color: #ffffff;
  height: 28px;
  font-size: 1.2rem;
  text-align: center;
  font-weight: 600;
  padding: 0 5px;
  border-radius: 0 5px 5px 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
