.stockLookupContainer__vrm-vin-lookup .mobxFieldGroup__input {
  display: flex;
  justify-content: space-between;

  .textInput {
    width: 60%;
  }

  .button {
    width: 38%;
  }
}

.stockLookupContainer__vrm-vin-lookup {
  align-items: center;
  
  .mobxFieldGroup__label--inline {
    margin-top: 0px;
    }
  }