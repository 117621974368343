.addUser {
  overflow: hidden;
}

.addUser__inputs {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.addUser__inputEach {
  width: 48%;

  @media (max-width: 990px) {
    width: 100%;
  }
}

.addUser__permissions {
  font-size: 1.4rem;
  font-weight: 700;
}

.addUser__duplicateEmailError {
  flex: 0 0 100%;
}
