.assignLeadModalUserList {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #b7b7b7;
  border-radius: 4px;
  min-height: 150px;
  font-size: 1.4rem;
  background: #fafafa;
  position: relative;
}
