.consumerHubInfoPanel {
  width: 100%;
  margin: 0 0 20px 0;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid rgba(100, 188, 70, 0.25);
  background-color: rgba(100, 188, 70, 0.075);

  @media (max-width: 1000px) {
    margin: 20px 0;
  }
}

.consumerHubInfoPanel__title {
  display: block;
  margin: 0 0 10px;
  font-size: 1.4rem;
  font-weight: 800;
}

.consumerHubInfoPanel__titleText {
}

.consumerHubInfoPanel__titleIcon {
  font-size: 19px;
}

.consumerHubInfoPanel__timestamp {
  font-weight: 500;
}

.consumerHubInfoPanel__activityList {
  margin: 0px;
  padding: 0 0 0 15px;
}

.consumerHubInfoPanel__activityItem {
  font-size: 1.2rem;
  margin: 10px 0 0;
}
