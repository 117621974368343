.multiLoansHeader {
  margin-bottom: 15px;
  p {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 20px;
    padding: 0px 16px;
  }

  &__button {
    display: flex;
    justify-content: center;

    div {
      color: black;
    }
  }

  .multiLoansContainer {
    margin: 20px 0px 15px 0px;
    height: 100%;
  }
}

.multiLoansHeader__divider {
  @media (min-width: 800px) {
    font-weight: bold;
    margin-bottom: 5px;
    padding: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #d3d3d3;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #d3d3d3;
    }
  }
}

.multiLoansDetails {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

