.toggleSwitch__wrapper {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.toggleSwitch__input {
  opacity: 0;
  position: absolute;
  width: 64px;
  height: 26px;
  z-index: 10;
  cursor: pointer;
  left: 0;
  right: 1px;
  top: 0;
  bottom: 4px;
  &:disabled {
    cursor: initial;
    pointer-events: none;
  }
}

.toggleSwitch__outer {
  display: block;
  width: 57px;
  height: 27px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-radius: 14px;
  position: relative;
  background-color: #d4d4d4;
  border: 2px solid #d4d4d4;
  transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;
}

.toggleSwitch__smallBlueVariant {
  width: 30px;
  height: 17px;
}

.toggleSwitch__smallBlueVariant .toggleSwitch__spanBase {
  width: 13px;
  height: 13px;
}

.toggleSwitch__outerActive {
  background-color: #6bc348;
  border-color: #6bc348;
}

.toggleSwitch__smallBlueVariant.toggleSwitch__outerActive {
  background-color: #3a82ce;
  border-color: #3a82ce;
}

.toggleSwitch__spanBase {
  display: inline-block;
  width: 23px;
  height: 23px;
  vertical-align: middle;
  border-radius: 14px;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  transition: left 200ms ease-in-out, border-color 200ms ease-in-out;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.toggleSwitch__spanActive {
  left: 30px;
}

.toggleSwitch__smallBlueVariant .toggleSwitch__spanActive {
  left: 13px;
}

.toggleSwitch__active--hover .toggleSwitch__spanBase {
  left: calc(100% - 16px);
}

.toggleSwitch__base--hover .toggleSwitch__spanBase {
  left: 4px;
}

.toggleSwitch__spanDisabled {
  cursor: auto;
}

.toggleSwitch__outerDisabled {
  cursor: default;
  opacity: 0.3;
  z-index: 11;
}

.toggleSwitch__lettering--positive {
  color: #ffffff;
  padding: 3px 0 0 4px;
  font-weight: 600;
  text-align: center;
  display: inline-block;
  width: 50%;
  font-size: 12px;
  @media (max-width: 1160px) {
    text-align: left;
    width: 90%;
  }
}

.toggleSwitch__lettering--negative {
  color: #ffffff;
  padding: 3px 8px 0 0;
  font-weight: 600;
  text-align: right;
  display: inline-block;
  width: 100%;
  font-size: 12px;
}

.toggleSwitch__valueOnly--valid {
  color: #6bc348;
  font-size: 24px;
}

.toggleSwitch__valueOnly--inValid {
  color: #e88d7e;
  font-size: 24px;
}

