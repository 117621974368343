// @TODO this @value stuff isn't the best.
@value heading from "~SM/routes/stock/routes/$vehicleId/routes/fix-issues/components/StockListFixIssuesContainer/components/ComparisonCards/components/StockListComparisonCard/stockListComparisonCard.module.scss";
@value tabs from '~SM/routes/stock/routes/$vehicleId/routes/fix-issues/components/StockListFixIssuesContainer/components/ComparisonCards/components/Tab/Tab.module.scss';
@value loadingIndicator from '~Common/Loading/loadingDots.scss';
@import '~SM/styles/stockStyles.module.scss';

.vrmCard {
  height: 100%;
  border: solid #e4ebef 1px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 12px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
}

.vrmVinDealerRef {
  font-weight: normal;
  white-space: nowrap;
}

.comparisonOuter {
  padding: 0 5px 20px;

  @media (min-width: 845px) {
    padding: 0 20px 20px;
  }
}

.comparisonTitle {
  font-size: 16px;
  color: #5e6977;
  margin-left: 5px;
}
.error {
  color: red;
  font-size: 20px;
}
.noResults {
  color: #5e6977;
  font-size: 20px;
}

.information {
  position: relative;
  display: flex;
  align-items: center;
  color: #4e91f5;
  font-size: 25px;
  padding: 0;
  margin: 12px 0;
}

.visResults {
  position: relative;
  border-radius: 8px;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin-left: 0;
}

.visResultsInner {
  width: 100%;
}

.loadingContainer .loadingIndicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;

  @media (max-width: 425px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
  }
}

.button {
  min-width: 150px;

  @media (max-width: 425px) {
    width: 100%;
    :first-of-type {
      margin-bottom: 10px;
    }
  }
}

.loadingContainer {
  min-height: 400px;
}

.confirmVehicleText {
  margin-top: 25px;
  font-size: 18px;
  font-weight: 800;
  color: #000;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}

.searchBar {
  color: #5e6977;
  font-size: 10px;
  font-weight: 800;
  margin: 0 0 20px;
  border-top: 1px solid #e4ebef;

  @media (min-width: 768px) {
    display: block;
  }
}

.searchBarParagraph {
  font-weight: 600;
  color: #5e6977;
  font-size: 16px;
  margin-top: 20px;
}

.comparisonCardOuter {
  display: flex;
  padding: 0;
  overflow-x: auto;
  margin-bottom: 20px;
}

.comparisonTabOuter {
  display: flex;
  padding: 0;
  overflow-x: auto;
  margin-bottom: 20px;
}

.tabs {
  .comparisonCard {
    .heading {
      display: none;
    }
  }
}

.comparisonCard {
  width: 100%;
}

.alertLink {
  cursor: pointer;
  color: $alert-001;
  text-decoration: underline;
}

.warningPositioning {
  margin-top: 12px;
  margin-bottom: 32px;
}

.mobileButtonGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-top: 16px;

  @media (min-width: 650px) {
    display: flex;

    .quoteButton,
    .backButton,
    .saveExitButton {
      width: fit-content;
    }
  }
}

.backButton {
  grid-area: 2 / 1 / 3 / 3;
  @media (min-width: 650px) {
    order: -1;
    margin-right: auto;
  }
}

.quoteButton {
  grid-area: 1 / 1 / 2 / 5;
}

.saveExitButton {
  grid-area: 2 / 3 / 3 / 5;
}
.panelHeader > {
  :global(.panelHeader__title) {
    padding: 16px 0;
    line-height: 24px;
    color: #060A0D;
  }
}
