.largeMenuButtonWrapper {
  text-align: center;
}

.largeMenuButtonWrapper__inner {
  overflow: hidden;
  display: flex;
  margin: 10px 0 0 0;
  padding: 20px 0 0 0;
  flex-wrap: wrap;
  justify-content: center;
}
