.latestDealCard {
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(154, 192, 216, 0.16);
  transition: all 0.2s ease-out;
  transform: translateY(0);
  margin: 0 0 20px 0;
  border: 1px solid #e9e9e9;
  &:hover,
  &:focus {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(188, 194, 208, 0.4);
  }
}

.latestDealCard__consumer {
  padding: 10px;
  background-color: #f8f9fc;
  border-radius: 12px 12px 0 0;
}
