$text-color: #5e6977;
$sub-label-color: #707070;
$border-color: #e4ebef;
$error-color: #e66c6c;

.stockRefinementSearch__comparison--outer {
  padding: 0px 5px 20px;

  @media (min-width: 845px) {
    padding: 0 20px 20px;
  }
}

.stockRefinementSearch__visResults {
  position: relative;
  border-radius: 8px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  margin-left: 0;
}

.stockRefinementSearch__buttonContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-top: 24px;

  @media (max-width: 425px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
  }
}

.stockRefinementSearch__button {
  min-width: 150px;

  @media (max-width: 425px) {
    width: 100%;
    :first-of-type {
      margin-bottom: 10px;
    }
  }
}

.stockRefinementSearch__confirmVehicleText {
  width: 100%;
  margin-top: 25px;
  font-size: 18px;
  font-weight: 400;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}

.stockRefinementSearch__manualCreation {
  width: 100%;
  margin-top: 25px;
  font-size: 18px;
  font-weight: 400;
  color: $text-color;
  display: flex;
  justify-content: flex-end;

  .icon {
    font-size: 24px;
  }
}

.stockRefinementSearch__createManuallyText {
  padding-right: 10px;
  font-style: italic;
}

.stockRefinementSearch__createManuallyButton {
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  font-size: 18px;
  font-weight: 800;
  color: $text-color;
  padding-left: 0;
  margin-left: 0;
}

.stockRefinementSearch__subLabel {
  display: flex;
  font-weight: 500;
  color: $sub-label-color;
  font-size: 15px;
  margin: 10px 0 0 0;
  padding: 0 10px;
}

.stockRefinementSearch__mobxForm {
  width: 500px;
  margin: 0 0 0 20px;
}

.stockRefinementSearch__vehicleMiscInformation {
  font-weight: bold;
  padding: 20px;
  font-size: 15px;
}

.stockRefinementSearch__vehicleConditions {
  padding: 10px 0;
  > span {
    font-weight: normal;
    padding-left: 10px;
  }
}

.stockRefinementSearch__vehicleVRMVIN {
  padding: 10px 0;
  text-transform: uppercase;
  > span {
    font-weight: normal;
    padding-left: 10px;
  }
}

.stockRefinementSearch__confirmationModal {
  padding-left: 15px;
  min-height: 50px;
}
