@import '../../styles/themes/themes.scss';

.relatedApplicationStatus {
  border-bottom: 1px solid #d2d3d5;
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  height: auto;
  padding: 10px;
  align-items: center;

  @media (min-width: 801px) {
    justify-content: space-between;
    flex-direction: row;
    padding: 25px;
    height: 86px;
  }

  &:last-of-type {
    border: none;
  }
}

.relatedApplicationStatus__loadingSpinner {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -10px;
  padding: 25px 0 0 0;

  @include theme(
    (
      color: bodyMain
    )
  );
}

.relatedApplicationStatus__main {
  margin-bottom: 30px;
  border: 1px solid #d2d3d5;
  border-top: none;
  border-radius: 0 0 12px 12px;
  padding-left: 11px;
  font-weight: bold;
}

.relatedApplicationStatus__icon {
  padding-top: 4px;
  flex: 0 1 auto;
  @media (max-width: 800px) {
    padding: 6px;
  }
}

.relatedApplicationStatus__name {
  font-size: 14px;
  padding: 0 20px 0 20px;
  width: 100%;

  @media (max-width: 800px) {
    padding: 0 10px;
    text-align: center;
  }
}

.relatedApplicationStatus__status {
  padding: 0 0 0 0;

  @media (max-width: 800px) {
    text-align: center;
    margin: 0 auto;
    padding: 10px;
  }
}

.relatedApplicationStatus__statusLarge {
  padding: 10px 0 10px 0;
  width: 100%;
  text-align: right;

  @media (max-width: 800px) {
    padding-top: 10px;
    text-align: center;
  }
}

.relatedApplicationStatus__button {
  padding: 0 0 0 0;

  @media (max-width: 800px) {
    width: 100%;
  }
}

.relatedApplicationStatus__links {
  display: flex;
  width: 370px;
  padding: 0 30px 0 0;
  justify-content: space-around;
  font-size: 14px;
  font-weight: 600;
  @media (max-width: 800px) {
    width: 100%;
    padding: 10px;
  }
}

.relatedApplicationStatus__link {
  @include theme(
    (
      color: bodyMain
    )
  );
}

.relatedApplicationStatus__notSubmittedMesage {
  color: #a9a9a9;
  font-size: 14px;
  width: 250px;
  padding: 0 40px 0 20px;
  font-weight: 600;

  @media (max-width: 800px) {
    width: 100%;
    text-align: center;
  }
}

.relatedApplicationStatus__updated {
  font-size: 12px;
  width: 300px;
  text-align: left;
  padding: 0 20px 0 0;
  @media (max-width: 800px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    > div {
      padding-left: 5px;
    }
  }
}
