.broadcastStockListContainer {
  .broadcastStockListContainer__filter {
    display: inline-flex;
    width: 23%;
    border-right: solid #e4ebef 1px;

    @media only screen and (max-width: 1200px) {
      width: 100%;
      border: none;
    }
  }

  .broadcastStockListContainer__container {
    display: inline-flex;
    width: 77%;
    flex-direction: column;
    position: relative;
    @media only screen and (max-width: 1200px) {
      width: 100%;
    }
  }

  .broadcastStockListContainer__results {
    padding: 10px 0 10px 20px;

    @media only screen and (max-width: 1200px) {
      padding: 10px 0;
    }
  }

  .broadcastStockListContainer__searchSuggestions {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }

  .broadcastStockListContainer__suggestionsContainer {
    border: solid #f0f0ed 2px;
    border-radius: 7px;
    margin-top: 5px;
    position: absolute;
    width: calc(100% - 20px);
    background-color: white;
    z-index: 100;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.16);
  }

  .broadcastStockListContainer__listItem {
    color: #5e6977;
    font-size: 1.6rem;
    padding: 5px 15px;
    display: block;
    cursor: pointer;

    &:hover {
      background-color: #f0f0ed;
    }
  }

  .broadcastStockListContainer__preResultInfo {
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: 12px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e4ebef;
    padding: 10px 0;

    button.button {
      box-shadow: unset;
      margin: unset;
    }

    @media screen and (min-width: 1201px) {
      padding: 10px 10px 10px 20px;
      margin-left: 10px;
    }
  }

  .broadcastStockListContainer__paginationSummary {
    margin-left: auto;
    padding: unset;
    border: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  button.button {
    min-width: unset;
    width: auto;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.vrmPlate__yellow {
  padding: 0 20px;
}
