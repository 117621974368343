@import '~SM/styles/stockStyles.module.scss';
@import '~/styles/themes/themes.scss';

.stockListContainer {
  position: relative;
  min-height: 100vh;
  padding: 0 10px 10px;
  @media (max-width: $mobile-end) {
    padding: 0;
  }
}

.container {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 1080px;
}

.results {
  width: 100%;
  @media (min-width: $desktop-start) {
    padding: 10px;
  }
}

.searchSuggestions {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.suggestionsContainer {
  border: solid #f0f0ed 2px;
  border-radius: 7px;
  margin-top: 5px;
  position: absolute;
  width: calc(100% - 20px);
  background-color: white;
  z-index: 100;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.16);
}

.suggestionListItem {
  color: #5e6977;
  font-size: 1.6rem;
  padding: 5px 15px;
  display: block;
  cursor: pointer;

  &:hover {
    background-color: #f0f0ed;
  }
}

.paginationSummary {
  display: flex;
  align-items: center;
  border: none;
  font-weight: 400;
  font-family: 'Open Sans', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #6b6b6b;
  margin-bottom: 1rem;
}

.panelHeaderCont {
  display: flex;
  flex: 1;
  justify-content: space-between;
  border-bottom: 1px solid $grey-003;
}

.addVehicleBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    min-width: unset;
    min-height: 40px;
    width: auto;
    padding: 7px 24px;
    border-radius: 3px;
    border: none;

    &:not(.button--cancel) {
      @include theme(
        (
          background-color: bodyMain
        )
      );
      color: $white;
      &:hover {
        @include themeDarken(
          (
            background-color: bodyMain
          )
        );
      }
      &:active {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
      }
      @media screen and (max-width: $mobile-end) {
        margin-bottom: 16px;
        padding-top: 12px 0px;
      }
    }
  }
}

.vehiclesNotFound {
  font-size: 1.8rem;
  font-weight: 600;
  color: #5e6977;
  padding: 20px 10px;
}

.filterAndSearchBarContainer {
  position: relative;
  display: flex;
  margin-bottom: 6rem;
}

.resetTag {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 3px 8px;
  bottom: -35px;
  background: #30364e;
  color: white;
  border-radius: 50px;
  gap: 4px;
  cursor: pointer;
}
.resetTagCross {
  font-size: 12px;
}
