.collapsibleCardInformation__header {
  overflow: hidden;
  cursor: pointer;
  border-bottom: 1px #e3e3e3 solid;
  padding: 5px 0;
}

.collapsibleCardInformation__headerValue {
  float: right;
  overflow: hidden;
  font-size: 1.4rem;
  font-weight: 600;
  padding-right: 10px;
}

.collapsibleCardInformation__icon {
  width: 20px;
  height: 20px;
  float: left;
  margin: 0 10px 0 20px;
  font-size: 2rem;
}

.collapsibleCardInformation__title {
  display: inline;
  font-size: 1.4rem;
  font-weight: 600;
}

.collapsibleCardInformation__content--closed {
  display: none;
}

.collapsibleCardInformation__content--open {
  display: block;
  margin: 0 0 0 25px;
  padding: 10px 5px 10px 0;
  font-size: 1.4rem;
}

.collapsibleCardInformation__contentTable {
  display: block;
  margin: 0 0 60px 0;
}

.collapsibleCardInformation__warning {
  font-size: 11px;
  display: inline-block;
  color: #f4ba52;
  border: 1px solid #f4ba52;
  border-radius: 4px;
  padding: 1px 3px 2px 3px;
  margin-right: 10px;
  vertical-align: top;
}
