.lenderStatusLarge {
  position: relative;
}

.lenderStatusLarge__applicationStatus {
  border-radius: 4px;
  min-height: 64px;
  padding: 10px 20px 10px 44px;
  display: inline-flex;
  align-items: center;
  color: #ffffff;
  background-color: #888888;
  border-style: solid;
  border-width: 1px;
  position: relative;
  overflow: hidden;
}

.lenderStatusLarge__high {
  color: #6bc348;
  border-color: #6bc348;
  background-color: #fff;
}

.lenderStatusLarge__medium {
  color: #f4ba52;
  border-color: #f4ba52;
  background-color: #fff;
}

.lenderStatusLarge__low {
  color: #e66c6c;
  border-color: #e66c6c;
  background-color: #fff;
}

.lenderStatusLarge__listIcon {
  position: absolute;
  top: 50%;
  left: 10px;
  font-size: 40px;
  transform: translateY(-50%);
}

.lenderStatusLarge__iconText {
  margin: 0 0 0 10px;
}

.lenderStatusLarge__iconTop {
  display: block;
  height: 22px;
  line-height: 20px;
  font-size: 1.4rem;
}

.lenderStatusLarge__iconStatus {
  display: block;
  line-height: 20px;
  font-size: 2rem;
  font-weight: 700;
}

.progress {
  background: linear-gradient(
    90deg,
    rgba(244, 186, 82, 0.2),
    rgba(244, 186, 82, 0.2) 50%,
    rgba(236, 240, 241, 0.2) 50%,
    rgba(236, 240, 241, 0.2)
  );
  background-size: 200% 100%;
  animation: progressBar 7s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

@keyframes progressBar {
  0% {
    background-position: 100% 0;
  }
  25% {
    background-position: 75% 0;
  }
  50% {
    background-position: 50% 0;
  }
  75% {
    background-position: 25% 0;
  }
  100% {
    background-position: 0 0;
  }
}

