.alertCardWrapper {
  margin: 15px 0;
}

.questionTitle {
  margin-bottom: 0;
  font-weight: bold;
  color: #4a4a4a;
}

.questionContainer {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 20px;
  }
}
