.lenderStatus {
  font-weight: bold;
  font-size: 1.2rem;
  min-width: 120px;
  max-width: 120px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  padding: 2px 5px;
}

.lenderStatus__high {
  color: #6bc348;
  border-color: #6bc348;
}

.lenderStatus__medium {
  color: #f4ba52;
  border-color: #f4ba52;
}

.lenderStatus__low {
  color: #e66c6c;
  border-color: #e66c6c;
}
