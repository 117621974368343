.reproposeToSameLenderModal__title {
  font-size: 1.4rem;
  font-weight: bold;
  text-decoration: underline;
  margin: 0 0 40px 0;
  @media (max-width: 500px) {
    margin: 40px 0;
  }
}

.reproposeToSameLenderModal__text {
  margin: 0 0 40px 0;
}

.reproposeToSameLenderModal__buttons {
  overflow: hidden;
}

.reproposeToSameLenderModal__proceed {
  float: right;
  margin: 0 0 0 15px;
  @media (max-width: 650px) {
    width: 100%;
    float: none;
    margin: 0 0 10px 0;
  }
}

.reproposeToSameLenderModal__edit {
  float: right;
  width: 220px;
  @media (max-width: 650px) {
    width: 100%;
    float: none;
    margin: 0 0 10px 0;
  }
}

.reproposeToSameLenderModal__cancel {
  float: left;
  @media (max-width: 650px) {
    width: 100%;
    float: none;
    margin: 0 0 10px 0;
  }
}
