.editProductCommissionsForm__modalHeader {
  text-align: left;
  font-weight: 700;
  font-size: 1.8rem;
  color: #000;
}

.editProductCommissionsForm__divider {
  background: #d4d4d4;
  width: calc(100% - 20px);
  height: 1px;
  margin: 20px 10px;
}

.editProductCommissionsForm__formFooter {
  overflow: hidden;
}

.editProductCommissionsForm__applyButton {
  float: right;
  width: 120px;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.editProductCommissionsForm__cancelButton {
  float: left;
  width: 120px;

  @media screen and (max-width: 500px) {
    width: 100%;
    clear: both;
    margin: 10px 0 0 0;
  }
}
