.inputTwo {
  width: 100%;
  display: table;
  vertical-align: middle;
  height: 40px;
  border-collapse: separate;
  position: relative;
  z-index: 10;
}

.inputTwo__prefixWrapper {
  display: table-cell;
  width: 0;
  vertical-align: middle;
}

.inputTwo__prefix {
  height: 38px;
  border-right: 1px solid #e4ebef;
  border-radius: 4px 0px 0px 4px;
  background-color: #f9fafc;
  position: absolute;
  left: 1px;
  top: 1px;
  padding: 0 10px;
  text-align: center;
  vertical-align: middle;
  line-height: 38px;
  font-size: 1.96rem;
}

.inputTwo__textPrefix {
  font-size: 1.4rem;
  left: 1px;
}

.inputTwo__input {
  display: table-cell;
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 10px;
  margin: 0;
  border: 1px solid #e4ebef;
  border-radius: 4px;
  background-color: #fff;
  font-size: 1.4rem;
  outline: none;
  background-position: right;
  background-repeat: no-repeat;

  &:hover {
    border: 1px solid #999999;
  }

  &:focus {
    border: 1px solid #055bd4;
  }
}

.inputTwo__inputSuffix {
  display: table-cell;
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  float: left;
  height: 40px;
  padding: 10px;
  margin: 0;
  border: 1px solid #e4ebef;
  border-radius: 4px;
  background-color: #fff;
  font-size: 1.4rem;
  outline: none;
  background-position: right;
  background-repeat: no-repeat;

  &:hover {
    border: 1px solid #999999;
  }

  &:focus {
    border: 1px solid #055bd4;
  }
}

.inputTwo__inputWithPrefix {
  display: table-cell;
  overflow: visible;
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 10px 10px 10px 45px;
  margin: 0;
  border: 1px solid #e4ebef;
  border-radius: 4px;
  background-color: #fff;
  font-size: 1.4rem;
  outline: none;
  background-position: right;
  background-repeat: no-repeat;

  &:hover {
    border: 1px solid #999999;
  }

  &:focus {
    border: 1px solid #055bd4;
  }
}

.inputTwo__suffix {
  height: 38px;
  border-left: 1px solid #e4ebef;
  border-radius: 0 4px 4px 0;
  background-color: #f9fafc;
  position: absolute;
  right: 3px;
  top: 1px;
  padding: 0 10px;
  text-align: center;
  vertical-align: middle;
  line-height: 36px;
}

.inputTwo__suffixFontSizeLarge {
  font-size: 1.96rem;
}

.inputTwo__suffixFontSizeSmall {
  font-size: 1.2rem;
}

.inputTwo__suffixWrapper {
  display: table-cell;
  width: 1%;
  vertical-align: middle;
}

.inputTwo__invalid {
  border: 1px solid #e66c6c;

  &:focus {
    border: 1px solid #055bd4;
  }
}

.inputTwo__disabled {
  background-color: #eaeaea;
}
