$breakpoint-mobileSm: 400px;
$breakpoint-mobileXl: 600px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

.VehicleDetailsSidebar {
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  @media (max-width: $breakpoint-tablet) {
    flex-direction: row;
  }
  @media (max-width: $breakpoint-mobileXl) {
    flex-direction: column;
  }
}

.VehicleDetailsSidebar__image {
  width: 100%;
  height: 100%;
  @media (max-width: $breakpoint-tablet) {
    width: calc((100% - 20px) * 0.65);
    margin: 0 20px 0 0;
  }
  @media (max-width: $breakpoint-mobileXl) {
    width: 100%;
  }
}

.VehicleDetailsSidebar__vehicleDetails {
  width: 100%;
  @media (max-width: $breakpoint-tablet) {
    width: calc((100% - 20px) * 0.35);
    text-align: right;
  }
  @media (max-width: $breakpoint-mobileXl) {
    width: 100%;
    text-align: left;
  }
}

.VehicleDetailsSidebar__vehicleInfo {
  display: flex;
  flex-direction: column;
  margin: 20px 0 0;
  @media (max-width: $breakpoint-tablet) {
    margin: 0;
  }
  @media (max-width: $breakpoint-mobileXl) {
    margin: 20px 0 0;
  }
}

.VehicleDetailsSidebar__vehicleMake {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}
.VehicleDetailsSidebar__vehicleDerivative {
  color: #464646;
  font-size: 14px;
}

.VehicleDetailsSidebar__vehicleReg {
  display: flex;
  justify-content: flex-start;
  .dashboardVehicle {
    margin: 0;
  }
  @media (max-width: $breakpoint-tablet) {
    justify-content: flex-end;
  }
  @media (max-width: $breakpoint-mobileXl) {
    justify-content: flex-start;
  }
}

.VehicleDetailsSidebar__vehiclePricing {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 0;
  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }
  @media (max-width: $breakpoint-mobileXl) {
    flex-direction: row;
  }
}

.VehicleDetailsSidebar__vehiclePrice--strikeout {
  text-decoration: line-through;
}

.VehicleDetailsSidebar__vehicleOfferPrice {
  margin: 0 0 0 10px;
  color: #64bc46;
  @media (max-width: $breakpoint-tablet) {
    margin: 0;
  }
  @media (max-width: $breakpoint-mobileXl) {
    margin: 0 0 0 10px;
  }
}

// override https://localhost:3000/dealer/platform/d/[ID]/stock/create
.VehicleDetailsSidebar--stockUploadStyles {
  @media (min-width: 419px) and (max-width: 919px) {
    flex-direction: row;

    .VehicleDetailsSidebar__image {
      max-width: 230px;
      margin-right: 24px;
    }
    .VehicleDetailsSidebar__vehicleDetails {
      text-align: left;
    }
    .VehicleDetailsSidebar__vehicleReg {
      justify-content: flex-start;
    }
    .VehicleDetailsSidebar__vehicleInfo {
      margin-top: 0;
    }
  }
}
