.fileUploadInput__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
}

.fileUploadInput__fileUploader {
  border: 2px dashed #e6e6e6;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 8px;
  text-align: center;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.fileUploadInput__prevFileOuter {
  max-width: 500px;
  margin: 0 auto;
}

.fileUploadInput__uploader {
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
  background-color: #f7f7f7;
  color: #4a4a4a;
  font-size: 1.4rem;
}

.fileUploadInput__uploaderInput {
  height: 100%;
  padding-top: 20px;
}

.fileUploadInput__uploaderInner {
  max-width: 250px;
  margin: 0 auto;
}

.fileUploadInput__ready {
  font-size: 14px;
}

.fileUploadInput__readyCard {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 6px 20px rgba(188, 194, 204, 0.34);
  font-size: 13px;
  font-weight: bold;
}

.fileUploadInput__buttonOuter {
  display: flex;
  gap: 5px;
}

.fileUploadInput__removeButton {
  outline: none;
  border: 1px solid #e4ebef;
  border-radius: 2px;
  color: #000;
  background-color: #f9fafc;
  cursor: pointer;
  font-size: 20px;
  padding: 2px;

  &:hover {
    border-color: darken(#e4ebef, 10%);
    transition: border-color 0.1s ease-in-out;
  }
}

.fileUploadInput__warning {
  padding: 10px 0 20px 0;
  color: #e66c6c;
  font-weight: bold;
}

.fileUploadInput__buttonInner {
  display: flex;
  align-items: center;
}

.fileUploadInput__icon {
  font-size: 20px;
}

.fileUploadInput__explainerText,
.fileUploadInput__maxFileSize {
  margin: 30px auto;
  max-width: 500px;
  text-align: justify;
}
