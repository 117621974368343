.miniPanelHeader__title {
  font-family: 'Montserrat', 'Open Sans', Arial, sans-serif;
  position: relative;
  border-bottom: none;
  margin: 0;
  border-radius: 12px 12px 0 0;
  font-size: 1.6rem;
  line-height: 39px;
  overflow: hidden;
  padding: 10px 20px;
  background: #f5f7fa;
  width: 100%;
  @media print {
    border-radius: unset;
  }
}

.miniPanelHeader__childWrapper {
  position: absolute;
  top: 8px;
  right: 10px;
  line-height: 0;
  @media (max-width: 768px) {
    position: static;
    padding: 0 0 8px 0;
  }
}

.miniPanelHeader__oneChildWrapper {
  position: absolute;
  top: 8px;
  right: 10px;
  line-height: 0;
  @media (max-width: 768px) {
    position: absolute;
  }
}
