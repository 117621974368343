.vrmLookup {
  display: flex;
}

.vrmLookup__input {
  width: 50%;
  padding-right: 5px;
}

.vrmLookup__button {
  flex: 1;
  padding-left: 5px;
}

.vrmLookup__input--noButton {
  width: 100%;
  margin-right: 0px;
}

