@import '../../styles/utility/browsers.scss';
.pushDealConfirm__container {
  padding: 20px 60px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    padding: 10px;
  }
}

.pushDealConfirm__vehicleContainer {
  width: 70%;
  border: solid #e2e2e1 1px;
  margin: 20px auto 0px;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    width: 80%;
  }
  @media screen and (max-width: 900px) {
    width: 90%;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    border: 0px;
    margin: 0px;
  }
}

.pushDealConfirm__customerInfoContainer {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid #e2e2e1;
  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;
    padding-bottom: 20px;
    margin-bottom: 7px;
    width: 100%;
  }
}

.pushDealConfirm__sectionTitle {
  font-weight: 600;
  font-size: 18px;
  color: #464646;
}

.pushDealConfirm__subtitle {
  font-weight: 500;
  color: #707070;
}

.pushDealConfirm__vapText {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  color: #707070;

  > div:nth-child(2) {
    font-size: 0.75em;
  }
}

.pushDealConfirm__customerInfo {
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

.pushDealConfirm__customerName {
  word-break: break-all;
  font-weight: 700;
  font-size: 20px;
  color: #464646;
}

.pushDealConfirm__customerEmail {
  word-break: break-all;
  font-size: 20px;
  font-weight: 500;
  color: #5f5f5f;
  padding-bottom: 10px;
}

.pushDealConfirm__editCustomerButton {
  @media screen and (max-width: 600px) {
    margin: auto;
  }
}

.pushDealConfirm__sendingTo {
  font-size: 16px;
  font-weight: 500;
  color: #808080;
}

.pushDealConfirm__inner {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.pushDealConfirm__section {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  color: #464646;
  font-size: 15px;
  font-weight: 600;
  .pushDealConfirm__highlightedValue {
    color: #6bc348;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 425px) {
    border: none;
    padding: 5px;
  }
}

.pushDealConfirm__quotePreviewSection {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  color: #464646;
  font-size: 15px;
  gap: 20px;
  .pushDealConfirm__highlightedValue {
    color: #6bc348;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 425px) {
    border: none;
    padding: 0px;
  }
}

.pushDealConfirm__sectionDivide {
  width: 100%;
  margin: 10px;
  border-bottom: 1px solid #e2e2e1;
}

.pushDealConfirm__quoteSection {
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 500px) {
    margin: none;
    border: none;
  }
}

.pushDealConfirm__sendDealLoadingImage {
  width: 45%;
  margin: 0 auto;
  @include ie-old-only {
    max-height: 200px;
    // SVGs default to huge sized on ie
  }
}

.pushDealConfirm__sendDealConfirmationImage {
  @include ie-old-only {
    max-height: 170px;
    // SVGs default to huge sized on ie
  }
}
.pushDealConfirm__sendDealConfirmationImage {
  width: 25%;
  margin: 0 auto;
}

.pushDealConfirm__imageUpperText {
  font-size: 1.8rem;
  text-align: center;
  margin: 30px 0 3px 0;
}

.pushDealConfirm__imageLowerText {
  font-size: 1.4rem;
  text-align: center;
  margin: 3px 0 5px 0;
  color: #8f8e8e;
}

.pushDealConfirm__buttonContainer {
  margin: 0 auto;
  width: fit-content;
  padding: 20px 0 2px 0;
}

.pushDealConfirm__error {
  color: #ff6600;
  padding: 10px 20px;
  margin: 0 auto;
  text-align: center;
  font-size: 2rem;
}

.pushDealConfirm__imageContainer {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 600px;
}

.pushDealConfirm__checkbox {
  width: 70%;
  margin: 20px auto;
  border: solid #e2e2e1 1px;
  border-radius: 6px;
  padding: 10px 20px;

  @media screen and (max-width: 1024px) {
    width: 80%;
  }

  @media screen and (max-width: 900px) {
    width: 90%;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    border: 0px;
    margin: 0px;
    padding: 0;
  }
}

.pushDealConfirm__strikeout {
  text-decoration: line-through;
}

