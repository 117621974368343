@import '../../../styles/themes/themes.scss';

.fieldToggle {
  overflow: hidden;
  text-align: right;
  font-size: 1.2rem;
  font-weight: 600;
  color: #6bc348;
}

.fieldToggle__inner {
  float: right;
  cursor: pointer;
  @media (max-width: 700px) {
    margin-right: 15px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.fieldToggle__upArrow {
  width: 0;
  height: 0;
  float: left;
  margin: -5px 3px 0 0;

  border-top: solid 9px transparent;
  border-left: solid 9px transparent;
  border-right: solid 9px transparent;
  border-bottom: solid 9px;

  color: #6bc348;
}

.fieldToggle__downArrow {
  width: 0;
  height: 0;
  float: left;
  margin: 4px 3px -4px 0;

  border-top: solid 9px;
  border-left: solid 9px transparent;
  border-right: solid 9px transparent;
  border-bottom: solid 9px transparent;

  color: #6bc348;
}
