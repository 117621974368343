.resetPasswordSuccess__header {
  margin-top: 160px;
  font-size: 3rem;
  font-weight: 300;
  text-align: center;
}

.resetPasswordSuccess__text {
  color: #666666;
  text-align: center;
}

.resetPasswordSuccess__success {
  margin-top: 40px;
}
