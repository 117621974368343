.tooltip__content {
  background-color: #30364e;
  color: #fff;
  padding: 0.5rem;
  border-radius: 4px;
  font-family: sans-serif;
}

.metrics {
  padding: 1rem;
  max-width: 180px;
}

.tooltip__arrow {
  fill: #30364e;
}

.message {
  max-width: 20rem;
  text-align: center;
}
