.vehicleReservationItem {
  margin: 0 0 20px 0;
  border: solid 1px #e9e9e9;
  border-radius: 12px;
  font-size: 1.4rem;
  box-shadow: 0 2px 4px rgba(154, 192, 216, 0.16);
  transition: all 0.2s ease-out;
  transform: translateY(0);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(188, 194, 208, 0.4);
  }
}

.vehicleReservationItem__top {
  display: flex;
  flex: 1 0 auto;
  padding: 20px;
  border-bottom: solid 1px #edeeef;

  @media (max-width: 1100px) {
    flex-wrap: wrap;
  }
}

.vehicleReservationItem__vehicleDetails {
  display: flex;
  justify-content: flex-start;
  flex: 0 1 auto;
  width: calc((100% - 300px) / 2);
  min-width: 360px;

  @media (min-width: 1370px) {
    width: calc(100% - 220px - 300px);
  }

  @media (max-width: 1100px) {
    width: calc(100% - 300px);
    min-width: auto;
    flex: 1 1 auto;
  }

  @media (max-width: 700px) {
    width: 100%;
  }

  @media (max-width: 480px) {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
}

.vehicleReservationItem__paymentDetails {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  width: calc((100% - 300px) / 2);
  padding: 0 0 0 20px;

  @media (min-width: 1370px) {
    width: 220px;
  }

  @media (max-width: 1100px) {
    max-width: 270px;
    min-width: 220px;
  }

  @media (max-width: 880px) {
    width: 100%;
    max-width: none;
    min-width: none;
    justify-content: space-between;
    padding: 0;
    margin: 20px 0 0 0;
    align-items: center;
  }

  @media (max-width: 480px) {
    justify-content: center;
    margin: 30px 0;
  }
}

.vehicleReservationItem__buttonsOuter {
  width: 300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  @media (max-width: 1100px) {
    width: 100%;
    justify-content: center;
    margin: 20px 0 0 0;
  }

  @media (max-width: 700px) {
    justify-content: space-between;
  }

  @media (max-width: 400px) {
    margin: 0;
  }
}

.vehicleReservationItem__buttonsOuter--single {
  @media (max-width: 700px) {
    justify-content: center;
  }
}

.vehicleReservationItem__imageOuter {
  width: 130px;
  margin: 0 20px 0 0;

  @media (max-width: 480px) {
    margin: 0 0 20px 0;
  }
}

.vehicleReservationItem__vehicle {
  font-weight: 400;

  @media (max-width: 480px) {
    width: 100%;
  }
}

.vehicleReservationItem__makeModel {
  font-size: 1.6rem;
  font-weight: 400;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  margin: 0 0 8px 0;
}

.vehicleReservationItem__derivative {
  margin: 0 0 10px 0;
  font-size: 1.4rem;
  color: #646c76;
}

.vehicleReservationItem__price {
  font-size: 1.6rem;
  letter-spacing: 0.5px;
}

.vehicleReservationItem__paymentTitle {
  display: flex;
  justify-content: flex-start;
  color: #979797;
  font-weight: 600;
  @media (min-width: 1320px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    width: 100%;
    justify-content: center;
    margin: 0 0 10px 0;
  }
}

.vehicleReservationItem__paymentIcon {
  font-size: 1.8rem;
  margin: 0 5px 0 0;
}

.vehicleReservationItem__paymentInner {
  display: flex;
  justify-content: flex-start;

  @media (min-width: 1100px) {
    width: 100%;
  }

  @media (max-width: 1100px) and (min-width: 881px) {
    display: block;
  }
}

.vehicleReservationItem__paymentPrice {
  font-size: 2rem;
}

.vehicleReservationItem__paymentTagOuter {
  display: flex;
  height: 27px;
  justify-content: flex-start;
  position: relative;

  @media (max-width: 1100px) {
    height: auto;
  }
}

.vehicleReservationItem__button {
  width: fit-content;
  margin: 0 0 10px 0;
  max-width: 250px;

  @media (max-width: 1100px) {
    margin: 0 10px;
  }

  @media (max-width: 700px) {
    margin: 0 auto 10px auto;
  }

  @media (max-width: 400px) {
    width: 100%;
    margin: 0 0 10px 0;
  }
}

.vehicleReservationItem__refundError {
  width: 200px;
  text-align: center;
  color: red;

  @media (max-width: 1100px) {
    width: 100%;
    margin: 10px 0 0 0;
  }
}

.vehicleReservationItem__bottom {
  background-color: #f7fafc;
  padding: 20px 20px 20px 0;
  display: flex;
  border-radius: 0 0 12px 12px;

  @media (max-width: 900px) {
    flex-wrap: wrap;
  }

  @media (max-width: 480px) {
    padding: 20px;
  }
}

.vehicleReservationItem__bottomItem {
  border-right: solid 1px #e3e5e7;
  padding: 0 40px 0 20px;
  max-width: 25%;
  overflow: hidden;

  @media (min-width: 901px) {
    flex-basis: 280px;
    flex-grow: 1;
    max-width: 250px;
  }

  &:last-child {
    border-right: none;
  }

  @media (max-width: 1100px) {
    width: 25%;
  }

  @media (max-width: 900px) {
    max-width: none;
    width: 50%;
    border: none;

    &:nth-child(1) {
      margin: 0 0 10px 0;
    }

    &:nth-child(2) {
      margin: 0 0 10px 0;
    }
  }

  @media (max-width: 480px) {
    width: 100%;
    margin: 0 0 10px 0;
    display: flex;
    justify-content: space-between;
    padding: 0;
  }
}

.vehicleReservationItem__bottomTitle {
  font-size: 1.4rem;
  margin: 0 0 15px 0;

  @media (max-width: 900px) {
    margin: 0 0 5px 0;
  }
}

.vehicleReservationItem__bottomInfo {
  font-weight: 400;
  color: #000000;

  @media (max-width: 480px) {
    text-align: right;
    max-width: 70%;
  }
}

.vehicleReservationItem__customerName {
  @extend .vehicleReservationItem__bottomInfo;
  text-transform: capitalize;
}

.vehicleReservationItem__bottomLink {
  display: block;
  color: #000000;
  margin: 0 0 5px 0;
  text-decoration: none;
  word-wrap: break-word;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
