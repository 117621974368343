.applicationsListItem__outer {
  padding: 15px;
  :hover {
    cursor: pointer;
  }
}

.applicationsListItem__opacity {
  opacity: 0.5;
}

.applicationsListItem__dot {
  margin: 10px 10px 0 0;
  float: left;
  @media (max-width: 1000px) {
    margin: 30px 10px 0 0;
  }

  @media (max-width: 500px) {
    margin: 70px 10px 0 0;
  }
}

.applicationsListItem__logo {
  width: 100px;
  height: auto;
  margin: 0 auto;
}

.applicationsListItem__status {
  display: inline-block;
  margin: 0 auto;
}
