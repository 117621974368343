.settingsProductTableItem__row {
  font-size: 1.4rem;

  &:nth-child(even) {
    background: #fdfdfd;
  }
}

.settingsProductTableItem__all {
  padding: 5px 10px;
}

.settingsProductTableItem__checkbox {
  padding: 5px 10px;
  text-align: center;
}
