@import '../../styles/themes/themes.scss';

$cancelCol: #f55e5e;

.splitButtonOptionDefault {
  display: block;
  width: 100%;
  overflow: hidden;
  outline: 0;
  border-style: solid;
  border-width: 1px;
  font-size: 1.4rem;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  color: #fff;
  transition: background-color 0.3s ease-in-out, border-color 0.3s, color 0.3s;
  position: relative;
  height: 40px;
  line-height: 33px;
  // margin: 0;
  border-radius: 3px;
  @media screen and (max-width: 768px) {
    font-size: 1.1rem;
  }

  padding: 2px 52px 2px 12px;
}

.splitButtonOptionDefault--primary {
  @include theme(
    (
      background-color: bodyMain,
      border-color: bodyMain
    )
  );

  :focus {
    @include themeDarken(
      (
        border-color: bodyMain
      )
    );
  }
}

.button--cancel {
  background: none;
  border: 1px solid $cancelCol;
  color: $cancelCol;
  text-align: center;

  &:hover {
    background-color: darken($cancelCol, 15%);
    border-color: darken($cancelCol, 15%);
    color: #ffffff;
  }

  .button__spinner {
    border-color: $cancelCol;
  }
}

.splitButtonOptionDefault--cancel {
  background: none;
  border: 1px solid $cancelCol;
  color: $cancelCol;
  white-space: nowrap;
  &:hover {
    background-color: darken($cancelCol, 15%);
    border-color: darken($cancelCol, 15%);
    color: #ffffff;
  }
}

.splitButtonOptionDefault--primaryHover {
  @include themeDarken(
    (
      background-color: bodyMain,
      border-color: bodyMain
    )
  );
}

.splitButtonOptionDefault__disabled {
  border-color: #989898 !important;
  background-color: #989898 !important;
  color: #ffffff !important;
  cursor: auto;
  pointer-events: none;
}

.splitButtonOptionDefault__stretch {
  width: 100%;
  line-height: 33px;
}

.splitButtonOptionDefault__noStretch {
  padding: 0px 30px 0px 30px;
}

.splitButtonOptionDefault__label {
  display: inline-block;
  transform: scale(1);
  transition: transform 0.3s;
  opacity: 1;
  background: transparent;
}

.splitButtonOptionDefault__labelLoading {
  color: #f60;
  transform: scale(0.5);
  opacity: 0;
}

.splitButtonOptionDefault__error {
  background-color: transparent;
  border-color: $cancelCol;
  color: $cancelCol;
}

.splitButtonOptionDefault__done {
  @include theme(
    (
      background-color: bodyMain,
      border-color: bodyMain
    )
  );
}

.splitButtonOptionDefault__loadings {
  @include theme(
    (
      background-color: bodyMain,
      border-color: bodyMain
    )
  );
}

.splitButtonOptionDefault__hideLabel {
  opacity: 0;
}

.splitButtonOptionDefault__tick {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  background-size: 30px 30px;
  background-position: center center;
  background-repeat: no-repeat;
  font-size: 24px;
  padding-top: 5px;
}

.splitButtonOptionDefault__errorContainer {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
}

.splitButtonOptionDefault__errorMessage {
  display: inline-block;
  padding: 0;
  animation: slideUp 0.3s;
  font-weight: bold;
  color: #ffffff;
}

.splitButtonOptionDefault__spinner {
  opacity: 1;
  transform: scale(1);
  transition: transform 0.3s;
  position: absolute;
  top: 2px;
  left: 50%;
  margin-top: 5px;
  margin-left: -8px;
  display: block;
  width: 20px;
  height: 20px;
  background-image: url('../../styles/icons/svg-loading-icon.svg');
  background-repeat: no-repeat;
}

.splitButtonOptionDefault__spinnerLoading {
  opacity: 1;
  transform: scale(1);
}
