.radioGroup {
  display: flex;
  gap: 10px;
}

.radioGroup--inline {
  flex-direction: row;
}
.radioGroup--stacked {
  flex-direction: column;
}
