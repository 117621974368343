.quickQuoteFindVehicle {
  width: 85%;
  margin: 0 auto;
}

.quickQuoteFindVehicle__radio {
  display: flex;
  padding: 2rem 0;
  gap: 2rem;
  font-size: 1.2rem;
}

.quickQuoteFindVehicle__VRMLookup {
  display: flex;
  gap: 2rem;
}

.quickQuoteFindVehicle__vrmError {
  color: #64bc46;
  font-size: 1.8rem;
  font-weight: 700;
  padding-bottom: 1rem;
}

.quickQuoteFindVehicle__vrmMandatory {
  padding: 5px 0 0 0;
  color: #e66c6c;
  font-weight: 600;
  font-size: 13px;
}
