.input__outer {
  position: relative;
}

.input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  color: #000;
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #e4ebef;
  background-color: white;
  border-radius: 4px;
  font-size: 1.4rem;
  font-weight: 400;
  outline: none;
  background-position: right;
  background-repeat: no-repeat;
  text-overflow: ellipsis;
  transition: 0.3s border-color;

  &:hover {
    border-color: #999999;
    outline: none;
  }

  &:focus {
    border-color: #055bd4;
    outline: none;
  }

  &.is-invalid {
    border-color: #e66c6c;

    &:focus {
      border-color: #055bd4;
    }
  }

  &.is-disabled {
    background-color: #eaeaea;
  }
}

.input--hasPrefix {
  padding-left: 50px;
}

.input--hasSuffix {
  padding-right: 40px;
  text-overflow: ellipsis;
}

.input--hasError {
  border: 1px solid #e74c3c;
  color: #e74c3c;

  &:focus {
    color: #e74c3c;
    border-color: #e74c3c;
  }
}

.input--small {
  height: 30px;
  line-height: 30px;
  font-size: 1.2rem;
  border-width: 1px;
  padding: 0 10px 0 10px;

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  }

  &:focus {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  }
}

.input--medium {
  height: 40px;
  font-size: 1.4rem;
  border-width: 1px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  }

  &:focus {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  }
}

.input--large {
  height: 40px;
  font-size: 1.6rem;
}

.input__prefixIcon {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 24px;
}
.input__suffix {
  position: absolute;
  right: 2px;
  top: 2px;
  height: 36px;
  border-radius: 0 2px 2px 0;
  padding: 0 10px;
  text-align: center;
  vertical-align: middle;
  line-height: 36px;
  font-size: 1.4rem;
  color: #666666;
}

.input__suffixInvalid {
  background-color: #ff613d;
  color: #ffffff;
}

.input__suffixIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  &:hover {
    color: #666666;
    cursor: pointer;
  }
}

.input__prefixIcon--small {
  font-size: 15px;
  padding: 0 0 0 5px;
}

.input__prefixIcon--medium {
  font-size: 22px;
  padding: 2px 0 0 5px;
}

.input__prefixIcon--large {
  font-size: 20px;
  padding: 8px 0 0 3px;
}

.input__suffixIcon--small {
  font-size: 15px;
  padding: 0 5px 0 0;
}

.input__suffixIcon--medium {
  font-size: 22px;
  padding: 2px 3px 0 0;
}

.input__suffixIcon--large {
  font-size: 20px;
  padding: 8px 5px 0 0;
}

.input__iconBlue {
  color: #055bd4;
  transition: color 0.3s;
}

.input--postcode {
  text-transform: uppercase;

  &::placeholder {
    text-transform: none;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
