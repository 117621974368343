.eligibilityModalGaugeSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 24px;
  font-size: 1.2rem;
  color: #4a4a4a;

  &__upper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;

    @media (min-width: 600px) {
      flex-direction: row;
      justify-content: center;
      gap: 36px;
    }

    &__gaugeWrapper {
      align-self: flex-start;
    }

    &__initialText {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      text-align: left;
    }
  }
  &__decisionText {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 700;
  }

  // .bold {
  //   font-weight: 700;
  // }

  // p {
  //   font-size: 1.4rem;
  //   margin: 0 0 10px 0;
  // }
}
