.addCorporateForm {
  display: flex;
  flex-wrap: wrap;
}

.addCorporateForm__personalDetails {
  flex: 0 0 50%;

  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
  }
}

.addCorporateForm__addressDetails {
  flex: 0 0 50%;

  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
  }
}

.addCorporateForm__header {
  font-size: 1.6rem;
  font-weight: 600;
  padding: 15px 80px;
  margin: 0;
  border-bottom: 1px solid #e1e1e1;
  color: #000;
  text-align: left;
}

.addCorporateForm__personalDetailsHeader {
}

.addCorporateForm__fields {
  padding: 45px 80px 15px 80px;
  border-right: 1px solid #e1e1e1;

  @media screen and (max-width: 768px) {
    border-right: none;
  }
}

.addCorporateForm__footer {
  flex: 0 0 100%;
}
