.miniPanelToolbar__toolbar {
  overflow: auto;
  background-color: #eaeef4;
  border-bottom: none;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.miniPanelToolbar__notPadded {
  padding: 0;
}

.miniPanelToolbar__padded {
  padding: 10px 10px;
}
