.visCard {
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease-out;
  padding: 20px;
  color: #5e6977;
  transform: translatey(0);
  box-shadow: 0 3px 3px rgba(188, 194, 208, 0.5), 0 -1px 3px rgba(188, 194, 208, 0.5);

  &:hover {
    transform: translatey(-1px);
    box-shadow: 0 14px 30px rgba(188, 194, 208, 0.6), 0 -1px 3px rgba(188, 194, 208, 0.5);
  }

  @media (max-width: 800px) {
    flex-wrap: wrap;
    position: relative;
  }
}

.visCard__buttonWrap {
  width: 95px;
  height: 30px;
}

.visCard__title {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  align-items: flex-start;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 17px;

  @media (max-width: 800px) {
    font-size: 15px;
  }
}

.visCard__derivative {
  margin-bottom: 10px;
}

.visCard__select {
  display: flex;
  justify-content: space-between;
  order: 1;
  font-size: 12px;
  color: #6bc348;
  text-transform: none;
  // padding-right: 20px;
}

.visCard__mobile {
  display: none;
  @media (max-width: 800px) {
    display: flex;
    width: 60px;
    justify-content: space-between;
    font-size: 12px;
    color: #6bc348;
    align-items: flex-start;
    text-transform: none;
  }
}

.visCard__details {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px 0 0;
  margin: 0px;
  border-top: 1px solid rgb(219, 219, 219);
  list-style-type: none;
  font-size: 12px;

  > li:not(:last-child):after {
    content: '';
    margin: 0 10px;
    background-color: rgb(219, 219, 219);
    width: 1px;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }

  @media (max-width: 800px) {
    font-size: 11px;
  }
}
