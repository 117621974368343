.soldFinance {
  border-top: solid #e0e0e0 1px;
  padding: 20px 0 0 0;
  height: 100%;
}

.soldFinance__formLeft,
.soldFinance__formRight {
  .mobxFieldGroup__label--small {
    flex: unset;
  }
}
.soldFinance__left {
  padding: 0 20px;
}

.soldFinance__right {
  padding: 0 20px;
}

.soldFinance__infoWarning {
  padding: 0 0 20px 0;
  border-bottom: solid 1px #e0e0e0;
}

.soldFinance__vehicleDetails {
  border-bottom: solid 1px #e0e0e0;
}

.soldFinance__dateFields {
  padding-top: 20px;
  @media (max-width: 650px) {
    .mobxFieldGroup {
      margin-bottom: 0;
    }
  }
}

.soldFinance__asterisk {
  font-size: 1.2rem;
  margin: 0 0 10px 0;
}

.soldFinance__divider {
  width: 100%;
  border-bottom: solid #e0e0e0 1px;
  margin-bottom: 20px;
}

.soldFinance__mediaDivider {
  @media (max-width: 1000px) {
    width: 100%;
    border-bottom: solid #e0e0e0 1px;
    margin: 10px auto 20px;
  }
}

.soldFinance__bottom {
  clear: both;
}

.soldFinance__checkbox {
  text-align: center;
}

.soldFinance__buttons {
  clear: both;
  margin-bottom: 40px;
}

.soldFinance__proceedButton {
  float: right;
  width: 150px;

  @media (max-width: 500px) {
    float: none;
    width: 100%;
    margin: 0 0 10px 0;
  }
}

.soldFinance__cancelButton {
  float: left;
  width: 150px;

  @media (max-width: 500px) {
    float: none;
    width: 100%;
  }
}

.soldFinance__formLeft {
  float: left;
  width: 50%;
  border-right: solid 1px #e0e0e0;

  @media (max-width: 1000px) {
    float: none;
    width: 100%;
    border-right: none;
  }
}

.soldFinance__formRight {
  float: right;
  width: 50%;
  @media (max-width: 1000px) {
    float: none;
    width: 100%;
  }
}

.soldFinance__vapsButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.soldFinance__vapsButton {
  min-width: 200px;
  width: fit-content;
  margin: 10px 0 30px 0;

  @media (max-width: 340px) {
    width: 100%;
    margin: 10px 0 30px auto;
  }
}

.soldFinance__qualifying {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 30px 0;
}

