.buttonInfoIcon {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 0 0 4px;
  border: none;
  outline: none;
  background-color: transparent;
  background-image: url('../../../styles/icons/help-icon.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  flex: 0 0 auto;
  cursor: pointer;
  @media (max-width: 650px) {
    float: right;
    margin: 0;
  }
}
