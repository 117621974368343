.quotesListTableItem__logo {
  padding: 10px;
}

.quotesListTableItem__funderLogo {
  max-width: 200px;
  padding-top: 7px;
}

.quotesListTableItem__videoOuter {
  margin: 0 0 0 5px;
}

.quotesListTableItem__outer {
  & > :first-child {
    margin-top: 0;
  }
}

.quotesListTableItem {
  width: 100%;
  font-size: 14px;
  text-align: left;
  overflow: hidden;
  margin: 30px 0;
  border: 1px solid #e9e9e9;
  position: relative;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(154, 192, 216, 0.16);
  transition: all 0.2s ease-out;
  transform: translateY(0);
  &:hover,
  &:focus {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(188, 194, 208, 0.4);
  }

  &:first-child {
    margin-top: 0;
  }
}

.quotesListTableItem__alerts {
  margin: 10px auto 10px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.quotesListTableItem__selected {
  border: 1px solid #6bc348;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(188, 194, 208, 0.4);
}

.quotesListTableItem__quoteInfo--apr {
  max-width: 75px !important;
}

.quotesListTableItem__quoteInfo--col {
  @media (min-width: 1166px) {
    max-width: 120px;
  }
}
.quotesListTableItem__quoteInfo--schemes {
  min-width: 165px;
  margin-top: 12px;
}
.quotesListTableItem__combinedQuoteMessage {
  min-width: 165px;
}

.quotesListTableItem__top {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 0 10px;
}

.quotesListTableItem__checkbox {
  padding: 10px 0 0 0;
}

.quotesListTableItem__quoteInfo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px 10px 15px 10px;
  margin-bottom: 10px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-bottom: 25px;
  }
}

.quotesListTableItem__quoteInfo--col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 100%;
}

.quotesListTableItem__quoteInfo--product {
  position: relative;
  margin-right: 5px;
}

.quotesListTableItem__quoteInfo--productName {
  line-height: 16px;
  align-self: flex-start;

  @media (max-width: 1290px) {
    width: 120px;
  }
}

.quotesListTableItem__quoteInfo--infoButton {
  padding: 0 0 0 5px;
}

.quotesListTableItem__quoteInfo--eligibility {
  text-align: center;
}

.quotesListTableItem__quoteInfo--header {
  color: #9e9e9e;
  font-weight: 600;
  padding: 0 0 5px 0;
  display: flex;
  align-self: auto;
}

.quotesListTableItem__quoteInfo--figure {
  font-weight: 700;
  flex: 0 1;
  height: 30px;
  flex-wrap: wrap;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    max-width: 140px;
    transform: translateY(-10px);
  }
}

.quotesListTableItem__quoteInfo--erroredName {
  @extend .quotesListTableItem__quoteInfo--figure;
  transform: translateY(0);
}

.quotesListTableItem__quoteInfo--spacer {
  border-right: 2px solid #e4ebef;
  height: 100%;
  min-height: 40px;
  width: 1px;
  margin: 10px 10px 5px 10px;
}

.quotesListTableItem__quoteInfo--quoteButton {
  padding: 10px 0 0 20px;
  min-width: 168px;
  position: relative;
}
.quotesListTableItem__quoteSmallPrint {
  background-color: #f8f9fc;
  padding: 0px 20px 0 20px;
}

.quotesListTableItem__smallPrintButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 460px) {
    flex-direction: column;
    justify-content: center;
  }
}

.quotesListTableItem__quoteSmallPrint--footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 5px;
}

.quotesListTableItem__erroredQuote {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
}

.quotesListTableItem__errorQuoteInfo--messages {
  min-width: 65%;
  margin: 0 0 0 auto;
}

.quotesListTableItem__errorQuote--funderLogo {
  padding: 15px 0 0 0;
}

.quotesListTableItem__errorQuoteInfo--product {
  padding: 10px 30px 0 10px;
}

/* ANIMATIONS */
.quotesListTableItem__quoteSmallPrint-animate-enter {
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.3s ease-in-out;
}

.quotesListTableItem__quoteSmallPrint-animate-enter-active {
  transform: scaleY(1);
  transform-origin: top;
}

.quotesListTableItem__quoteSmallPrint-animate-exit {
  transform: scaleY(1);
  transform-origin: top;
  transition: transform 0.3s ease-in-out;
}

.quotesListTableItem__quoteSmallPrint-animate-exit-active {
  transform: scaleY(0);
  transform-origin: top;
}

.quotesListTableItem__rbp {
  margin: 0 -5px 10px -5px;
}

.quotesListTableItem__warningOuter {
  display: flex;
  margin: 5px -5px 5px -5px;
  color: #f55e5e;
  padding: 3px 0;
  align-items: center;
  cursor: pointer;
}

.quotesListTableItem__warning {
  width: 100%;
}

.quotesListTableItem__warningIcon {
  font-size: 18px;
  padding-right: 5px;
}

.quotesListTableItem__modalOuter {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.quotesListTableItem__modalTop {
  color: #f55e5e;
  font-size: 18px;
}

.quotesListTableItem__modalBottom {
  margin-top: 30px;
}

.quotesListTableItem__smallPrintButtonWrap {
  min-width: 125px;
  padding: 0 5px;
}

.quotesListTableItem__smallPrintButtonWrap--withWarning {
  padding: 0 20px;
  min-width: 155px;
}

.quotesListTableItem__quoteWarningWrap {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 18px;
  padding-left: 12px;
}
