@import './themes/themes.scss';

$text-color: #4a4a4a;
$accent-color: #4078a0;
$light-background: #0080800d;
$shadow-color: rgba(188, 194, 204, 0.32);
$border: 1px solid #e4ebef;
$gray-fa: #fafafa;
$info-blue: #006ed8;

$small-shadow: 0.5px 1px 1px $shadow-color;
$medium-shadow: 1px 2px 2px $shadow-color, 2px 4px 4px $shadow-color, 3px 6px 6px $shadow-color;
$large-shadow: 1px 2px 2px $shadow-color, 2px 4px 4px $shadow-color, 4px 8px 8px $shadow-color,
  8px 16px 16px $shadow-color, 16px 32px 32px $shadow-color;
