.vulnerableCustomers {
  border-top: 1px solid #e4ebef;

  :global(.collapsiblePanel__heading),
  :global(.collapsiblePanel__content) {
    padding: 0;
  }
}
.vulnerableCustomersTitle {
  font-weight: bold;
  padding-top: 20px;
}

.vulnerableCustomersServices {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 10px;
}

.vulnerableCustomersService {
  flex: 0 1 calc(50% - 10px);
  border: 1px solid #c1c1c1;
  border-radius: 10px;
  padding: 20px;
}

@media (max-width: 700px) {
  .vulnerableCustomersService {
    flex: 1 1 100%;
  }
}

.vulnerableCustomersService__title {
  margin-top: 0;
  font-weight: bold;
}
.vulnerableCustomers_serviceSection {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li {
    line-height: 2.5em;

    img {
      width: 20px;
      height: 20px;
      position: relative;
      top: 5px;
      margin-right: 10px;
    }
  }
}
