.panel {
  @media (max-width: 500px) {
    padding: 0px;
  }
}

.page {
  @media screen and (max-width: 819px) {
    padding-left: 4px;
    padding-right: 4px;
  }
}

.headerWrapper {
  position: relative;
  margin: 0;
  background-color: white;
  border-radius: 14px 14px 0 0;
}

.headerTitle {
  font-family: 'Montserrat', 'Open Sans', Arial, sans-serif;
  display: flex;
  font-size: 1.6rem;
  color: #4a4a4a;
  line-height: 65px;
  border-bottom: 1px solid #e4ebef;
  justify-content: flex-end;
}

.confirmationModal {
  padding-left: 15px;
  min-height: 50px;
}

.headerContent {
  width: 100%;
  line-height: 28px;
  min-height: 88px;
  padding: 12px 0;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: normal;
    margin-bottom: 15px;
    padding: 12px;
  }
}

.selectInput {
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  height: 40px;
  padding: 0 10px;
  border: 1px solid #e4ebef;
  border-radius: 4px;
  background-color: #fff;
  font-size: 1.4rem;
  outline: none;
  background-position: 100%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAKxJREFUeNrslE0NgDAMRgcK8DADSAAJOEAaDpAAEjAwD0igkB4Ih9F+wIV8L2l66c/LtiwEQggh5FuKu4KUUiVplGgkZokuxri+3VcaZHsdFjRPuuROYrr09bmeEjjFOidzkqg9Qy0ig8RikclILDoHfyOGBe1+95aaxyIGmU4fJiThEgHu3yzhFnHIuCQgEYOMWwIWychAEug/cqDLWv01g2ZIghBCyK/ZBBgAS+tWiAnta3IAAAAASUVORK5CYII=);
  transition: 0.4s ease;
  display: block;
  min-height: 42px;
  width: auto;
  margin-right: 12px;
  min-width: 150px;
}

.button {
  padding: 0 36px;
  // style doesn't apply if targeting <Button .> classNames
  [data-th='Icon'] {
    margin-right: 4px;
  }
  > div > div {
    // style doesn't apply if targeting <Button .> classNames
    justify-content: center;
  }
}

.newButton {
  min-width: unset;
  width: auto;
  box-shadow: unset;
  padding-left: 30px;
  padding-right: 30px;
}

.headerSaveButton {
  padding: 0 36px;
  margin-left: 20px;
}

.headerButtonGroup {
  display: flex;
  justify-content: flex-end;
}

.disabledButtonWrapper {
  display: flex;
}

.hoverMessage {
  right: 0;
  background-color: #d4d4d4;
  color: white;
  display: flex;
  padding: 12px;
  border-radius: 8px;
}

.hoverMessageContainer {
  position: absolute;
  right: 0;
  display: flex;
  align-content: space-around;
  justify-content: flex-end;
  margin-top: 30px;
}
