.settingsAccountEditDetails {
  overflow: hidden;
  zoom: 1;
}

.settingsAccountEditDetails__inner {
  overflow: hidden;
  zoom: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-right: 20px;
}

.settingsAccountEditDetails__duplicateEmailError {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  width: 100%;
}

.settingsAccountEditDetails__section {
  width: 48%;

  @media (max-width: 980px) {
    width: 100%;
  }
}

.settingsAccountEditPermissions__section {
  position: relative;
  width: 100%;
}

.settingsAccountEditDetails__userGroup {
  margin: 0 0 40px 0;
}

.settingsAccountEditDetails__userGroup--radios {
  display: flex;
  flex-direction: row;
}

.settingsAccountEditDetails__userGroup--radio {
  display: flex;
  height: 5rem;
  border: solid black;
  border-radius: 5px;
  margin: 1rem;
  padding-left: 1rem;
}

.settingsAccountEditDetails__userGroupLabel {
  font-weight: 600;
  font-size: 15px;
  padding: 0 25px 0 0;
  margin: 0 0 10px 0;
}

.settingsAccountEditDetails__userGroupData {
  padding: 5px 0;
  font-size: 1.4rem;
}

.settingsAccountEditPermissions__header {
  font-size: 1.4rem;
  font-weight: 700;
}

.settingsAccountEditPermissions__desc {
  font-size: 1.4rem;
  margin: 10px 0;
}

.settingsAccountEditPermissions__btnExpand {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  .buttonWithIcon__icon {
    font-size: 20px;
    padding-top: 4px; // for some reason the icon is off-center
  }
}

.settingsAccountEditDetails__permissionsHeader {
  font-size: 1.4rem;
  font-weight: 700;
}

.settingsAccountEditDetails__passwordContainer__section {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
}

.settingsAccountEditDetails__passwordContainer {
  margin: 15px 0 0 0;
  @media (max-width: 750px) {
    margin: 20px 0 0 0;
  }
}

.settingsAccountEditDetails__buttonContainer {
  padding: 20px 0 0 0;
  border-top: solid #e4ebef 1px;
  margin: 20px 0 0 0;
  overflow: hidden;
}

.settingsAccountEditDetails__saveButton {
  float: right;
  min-width: 100px;
  display: inline-block;

  @media screen and (max-width: 500px) {
    width: 100%;
    margin: 10px 0 0 0;
  }
}

.settingsAccountEditDetails__removeButton {
  float: right;
  margin: 0 10px 0 0;
  min-width: 110px;

  @media screen and (max-width: 500px) {
    width: 100%;
    margin: 10px 0 0 0;
  }
}

.settingsAccountEditDetails__cancel {
  float: left;

  @media screen and (max-width: 500px) {
    width: 100%;
    margin: 10px 0 0 0;
  }
}

