.youtubeVideo {
  position: relative;
}

.youtubeVideo--error {
  border: solid #dfdfdf 1px;
  height: 206px;
  padding: 10px;
  background: #dedede;
}
