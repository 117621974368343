.helpVideoImageLink {
  cursor: pointer;
  margin: 10px;

  &:hover {
    color: #a0a0a0;
  }
}

.helpVideoImageLink__inner {
  width: 100%;
  height: 100%;
}

.helpVideoImageLink__imageContainer {
}

.helpVideoImageLink__text {
  font-size: 1.6rem;
  font-weight: bold;
  max-width: 200px;
}
