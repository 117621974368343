.manageUsersTableItem__row {
  font-size: 1.4rem;
  cursor: pointer;

  &:nth-child(even) {
    background-color: #f6f9fc;
  }

  &:hover {
    background-color: #f2f2f2;
  }
}

.manageUsersTableItem__all {
  padding: 20px 0 20px 10px;
  border-bottom: 1px solid rgb(232, 233, 233);
}
