.landingPage {
  padding: 20px;

  @media (max-width: 450px) {
    padding: 10px;
  }
}

.landingPage__tilesOuter {
  overflow: auto;
}

.landingPage__leadsFilter {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 20px 20px;

  @media (max-width: 1342px) {
    margin: 0 0px 20px;
  }
}

.landingPage__tilesAndHelpMessage {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap-reverse;
}

a {
  text-decoration: none;
  color: #055bd4;
}
