@import '../../styles/themes/themes.scss';

.closeDealOptionsButtonItem {
  width: 25%;
  display: inline-block;
  text-align: center;

  @media (max-width: 1100px) {
    width: 50%;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
}

.closeDealOptionsButtonItem__button {
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  outline: none;
  border: none;
}

.closeDealOptionsButtonItem__outer {
  background-color: transparent;
  padding: 0;
  border-radius: 8px;
  display: flex;
  box-shadow: 0px 0 0 rgba(188, 194, 204, 0);
  transform: translatey(0);
  transition: 0.2s all ease-out;
  align-items: center;
  width: 200px;
  height: 60px;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: start;

  @media (max-width: 1100px) {
    margin: 0 0 20px 0;
  }

  @media (max-width: 450px) {
    width: 100%;
  }

  border: solid #cccccc 1px;

  &:hover {
    transform: translatey(-2px);
    box-shadow: 0px 20px 30px rgba(188, 194, 204, 0.6);
  }
}

.closeDealOptionsButtonItem__outer--active {
  border-color: #6bc348;
  color: #6bc348;
  transform: translatey(-2px);
  box-shadow: 0px 20px 30px rgba(188, 194, 204, 0.6);

  & > .closeDealOptionsButtonItem__text {
    color: #6bc348;
  }
}

.closeDealOptionsButtonItem__text {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  color: #cccccc;
}
