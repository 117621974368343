.combinedQuotesModal__subHeader {
  border-top: 1px solid #e4ebef;
  padding: 20px 0 0 0;
}

.combinedQuotesModal__breakdown {
  border: 1px solid #e4ebef;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  padding: 0;
  border-collapse: collapse;
  @media (max-width: 480px) {
    overflow-x: auto;
    display: block;
  }
}

.combinedQuotesModal__breakdown--header {
  padding: 10px;
  background-color: #f8f9fc;
}

.combinedQuotesModal__breakdown--headerText {
  color: #9e9e9e;
  font-weight: 600;
  padding: 10px;
}

.combinedQuotesModal__breakdown--info {
  background: #ffffff;
  padding: 10px;
}

.combinedQuotesModal__proceedButton {
  width: 200px;
  margin: 30px auto 0 auto;
}

.combinedQuotesModal__breakdown--loanType {
  padding: 10px;
}

.combinedQuotesModal__breakdown--apr {
  padding: 0 10px 0 5px;
}

.combinedQuotesModal__breakdown--totalPayable {
  padding: 10px;
}

.combinedQuotesModal__breakdown--term {
  padding: 10px;
}

.combinedQuotesModal__breakdown--monthlyPayment {
  padding: 10px;
}
