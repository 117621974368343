.mobxButtonFieldGroup {
  margin: 0 0 10px 0;
  position: relative;
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;

  @media (max-width: 650px) {
    margin-bottom: 20px;
  }

  @media (max-width: 700px) {
    flex-wrap: wrap;
  }
}

.mobxButtonFieldGroup__label {
  padding: 0 25px 0 0;
  font-weight: 600;
  order: 0;
  flex: 1 0 20%;

  text-align: right;
  align-self: center;
  height: 100%;
  @media (max-width: 700px) {
    text-align: left;
    padding-left: 10px;
  }
}

.mobxButtonFieldGroup__formElements {
  width: 100%;
  order: 1;
  flex: 1 1 auto;
  padding: 5px 10px;
  max-width: 450px;

  @media (max-width: 700px) {
    max-width: 100%;
    padding: 0 10px;
    order: 2;
  }
}

.mobxButtonFieldGroup__input {
  width: 50%;
  display: inline-block;
}

.mobxButtonFieldGroup__divider {
  width: 7%;
  display: inline-block;
  text-align: center;
  font-size: 14px;
}

.mobxButtonFieldGroup__button {
  width: 43%;
  display: inline-block;
  vertical-align: top;
}

.mobxButtonFieldGroup__error {
  width: 100%;
  margin: 10px 0 0 0;
  position: relative;

  @media (max-width: 650px) {
    width: 100%;
    margin: 0;
  }
}

.mobxButtonFieldGroup__errorMessage {
  color: #e66c6c;
  font-weight: 600;
  position: relative;
  min-height: 25px;
  z-index: 1;
  order: 3;
  flex: 1 0 15%;
  font-size: 13px;
  align-self: center;
  height: 100%;
  padding: 2px 0 0 0;
  text-align: left;

  @media (max-width: 650px) {
    padding: 0;
    margin: 0;
  }

  @media (max-width: 700px) {
    padding: 4px 0 0 10px;
  }
}

.mobxButtonFieldGroup__icon {
  width: 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  right: 3px;
  top: 0;
  font-size: 20px;
  order: 2;
  position: relative;
  padding: 0;
  flex: 0 0 3%;
  align-self: center;
  height: 100%;

  @media (max-width: 700px) {
    padding-right: 25px;
    flex: 0 0 4%;
    order: 1;
  }
}

.mobxButtonFieldGroup__iconMedia {
  display: none;

  @media (max-width: 650px) {
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    right: 0;
    top: 0;
  }
}

.mobxButtonFieldGroup__cross {
  color: #e66c6c;
}

.mobxButtonFieldGroup__message {
  font-size: 13px;
  font-style: italic;
  text-align: left;
  padding: 0 0 10px 365px;
  transform: translateY(-20%);

  @media (max-width: 1400px) {
    padding-left: 10px;
    text-align: center;
  }

  @media (max-width: 700px) {
    padding: 0 0 0 10px;
    transform: translateY(-110%);
    text-align: left;
  }
}
